import { getCurrentCruisesFromStore } from "actions/cruisesActions";
import axios from "axios";
import { getOswUrl, getShipCodeByName } from "config/configUtils";
import { getCruisesInfo } from "utils/cruiseUtils";
import { store } from "WithProviders";

enum TreatmentsStatusesEnum {
  pending = "createAndPutOnHold",
  paid = "commit",
}

export const getTreatmentsBookedCardsList = async () => {
  const sessionId = store.getState().authentication.sessionId;
  const cruises = getCurrentCruisesFromStore();

  const cruisesInfo = getCruisesInfo(cruises.cruiseDetails);

  const URL = `${getOswUrl()}/api/ships/${getShipCodeByName({
    shipName: cruisesInfo?.shipName,
  })}/planner/booked-card`;

  const response = await axios
    .get(URL, {
      headers: {
        SessionId: sessionId,
      },
    })
    .catch((error) => {
      console.error("getTreatmentsBookedCardsList", error);
      return error;
    });

  if (response && response.stack && response.message) {
    return { oswPaidBookings: [], oswNonPaidBookings: [] };
  }

  const oswPaidBookings =
    response?.data.bookedSpaTreatmentDetailsDtos?.filter(
      (oswEntry: any) => oswEntry?.status === TreatmentsStatusesEnum.paid
    ) ?? [];

  const oswNonPaidBookings =
    response?.data.bookedSpaTreatmentDetailsDtos?.filter(
      (oswEntry: any) => oswEntry?.status === TreatmentsStatusesEnum.pending
    ) ?? [];

  const oswCartExpirationTimeInMinutes =
    response?.data.oswCartExpirationTimeInMinutes || 0;

  return {
    oswPaidBookings,
    oswNonPaidBookings,
    oswCartExpirationTimeInMinutes,
  };
};
