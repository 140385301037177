import { FC } from "react";
import CartElementOpenRefund from "../CartElementOpenRefund";

type Props = {
  entry: any;
  date: any;
};

const CartElementTacOpenRefund: FC<Props> = (props) => {
  const entry = props.entry;
  const nrGuests = entry.nrAdults + entry.nrChildren;

  return (
    <CartElementOpenRefund
      name={entry.name}
      imageReference={entry.serviceProfileId}
      linkTo={{ pathname: "/tac/restaurants", state: { date: props.date } }}
      tooltipId={entry.id}
    >
      <div>
        Table for {nrGuests} {nrGuests > 1 ? "people" : "person"}
      </div>
      <div className="mt-1">
        at <span className="font-bold">{entry.time}</span>
      </div>
    </CartElementOpenRefund>
  );
};

export default CartElementTacOpenRefund;
