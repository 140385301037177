import { useCallback, useEffect, useState } from "react";
import PaymentConfirmationOverview from "../../components/PaymentConfirmationOverview";
import BackLink from "../../components/molecules/BackLink";
import { orderCart } from "../../actions/cartActions";
import useAppSelector from "../../hooks/useAppSelector";
import { useGetConfiramtionCartOSW } from "./useGetConfiramtionCartOSW";
import { useGetConfiramtionCartMusement } from "./useGetConfiramtionCartMusement";
import { IPayment } from "interfaces/ReduxState/IPayment";
import { useGetConfiramtionCartMxp } from "./useGetConfiramtionCartMxp";

const PaymentConfirmationGPP = () => {
  const { getConfiramtionCartOSW } = useGetConfiramtionCartOSW();
  const { getConfiramtionCartMusement } = useGetConfiramtionCartMusement();
  const { getConfiramtionCartMxp } = useGetConfiramtionCartMxp();

  const payment = useAppSelector((state) => state.payment);

  const [orderError, setOrderError] = useState<boolean | null>(null);
  const [orderRequestResult, setOrderRequestResult] = useState<any>(null);

  const onOrderCompleted = useCallback((response: any) => {
    setOrderRequestResult(response);
    setOrderError(false);
  }, []);

  const onOrderError = useCallback((response: any) => {
    console.error("error while executing order, response: ", response);
    setOrderError(true);
    setOrderRequestResult(response);
  }, []);

  const _order = useCallback(async () => {
    let payload = {
      confirmationMail: payment.confirmationMail,
      paymentReference: payment.paymentReference,
      mobileNumber: payment.mobileNumber,
      transactionId: payment.transactionId,
    };

    orderCart(payload, onOrderCompleted, onOrderError, true);
  }, [
    onOrderCompleted,
    onOrderError,
    payment.confirmationMail,
    payment.mobileNumber,
    payment.paymentReference,
    payment.transactionId,
  ]);

  const _orderOSW = useCallback(
    async ({ transactionId }: { transactionId: IPayment["transactionId"] }) => {
      const { data, loading, error } = await getConfiramtionCartOSW({
        transactionId: transactionId,
      });

      if (!loading) {
        if (error) {
          onOrderError(error);
        } else {
          onOrderCompleted(data);
        }
      }
    },
    [getConfiramtionCartOSW, onOrderCompleted, onOrderError]
  );

  const _orderMusement = useCallback(
    async ({ transactionId }: { transactionId: IPayment["transactionId"] }) => {
      const { data, loading, error } = await getConfiramtionCartMusement({
        transactionId: transactionId,
      });

      if (!loading) {
        if (error) {
          onOrderError(error);
        } else {
          onOrderCompleted(data);
        }
      }
    },
    [getConfiramtionCartMusement, onOrderCompleted, onOrderError]
  );

  const _orderMxp = useCallback(
    async ({ transactionId }: { transactionId: IPayment["transactionId"] }) => {
      const { data, loading, error } = await getConfiramtionCartMxp({
        transactionId: transactionId,
      });

      if (!loading) {
        if (error) {
          onOrderError(error);
        } else {
          onOrderCompleted(data);
        }
      }
    },
    [getConfiramtionCartMxp, onOrderCompleted, onOrderError]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    if (payment?.paymentType === "osw") {
      _orderOSW({ transactionId: payment.transactionId });
    } else if (payment?.paymentType === "musement") {
      _orderMusement({ transactionId: payment.transactionId });
    } else if (payment?.paymentType === "mxp") {
      _orderMxp({ transactionId: payment.transactionId });
    } else {
      _order();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (orderError === null) {
    return (
      <div>
        <div className="container mx-auto mt-4 text-primary-dark">
          <div className="xl:inline xl:pr-2 text-4xl">
            Placing your order...
          </div>
        </div>
      </div>
    );
  }

  if (orderError) {
    // TODO - add the check for when booking fails because of sold out once hub returns that information
    return (
      <div>
        <div className="container mx-auto mt-4 text-blue-dark">
          <div>
            <BackLink to="/itinerary" text="Return to Cruise control Planner" />
          </div>
          <div className="text-xl mt-8">
            We’re sorry, something went wrong while placing your order. Please
            contact Customer Services
          </div>
          <div className="mt-2 mb-2">
            <a
              className="text-blue"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.tui.co.uk/destinations/contact-us"
            >
              https://www.tui.co.uk/destinations/contact-us
            </a>
          </div>
          <div className="mb-4"></div>
        </div>
      </div>
    );
  }

  return (
    <PaymentConfirmationOverview
      bookingNr={
        orderRequestResult?.groupReference ??
        (orderRequestResult?.bookingReference || "")
      }
    />
  );
};

export default PaymentConfirmationGPP;
