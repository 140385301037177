import axios from "axios";
import { getOswUrl, getShipCodeByName } from "config/configUtils";
import useAppSelector from "hooks/useAppSelector";
import { useCallback, useState } from "react";
import { getCruisesInfo } from "utils/cruiseUtils";

type Props = {
  date: string;
  treatmentId: string;
  treatmentSlotId: number;
};

export const useGetTimeSlotPrice = () => {
  const sessionId = useAppSelector((state) => state.authentication.sessionId);
  const cruiseDetails = useAppSelector((state) => state.cruises.cruiseDetails);
  const cruisesInfo = getCruisesInfo(cruiseDetails);

  const [data, setData] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const getTimeSlotPrice = useCallback(
    async ({ date, treatmentId, treatmentSlotId }: Props) => {
      setLoading(true);
      const URL = `${getOswUrl()}/api/ships/${getShipCodeByName({
        shipName: cruisesInfo.shipName,
      })}/prices/by-treatment/${treatmentId}/by-open-time-slot/${treatmentSlotId}/by-day/${date}`;

      const response = await axios
        .get(URL, {
          headers: {
            SessionId: sessionId,
          },
        })
        .catch((error) => {
          setError(error);
        });

      setData(response?.data);
      setLoading(false);
    },
    [cruisesInfo.shipName, sessionId]
  );

  return { getTimeSlotPrice, data, loading, error };
};
