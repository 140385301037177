import { useState, useEffect, RefObject, FC, createRef } from "react";
import { postToHub } from "../../actions/networkActions";
import useGet from "../../hooks/useGet";
import Checkbox from "../Checkbox";
import InfoBox from "../passengerInformation/InfoBox/InfoBox";
import browserHistory from "../../utils/history";
import Modal from "../modals/Modal/Modal";
import ActionButton from "../Buttons/ActionButton";
import { useSurveyContext } from "../../context/SurveyContext";
import { convertYMDtoDMY } from "../../utils/dateTimeUtils";

type Props = {
  token: any;
  replaceExisting: boolean;
  passengerId: string;
  cardInfo: any;
  backToReview: any;
  submit: () => void;
};

const CardRegistration: FC<Props> = ({
  token,
  replaceExisting,
  passengerId,
  cardInfo,
  backToReview,
  submit,
}) => {
  const [iframeUrl, setIframeUrl] = useState("");
  const [pageState, setPageState] = useState("loading");
  const [trigger, setTrigger] = useState(0);
  const [noCardReason, setNoCardReason] = useState("");

  useEffect(() => {
    const getUrl = async () => {
      let url = "pxp/redirecturl";

      if (token) {
        url += `/${token}`;
      }
      url += `?replaceExisting=${replaceExisting ? "true" : "false"}`;

      const { redirectUrl } = await postToHub(url, {
        passengerId: passengerId,
      });
      setPageState("ccentry");
      setIframeUrl(redirectUrl);
    };

    getUrl();
  }, [passengerId, replaceExisting, trigger]);

  return (
    <div>
      <div className="leading-middle text-lg text-grey-darker">
        {noCardReason === "" && (
          <InfoText cruiseEnd={convertYMDtoDMY(cardInfo.cardMaxValidityDate)} />
        )}
        {noCardReason === "notOwn" && <NotOwnText />}
        {noCardReason === "notWant" && <NotWantText />}
      </div>

      {noCardReason === "" && (
        <InfoBox
          classes="mt-6 md:inline-block"
          infoText="While you’re sailing, we’ll make a provisional transaction to your registered card to secure payment. Your final balance will be charged to your card at the end of your cruise. The provisional transactions will appear on your bank statement for up to 14 days, depending on your bank."
        />
      )}

      {pageState === "error" && (
        <div>
          <div className="mt-6 text-lg text-grey-darker">
            There has been an error with the Credit Card capture. Please check
            your credit card data and try again
          </div>
          <div className="mt-4">
            <button
              className="w-mobile action-button action-button-enabled"
              onClick={() => {
                setIframeUrl("");
                setTrigger(trigger + 1);
                setPageState("ccentry");
              }}
            >
              Try again
            </button>
          </div>
        </div>
      )}

      {pageState === "expireError" && (
        <div>
          <div className="mt-6 text-lg text-grey-darker">
            The card you have entered will expire by the end of your cruise.
            Please provide a card which will still be valid on the last day of
            your cruise.
          </div>
          <div className="mt-4">
            <button
              className="w-mobile action-button action-button-enabled"
              onClick={() => {
                setIframeUrl("");
                setTrigger(trigger + 1);
                setPageState("ccentry");
              }}
            >
              Try again
            </button>
          </div>
        </div>
      )}

      {pageState === "success" && (
        <CCRouting
          passengerId={passengerId}
          backToReview={backToReview}
          token={token}
        />
      )}

      {pageState === "ccentry" && iframeUrl && noCardReason === "" && (
        <CCForm
          url={iframeUrl}
          setPageState={setPageState}
          showIframe={noCardReason === ""}
          replaceExisting={replaceExisting}
          setNoCardReason={setNoCardReason}
        />
      )}

      {noCardReason !== "" && (
        <ActionButton
          onClick={submit}
          disabled={false}
          classes="mb-8 mt-4 xl:mb-12 w-mobile"
          buttonText="Next"
        />
      )}
    </div>
  );
};

export default CardRegistration;

type InfoTextProps = {
  cruiseEnd: string;
};

const InfoText: FC<InfoTextProps> = ({ cruiseEnd }) => {
  return (
    <>
      <p className="mb-2">
        All of our ships are cashless and you need to register a card before
        boarding.
      </p>
      <p className="mb-2">
        These will be stored securely, ready for your cruise, to ensure a
        seamless onboarding experience. You are also able to assign your card to
        others in your party, if you so wish.
      </p>
      <p className="mb-2">
        Please ensure the card you register doesn't expire before the end of
        your cruise, the last date of which is {cruiseEnd}
      </p>
    </>
  );
};

const NotOwnText = () => {
  return (
    <>
      <p>
        Thank you for letting us know you are unable to register a card as you
        don't own one.
      </p>
      <p>
        You can now proceed through the next screen and complete your check-in
        process.
      </p>
    </>
  );
};

const NotWantText = () => {
  return (
    <>
      <p>Thank you for letting us know you don't want to register a card.</p>
      <p>
        You can now proceed through the next screen and complete your check-in
        process.
      </p>
    </>
  );
};

type CCFormProps = {
  setPageState: (value: string) => void;
  setNoCardReason: (value: string) => void;
  showIframe: boolean;
  url: string;
  replaceExisting: boolean;
};

const CCForm: FC<CCFormProps> = ({
  setPageState,
  setNoCardReason,
  url,
  showIframe,
  replaceExisting,
}) => {
  const [iframeHeight, setIframeHeight] = useState(400);
  useEffect(() => {
    const handleMessage = (e: { data: number }) => {
      if (e.data) {
        setIframeHeight(e.data + 24);
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  const [showModal, setShowModal] = useState("");

  const { setAnswer } = useSurveyContext();
  const iframeRef: RefObject<any> = createRef();

  const onLoad = () => {
    // check for url, to decide if we need to show error/success UI
    try {
      const location = iframeRef.current.contentWindow.location;

      if (location.pathname === "/ccregistrationsuccess.html") {
        setPageState("success");
      }

      if (location.pathname === "/ccregistrationfailed.html") {
        const queryParams = new URL(location).searchParams;
        const errorCode = queryParams.get("code");

        if (errorCode === "cardwillexpire") {
          setPageState("expireError");
        } else {
          setPageState("error");
        }
      }
    } catch (e) {
      // no need to handle this as it means the location can not be accessed on external page
      console.error("not able to get location", e);
    }
  };

  const handleNoCard = (paxOwnsCard: any) => {
    const reason = paxOwnsCard ? "I don't want to give CC" : "I don't own a CC";

    setAnswer("cc-1", reason);
    setNoCardReason(paxOwnsCard ? "notWant" : "notOwn");
    setShowModal("");
  };

  return (
    <>
      {showIframe && (
        <iframe
          className="w-full border overflow-hidden mb-8"
          sandbox="allow-same-origin allow-popups allow-forms allow-scripts"
          style={{ height: `${iframeHeight}px` }}
          src={url}
          title="PXP CC capture"
          ref={iframeRef}
          onLoad={onLoad}
        />
      )}

      {!replaceExisting && (
        <div className="mt-8">
          <button
            onClick={() => {
              setShowModal("reasonSelectModal");
            }}
            className="mb-8 xl:mb-12 underline"
          >
            Are you unable to register a card?
          </button>

          {/* Modal for choosing i dont want to / i don't own a card*/}
          <ReasonSelectModal
            showModal={showModal}
            setShowModal={setShowModal}
            handleNoCard={handleNoCard}
          />

          {/* Modal for showing benefits of adding a card*/}
          <ConfirmNoCardModal
            showModal={showModal}
            setShowModal={setShowModal}
            handleNoCard={handleNoCard}
          />
        </div>
      )}
    </>
  );
};

type CCRoutingProps = {
  token: any;
  passengerId: any;
  backToReview: any;
};
const CCRouting: FC<CCRoutingProps> = ({
  token,
  backToReview,
  passengerId,
}) => {
  const statusUrl = token ? `passengers/all//${token}` : "passengers/all";
  const passengers = useGet(statusUrl, []) as any[];
  const [selectedPassengers, setSelectedPassengers] = useState<any>([]);

  const togglePassenger = (passengerId: any) => {
    setSelectedPassengers(
      selectedPassengers.includes(passengerId)
        ? selectedPassengers.filter(
            (selectedId: any) => selectedId !== passengerId
          )
        : [...selectedPassengers, passengerId]
    );
  };

  const onSubmit = async () => {
    if (selectedPassengers.length > 0) {
      const payload = {
        sourcePassengerId: passengerId,
        targetPassengerIds: selectedPassengers,
      };
      let postUrl = "pxp/assignccdetails";
      if (token) {
        postUrl += `/${token}`;
      }
      const result = await postToHub(postUrl, payload);
      console.info(result);
    } else {
      console.error("no pax selected, skiping the routing");
    }

    const url = token
      ? `/precruise/guest/${passengerId}/${
          backToReview ? "review" : "finish"
        }/${token}`
      : `/precruise/passenger/${passengerId}/${
          backToReview ? "review" : "finish"
        }`;
    browserHistory.push(url);
  };

  const sourcePassenger = passengers.find(
    (passenger) => passenger.id === passengerId
  );

  return (
    <div className="mt-6">
      <div className="text-lg text-grey-darker">
        Who do you want to assign the card to?
      </div>

      <div>
        {passengers.length > 0 && (
          <div className="mt-4">
            <Checkbox
              checked={true}
              value={passengerId}
              disabled={true}
              handleCheckboxChange={() => {}}
            >
              {sourcePassenger.firstName} {sourcePassenger.lastName}
            </Checkbox>
          </div>
        )}
        {passengers
          .filter((passenger) => passenger.id !== passengerId)
          .map((passenger) => (
            <div className="my-4" key={passenger.id}>
              <Checkbox
                checked={selectedPassengers.includes(passenger.id)}
                value={passenger.id}
                handleCheckboxChange={() => togglePassenger(passenger.id)}
              >
                {passenger.firstName} {passenger.lastName}
              </Checkbox>
            </div>
          ))}
      </div>
      <div>
        <button
          className="action-button action-button-enabled w-mobile"
          onClick={onSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

type ReasonSelectModalProps = {
  showModal: string;
  setShowModal: (value: string) => void;
  handleNoCard: (value: boolean) => void;
};

const ReasonSelectModal: FC<ReasonSelectModalProps> = ({
  showModal,
  setShowModal,
  handleNoCard,
}) => {
  return (
    <Modal
      show={showModal === "reasonSelectModal"}
      onClose={() => setShowModal("")}
      title="Unable to register a card"
    >
      <div className="p-4">
        <div className="text-blue-dark text-2xl">
          <p>Could you provide some more information?</p>
        </div>
        <div className="text-grey-darker text-lg">
          <p>
            You've selected that you're unable to register a card for us
            onboard.
          </p>
          <p>In order for us to help you, can you explain why that is?</p>
        </div>

        <div className="mt-8 md:flex md:justify-between">
          <ActionButton
            onClick={() => handleNoCard(false)}
            disabled={false}
            classes=""
            buttonText="I don't own a debit/credit card"
          />

          <ActionButton
            onClick={() => setShowModal("confirmNoCardModal")}
            disabled={false}
            classes="mt-12 md:mt-0"
            buttonText="I don't want to register a card"
          />
        </div>
      </div>
    </Modal>
  );
};

type ConfirmNoCardModalProps = ReasonSelectModalProps;

const ConfirmNoCardModal: FC<ConfirmNoCardModalProps> = ({
  showModal,
  setShowModal,
  handleNoCard,
}) => {
  return (
    <Modal
      show={showModal === "confirmNoCardModal"}
      onClose={() => setShowModal("")}
      title="Don't want to register a card"
    >
      <div className="p-4">
        <div className="text-blue-dark text-2xl">
          <p>Did you know...</p>
        </div>
        <div className="text-grey-darker">
          <div className="text-lg">
            We've implemented online check-in to make things easier for you.
          </div>
          <div className="mt-2">
            <ul className="list-disc list-inside">
              <li>
                Provide your details before sailing and benefit from speedy
                boarding
              </li>
              <li className="mt-2">
                Registering a card means you can simply join the ship with your
                boarding pass and start enjoying your cruise
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-6 md:flex md:justify-between">
          <ActionButton
            onClick={() => setShowModal("")}
            disabled={false}
            classes="mt-4 md:mt-0"
            buttonText="I want to register a card"
          />

          <ActionButton
            onClick={() => handleNoCard(true)}
            disabled={false}
            classes="mt-8 md:mt-0"
            buttonText="I don't want to register a card"
          />
        </div>
      </div>
    </Modal>
  );
};
