import { Route, Redirect, RouteProps } from "react-router-dom";
import useAppSelector from "../../hooks/useAppSelector";
import { FC } from "react";

type Props = RouteProps & {
  component: FC<any>;
};

const GuestRoute: FC<Props> = ({ component: Component, ...rest }) => {
  const isAuthenticated = useAppSelector(
    (state) => state.authentication.isAuthenticated
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Redirect to={{ pathname: "/dashboard" }} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default GuestRoute;
