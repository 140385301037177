import ItineraryEntry from "./ItineraryEntry";
import CruiseHeaderImage from "./CruiseHeaderImage";
import CruisesItineraryInfo from "./CruisesItineraryInfo";
import SvgIcon from "./atoms/SvgIcon";
import {
  collapseAll,
  expandAll,
  setScrollToItineraryEntry,
  toggleExpand,
} from "../actions/itineraryActions";
import useAppSelector from "../hooks/useAppSelector";
import {
  isGoLiveForMusementPerShip,
  isShipNameInOswMVP,
} from "config/configUtils";
import { getCruisesInfo } from "utils/cruiseUtils";
import { convertDMYtoYMD } from "utils/dateTimeUtils";
import { useCallback, useLayoutEffect } from "react";
import {
  IMusementCartEntry,
  IMxpCartEntry,
  IOswCartEntry,
} from "interfaces/ReduxState/ICart";

const ItineraryOverview = () => {
  const cruiseDetails = useAppSelector((state) => state.cruises.cruiseDetails);
  const cruisesInfo = getCruisesInfo(cruiseDetails);

  const itineraryHasItems = useAppSelector(
    (state) =>
      state.openRefunds.nrEntries > 0 ||
      state.bookings.nrExcursions > 0 ||
      state.cart.nrExcursions > 0
  );
  const bookings = useAppSelector((state) => state.bookings);
  const openRefunds = useAppSelector((state) => state.openRefunds);
  const cartExcursions = useAppSelector((state) =>
    state.cart.excursions ? state.cart.excursions : {}
  );

  const cartMxpDining = useAppSelector((state) =>
    state.cart.mxp ? state.cart.mxp : {}
  );

  const cartTac = useAppSelector((state) => state.cart.tac);
  const cartOsw = useAppSelector((state) => state.cart.osw);
  const cartMusement = useAppSelector((state) => state.cart.musement);
  const itinerary = useAppSelector((state) => state.itinerary);

  const getCartItemsCount = (
    cart: IMxpCartEntry | IOswCartEntry | IMusementCartEntry,
    itineraryDate: string,
    convertDateFn?: (date: string) => string
  ) => {
    const dateKey = convertDateFn
      ? convertDateFn(itineraryDate)
      : itineraryDate;
    return cart.hasOwnProperty(dateKey) ? Object.keys(cart[dateKey]).length : 0;
  };

  // Use reduce to sum the counts from all carts
  const getTotalCartItemsCount = useCallback(
    (itineraryDate: string) => {
      const carts = [
        { cart: cartOsw, convertFn: undefined },
        { cart: cartMusement, convertFn: undefined },
        { cart: cartMxpDining, convertFn: convertDMYtoYMD },
      ];

      return carts.reduce((count, { cart, convertFn }) => {
        return count + getCartItemsCount(cart, itineraryDate, convertFn);
      }, 0);
    },
    [cartMusement, cartMxpDining, cartOsw]
  );

  const countItineraryItems = (itineraryId: string, itineraryDate: string) => {
    let count = 0;
    if (bookings.excursions.hasOwnProperty(itineraryId)) {
      count += Object.keys(bookings.excursions[itineraryId]).length;
    }

    if (cartExcursions.hasOwnProperty(itineraryId)) {
      count += Object.keys(cartExcursions[itineraryId]).length;
    }

    if (openRefunds.excursions.hasOwnProperty(itineraryId)) {
      count += Object.keys(openRefunds.excursions[itineraryId]).length;
    }

    if (bookings.tac.hasOwnProperty(itineraryDate)) {
      count += Object.keys(bookings.tac[itineraryDate]).length;
    }

    if (cartTac.hasOwnProperty(itineraryDate)) {
      count += Object.keys(cartTac[itineraryDate]).length;
    }

    if (bookings.osw.hasOwnProperty(itineraryDate)) {
      count += Object.keys(bookings.osw[itineraryDate]).length;
    }

    if (openRefunds.tac.hasOwnProperty(itineraryDate)) {
      count += Object.keys(openRefunds.tac[itineraryDate]).length;
    }

    count += getTotalCartItemsCount(itineraryDate);

    if (bookings.musement.hasOwnProperty(itineraryDate)) {
      count += Object.keys(bookings.musement[itineraryDate]).length;
    }

    if (bookings.mxp.hasOwnProperty(convertDMYtoYMD(itineraryDate))) {
      count += Object.keys(bookings.mxp[convertDMYtoYMD(itineraryDate)]).length;
    }

    return count;
  };

  const lastDayIndex = itinerary.length - 1;
  const nrItems = itinerary.map((day: any) =>
    countItineraryItems(day.id, day.date)
  );

  const showExpandAll =
    itinerary.findIndex(
      (day: any, index: number) => day.expanded === false && nrItems[index] > 0
    ) > -1;

  const shouldShowOSW = isShipNameInOswMVP({ shipName: cruisesInfo.shipName });

  useLayoutEffect(() => {
    itinerary.forEach((itineraryElement) => {
      setScrollToItineraryEntry(itineraryElement.id, false);

      if (getTotalCartItemsCount(itineraryElement?.date) > 0) {
        toggleExpand(itineraryElement?.id, true);
      } else {
        toggleExpand(itineraryElement?.id, false);
      }
    });

    const firstFoundItineraryWithScrollTo = itinerary?.find(
      (itineraryElement) => itineraryElement?.expanded === true
    );

    if (firstFoundItineraryWithScrollTo) {
      setScrollToItineraryEntry(firstFoundItineraryWithScrollTo.id, true);
    }
  }, [getTotalCartItemsCount]);

  return (
    <div>
      <div className="my-4 md:flex">
        <div className="md:w-2/3 md:pr-2">
          <CruiseHeaderImage />
        </div>
        <div className="pt-4 md:pt-0 md:w-1/3 md:pl-2 xl:flex xl:flex-col">
          <CruisesItineraryInfo />
        </div>
      </div>
      <div className="text-blue-dark my-8">
        <div className="flex">
          <h2 className="flex-grow">Your itinerary</h2>
          {itineraryHasItems && (
            <button
              className="text-blue"
              onClick={showExpandAll ? expandAll : collapseAll}
            >
              {showExpandAll ? "Expand all" : "Collapse all"}
              <SvgIcon
                name={showExpandAll ? "chevron-down" : "chevron-up"}
                className="w-6 h-6 inline ml-2"
              />
            </button>
          )}
        </div>

        <div className="my-4 text-blue-dark">
          {itinerary.map((itineraryElement, index: number) => {
            const shouldShowMusementExcursions = isGoLiveForMusementPerShip({
              shipName: cruisesInfo.shipName,
              cruiseStart: itineraryElement.date,
            });

            return (
              <ItineraryEntry
                firstDay={index === 0}
                lastDay={index === lastDayIndex}
                key={itineraryElement.id}
                nrItems={nrItems[index]}
                shouldShowOSW={shouldShowOSW}
                shouldShowMusementExcursions={shouldShowMusementExcursions}
                scrollTo={itineraryElement?.scrollTo}
                type={itineraryElement?.type}
                id={itineraryElement?.id}
                numberOfBookableExcursions={
                  itineraryElement?.numberOfBookableExcursions
                }
                dayOfCruise={itineraryElement?.dayOfCruise}
                port={itineraryElement?.port}
                expanded={itineraryElement?.expanded}
                date={itineraryElement?.date}
                excursions={itineraryElement?.excursions}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ItineraryOverview;
