import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import * as JsBarcode from "jsbarcode";
import FoldLabelHorizontal from "./FoldLabelHorizontal";
import {
  convertYMDtoDMY,
  formatCruiseStartDate,
} from "../../utils/dateTimeUtils";
import { imagePath } from "../../utils/imageUtils";

// variables to tweak the final design
const verticalPagePadding = "14px";
const pagePartPadding = "14px 40px";

const BoardingPassPdf = (props) => {
  return (
    <Document title="Boarding pass">
      {props.passengers.map((passenger, index) => (
        <BoardingPassPage
          key={passenger.passengerId}
          passenger={passenger}
          bookingData={props.bookingData}
          passengerNr={index}
        />
      ))}
    </Document>
  );
};

export default BoardingPassPdf;

const BoardingPassPage = (props) => {
  return (
    <Page
      size="A4"
      style={{
        flexDirection: "column",
        backgroundColor: "white",
      }}
    >
      <Part1
        passenger={props.passenger}
        bookingData={props.bookingData}
        passengerNr={props.passengerNr}
      />

      <FoldLine />

      <Part2 />

      <FoldLine />

      <Part3 />
    </Page>
  );
};

const Part1 = (props) => {
  return (
    <PagePartContainer>
      <Text
        style={{
          textTransform: "uppercase",
          paddingVertical: "4px",
          color: "white",
          backgroundColor: "#092a5e",
          fontSize: "10.5px",
          fontWeight: "bold",
          textAlign: "center",
          marginTop: verticalPagePadding,
          marginBottom: 12,
        }}
      >
        Have your boarding pass and passport ready to show us when you're
        boarding the ship
      </Text>

      <BlueContainer>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <View style={{ flex: 1 }}>
            <BarCode number={props.passenger.boardingPassCode} />
          </View>

          <Text
            style={{
              flex: 1,
              textTransform: "uppercase",
              fontWeight: "bold",
              marginTop: "5px",
              fontSize: "18px",
              textAlign: "center",
              color: "#092a5e",
            }}
          >
            Boarding pass
          </Text>

          <View style={{ flex: 1 }}>
            <Text
              style={{
                paddingVertical: "5px",
                textAlign: "center",
                color: "white",
                backgroundColor: "#092a5e",
                textTransform: "uppercase",
                fontSize: "16px",
              }}
            >
              Marella Cruises
            </Text>
          </View>
        </View>

        <View
          style={{ display: "flex", flexDirection: "row", marginTop: "8px" }}
        >
          <View style={{ flex: 1 }}>
            <LabeledText label="Ship name" value={props.bookingData.shipName} />
            <LabeledText
              label="Passenger name"
              value={`${props.passenger.title} ${props.passenger.firstName} ${props.passenger.lastName}`}
            />
            <LabeledText
              label="Embarkation date"
              value={formatCruiseStartDate(
                convertYMDtoDMY(props.bookingData.embarkationDate)
              )}
            />
            <LabeledText
              label="Disembarkation date"
              value={formatCruiseStartDate(
                convertYMDtoDMY(props.bookingData.disembarkationDate)
              )}
              lastItem={true}
            />
          </View>

          <View style={{ flex: 1, marginLeft: "20px" }}>
            <LabeledText
              label="Cabin allocation and board"
              labelOwnLine={true}
              value={`Deck ${props.passenger.deckNumber}, cabin ${props.passenger.cabinNumber}`}
            />
          </View>
        </View>
      </BlueContainer>
    </PagePartContainer>
  );
};

const LabeledText = (props) => {
  const labelStyle = {
    color: "#092a5e",
    fontWeight: "bold",
    fontSize: "11px",
    marginRight: "3px",
    marginBottom: "2px",
    textTransform: "uppercase",
  };

  const valueStyle = {
    color: "#4c4c4c",
    fontSize: "11px",
  };

  const containerStyle = {
    marginBottom: props.lastItem ? "0" : "6px",
  };

  const ContainerComponent = props.labelOwnLine ? View : Text;

  return (
    <ContainerComponent style={containerStyle}>
      <Text style={labelStyle}>{props.label}: </Text>
      <Text style={valueStyle}>{props.value}</Text>
    </ContainerComponent>
  );
};

const Part2 = (props) => {
  return (
    <PagePartContainer>
      <BlueContainer>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <View style={{ flex: 1 }}>
            <TextWithTitle title="Navigate your way">
              <Text>
                Navigate is our onboard app that puts everything right at your
                fingertips. Want to check your onboard account, browse the
                restaurant menus or book a spa treatment?
              </Text>
              <Text style={{ marginTop: "2px" }}>
                If you're bringing a smartphone, laptop or tablet, you'll be
                able to use our app for free, you don't even need to pay for
                Wi-Fi.
              </Text>
            </TextWithTitle>

            <TextWithTitle title="Mymuster" style={{ marginTop: "14px" }}>
              <Text>
                Once you're onboard, you'll need to complete our safety drill
                before we set sail. We've made it as contact-free as possible -
                simply log in to Navigate, chose the MyMuster icon and watch the
                video.
              </Text>
              <Text style={{ marginTop: "2px" }}>
                All done? Head to your muster station to scan your cruise card.
                Mission complete.
              </Text>
            </TextWithTitle>
          </View>

          <View style={{ flex: 1, marginLeft: "20px" }}>
            <TextWithTitle title="Your cruise card">
              <Text>
                You'll find your cruise card hanging out on your cabin door
                handle. This is your cabin key, onboard account card and you'll
                need it to get on and off the ship.
              </Text>
              <Text style={{ marginTop: "2px" }}>
                Keep it with you at all times.
              </Text>
            </TextWithTitle>

            <TextWithTitle title="Check-in" style={{ marginTop: "14px" }}>
              <Text>
                When we check you in for your cruise, we’ll take your picture
                for security and scan this boarding pass and your passport.
              </Text>
              <Text style={{ marginTop: "2px" }}>
                Please have everything ready and make sure your luggage labels
                are attached to your bags.
              </Text>
            </TextWithTitle>
          </View>
        </View>
      </BlueContainer>
    </PagePartContainer>
  );
};

const TextWithTitle = (props) => {
  const textStyle = {
    color: "#4c4c4c",
    fontSize: props.textSize || "10.5px",
  };

  const titleStyle = {
    color: "#092a5e",
    fontSize: "11px",
    fontWeight: "bold",
    textTransform: "uppercase",
    marginBottom: "2px",
    ...props.style,
  };

  return (
    <>
      <Text style={titleStyle}>{props.title}</Text>
      <View style={textStyle}>{props.children}</View>
    </>
  );
};

const Part3 = (props) => {
  return (
    <PagePartContainer hideBorder={true}>
      <BlueContainer>
        <TextWithTitle title="Identification requirements" textSize="10px">
          <Text>
            HAVE YOUR BOARDING PASS AND PASSPORT READY TO SHOW US WHEN ARRIVING
            AT THE PORT AND BOARDING THE SHIP, plus any required visas. You’ll
            be refused travel if you do not present these.
          </Text>
        </TextWithTitle>

        <TextWithTitle
          title="Terms & conditions"
          style={{ marginTop: "14px" }}
          textSize="10px"
        >
          <Text>
            The below Conditions of Carriage will apply to your Marella Cruise
            booking and the Carrier will be TUI UK Limited. These conditions are
            binding whether you have read them or not:
          </Text>
          <Text style={{ fontWeight: "bold" }}>
            https://www.tui.co.uk/destinations/info/cruise-conditions-of-carriage
          </Text>
          <Text style={{ marginTop: "2px" }}>
            Your booking is also subject to our Booking Terms and Conditions,
            which you can find here:
          </Text>
          <Text style={{ fontWeight: "bold" }}>
            https://www.tui.co.uk/destinations/info/booking-terms-and-conditions.
          </Text>
        </TextWithTitle>
      </BlueContainer>

      <Footer />
    </PagePartContainer>
  );
};

const Footer = () => {
  const containerStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "12px",
    marginBottom: verticalPagePadding,
    backgroundColor: "#092a5e",
    fontSize: "12px",
    fontWeight: "bold",
    color: "white",
    paddingVertical: "4px",
  };

  const imageStyle = {
    width: "24px",
    height: "24px",
    marginRight: "5px",
  };

  const textMargin = {
    marginRight: "18px",
  };

  return (
    <View style={containerStyle}>
      <Image src={imagePath("hands_pdf.png")} style={imageStyle} />

      <Text style={textMargin}>HANDS</Text>

      <Image src={imagePath("face_pdf.png")} style={imageStyle} />

      <Text style={textMargin}>FACE</Text>

      <Image src={imagePath("space_pdf.png")} style={imageStyle} />

      <Text>SPACE</Text>
    </View>
  );
};

const blueBoxStyle = {
  flex: 1,
  backgroundColor: "#e2f3fe",
  padding: 10,
  display: "flex",
  color: "#4c4c4c",
  fontSize: "14px",
};

const PagePartContainer = (props) => {
  const containerStyle = {
    borderBottom: props.hideBorder ? "none" : "1px dashed #092a5e",
    flex: 1,
    padding: pagePartPadding,
  };

  return <View style={containerStyle}>{props.children}</View>;
};

const BlueContainer = (props) => {
  const whiteStyle = {
    backgroundColor: "white",
    padding: 13,
    flexGrow: 1,
    display: "flex",
  };

  return (
    <View style={blueBoxStyle}>
      <View style={whiteStyle}>
        <View style={{ display: "flex", flexGrow: 1 }}>{props.children}</View>
      </View>
    </View>
  );
};

const FoldLine = () => (
  <FoldLabelHorizontal
    offsets={[
      { side: "left", value: "6%" },
      { side: "right", value: "6%" },
    ]}
  />
);

const BarCode = (props) => {
  const canvas = document.createElement("canvas");
  JsBarcode(canvas, props.number);
  const barcode = canvas.toDataURL();

  return <Image src={barcode} style={{ width: "100%", height: "60px" }} />;
};
