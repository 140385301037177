import useAppSelector from "hooks/useAppSelector";
import OpenRefund from "../../OpenRefund";

const OpenRefundsList = () => {
  const openRefunds = useAppSelector((state) => state.openRefunds.bookingParts);

  if (!openRefunds || openRefunds.length === 0) {
    return null;
  }

  return (
    <div>
      <h2>Open refunds</h2>

      {openRefunds.map((openRefund: any) => (
        <OpenRefund
          key={openRefund.id}
          refundId={openRefund.id}
          groupReference={openRefund.groupReference}
          total={openRefund.total}
          paymentReference={openRefund.paymentReference}
          details={openRefund.details}
        />
      ))}
    </div>
  );
};

export default OpenRefundsList;
