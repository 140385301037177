import { IAgencyAuthentication } from "interfaces/ReduxState/IAgencyAuthentication";
import { AuthenticationAction } from "../actions/authenticationActions";

const INITIAL_STATE: IAgencyAuthentication = {
  sessionId: null,
  isAuthenticated: false,
};

const authenticationAgencyReducers = (
  state = INITIAL_STATE,
  action: AuthenticationAction
) => {
  switch (action.type) {
    case "CLEAR_AGENCY_AUTHENTICATION":
      return {
        isAuthenticated: false,
        sessionId: null,
      };

    case "SET_AGENCY_AUTHENTICATION":
      return action.authentication;

    default:
      return state;
  }
};

export default authenticationAgencyReducers;
