import React from "react";
import useAppSelector from "hooks/useAppSelector";
import CartElementElementItineraryMxpDining from "../CartElementElementItineraryMxpDining";
import CartElementPaidActivityMxp from "modules/mxp/dining/cartElements/CartElementPaidActivityMxp";
import { priceWithTwoDecimals } from "utils/cartUtils";

type Props = {
  date: string;
};

const CartElementsMxpDining: React.FC<Props> = ({ date }) => {
  const allCartMxpDiningInCart = useAppSelector((state) => state.cart.mxp);

  const allBookedActivities = useAppSelector((state) => state.bookings.mxp);

  return (
    <div className="w-full my-2 text-grey-darker">
      {allCartMxpDiningInCart[date]?.map((dining) => (
        <CartElementElementItineraryMxpDining
          key={dining.VacancyId}
          title={dining.VenueName}
          image={dining.restaurantImageUrl}
          vacancyId={dining.VacancyId}
          scheduledTime={dining.AvailableTime}
          nrOfPeople={dining.numberOfPersons}
          linkTo={`/mxp/restaurants/${date}`}
        />
      ))}

      {allBookedActivities[date]?.map((dining) => {
        return (
          <CartElementPaidActivityMxp
            key={dining.VacancyId}
            title={dining.VenueName}
            image={dining.restaurantImageUrl}
            vacancyId={dining.VacancyId}
            totalPrice={dining.totalAmount}
            tooltipText={`Price paid for this booking £${priceWithTwoDecimals(
              dining.totalAmount?.toString()
            )}`}
            tooltipId={dining.VacancyId}
            isBookedByAgency={dining.agency}
            transactionId={dining.transactionId}
            linkTo={`/mxp/restaurants/${date}`}
            availableTime={dining.AvailableTime}
            nrOfPeople={dining.numberOfPersons}
          >
            <div className="my-2">
              <div className="mb-1">
                Booking for {dining.numberOfPersons}{" "}
                {dining.numberOfPersons > 1 ? "people" : "person"}
              </div>
              <div>
                at <span className="font-bold">{dining.AvailableTime}</span>
              </div>
            </div>
          </CartElementPaidActivityMxp>
        );
      })}
    </div>
  );
};

export default CartElementsMxpDining;
