import { useState } from "react";
import TextInputWithLabel from "../../components/TextInputWithLabel";
import { postToHub } from "../../actions/networkActions";
import browserHistory from "../../utils/history";
import PinInput from "../../components/passengerInformation/PinInput";
import { isPinValid } from "../../utils/miscUtils";
import FormLockBase from "../../components/passengerInformation/FormLockBase";
import useSubmitNoCC from "../../hooks/useSubmitNoCC";
import { useRouteMatch } from "react-router-dom";

interface IMatchState {
  passengerId: string;
}

const PassengerLockForm = () => {
  const match = useRouteMatch<IMatchState>();

  const [lock, setLock] = useState(false);
  const [pin, setPin] = useState(["", "", "", "", "", ""]);
  const [confirmPin, setConfirmPin] = useState(["", "", "", "", "", ""]);
  const [memorableWord, setMemorableWord] = useState("");
  const [error, setError] = useState("");
  const [submitInProgress, setSubmitInProgress] = useState(false);

  const { loading, ccError } = useSubmitNoCC(match.params.passengerId);

  const onSubmit = async () => {
    if (lock) {
      if (!validate()) {
        setError(
          "Please make sure to enter valid PINS that match and provide a valid memorable word."
        );
        return;
      }

      const passengerId = match.params.passengerId;
      const data = {
        passengerId,
        pin: pin.join(""),
        confirmPin: confirmPin.join(""),
        memorableWord,
      };

      if (submitInProgress) {
        return;
      }

      setSubmitInProgress(true);
      const result = await postToHub("precruise/passenger/submitPin", data);
      setSubmitInProgress(false);

      if (result === "") {
        browserHistory.push("/precruise/passengers");
      } else {
        setError(
          "There has been an error with setting your PIN. Please try again."
        );
      }
    } else {
      browserHistory.push("/precruise/passengers");
    }
  };

  const validate = () => {
    if (lock === null) {
      return false;
    }

    if (lock === false) {
      return true;
    }

    return (
      isPinValid(pin) &&
      pin.join("") === confirmPin.join("") &&
      memorableWord.length > 5
    );
  };

  const isFormValid = validate();

  if (loading) {
    return (
      <div className="container mx-auto">
        <div className="mt-4">Loading...</div>
      </div>
    );
  }

  if (ccError) {
    return (
      <div className="container mx-auto">
        <div className="mt-4">{ccError}</div>
      </div>
    );
  }

  return (
    <div className="container mx-auto">
      <FormLockBase lock={lock} setLock={setLock} />

      {lock && (
        <div className="mt-4 border bg-grey-lightest-2 p-4">
          <div>
            <div className="uppercase">Set your PIN</div>

            <PinInput
              autoFocus={true}
              pin={pin}
              setPin={setPin}
              submit={onSubmit}
              idPrefix="pin"
            />
          </div>

          <div className="my-4">
            <div className="uppercase">Confirm your PIN</div>

            <PinInput
              pin={confirmPin}
              setPin={setConfirmPin}
              submit={onSubmit}
              idPrefix="confirm-pin"
            />
          </div>

          <div className="my-4">
            <TextInputWithLabel
              label="Memorable word"
              subLabel="This will be used if you forget your PIN and need to reset it"
              type="text"
              value={memorableWord}
              _onChange={(val: any) => setMemorableWord(val)}
              errorMessage={""}
            />
          </div>
        </div>
      )}

      {error && <div className="mt-4 text-red-tui">{error}</div>}

      <div className="mt-6 mb-8 xl:mb-12">
        <button
          className={`w-mobile action-button ${
            isFormValid && !submitInProgress
              ? "action-button-enabled"
              : "action-button-disabled"
          }`}
          disabled={!isFormValid}
          onClick={onSubmit}
        >
          Submit and back to dashboard
        </button>
      </div>
    </div>
  );
};

export default PassengerLockForm;
