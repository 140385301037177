import { updateCart, updateCartExpirationTime } from "actions/cartActions";
import ModalFooter from "components/modals/ModalFooter";
import NotificationModal from "components/modals/NotificationModal";
import useAppSelector from "hooks/useAppSelector";
import { useEffect, useState } from "react";
import browserHistory from "utils/history";

const countdown = (
  durationInSeconds: number,
  updateTimer: (time: number) => void
) => {
  let seconds = durationInSeconds;

  const timer = setInterval(() => {
    if (seconds > 0) {
      seconds--;

      updateTimer(seconds);
    } else {
      clearInterval(timer);
    }
  }, 1000);

  return timer;
};

const updateCartExpiration = (newTimeLeft: number) => {
  // Update Redux every minute
  if (newTimeLeft % 60 === 0) {
    updateCartExpirationTime(newTimeLeft / 60);
  }
};

const CartExpirationModal = () => {
  const cartExpirationTime = useAppSelector(
    (state) => state.cart.cartExpirationTime
  );

  const [timeLeft, setTimeLeft] = useState<number>(cartExpirationTime * 60);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | undefined>();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (timeLeft === 0 && cartExpirationTime > 0) {
      setShowModal(true);
    }

    updateCartExpiration(timeLeft);
  }, [timeLeft]);

  const updateTimer = (newTimeLeft: number) => {
    setTimeLeft(newTimeLeft);
  };

  useEffect(() => {
    setTimeLeft(cartExpirationTime * 60);

    if (cartExpirationTime > 0) {
      const id = countdown(cartExpirationTime * 60, updateTimer);

      setTimerId(id);
      setShowModal(false);
    }

    if (timerId) {
      clearInterval(timerId);
    }
  }, [cartExpirationTime]);

  const handleCloseModal = () => {
    updateCart();
    setShowModal(false);
  };

  const handleActionButton = () => {
    updateCart();
    setShowModal(false);
    browserHistory.push("/itinerary");
  };

  return (
    <NotificationModal
      show={showModal}
      onClose={handleCloseModal}
      title="Your Cart has expired"
      message="Sorry, your cart has expired. To continue booking, please check availability and select your items again."
      footer={
        <ModalFooter
          actionDisabled={false}
          actionButtonText="Go to planner"
          onActionClick={handleActionButton}
        />
      }
    />
  );
};

export default CartExpirationModal;
