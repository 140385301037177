import { combineReducers } from "redux";

import authenticationAgencyReducers from "./authenticationAgencyReducers";
import authenticationReducers from "./authenticationReducers";
import cartReducers from "./cartReducers";
import cruiseReducers from "./cruisesReducers";
import bookingsReducers from "./bookingsReducers";
import paymentReducers from "./paymentReducers";
import storeReducers from "./storeReducers";
import openRefundsReducers from "./openRefundsReducers";
import itineraryReducers from "./itineraryReducers";
import locationReducers from "./locationReducers";
import appConfigReducers from "./appConfigReducers";
import preCruiseStatusReducer from "./preCruiseStatusReducer";

const allReducers = combineReducers({
  agency: authenticationAgencyReducers,
  authentication: authenticationReducers,
  preCruiseStatus: preCruiseStatusReducer,
  bookings: bookingsReducers,
  cart: cartReducers,
  cruises: cruiseReducers,
  itinerary: itineraryReducers,
  location: locationReducers,
  openRefunds: openRefundsReducers,
  payment: paymentReducers,
  store: storeReducers,
  appConfig: appConfigReducers,
});

export default allReducers;
