import { useUpdateCart } from "actions/useUpdateCart";
import axios from "axios";
import { getPrebookUrl } from "config/configUtils";
import useAppSelector from "hooks/useAppSelector";
import { IPassenger } from "interfaces/Common/IPassenger";
import { useCallback, useState } from "react";

type Props = {
  productsToBeAdded: {
    quantity: number;
    type: string;
    product_identifier: string;
    itineraryId: string;
    persons: IPassenger;
    itineraryDate: string;
  }[];
};

export const usePostAddActivityToPlanner = () => {
  const sessionId = useAppSelector((state) => state.authentication.sessionId);
  const { updateCart } = useUpdateCart();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const postAddActivityToPlanner = useCallback(
    async ({ productsToBeAdded }: Props) => {
      setLoading(true);
      const URL = `${getPrebookUrl()}/api/carts/items`;

      const response = await axios
        .post(URL, productsToBeAdded, {
          headers: {
            SessionId: sessionId,
          },
        })
        .catch((error) => {
          setError(error);
        });

      setData(response?.data);
      await updateCart();

      setLoading(false);
    },
    [sessionId, updateCart]
  );

  return { postAddActivityToPlanner, data, loading, error };
};
