import { Link } from "react-router-dom";
import BackLink from "../../components/molecules/BackLink";
import InfoBox from "../../components/passengerInformation/InfoBox/InfoBox";
import { imagePath } from "../../utils/imageUtils";
import useTravelDocs from "../../hooks/useTravelDocs";
import DownloadButton from "../../components/pdf/DownloadButton";

const BoardingPass = () => {
  const { passengers, bookingData } = useTravelDocs();

  if (bookingData.cabinAllocated === null) {
    return (
      <div className="container mx-auto mt-4">
        <p>loading...</p>
      </div>
    );
  }

  if (bookingData.cabinAllocated === false) {
    return (
      <div className="container mx-auto mt-4">
        <div className="mb-4">
          <BackLink to="/" text="Back to Cruise Control Dashboard" />
        </div>
        <p>Thank you for preparing to print your boarding pass.</p>
        <p>
          If you can’t see your boarding pass or your cabin number doesn’t look
          right, don’t worry. Check in as normal at your UK airport and we’ll
          take care of all the details when you get to the port terminal.
        </p>
        <p>Sea you soon.</p>
      </div>
    );
  }

  // temporary deprecated due to disabled COVID-19 questionnaire
  /*  if (bookingData.boardingPassAccessAllowed === false) {
      return (
        <div className="container mx-auto mt-4">
          <p>
            Please make sure to fill out pre travel questionnaire and covid survey
            before accessing the boarding passes
          </p>
        </div>
      );
    }*/

  if (bookingData.error) {
    return (
      <div className="container mx-auto mt-4">
        <p>There has been an error, please try again later.</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto mt-4">
      <BackLink to="/" text="Back to Cruise Control Dashboard" />

      <div className="md:flex">
        <div className="w-full md:w-2/3 md:pr-4">
          <h1>Boarding pass</h1>
          <InfoBox
            classes="mt-6 md:inline-block"
            infoText="You need to show this in order to board the ship."
          />
          <div className="md:hidden">
            <img
              className="w-full mt-6"
              src={imagePath("boarding_pass_circle.svg")}
              alt=""
            />
          </div>

          <div className="mt-4">
            <p className="text-lg text-grey-darker">
              Please use the button below to download your boarding pass. Please
              print a copy and bring it with you when you travel.
            </p>
          </div>

          <div className="mt-6">
            <DownloadButton
              type="boarding"
              bookingData={bookingData}
              passengers={passengers}
              label="Download boarding pass"
            />
          </div>

          <div className="mt-4 mb-12">
            <Link
              className="hidden xl:block action-button action-button-enabled w-mobile"
              to={{
                pathname: "/boarding/detail",
                state: {
                  passengers,
                  bookingData,
                },
              }}
            >
              View boarding pass
            </Link>
          </div>
        </div>
        <div className="hidden md:flex md:w-1/3 items-center pl-4">
          <img
            className="w-full"
            src={imagePath("boarding_pass_circle.svg")}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default BoardingPass;
