import { useEffect, useState } from "react";
import Slider from "react-slick";
import RecommendationEntry from "./RecommendationEntry";
import { getFromHub } from "../actions/networkActions";
import { FC } from "react";

// css styles for the carousel
import "../resources/css/carousel.css";

type Props = {
  path: string;
  title: string;
};

const RecommendationSlider: FC<Props> = ({ path, title }) => {
  const [recommendations, setRecommendations] = useState([]);

  const showFullSlider = recommendations.length >= 4;

  const sliderSettings = {
    dots: true,
    infinite: showFullSlider,
    speed: 500,
    autoplay: showFullSlider,
    autoplaySpeed: 8000,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
      {
        breakpoint: 1080,
        settings: { slidesToShow: 2, slidesToScroll: 2 },
      },
      {
        breakpoint: 1150,
        settings: { slidesToShow: 3, slidesToScroll: 3 },
      },
    ],
  };

  const updateRecommendations = (recommendations: any) => {
    setRecommendations(recommendations);
  };

  useEffect(() => {
    getFromHub(path, updateRecommendations);
  }, [path]);

  //molecules/If there are no recommendations, just hide the whole section
  if (recommendations.length < 1) {
    return null;
  }

  return (
    <div className="bg-blue-lightest print-hidden">
      <div className="container mx-auto">
        <div className="text-blue-dark uppercase">
          <h2 className="py-4">{title}</h2>
        </div>
        <div className="max-w-full">
          <Slider {...sliderSettings}>
            {recommendations.map((recommendation, index) => (
              <RecommendationEntry
                key={index}
                recommendation={recommendation}
              />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default RecommendationSlider;
