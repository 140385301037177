import { IExcursionPassenger } from "interfaces/Common/IPassenger";
import { IMusementActivitiesCartType } from "interfaces/Musement/IExcursionCartType";

export const getMusementItemUuidsByPersonType = ({
  musementEntries,
  personType,
}: {
  musementEntries: IMusementActivitiesCartType[];
  personType: "Adult" | "Child" | "Baby" | "All";
}) => {
  if (personType === "All") {
    const itemUuids = musementEntries?.map(
      (musementEntry: IMusementActivitiesCartType) => musementEntry.itemUuid
    );

    return itemUuids;
  }

  const itemUuids = musementEntries
    .filter((musementEntry: IMusementActivitiesCartType) => {
      const childItem = musementEntry.persons?.find(
        (person: IExcursionPassenger) => person.type === personType
      );

      return childItem; // Keep the entry if there is an adult item
    })
    .map(
      (musementEntry: IMusementActivitiesCartType) => musementEntry.itemUuid
    );

  return itemUuids;
};
