import { FC } from "react";
import { formatTimeHM } from "utils/dateTimeUtils";

type AvailabilityOptionProps = {
  availability: any;
  onClick: () => void;
};

const AvailabilityOption: FC<AvailabilityOptionProps> = ({
  availability,
  onClick,
}) => {
  let classes =
    "relative p-4 border shadow flex md:flex-col border-blue cursor-pointer select-none";

  if (availability.selected) {
    classes += " bg-blue text-white";
  } else {
    classes += " text-grey-darker hover:bg-blue-lightest";
  }

  return (
    <div className="md:px-2 pb-4  md:w-1/4 text-center">
      <div className={classes} onClick={onClick}>
        {availability.selected && (
          <div className="diagonal-blue w-6 h-6 absolute top-0 right-0 text-white text-xs text-right">
            &#x2714;
          </div>
        )}

        <div
          className={`font-bold mr-2 ${
            availability.selected ? "" : "text-blue"
          }`}
        >
          {formatTimeHM(availability.fromTime, "Couldn't get time")}
        </div>
        <div>{`Adult £${availability.adultPrice}`}</div>

        <div className="md:hidden mr-1">,</div>
        <div>
          {"Child " +
            (availability.maximumNumberOfChildren > 0
              ? `£${availability.childrenPrice}`
              : " N/A ")}
        </div>
      </div>
    </div>
  );
};

export default AvailabilityOption;
