import { FC } from "react";
import useAppSelector from "../hooks/useAppSelector";
import { IPassenger } from "interfaces/Common/IPassenger";

type Props = {
  itineraryId: string;
  excursionId: string;
};

const BookedPassengersList: FC<Props> = ({ excursionId, itineraryId }) => {
  const bookings = useAppSelector((state) => state.bookings.excursions);

  if (!bookings[itineraryId] || !bookings[itineraryId][excursionId]) {
    return null;
  }

  const passengers = bookings[itineraryId][excursionId].passengers;

  return (
    <>
      <div className="bg-white py-4 px-2 sm:px-4">
        <div className="font-bold">Currently booked and paid passengers:</div>
        <ul className="list-reset">
          {passengers.map((passenger: IPassenger, index: number) => (
            <li
              key={index}
            >{`${passenger.firstName} ${passenger.lastName}`}</li>
          ))}
        </ul>
      </div>
    </>
  );
};
export default BookedPassengersList;
