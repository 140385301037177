import { FC } from "react";
import browserHistory from "utils/history";
import Modal from "../Modal/Modal";
import { imagePath } from "utils/imageUtils";
import ModalFooter from "components/modals/ModalFooter";
import { buildTestId } from "utils/buildTestId";

export type Props = {
  show: boolean;
  title: string;
  message: string;
  onClose: () => void;
};

const SuccessModal: FC<Props> = ({ show, title, message, onClose }) => {
  return (
    <Modal show={show} onClose={onClose} title={title}>
      <div className="flex flex-grow flex-col">
        <div className="flex flex-grow flex-col justify-center">
          <div
            data-testid={buildTestId("img", "success modal img")}
            className="text-center mt-8 mb-3"
          >
            <img
              className="align-middle w-12 h-12 inline-block"
              alt="success tick"
              src={imagePath("success-tick-green.svg")}
            />
          </div>
          <div className="text-blue-dark text-2xl text-center">
            <p data-testid={buildTestId("text", "success modal message")}>
              {message}
            </p>
          </div>
        </div>
        <div data-testid={buildTestId("container", "success modal footer")}>
          <ModalFooter
            actionDisabled={false}
            actionButtonText="Go to planner"
            onActionClick={() => {
              browserHistory.push("/itinerary");
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SuccessModal;
