import { useState, useEffect, useRef } from "react";
import { usePDF } from "@react-pdf/renderer";
import LuggageTagPdf from "./LuggageTagPdf";
import BoardingPassPdf from "./BoardingPassPdf";

const DownloadButton = (props) => {
  const [generateFile, setGenerateFile] = useState(false);

  if (generateFile) {
    return <FileComponent {...props} />;
  }

  return (
    <button
      className="block action-button action-button-enabled w-mobile"
      onClick={() => setGenerateFile(true)}
    >
      {props.label}
    </button>
  );
};

export default DownloadButton;

const FileComponent = (props) => {
  let document = null;
  switch (props.type) {
    case "luggage":
      document = (
        <LuggageTagPdf
          bookingData={props.bookingData}
          passengers={props.passengers}
        />
      );
      break;
    case "boarding":
      document = (
        <BoardingPassPdf
          bookingData={props.bookingData}
          passengers={props.passengers}
        />
      );
      break;
  }

  const [instance, updateInstance] = usePDF({ document });

  if (instance.error) {
    return <div>There was an error: {instance.error}, please try again.</div>;
  }

  if (instance.loading) {
    return <div>Generating PDF...</div>;
  }

  if (instance.url) {
    return <AutoClickLink url={instance.url} label={props.label} />;
  }

  return null;
};

const AutoClickLink = (props) => {
  const linkRef = useRef(null);

  useEffect(() => {
    linkRef.current.click();
  }, []);

  return (
    <a
      className="block action-button action-button-enabled w-mobile"
      ref={linkRef}
      href={props.url}
      target="_blank"
      rel="noopener noreferrer"
    >
      Pdf ready - click to download
    </a>
  );
};
