import axios from "axios";
import { getPrebookUrl } from "config/configUtils";
import useAppSelector from "hooks/useAppSelector";
import { useCallback, useState } from "react";

type Props = {
  tenantReference: string;
  emailAddress: string;
};

export const usePostInitPaymentMusement = () => {
  const userAuthentication = useAppSelector((state) => state.authentication);

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const postInitPaymentMusement = useCallback(
    async ({ tenantReference, emailAddress }: Props) => {
      setLoading(true);
      let initialError = null;
      const URL = `${getPrebookUrl()}/api/payments/opp/init`;

      const response = await axios
        .post(
          URL,
          {
            tenantReference,
            emailAddress,
            sessionId: userAuthentication.sessionId,
            sessionType: userAuthentication.sessionType,
            sessionName: userAuthentication.sessionName,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              SessionId: userAuthentication.sessionId,
            },
          }
        )
        .catch((error) => {
          setError(error);
          initialError = error;
        });

      setData(response?.data);
      setLoading(false);

      return { responseData: response?.data, error: initialError };
    },
    [
      userAuthentication.sessionId,
      userAuthentication.sessionName,
      userAuthentication.sessionType,
    ]
  );

  return { postInitPaymentMusement, data, loading, error };
};
