import { FC, PropsWithChildren, useCallback, useEffect, useState } from "react";
import ExcursionEntry from "./ExcursionEntry";
import RadioButton from "components/Buttons/RadioButton/RadioButton";
import Checkbox from "components/Checkbox";
import SvgIcon from "components/atoms/SvgIcon";

type FilteredExcursionsProps = {
  excursions: any;
  excrusionFilterOptions: any;
};

type ExcursionFilterProps = PropsWithChildren & {
  filterBy: string;
  closeFilter: () => void;
  clearFilter: () => void;
};

type FilterHeaderProps = {
  openFilter: string;
  filterId: string;
  toggleVisibility: (filterId: string) => void;
  filterName: string;
};

type ExcursionDurationFilterProps = {
  options: any;
  selectedRadio: any;
  handleRadioChange: (option: any) => void;
};

type ExcursionTypeFilterProps = {
  excursionTypes: any;
  handleCheckboxChange: (type: any) => void;
};

const FilteredExcursions: FC<FilteredExcursionsProps> = ({
  excursions,
  excrusionFilterOptions,
}) => {
  const [openFilter, setOpenFilter] = useState("");
  const [filteredExcursions, setFilteredExcursions] = useState(excursions);
  const [typeFilter, setTypeFilter] = useState({
    options: excrusionFilterOptions,
  });

  const [durationFilter, setDurationFilter] = useState({
    options: [
      {
        label: "all",
        minDuration: 0,
        maxDuration: 1440,
        disabled: false,
      },
      {
        label: "2h or less",
        minDuration: 0,
        maxDuration: 120,
        disabled: false,
      },
      {
        label: "2h to 5h",
        minDuration: 120,
        maxDuration: 300,
        disabled: false,
      },
      {
        label: "5h or more",
        minDuration: 300,
        maxDuration: 1440,
        disabled: false,
      },
    ],
    selectedOption: "all",
    minDuration: 0,
    maxDuration: 1440,
  });

  const toggleFilterVisibility = useCallback(
    (filterName: string) => {
      if (filterName === openFilter) {
        setOpenFilter("");
      } else {
        setOpenFilter(filterName);
      }
    },
    [openFilter]
  );

  const matchesTypeCriteria = useCallback(
    (excursion: any) => {
      let showExcursion = true;

      for (const type in typeFilter.options) {
        if (
          typeFilter.options[type].selected &&
          excursion.excursionTypes.indexOf(type) < 0
        ) {
          showExcursion = false;
          break;
        }
      }

      return showExcursion;
    },
    [typeFilter.options]
  );

  const matchesDurationCriteria = useCallback(
    (excursion: any) => {
      //check if excursion duration is between minDuration and maxDuration
      return !(
        excursion.duration > durationFilter.maxDuration ||
        excursion.duration < durationFilter.minDuration
      );
    },
    [durationFilter.maxDuration, durationFilter.minDuration]
  );

  const toggleTypeFilterOption = useCallback(
    (type: any) => {
      let typeFilterOptions = typeFilter.options;
      typeFilterOptions[type].selected = !typeFilterOptions[type].selected;
      setTypeFilter((prevState) => ({
        ...prevState,
        options: { ...typeFilterOptions },
      }));
    },
    [typeFilter.options]
  );

  const handleDurationFilterChange = useCallback(
    (selectedOption: any) => {
      setDurationFilter({
        ...durationFilter,
        selectedOption: selectedOption.label,
        minDuration: selectedOption.minDuration,
        maxDuration: selectedOption.maxDuration,
      });
    },
    [durationFilter]
  );

  const clearAllFilters = useCallback(() => {
    let typeFilterOptions = typeFilter.options;
    for (const type in typeFilterOptions) {
      typeFilterOptions[type].selected = false;
    }

    setTypeFilter((prevState) => ({
      ...prevState,
      options: { ...typeFilterOptions },
    }));

    setDurationFilter({
      ...durationFilter,
      selectedOption: "all",
      minDuration: 0,
      maxDuration: 1440,
    });
  }, [durationFilter, typeFilter.options]);

  const excursionMatchesFilterCriteria = useCallback(
    (excursion: any) => {
      return (
        matchesTypeCriteria(excursion) && matchesDurationCriteria(excursion)
      );
    },
    [matchesDurationCriteria, matchesTypeCriteria]
  );

  const filterExcursions = useCallback(() => {
    const filteredByType = excursions.filter((excursion: any) => {
      return matchesTypeCriteria(excursion);
    });

    const filteredExcursions = excursions.filter((excursion: any) => {
      return excursionMatchesFilterCriteria(excursion);
    });

    //check which type filters should be disabled
    //this could be optimized if performance becomes a problem
    let typeFilterOptions: any = excrusionFilterOptions;
    for (const type in typeFilterOptions) {
      if (!typeFilterOptions[type].selected) {
        typeFilterOptions[type].disabled =
          filteredExcursions.filter(
            (excursion: any) => excursion.excursionTypes.indexOf(type) >= 0
          ).length === 0;
      }
    }

    //check which duration filters should be disabled
    //this could be optimized if performance becomes a problem
    durationFilter.options.map((durationOption) => {
      durationOption.disabled = !filteredByType.some(
        (filteredExcursion: any) =>
          filteredExcursion.duration <= durationOption.maxDuration &&
          filteredExcursion.duration >= durationOption.minDuration
      );
      return durationOption;
    });

    setFilteredExcursions(filteredExcursions);
    setTypeFilter((prevState) => ({
      ...prevState,
      options: typeFilterOptions,
    }));
  }, [
    durationFilter.options,
    excrusionFilterOptions,
    excursionMatchesFilterCriteria,
    excursions,
    matchesTypeCriteria,
  ]);

  useEffect(() => {
    filterExcursions();
  }, [filterExcursions]);

  return (
    <div>
      {/*filter headers*/}
      <div className="excursion-filters-container">
        <div className="container mx-auto flex">
          <FilterHeader
            filterId="type"
            filterName="Excursion type"
            openFilter={openFilter}
            toggleVisibility={toggleFilterVisibility}
          />

          <FilterHeader
            filterId="duration"
            filterName="Duration"
            openFilter={openFilter}
            toggleVisibility={toggleFilterVisibility}
          />
        </div>
      </div>

      {/*filter content*/}
      <div>
        {/*type filter*/}
        {openFilter === "type" && (
          <ExcursionFilter
            filterBy="Excursion type"
            closeFilter={() => toggleFilterVisibility("type")}
            clearFilter={clearAllFilters}
          >
            <ExcursionTypeFilter
              excursionTypes={typeFilter.options}
              handleCheckboxChange={toggleTypeFilterOption}
            />
          </ExcursionFilter>
        )}
        {/*duration filter*/}
        {openFilter === "duration" && (
          <ExcursionFilter
            filterBy="Excursion duration"
            closeFilter={() => toggleFilterVisibility("duration")}
            clearFilter={clearAllFilters}
          >
            <ExcursionDurationFilter
              options={durationFilter.options}
              handleRadioChange={handleDurationFilterChange}
              selectedRadio={durationFilter.selectedOption}
            />
          </ExcursionFilter>
        )}
      </div>

      {/*excursions*/}
      <div className="bg-blue-lightest">
        <div className="container mx-auto py-8">
          {filteredExcursions.map((excursion: any, index: number) => (
            <ExcursionEntry
              key={excursion.id}
              first={index === 0}
              {...excursion}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const FilterHeader: FC<FilterHeaderProps> = ({
  openFilter,
  filterName,
  filterId,
  toggleVisibility,
}) => {
  const filterOpen = openFilter === filterId;

  return (
    <div
      className={
        "pt-4 flex-1 text-center uppercase border-l-2 border-r border-white cursor-pointer select-none" +
        (filterOpen ? " text-blue bg-white" : " text-white")
      }
      onClick={() => toggleVisibility(filterId)}
    >
      <div className="mb-4">{filterName}</div>
      <div className="font-bold">{filterOpen ? " \uFE3F " : "\uFE40"}</div>
    </div>
  );
};

const ExcursionFilter: FC<ExcursionFilterProps> = ({
  filterBy,
  closeFilter,
  clearFilter,
  children,
}) => {
  return (
    <div>
      {/*filter header*/}
      <div className="container mx-auto">
        <div className=" py-4 border-b">
          Filter by <span className="font-bold">{filterBy}</span>
          <div
            className="float-right text-blue-light cursor-pointer"
            onClick={closeFilter}
          >
            <SvgIcon name="x" className="w-4 h-4 inline" />
          </div>
        </div>
      </div>
      {/*filter content*/}
      <div className="container mx-auto">
        <div className="px-4">{children}</div>
      </div>
      {/*filter footer*/}
      <div className="bg-blue-lightest">
        <div className="container mx-auto py-4 text-right">
          <div className="uppercase cursor-pointer" onClick={clearFilter}>
            clear
          </div>
        </div>
      </div>
    </div>
  );
};

const ExcursionTypeFilter: FC<ExcursionTypeFilterProps> = ({
  excursionTypes,
  handleCheckboxChange,
}) => {
  return (
    <div>
      <ul className="list-reset flex flex-wrap">
        {Object.keys(excursionTypes).map((type) => (
          <li className="my-2 h-6 w-full sm:w-1/3" key={type}>
            <Checkbox
              label={type}
              checked={excursionTypes[type].selected}
              disabled={excursionTypes[type].disabled}
              handleCheckboxChange={() => handleCheckboxChange(type)}
              key={type}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

const ExcursionDurationFilter: FC<ExcursionDurationFilterProps> = ({
  options,
  handleRadioChange,
  selectedRadio,
}) => {
  return (
    <div>
      <ul className="list-reset sm:flex sm:justify-around">
        {options.map((option: any, index: number) => (
          <li className="my-2 h-6" key={index}>
            <RadioButton
              label={option.label}
              _onChange={() => handleRadioChange(option)}
              isSelected={selectedRadio === option.label}
              isDisabled={option.disabled}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FilteredExcursions;
