import { PreCruiseStatusAction } from "actions/preCruiseStatusAction";
import { IPreCruiseStatus } from "interfaces/ReduxState/IPreCruiseStatus";

const INITIAL_STATE: IPreCruiseStatus = {
  apiStatus: "",
  travelInfoOverAllStatus: "",
  covidSurveyOverallStatus: "",
  cardRegistrationOverAllStatus: "",
  showCruisePlannerTile: false,
  showPreTravelTile: false,
  preTravelSubmissionOpen: false,
  preTravelSubmissionElapsed: false,
  showInHousePassportInfoTile: false,
  show3rdPartyPassportInfoTile: false,
  showCovidSurveyTile: false,
  covidSurveySubmissionOpen: false,
  covidSurveySubmissionElapsed: false,
  showBoardingPassTile: false,
  boardingPassAccessOpen: false,
  boardingPassAccessElapsed: false,
  showLuggageTagsTile: false,
  luggageTagAccessOpen: false,
  luggageTagAccessElapsed: false,
  isThirdPartyBooking: false,
  bookingType: "OceanCruise",
  showCardRegistrationTile: false,
  cardRegistrationOpen: false,
  cardRegistrationElapsed: false,
  passengers: [],
};

const preCruiseStatusReducer = (
  state = INITIAL_STATE,
  action: PreCruiseStatusAction
) => {
  switch (action.type) {
    case "SET_PRECRUISE_STATUS":
      return action.preCruiseStatus;
    case "CLEAR_PRECRUISE_STATUS":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default preCruiseStatusReducer;
