import configureStore from "configureStore";
import { ReactNode } from "react";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Router } from "react-router-dom";
import browserHistory from "./utils/history";

export const store = configureStore();

const queryClient = new QueryClient();

const WithProviders = ({ children }: { children: ReactNode }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Router history={browserHistory}>{children}</Router>
      </Provider>
    </QueryClientProvider>
  );
};

export default WithProviders;
