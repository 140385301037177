import useAppSelector from "hooks/useAppSelector";

export const useCheckFeatureFlags = () => {
  const isMxpEnabled = useAppSelector(
    (state) => state.appConfig.featureFlags
  )?.find((flag) => flag.code === "IsMxpEnabled")?.isActive;

  const isNewDiningGetAvailabilitiesEnabled = useAppSelector(
    (state) => state.appConfig.featureFlags
  )?.find(
    (flag) => flag.code === "isNewDiningGetAvailabilitiesEnabled"
  )?.isActive;

  const IsBoardingPassTileEnabled = useAppSelector(
    (state) => state.appConfig.featureFlags
  )?.find((flag) => flag.code === "IsBoardingPassTileEnabled")?.isActive;

  return {
    isMxpEnabled,
    isNewDiningGetAvailabilitiesEnabled,
    IsBoardingPassTileEnabled,
  };
};
