import { Route, Redirect, RouteProps } from "react-router-dom";
import useAppSelector from "../../hooks/useAppSelector";
import { FC } from "react";

type Props = RouteProps & {
  component: FC<any>;
};

const AnyAuthRoute: FC<Props> = ({ component: Component, ...rest }) => {
  const isAuthenticated = useAppSelector(
    (state) => state.authentication.isAuthenticated
  );
  const isAgency = useAppSelector((state) => state.agency.isAuthenticated);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated && !isAgency) {
          return <Redirect to="/login" />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default AnyAuthRoute;
