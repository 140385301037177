import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  formatItineraryDateToOneSpaWorldDate,
  formatPortDate,
  formatTimeHM,
} from "../../../utils/dateTimeUtils";
import "moment/locale/en-gb";
import PlannerContainer from "../../../components/PlannerContainer";
import BackLink from "../../../components/molecules/BackLink";

import useAppSelector from "../../../hooks/useAppSelector";
import OneSpaWorldItem from "../OneSpaWorldItem";
import { formatPortName } from "utils/cruiseUtils";
import Loader from "components/atoms/Loader";
import { useGetSpaTreatmentsList } from "../hooks/useGetSpaTreatmentsList";
import { buildTestId } from "utils/buildTestId";
import { RouteComponentProps } from "react-router-dom";
import FilterHeader from "components/filters/FilterHeader";
import FilterBody from "components/filters/FilterBody";
import RadioButtonsListFilter from "components/filters/RadioButtonsListFilter";
import { useGetAllSpaCategories } from "../hooks/getAllSpaCategories";
import { IOneSpaWorldCategory } from "interfaces/OSW/IOneSpaWorldCategory";
import PaginationButtonsGroup from "components/Buttons/PaginationButtonsGroup";

const INITIAL_CATEGORIES_FILTERS = {
  options: [
    {
      label: "",
      value: "",
    },
  ],
  selectedOption: "",
  selectedValue: "",
};

type CategoryOptionType = {
  label: string;
  value: string;
};

type CategoriesFilterType = {
  options: CategoryOptionType[];
  selectedOption: string;
  selectedValue: string;
};

const SORT_FILTERS = {
  options: [
    {
      label: "lower price",
      value: "asc",
    },
    {
      label: "higher price",
      value: "desc",
    },
  ],
  selectedOption: "lower price",
  selectedValue: "asc",
};

const ITEMS_PER_PAGE = 10;

export interface Props
  extends RouteComponentProps<{
    itineraryId: string;
  }> {}

const OneSpaWorldList: FC<Props> = ({ match, location, history }) => {
  let transformedCategoriesInitialState = useRef(INITIAL_CATEGORIES_FILTERS);

  // Extract the current page from the query parameters using URLSearchParams
  const params = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);
  const page = params.get("page") ?? "1";
  const [currentPage, setCurrentPage] = useState(Number(page));

  const [openFilter, setOpenFilter] = useState("");
  const [sortFilters, setSortFilters] = useState(SORT_FILTERS);
  const [selectedCategoriesIds, setSelectedCategoriesIds] = useState("");
  const [categories, setCategories] = useState<CategoriesFilterType>(
    INITIAL_CATEGORIES_FILTERS
  );

  const itinerary = useAppSelector((state) => {
    return state.itinerary.find(
      (itinerary) => itinerary.id === match.params.itineraryId
    );
  });
  const formattedItineraryDate = formatItineraryDateToOneSpaWorldDate(
    // @ts-ignore TS FIX ME
    itinerary.date
  );

  const { spaTreatmentsData, spaTreatmentsDataIsLoading } =
    useGetSpaTreatmentsList({
      date: formattedItineraryDate,
      sort: sortFilters.selectedValue,
      categoryIds: selectedCategoriesIds,
      page: currentPage,
    });

  const { allSpaCategories, allSpaCategoriesIsLoading } =
    useGetAllSpaCategories({
      date: formattedItineraryDate,
    });

  useEffect(() => {
    if (allSpaCategories) {
      const transformedCategories = allSpaCategories.map(
        (category: IOneSpaWorldCategory) => {
          return {
            value: category.id,
            label: category.name,
          };
        }
      );

      transformedCategoriesInitialState.current = {
        options: transformedCategories,
        selectedOption: "",
        selectedValue: "",
      };
      setCategories(transformedCategoriesInitialState.current);
    }
  }, [allSpaCategories]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [page]);

  const handlePageChange = useCallback(
    (newPage: number) => {
      setCurrentPage(newPage);

      params.set("page", newPage.toString());
      history.push(`/spa-and-wellness/${itinerary?.id}?${params.toString()}`);
    },
    [history, itinerary?.id, params]
  );

  const toggleFilterVisibility = useCallback(
    (filterId: string) => {
      if (filterId === openFilter) {
        setOpenFilter("");
      } else {
        setOpenFilter(filterId);
      }
    },
    [openFilter]
  );

  const handleCategoryFiltersChange = useCallback(
    (item: any) => {
      handlePageChange(1);

      setCategories((prevState) => {
        return {
          ...prevState,
          selectedOption: item.label,
          selectedValue: item.value,
        };
      });

      setSelectedCategoriesIds(item.value);
    },
    [handlePageChange]
  );

  const handleSortFiltersChange = useCallback(
    (selectedOption: { label: string; disabled: boolean }) => {
      setSortFilters({
        ...sortFilters,
        selectedOption: selectedOption.label,
        selectedValue:
          SORT_FILTERS.options.find(
            (option) => option.label === selectedOption.label
          )?.value ?? "asc",
      });
    },
    [sortFilters]
  );

  const clearAllFilters = useCallback(() => {
    handlePageChange(1);
    setSortFilters(SORT_FILTERS);
    setCategories(transformedCategoriesInitialState.current);
    setSelectedCategoriesIds("");
  }, [handlePageChange]);

  // @ts-ignore TS FIX ME
  const portName = formatPortName(itinerary.type, itinerary.port);

  if (!itinerary) {
    return null;
  }

  return (
    <div>
      <PlannerContainer />
      <div
        data-testid={buildTestId(
          "container",
          "spa treatments itinerary details"
        )}
        className="container mx-auto my-4 text-blue-dark"
      >
        <BackLink to="/itinerary" text="Cruise Planner" />
        <h1>Spa & Wellness Treatments</h1>
        <div className="text-2xl">
          DAY {itinerary.dayOfCruise} ({formatPortDate(itinerary.date)})
        </div>
        <div className="text-3xl mt-1">{portName}</div>
        {itinerary.type === "Port" && (
          <div className="text-3xl mt-1">
            Time in port: {formatTimeHM(itinerary.arrival, "Previous day")} -{" "}
            {formatTimeHM(itinerary.departure, "Next day")}
          </div>
        )}
      </div>

      {/* filter header */}
      <div className="excursion-filters-container">
        <div className="container mx-auto flex">
          <FilterHeader
            filterId="filter"
            filterName="Select your Category"
            openFilter={openFilter}
            toggleVisibility={toggleFilterVisibility}
          />

          <FilterHeader
            filterId="sort"
            filterName="Sort"
            openFilter={openFilter}
            toggleVisibility={toggleFilterVisibility}
          />
        </div>
      </div>

      {/* filter content */}
      <div>
        {/*type filter*/}
        {openFilter === "filter" && (
          <FilterBody
            filterBy="Select your Category"
            closeFilter={() => toggleFilterVisibility("filter")}
            clearFilter={clearAllFilters}
          >
            <RadioButtonsListFilter
              options={categories.options}
              handleRadioChange={handleCategoryFiltersChange}
              selectedRadio={categories.selectedOption}
            />
          </FilterBody>
        )}

        {/* sort filter */}
        {openFilter === "sort" && (
          <FilterBody
            filterBy="Sort"
            closeFilter={() => toggleFilterVisibility("sort")}
            clearFilter={clearAllFilters}
          >
            <RadioButtonsListFilter
              options={sortFilters.options}
              handleRadioChange={handleSortFiltersChange}
              selectedRadio={sortFilters.selectedOption}
            />
          </FilterBody>
        )}
      </div>

      <div
        data-testid={buildTestId("container", "spa treatments list")}
        className="container mx-auto px-2 sm:px-4 py-8 flex flex-col gap-y-8"
      >
        {!spaTreatmentsDataIsLoading && !allSpaCategoriesIsLoading ? (
          <>
            {spaTreatmentsData?.oswTreatments?.map((spaTreatment) => (
              <OneSpaWorldItem
                key={spaTreatment.serviceId}
                id={spaTreatment.serviceId}
                itineraryId={itinerary.id}
                title={spaTreatment.title}
                shortDescription={spaTreatment.shortDescription}
                image={spaTreatment?.imagePublicUrl || ""}
                duration={spaTreatment.duration}
                standardPrice={spaTreatment.standardPrice}
                isBookable={spaTreatmentsData.isBookable}
                date={formatItineraryDateToOneSpaWorldDate(itinerary.date)}
              />
            ))}

            {spaTreatmentsData?.totalRecordsOfTreatments > ITEMS_PER_PAGE && (
              <div className="mx-auto">
                <PaginationButtonsGroup
                  count={Math.ceil(
                    spaTreatmentsData.totalRecordsOfTreatments / ITEMS_PER_PAGE
                  )} // always display up to 10 items per page
                  page={currentPage}
                  handleChange={handlePageChange}
                />
              </div>
            )}
          </>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default OneSpaWorldList;
