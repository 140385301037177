import { useState } from "react";
import If from "./molecules/IF";
import logo from "../resources/images/tuilogo.svg";
import browserHistory from "utils/history";
import { imagePath } from "utils/imageUtils";
import LogoutModal from "./modals/LogoutModal/LogoutModal";
import { isDevelopmentMode } from "../config/configUtils";
import useAppSelector from "../hooks/useAppSelector";

const Header = () => {
  const authentication = useAppSelector((state) => state.authentication);
  const agency = useAppSelector((state) => state.agency);
  const isCartEmpty = useAppSelector((state) => state.cart.nrExcursions === 0);

  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
  const [route, setRoute] = useState<string>("");

  const onLogoutClick = (route: string) => {
    if (isCartEmpty) {
      browserHistory.push(route);
      return;
    }
    setShowLogoutModal(true);
    setRoute(route);
  };

  const hideModal = () => {
    setShowLogoutModal(false);
  };

  const logout = () => {
    hideModal();
    browserHistory.push(route);
  };

  return (
    <header className="bg-blue-light print-hidden">
      <div className="container mx-auto">
        <div className="flex justify-between">
          <div className="header-logo flex items-center">
            <img
              onClick={() => browserHistory.push("/")}
              src={logo}
              alt="logo"
              className="h-10 my-4 cursor-pointer"
            />
          </div>
          <div className="flex items-center text-blue-dark">
            <If test={agency.isAuthenticated}>
              <div>
                {agency.userName}
                <br />
                <button
                  className="cursor-pointer hover:underline"
                  onClick={() => onLogoutClick("/logoutAgency/1")}
                >
                  Logout
                </button>
              </div>
            </If>
            <If test={authentication.isAuthenticated}>
              <div className="ml-4">
                <If test={isDevelopmentMode()}>
                  {authentication.bookingReference}
                  <br />
                </If>

                <button
                  className="cursor-pointer hover:underline flex items-center"
                  onClick={() => onLogoutClick("/logoutUser")}
                >
                  <img
                    className="align-middle w-4 h-4 mr-2 fill-current inline-block"
                    alt="logout icon"
                    src={imagePath("logouticon.svg")}
                  />
                  <span>Sign out</span>
                </button>
              </div>
            </If>
          </div>
        </div>
      </div>
      <LogoutModal
        showModal={showLogoutModal}
        hideModal={hideModal}
        logout={logout}
      />
    </header>
  );
};

export default Header;
