import { IStore } from "interfaces/ReduxState/IStore";
import { StoreAction } from "../actions/storeActions";

const INITIAL_STATE: IStore = { id: "" };

const storeReducers = (state = INITIAL_STATE, action: StoreAction) => {
  switch (action.type) {
    case "CLEAR_STORE":
      return { id: "" };

    case "SET_STORE":
      return { id: action.storeId };

    default:
      return state;
  }
};

export default storeReducers;
