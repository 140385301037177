import { FC } from "react";
import CartElementConfirmation from "../CartElementConfirmation";
import { formatCartElementDate, formatDuration } from "utils/dateTimeUtils";

type Props = {
  excursion: any;
};

const CartExcursionEntryConfirmation: FC<Props> = (props) => {
  const implodePassengers = () => {
    return props.excursion.passengers
      .map(
        (passenger: { firstName: any; lastName: any }) =>
          `${passenger.firstName} ${passenger.lastName}`
      )
      .join(", ");
  };

  const excursion = props.excursion;

  return (
    <CartElementConfirmation
      title={excursion.excursionName}
      subtitle={`${excursion.portName?.toUpperCase()}, ${excursion.portCountry?.toUpperCase()}`}
      day={`On Day ${excursion.dayNr} (${formatCartElementDate(
        excursion.date
      )})`}
      time={`Duration: ${formatDuration(excursion.duration)}`}
      people={implodePassengers()}
      price={excursion.price}
    />
  );
};

export default CartExcursionEntryConfirmation;
