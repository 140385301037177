import { Text, View } from "@react-pdf/renderer";

const FoldLabelHorizontal = (props) => {
  const noCutStyle = {
    position: "absolute",
    top: "-4px",
    backgroundColor: "white",
    fontSize: "6px",
    textTransform: "uppercase",
    paddingHorizontal: "2px",
    color: "#092a5e",
    fontWeight: "bold",
  };

  return (
    <View
      style={{
        position: "relative",
        height: 0,
      }}
    >
      {props.offsets.map((offset, index) => {
        const style = { ...noCutStyle };
        style[offset.side] = offset.value;

        return (
          <Text key={index} style={style}>
            fold - do not cut
          </Text>
        );
      })}
    </View>
  );
};

export default FoldLabelHorizontal;
