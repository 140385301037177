export const getAgeRangeFromMusementProductName = (value?: string) => {
  if (!value) return "";

  const startIndex = value.indexOf("(");
  const endIndex = value.indexOf(")");

  let result = "";
  if (startIndex !== -1 && endIndex !== -1) {
    result = value.slice(startIndex, endIndex + 1);
  }

  return result;
};
