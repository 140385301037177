import { buildTestId } from "utils/buildTestId";
import { getSections } from "utils/questionnaireUtils";
import useAppSelector from "hooks/useAppSelector";
import { FC } from "react";

type Props = {
  activeSection: number;
};

const Progress: FC<Props> = ({ activeSection }) => {
  const isRiverUrl = useAppSelector((state) => state.appConfig.isRiverUrl);

  return (
    <div>
      <div className="relative">
        {/* lines between steps */}
        <div className="h-1 w-full absolute top-0 grid z-0 mt-2 grid-cols-progress-4">
          <div></div>
          {[...Array(getSections(isRiverUrl).length - 1)].map((_, index) => (
            <div
              key={index}
              className={`border-blue-dark border-b-2 ${
                activeSection > index ? "border-solid" : "border-dotted"
              }`}
            ></div>
          ))}
          <div></div>
        </div>

        <div className="grid grid-cols-4 text-center relative text-blue-dark">
          {getSections(isRiverUrl).map((section, index) => (
            <div key={index}>
              <div
                className={`w-4 h-4 rounded-full border border-blue-dark inline-flex items-center justify-center text-sm font-bold ${
                  index > activeSection ? "bg-white" : "bg-blue-dark text-white"
                }`}
              >
                <span className="leading-none mb-2px">{index + 1}</span>
              </div>
              <div
                data-testid={buildTestId("label", `section ${index}`)}
                className={`mt-1 text-sm md:text-normal ${
                  activeSection === index ? "font-bold" : ""
                }`}
              >
                {section.label}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Progress;
