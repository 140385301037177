import { ChangeEvent, MouseEvent, useState } from "react";
import SvgIcon from "./atoms/SvgIcon";
import { buildTestId } from "utils/buildTestId";
import { FC } from "react";

type Props = {
  handleBlur?: (value: ChangeEvent<HTMLSelectElement>) => void;
  handleChange: (value: ChangeEvent<HTMLSelectElement>) => void;
  classes?: string;
  selectId: string;
  labelClasses?: string;
  label: string;
  disabled?: boolean;
  error: any;
  value: any;
  options: any;
  dataTestIdName?: string;
};

const SelectDropdown: FC<Props> = ({
  handleBlur,
  handleChange,
  selectId,
  label,
  labelClasses,
  classes,
  options,
  value,
  error,
  disabled,
  dataTestIdName,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onBlur = (e: ChangeEvent<HTMLSelectElement>) => {
    setIsOpen(false);
    if (handleBlur) {
      handleBlur(e);
    }
  };

  const onClick = (e: MouseEvent<HTMLSelectElement>) => {
    if (e.currentTarget === e.target) {
      setIsOpen(!isOpen);
    }
  };

  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setIsOpen(false);
    handleChange(e);
  };

  return (
    <div className={classes ? classes : ""}>
      <label
        htmlFor={selectId}
        className={labelClasses || "block text-blue-dark mb-2"}
      >
        {label}
      </label>

      <div className="relative">
        <select
          id={selectId}
          data-testid={buildTestId("select", `basic ${dataTestIdName}`)}
          disabled={disabled}
          className={`w-full border rounded px-2 py-3 appearance-none ${
            disabled ? "cursor-not-allowed " : "cursor-pointer "
          }${error ? "border border-red" : ""}`}
          onBlur={onBlur}
          onChange={onChange}
          onClick={onClick}
          value={value}
        >
          {options.map((option: any, index: number) =>
            typeof option === "object" ? (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ) : (
              <option key={index} value={option}>
                {option}
              </option>
            )
          )}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center mr-3">
          <SvgIcon
            name={isOpen ? "chevron-up" : "chevron-down"}
            className="text-blue w-6 h-6 inline ml-2"
          />
        </div>
      </div>
    </div>
  );
};

export default SelectDropdown;
