import { FC, useRef } from "react";
import BackLink from "../../components/molecules/BackLink";
import { PDFViewer } from "@react-pdf/renderer";
import BoardingPassPdf from "../../components/pdf/BoardingPassPdf";

type OwnProps = {
  location: any;
};

const BoardingPassDetail: FC<OwnProps> = ({ location }) => {
  const pdfRef = useRef<HTMLIFrameElement>(null);

  const handlePrint = () => {
    pdfRef?.current?.contentWindow?.print();
  };

  const { passengers, bookingData } = location.state;

  return (
    <div className="container mx-auto mt-4">
      <BackLink to="/" text="Back to Cruise Control Dashboard" />

      <h1>Boarding pass</h1>

      <div className="mt-4">
        <button
          className="action-button action-button-enabled w-mobile"
          onClick={handlePrint}
        >
          Print boarding pass
        </button>
      </div>

      <div className="w-full container-a4 mt-6 mb-8">
        <div className="container-a4-content">
          <PDFViewer
            innerRef={pdfRef}
            style={{ width: "100%", height: "100%" }}
          >
            <BoardingPassPdf
              passengers={passengers}
              bookingData={bookingData}
            />
          </PDFViewer>
        </div>
      </div>
    </div>
  );
};

export default BoardingPassDetail;
