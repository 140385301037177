import { useMemo } from "react";

interface Props {
  count: number;
  page: number;
  handleChange: (page: number) => void;
}

const PaginationButtonsGroup = ({ count, page, handleChange }: Props) => {
  const handlePageClick = (newPage: number) => {
    if (newPage > 0 && newPage <= count) {
      handleChange(newPage);
    }
  };

  const pages = useMemo(
    () => Array.from({ length: count }, (_, index) => index + 1),
    [count]
  );

  return (
    <div className="flex items-center gap-2">
      <button
        onClick={() => handlePageClick(page - 1)}
        disabled={page === 1}
        className={`px-2 py-1 border rounded ${
          page === 1
            ? "bg-grey-lighter cursor-not-allowed"
            : "bg-transparent cursor-pointer hover:bg-grey-light"
        }`}
        aria-label="Previous page"
      >
        ‹
      </button>
      {pages.map((pageNumber) => (
        <button
          key={pageNumber}
          onClick={() => handlePageClick(pageNumber)}
          className={`px-2 py-1 border rounded ${
            page === pageNumber
              ? "bg-grey-light font-bold"
              : "bg-transparent hover:bg-grey-light"
          } cursor-pointer`}
          aria-label={`Page ${pageNumber}`}
        >
          {pageNumber}
        </button>
      ))}
      <button
        onClick={() => handlePageClick(page + 1)}
        disabled={page === count}
        className={`px-2 py-1 border rounded ${
          page === count
            ? "bg-grey-lighter cursor-not-allowed"
            : "bg-transparent cursor-pointer hover:bg-grey-light"
        }`}
        aria-label="Next page"
      >
        ›
      </button>
    </div>
  );
};

export default PaginationButtonsGroup;
