import { Action, applyMiddleware, createStore, StoreEnhancer } from "redux";
import thunk, { ThunkAction, ThunkDispatch } from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import allReducers from "./reducers";
import { loadState } from "./localStorage";
import { IItineraries } from "interfaces/ReduxState/IItinerary";
import { ICartState } from "interfaces/ReduxState/ICart";
import { IBookingsState } from "interfaces/ReduxState/IBookings";
import { IStore } from "interfaces/ReduxState/IStore";
import { IAppConfig } from "interfaces/ReduxState/IAppConfig";
import { IAuthentication } from "interfaces/ReduxState/IAuthentication";
import { IAgencyAuthentication } from "interfaces/ReduxState/IAgencyAuthentication";
import { ICruise } from "interfaces/ReduxState/ICruise";
import { ILocation } from "interfaces/ReduxState/ILocation";
import { IPayment } from "interfaces/ReduxState/IPayment";
import { IOpenRefunds } from "interfaces/ReduxState/IOpenRefunds";
import { IPreCruiseStatus } from "interfaces/ReduxState/IPreCruiseStatus";

export interface RootState {
  agency: IAgencyAuthentication;
  authentication: IAuthentication;
  preCruiseStatus: IPreCruiseStatus;
  bookings: IBookingsState;
  cart: ICartState;
  cruises: ICruise;
  itinerary: IItineraries;
  location: ILocation;
  openRefunds: IOpenRefunds;
  payment: IPayment;
  store: IStore;
  appConfig: IAppConfig;
}

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type AppDispatch = ThunkDispatch<RootState, unknown, Action<string>>;

const configureStore = () => {
  const persistedState = loadState();

  const store = createStore(
    allReducers,
    persistedState,
    composeWithDevTools(
      applyMiddleware<AppDispatch, RootState>(thunk) as StoreEnhancer<{
        dispatch: AppDispatch;
      }>
    )
  );

  return store;
};

export default configureStore;
