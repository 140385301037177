import { useState, useEffect } from "react";
import BackLink from "../molecules/BackLink";
import InfoBox from "./InfoBox/InfoBox";
import useGet from "../../hooks/useGet";
import { SurveyProvider, useSurveyContext } from "../../context/SurveyContext";
import Question from "./questions/Question";
import { getErrors, isFormValid } from "../../utils/questionnaireUtils";
import { postToHub } from "../../actions/networkActions";
import browserHistory from "../../utils/history";
import usePreCruisePassengers from "../../hooks/usePreCruisePassengers";

const CovidSurveyPage = () => {
  return (
    <SurveyProvider>
      <CovidSurvey />
    </SurveyProvider>
  );
};

export default CovidSurveyPage;

const CovidSurvey = () => {
  const [error, setError] = useState("");
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const data: any = useGet("precruise/questions/covidSurvey", {});
  const passengers = usePreCruisePassengers();
  const { survey, dispatch } = useSurveyContext();
  const activePart = 0;

  useEffect(() => {
    if (!data.covid) {
      return;
    }

    const {
      passenger,
      covid: { questions, answers = {} },
    } = data;

    dispatch({
      type: "SET_SURVEY_DATA",
      passenger,
      answers,
      questions,
    });
  }, [data, dispatch]);

  if (!survey.passenger || !survey.passenger.passengerId || !passengers) {
    return <div className="container mx-auto mt-12">Loading...</div>;
  }

  const buttonEnabled = isFormValid(
    survey.questions,
    survey.answers,
    survey.passenger,
    activePart
  );

  const listPassengerNames = () => {
    if (!passengers) {
      return "";
    }

    return passengers
      .map((passenger: any) => `${passenger.firstName} ${passenger.lastName}`)
      .join(", ");
  };

  const submit = async () => {
    if (validateForm() && !submitInProgress) {
      const body = {
        passengerId: survey.passenger.passengerId,
        category: "covidSurvey",
        answers: survey.answers,
      };

      setSubmitInProgress(true);
      const result = await postToHub("precruise/questions/submitAnswers", body);
      setSubmitInProgress(false);

      if (result === "") {
        browserHistory.push("/dashboard");
      } else {
        setError("There has been an error please try again");
      }
    }
  };

  const validateForm = () => {
    const errors = getErrors(
      survey.questions,
      survey.answers,
      survey.passenger,
      activePart
    );

    dispatch({
      type: "SET_ERRORS",
      errors,
    });

    return Object.keys(errors).length === 0;
  };

  return (
    <div className="container mx-auto">
      <div className="mt-4">
        <BackLink to="/dashboard" text="Back to Cruise Control Dashboard" />
      </div>
      <h1>COVID-19 survey</h1>

      <div className="mt-4 leading-normal text-lg text-grey-darker">
        The health declaration is to be completed by the lead passenger within
        72 hours prior to embarkation. You are making this Health Declaration on
        behalf of: <b>{listPassengerNames()}</b>
      </div>

      <InfoBox
        classes="mt-6 md:inline-block"
        infoText="This information is required to assist in protecting the health and safety of passengers and crew on this cruise."
      />

      <div>
        {survey.questions.map((question: any, index: number) => (
          <Question
            key={question.id}
            activePart={activePart}
            isFirst={index === 0}
            question={question}
          />
        ))}
      </div>

      {error && <div className="mt-4 text-red-tui">{error}</div>}

      <div className="mt-6 mb-8 xl:mb-12">
        <button
          className={`action-button w-mobile ${
            buttonEnabled && !submitInProgress
              ? "action-button-enabled"
              : "action-button-disabled"
          }`}
          onClick={submit}
        >
          Next
        </button>
      </div>
    </div>
  );
};
