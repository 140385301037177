import { FC, useEffect, useMemo, useRef, useState } from "react";
import { formatPortDate, formatTimeHM } from "utils/dateTimeUtils";
import "moment/locale/en-gb";
import PlannerContainer from "components/PlannerContainer";
import BackLink from "components/molecules/BackLink";
import "resources/css/excursion.css";
import useAppSelector from "hooks/useAppSelector";
import MusementFilteredExcursions from "./MusementFilteredExcursions";
import Loader from "components/atoms/Loader";
import { RouteComponentProps } from "react-router-dom";
import usePostTaxonomies from "../hooks/usePostTaxonomies";
import usePostExcursionsSlots from "../hooks/usePostExcursionsSlots";
import { isEmpty } from "lodash";
import { setItineraryExcursions } from "actions/itineraryActions";
import useAppDispatch from "hooks/useAppDispatch";
import { IItineraryExcursion } from "interfaces/Musement/IItineraryExcursion";

const MusementExcursionsList: FC<
  RouteComponentProps<{
    itineraryId: string;
  }>
> = ({ match }) => {
  // this "switch" is used to stop infinite loop update after itinerary has been updated in Redux Store with Slots (products)
  const isFirstTimeUpdated = useRef(false);
  const itinerary = useAppSelector((state) =>
    state.itinerary.find(
      (itinerary: any) => itinerary.id === match.params.itineraryId
    )
  );
  const dispatch = useAppDispatch();

  const [excursionsWithProducts, setExcursionsWithProducts] = useState<
    IItineraryExcursion[] | null
  >(null);

  const { fetchExcursionsSlots, excursionsSlots, excursionsSlotsIsLoading } =
    usePostExcursionsSlots();

  const { fetchTaxonomies, taxonomies, taxonomiesIsLoading } =
    usePostTaxonomies();

  const excursionsUuids = useMemo(() => {
    return itinerary?.excursions
      ? itinerary.excursions.map(
          (excursion: IItineraryExcursion) => excursion.uuid
        )
      : [];
  }, [itinerary?.excursions]);

  const taxonomiesMemo = useMemo(() => {
    return taxonomies || {};
  }, [taxonomies]);

  useEffect(() => {
    if (excursionsUuids.length > 0 && !isFirstTimeUpdated.current) {
      fetchTaxonomies({ activityUuids: excursionsUuids });
    }
  }, [excursionsUuids, fetchTaxonomies]);

  useEffect(() => {
    if (
      itinerary?.date &&
      excursionsUuids.length > 0 &&
      !isFirstTimeUpdated.current
    ) {
      fetchExcursionsSlots({
        activityUuids: excursionsUuids,
        date: itinerary.date,
      });
    }
  }, [excursionsUuids, fetchExcursionsSlots, itinerary?.date]);

  useEffect(() => {
    if (!isEmpty(excursionsSlots) && !isFirstTimeUpdated.current) {
      const allExcursionsWithProducts: IItineraryExcursion[] =
        itinerary?.excursions
          ? itinerary.excursions.map((excursion) => {
              const excursionSlot = excursionsSlots[excursion.uuid];
              const products = excursionSlot?.products || [];
              const startTime = excursionSlot?.time;
              const prices = excursionSlot?.prices;

              return { ...excursion, products, startTime, prices };
            })
          : [];

      dispatch(
        setItineraryExcursions({
          itineraryId: match.params.itineraryId,
          excursions: allExcursionsWithProducts ?? [],
        })
      );

      isFirstTimeUpdated.current = true;
      setExcursionsWithProducts(allExcursionsWithProducts);
    }
  }, [
    excursionsSlots,
    itinerary?.excursions,
    match.params.itineraryId,
    dispatch,
  ]);

  if (!itinerary) {
    return null;
  }

  return (
    <div>
      <PlannerContainer />

      {/* basic itinerary info */}
      <div className="container mx-auto my-4 text-blue-dark">
        <BackLink to="/itinerary" text="Cruise Planner" />
        <h1>
          {itinerary.excursions?.length} excursions from{" "}
          {itinerary.port.name.defaultValue?.toUpperCase()}
        </h1>
        <div className="text-2xl">
          DAY {itinerary.dayOfCruise} ({formatPortDate(itinerary.date)})
        </div>
        <div className="text-3xl">
          Time in port: {formatTimeHM(itinerary.arrival, "Previous day")} -{" "}
          {formatTimeHM(itinerary.departure, "Next day")}
        </div>
      </div>

      {/* filters and filtered excursions */}
      {excursionsSlotsIsLoading || taxonomiesIsLoading ? (
        <Loader />
      ) : (
        excursionsWithProducts &&
        excursionsWithProducts.length > 0 && (
          <MusementFilteredExcursions
            excursions={excursionsWithProducts}
            itineraryId={itinerary?.id}
            excrusionFilterOptions={taxonomiesMemo}
          />
        )
      )}
    </div>
  );
};

export default MusementExcursionsList;
