import { FC, useState } from "react";
import { postToHub } from "../../actions/networkActions";
import usePreCruisePassenger from "../../hooks/usePreCruisePassenger";
import BackLink from "../molecules/BackLink";
import PinInput from "./PinInput";
import { isPinValid } from "utils/miscUtils";
import browserHistory from "utils/history";
import { RouteComponentProps } from "react-router-dom";

const ResetPin: FC<
  RouteComponentProps<{
    passengerId: string;
    token: string;
  }>
> = ({ match }) => {
  const [pin, setPin] = useState<string[]>(["", "", "", "", "", ""]);
  const [confirmPin, setConfirmPin] = useState<string[]>([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [error, setError] = useState<string>("");
  const passengerId = match.params.passengerId;
  const { firstName, lastName } = usePreCruisePassenger(passengerId);
  const name = `${firstName} ${lastName}`;

  const onSubmit = async () => {
    if (!validate()) {
      setError("Please make sure to enter valid PINS that match.");
      return;
    }

    const payload = {
      passengerId,
      pin: pin.join(""),
      confirmPin: confirmPin.join(""),
      hash: match.params.token,
    };
    const response = await postToHub(`precruise/passenger/resetPin`, payload);

    if (response === "") {
      browserHistory.push(
        `/precruise/passenger/${passengerId}/resetPinSuccess`
      );
    } else {
      setError("There has been an error please try again");
    }
  };

  const validate = () => {
    return isPinValid(pin) && pin.join("") === confirmPin.join("");
  };

  const isFormValid = validate();

  return (
    <div className="container mx-auto">
      <div className="mt-4">
        <BackLink
          to="/precruise/passengers"
          text="Back to Travel information dashboard"
        />
      </div>

      <h1 className="my-6">PIN reset for {name}</h1>

      <div className="mt-4 border bg-grey-lightest-2 p-4">
        <div>
          <div className="uppercase">Set your new PIN</div>

          <PinInput
            autoFocus={true}
            pin={pin}
            setPin={setPin}
            submit={onSubmit}
            idPrefix="pin"
          />
        </div>

        <div className="my-4">
          <div className="uppercase">Confirm your new PIN</div>

          <PinInput
            pin={confirmPin}
            setPin={setConfirmPin}
            submit={onSubmit}
            idPrefix="confirm-pin"
          />
        </div>
      </div>

      {error && <div className="mt-4 text-red-tui">{error}</div>}

      <div className="mt-6 mb-8 xl:mb-12">
        <button
          className={`w-mobile action-button ${
            isFormValid ? "action-button-enabled" : "action-button-disabled"
          }`}
          disabled={!isFormValid}
          onClick={onSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default ResetPin;
