import { IAuthentication } from "interfaces/ReduxState/IAuthentication";
import { AuthenticationAction } from "../actions/authenticationActions";

const INITIAL_STATE: IAuthentication = {
  sessionId: null,
  isAuthenticated: false,
};

const authenticationReducers = (
  state = INITIAL_STATE,
  action: AuthenticationAction
) => {
  switch (action.type) {
    case "CLEAR_AUTHENTICATION":
      return {
        isAuthenticated: false,
        sessionId: null,
      };

    case "SET_AUTHENTICATION":
      return action.authentication;

    default:
      return state;
  }
};

export default authenticationReducers;
