import { store } from "WithProviders";
import moment from "moment/moment";
import { AnyAction } from "redux";

export interface CruisesActions extends AnyAction {
  cruises?: any;
  cruiseStartDate?: any;
  imageReferenceId?: any;
}

export const clearCruises = () => {
  store.dispatch({ type: "CLEAR_CRUISES" });
};

export const setCruises = (cruises: any) => {
  const cruiseDates = cruises.length
    ? cruises.map((cruise: any) => moment(cruise.fromDate, "DD/MM/YYYY"))
    : null;
  const cruiseStartDate = cruiseDates
    ? moment.min(cruiseDates).format("DD/MM/YYYY")
    : null;

  const cruiseToDates = cruises.length
    ? cruises.map((cruise: any) => moment(cruise.toDate, "DD/MM/YYYY"))
    : null;

  const cruiseEndDate = cruiseToDates
    ? moment.max(cruiseToDates).format("DD/MM/YYYY")
    : null;

  store.dispatch({
    type: "SET_CRUISES",
    cruises,
    cruiseStartDate,
    cruiseEndDate,
  });
};

export const setCruiseImage = (results: any) => {
  let imageReferenceId = null;

  if (results && results[0] && results[0].excursion) {
    imageReferenceId = results[0].excursion.id;
  }

  store.dispatch({ type: "SET_CRUISE_IMAGE_REFERENCE", imageReferenceId });
};

export const getCurrentCruisesFromStore = () => {
  return store.getState().cruises;
};
