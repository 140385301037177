import { useCallback, useEffect } from "react";
import CheckoutSubheader from "../../components/CheckoutSubheader";
import BackLink from "../../components/molecules/BackLink";
import { useLocation } from "react-router-dom";

interface stateType {
  mfaSource: any;
  paymentReference: any;
}

const PaymentGPP = () => {
  const location = useLocation<stateType>();

  const loadGppMfa = useCallback(() => {
    const existingScript = document.getElementById("comp_gppMfa");

    if (!existingScript) {
      const script = document.createElement("script");
      script.src = location.state.mfaSource;
      script.id = "comp_gppMfa";
      document.body.appendChild(script);
    }
  }, [location.state.mfaSource]);

  useEffect(() => {
    loadGppMfa();
  }, [loadGppMfa]);

  const paymentReference = location.state.paymentReference;

  return (
    <div>
      <div className="bg-blue text-white uppercase">
        <CheckoutSubheader active="payment" hideOnMobile={false} />
      </div>

      <div className="container px-4 mx-auto mt-4">
        <BackLink to="/checkout" text="Checkout" />

        <div>
          {/* @ts-ignore tui-payments*/}
          <tui-payments components="details, button" token={paymentReference} />
        </div>
      </div>
    </div>
  );
};

export default PaymentGPP;
