import Modal from "components/modals/Modal/Modal";
import { FC } from "react";
import { buildTestId } from "utils/buildTestId";

export type Props = {
  show: boolean;
  title: string;
  message: string;
  onClose: () => void;
};

const ErrorModal: FC<Props> = ({ show, title, message, onClose }) => {
  return (
    <Modal show={show} onClose={onClose} title={title}>
      <div className="flex flex-grow flex-col justify-center">
        <div className="my-8 text-blue-dark text-2xl text-center">
          <p data-testid={buildTestId("text", "error modal message")}>
            {message}
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default ErrorModal;
