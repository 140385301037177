export const imagePath = (fileName: string) => {
  return (
    require(`../../resources/images/${fileName}`)?.default ??
    require(`../../resources/images/${fileName}`)
  );
};

export const CRUISE_HEADER_IMAGE_PLACEHOLDER_ARRAY = [
  "images/CRUISE_HEADER_IMAGE_PLACEHOLDER_01.jpg",
  "images/CRUISE_HEADER_IMAGE_PLACEHOLDER_02.jpg",
  "images/CRUISE_HEADER_IMAGE_PLACEHOLDER_03.jpg",
  "images/CRUISE_HEADER_IMAGE_PLACEHOLDER_04.jpg",
];

export const getRandomImage = () => {
  const randomIndex = Math.floor(
    Math.random() * CRUISE_HEADER_IMAGE_PLACEHOLDER_ARRAY.length
  );
  return CRUISE_HEADER_IMAGE_PLACEHOLDER_ARRAY[randomIndex];
};
