// custom overrides of the styles
import { buildTestId } from "utils/buildTestId";
import "../../../resources/css/loader.css";
import { FC } from "react";

type Props = {
  text?: string;
};

const Loader: FC<Props> = ({ text = "Loading..." }) => {
  return (
    <div
      data-testid={buildTestId("container", "loader")}
      className="flex justify-center my-6"
    >
      <div className="flex flex-col justify-center items-center w-[240px] h-[180px]">
        <div className="loader" />
        <div className="flex justify-center mt-4">{text}</div>
      </div>
    </div>
  );
};

export default Loader;
