import { useUpdateCart } from "actions/useUpdateCart";
import axios from "axios";
import { getOswUrl } from "config/configUtils";
import useAppSelector from "hooks/useAppSelector";
import { useCallback, useState } from "react";

type Props = {
  transactionId: string;
  appointmentId: number;
};

export const useDeleteRefundOSW = () => {
  const sessionId = useAppSelector((state) => state.authentication.sessionId);
  const { updateCart } = useUpdateCart();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const deleteRefundOSW = useCallback(
    async ({ transactionId, appointmentId }: Props) => {
      setLoading(true);
      const URL = `${getOswUrl()}/api/payments/opp/refund/${transactionId}/${appointmentId}`;

      const response = await axios
        .delete(URL, {
          headers: {
            SessionId: sessionId,
          },
        })
        .catch((error) => {
          setError(error);
        });

      setData(response?.data);
      updateCart();
      setLoading(false);
    },
    [sessionId, updateCart]
  );

  return { deleteRefundOSW, data, loading, error };
};
