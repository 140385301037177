import Price from "components/Price/Price";
import { FC } from "react";

type Props = {
  title: string;
  subtitle?: string;
  day: string;
  time: string;
  people: string;
  price: string;
};

const CartElementOswConfirmation: FC<Props> = (props) => {
  return (
    <div className="mt-4 border-b print-border-0">
      <div className="sm:flex sm:justify-between sm:items-end pb-2">
        <div>
          <div className="sm:flex sm:items-baseline sm:flex-wrap">
            <div className="text-blue-dark text-lg sm:mr-2">{props.title}</div>
          </div>

          <div className="mt-1">{props.day}</div>
          <div className="mt-1">{props.time}</div>
          <div className="mt-1">{props.people}</div>
        </div>
        <div className="text-right">
          <Price price={props.price} />
        </div>
      </div>
    </div>
  );
};

export default CartElementOswConfirmation;
