import { FC } from "react";
import RadioButton from "components/Buttons/RadioButton/RadioButton";

type Props = {
  hidden?: boolean;
  iconAltText: string;
  iconSrc: string;
  title: string;
  checked: boolean;
  handleCheckboxChange: () => void;
};

const SelectableContentDividerWithIcon: FC<Props> = ({
  iconSrc,
  hidden,
  iconAltText,
  title,
  checked,
  handleCheckboxChange,
}) => {
  if (hidden) {
    return null;
  }

  return (
    <div className="mt-4 py-4 bg-blue-lightest flex items-center justify-between">
      <div>
        <img
          className="align-middle w-8 h-8 mx-4 fill-current inline-block"
          alt={iconAltText}
          src={iconSrc}
        />

        <h2 className="align-middle inline-block">{title}</h2>
      </div>

      <div className="flex items-center gap-4">
        <h2 className="items-center inline-block">Select Group Items To Pay</h2>
        <RadioButton
          label=""
          _onChange={() => handleCheckboxChange()}
          isSelected={checked}
        />
      </div>
    </div>
  );
};

export default SelectableContentDividerWithIcon;
