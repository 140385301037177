import { FC } from "react";

type Props = {
  hidden?: boolean;
  iconAltText: string;
  iconSrc: string;
  title: string;
};

const ContentDividerWithIcon: FC<Props> = ({
  iconSrc,
  hidden,
  iconAltText,
  title,
}) => {
  if (hidden) {
    return null;
  }

  return (
    <div className="mt-4 py-4 bg-blue-lightest">
      <img
        className="align-middle w-8 h-8 mx-4 fill-current inline-block"
        alt={iconAltText}
        src={iconSrc}
      />

      <h2 className="align-middle inline-block">{title}</h2>
    </div>
  );
};

export default ContentDividerWithIcon;
