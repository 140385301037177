import { FC, FormEvent, useState } from "react";
import CalendarPicker from "../../components/CalendarPicker";
import { imagePath } from "../../utils/imageUtils";
import TextInputWithLabel from "../../components/TextInputWithLabel";
import { getTexts } from "../../utils/loginPageUtils";
import { buildTestId } from "../../utils/buildTestId";
import { RouteComponentProps } from "react-router-dom";
import useAppDispatch from "../../hooks/useAppDispatch";
import { isDevelopmentMode, isTestMode } from "config/configUtils";
import If from "components/molecules/IF";
import SelectDropdown from "components/SelectDropdown";
import { setIsRiverUrl } from "actions/appConfigActions";
import useAppSelector from "hooks/useAppSelector";
import Checkbox from "components/Checkbox";
import { useLoginByPassenger } from "./hooks/useLoginByPassenger";
import { addMonths, subMonths } from "date-fns";

type OwnProps = RouteComponentProps & {};

export type BookingTypes = "RiverCruise" | "OceanCruise";

type StateProps = {
  departureDate: string;
  bookingReference: string;
  leadPassengerLastName: string;
  showError: boolean;
  isSubmitDisabled: boolean;
};

type LoginPageInfoTextProps = {
  classes?: string;
};

const PassengerLoginForm: FC<OwnProps> = ({ history }) => {
  const {
    postLoginByPassenger,
    postLoginByPassengerIsPending,
    postLoginByPassengerError,
  } = useLoginByPassenger();

  const dispatch = useAppDispatch();
  const isRiverUrl = useAppSelector((state) => state.appConfig.isRiverUrl);
  const isAgency = useAppSelector((state) => state.agency.isAuthenticated);
  const bookingType: BookingTypes = isRiverUrl ? "RiverCruise" : "OceanCruise";
  const texts = getTexts(isRiverUrl);
  const [shouldLoginAsAgency, setShouldLoginAsAgency] = useState(false);
  const [state, setState] = useState<StateProps>({
    departureDate: "",
    bookingReference: "",
    leadPassengerLastName: "",
    showError: false,
    isSubmitDisabled: false,
  });

  const minDate = isAgency ? subMonths(new Date(), 6) : new Date();
  const maxDate = addMonths(new Date(), 15);

  const handleFormChange = (field: keyof typeof state, value: any) => {
    const newState: any = { ...state };
    newState[field] = value;

    newState.isSubmitDisabled =
      newState.departureDate === "" ||
      newState.bookingReference === "" ||
      newState.leadPassengerLastName === "";

    setState(newState);
  };

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();

    await postLoginByPassenger({
      departureDate: state.departureDate,
      bookingReference: state.bookingReference,
      bookingType: bookingType,
      lastName: state.leadPassengerLastName,
      shouldLoginAsAgency,
    });
  };

  const LoginPageInfoText: FC<LoginPageInfoTextProps> = ({ classes }) => {
    const classNames = `w-full text-grey-darker mt-6 ${classes ? classes : ""}`;

    return (
      <div className={classNames}>
        <p>{texts.infoDescription}</p>
        {texts.infoItems.length > 0 && (
          <ul className="list-square list-inside mt-4">
            {texts.infoItems.map((infoItem, index) => (
              <li key={index} className="mt-2">
                {infoItem}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  return (
    <div className="container mx-auto">
      <h1 className="normal-case text-5xl mb-6">{texts.title}</h1>

      <LoginPageInfoText classes="hidden md:block" />
      <form
        className="mb-6 md:mb-8"
        onSubmit={async (e) => await handleLogin(e)}
      >
        <div className="flex flex-wrap">
          <LoginPageInfoText classes="block md:hidden" />
          <div className="w-full md:w-2/5 xl:w-1/3 mt-6 p-4 bg-grey-lightest-2 border">
            <div className="sm:w-1/2 md:w-full">
              <div>
                <label
                  htmlFor="login-datepicker"
                  className="input-label"
                  data-testid={buildTestId("label", "datepicker")}
                >
                  Departure date (DD/MM/YYYY)
                </label>
                <CalendarPicker
                  id="login-datepicker"
                  onChange={(value) => handleFormChange("departureDate", value)}
                  className="input-field text-black mt-1"
                  initialMinDate={minDate}
                  initialMaxDate={maxDate}
                />
              </div>
              <div className="mt-4">
                <TextInputWithLabel
                  classNames="w-full"
                  label="Booking reference"
                  subLabel="This is on your booking confirmation"
                  subLabelIcon="infoicon_inverted.svg"
                  value={state.bookingReference}
                  _onChange={(value) =>
                    handleFormChange("bookingReference", value)
                  }
                  placeholder="12345678"
                />
              </div>
              <div className="mt-4">
                <TextInputWithLabel
                  classNames="w-full"
                  label="Lead passenger surname"
                  value={state.leadPassengerLastName}
                  _onChange={(value) =>
                    handleFormChange("leadPassengerLastName", value)
                  }
                  placeholder="Enter Surname"
                />
              </div>
              <If test={isDevelopmentMode() || isTestMode()}>
                <div className="my-4 flex">
                  <SelectDropdown
                    classes="w-full"
                    selectId="booking-type-dropdown"
                    label="Select booking type"
                    options={["RiverCruise", "OceanCruise"]}
                    handleChange={(e) =>
                      dispatch(setIsRiverUrl(e.target.value === "RiverCruise"))
                    }
                    value={bookingType}
                    error={undefined}
                  />
                </div>
                <div className="my-4 flex">
                  <Checkbox
                    label={`Login as agency`}
                    checked={shouldLoginAsAgency}
                    handleCheckboxChange={() =>
                      setShouldLoginAsAgency((prevState) => !prevState)
                    }
                  />
                </div>
              </If>
            </div>
          </div>
          <div className="w-full md:w-3/5 xl:w-2/3 order-first md:order-none md:pl-4 mt-6">
            <div className="bg-blue-lightest-25 shadow h-full px-4 py-2">
              <div className="relative w-full h-full flex items-center justify-center">
                <img
                  className="md:absolute max-h-full max-w-sm md:max-w-full"
                  alt={texts.imageAltText}
                  src={imagePath(texts.imageFileName)}
                />
              </div>
            </div>
          </div>
          {postLoginByPassengerError && (
            <div className="w-full mt-4 errors">
              Login Failed, please check your data and try again.
            </div>
          )}

          <div className="mt-6 w-full sm:w-1/2 md:w-2/5 xl:w-1/3">
            <input
              type="submit"
              disabled={state.isSubmitDisabled || postLoginByPassengerIsPending}
              className={`w-full action-button ${
                state.isSubmitDisabled
                  ? "action-button-disabled"
                  : "action-button-enabled"
              }`}
              value="Continue"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default PassengerLoginForm;
