import { useEffect } from "react";
import { usePreCruiseContext } from "../context/PreCruiseContext";
import { getFromHub } from "../actions/networkActions";

const usePreCruisePassenger = (
  passengerId: string,
  token: string | null = null
) => {
  const { preCruiseInfo, setPreCruiseInfo } = usePreCruiseContext();

  useEffect(() => {
    if (preCruiseInfo?.passengers) {
      return;
    }

    let isCancelled = false;

    const fetchData = async () => {
      const url = token ? `passengers/all/${token}` : "passengers/all";
      const response = await getFromHub(url);

      if (!isCancelled) {
        setPreCruiseInfo({
          passengers: response.map((passenger: any) => ({
            ...passenger,
            passengerId: passenger.id,
          })),
        });
      }
    };

    fetchData();

    return () => {
      isCancelled = true;
    };
  }, [passengerId, token]);

  return (
    preCruiseInfo?.passengers?.find(
      (pax: any) => pax.passengerId === passengerId
    ) || {}
  );
};

export default usePreCruisePassenger;
