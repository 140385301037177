import { FC } from "react";
import CartElementOpenRefund from "../CartElementOpenRefund";

type Props = {
  excursion: any;
};

const CartExcursionEntryOpenRefund: FC<Props> = (props) => {
  const excursion = props.excursion;
  const tooltipId =
    "open-refund-" +
    excursion.itineraryId +
    excursion.excursionId +
    excursion.passengers.join("-");
  return (
    <CartElementOpenRefund
      name={excursion.excursionName}
      imageReference={excursion.excursionId}
      imageCategory="cruiseExcursion"
      linkTo={`/excursion/${excursion.itineraryId}/${excursion.excursionId}`}
      tooltipId={tooltipId}
    >
      <ul className="list-reset">
        {excursion.passengers.map((passenger: any, index: number) => (
          <li key={index}>{passenger}</li>
        ))}
      </ul>
    </CartElementOpenRefund>
  );
};

export default CartExcursionEntryOpenRefund;
