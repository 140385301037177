import { Switch, useRouteMatch } from "react-router-dom";
import { PreCruiseProvider } from "../../context/PreCruiseContext";
import PassengerOverview from "../passengerInformation/PassengerOverview";
import PassengersOverview from "../passengerInformation/PassengersOverview";
import PassengerFormPage from "../passengerInformation/PassengerFormPage";
import PassengerUnlock from "../passengerInformation/PassengerUnlock";
import PassengerReview from "../passengerInformation/PassengerReview";
import PassengerLockForm from "../passengerInformation/PassengerLockForm";
import ForgottenPin from "../../components/passengerInformation/ForgottenPin";
import ResetPin from "../../components/passengerInformation/ResetPin";
import ResetData from "../../components/passengerInformation/ResetData";
import CovidSurvey from "../../components/passengerInformation/CovidSurvey";
import ResetPinSuccess from "../../components/passengerInformation/ResetPinSuccess";
import ResetDataSuccess from "../../components/passengerInformation/ResetDataSuccess";
import GuestRouteWithLogout from "../../components/routing/GuestRouteWithLogout";
import GuestOverview from "../passengerInformation/GuestOverview";
import GuestLockForm from "../passengerInformation/GuestLockForm";
import AuthRoute from "../../components/routing/AuthRoute";

const PrecruiseRoutes = () => {
  let { path } = useRouteMatch();

  return (
    <PreCruiseProvider>
      <Switch>
        <AuthRoute exact path={path} component={Default} />

        <AuthRoute path={`${path}/passengers`} component={PassengersOverview} />
        <AuthRoute
          path={`${path}/passenger/:passengerId/finish`}
          component={PassengerLockForm}
        />
        <AuthRoute
          path={`${path}/passenger/:passengerId/form`}
          component={PassengerFormPage}
        />
        <AuthRoute
          path={`${path}/passenger/:passengerId/forgottenPin`}
          component={ForgottenPin}
        />
        <AuthRoute
          path={`${path}/passenger/:passengerId/reset`}
          component={ResetData}
        />
        <AuthRoute
          path={`${path}/passenger/:passengerId/resetSuccess`}
          component={ResetDataSuccess}
        />
        <AuthRoute
          path={`${path}/passenger/:passengerId/resetPin/:token`}
          component={ResetPin}
        />
        <AuthRoute
          path={`${path}/passenger/:passengerId/resetPinSuccess`}
          component={ResetPinSuccess}
        />
        <AuthRoute
          path={`${path}/passenger/:passengerId/review`}
          component={PassengerReview}
        />
        <AuthRoute
          path={`${path}/passenger/:passengerId/unlock`}
          component={PassengerUnlock}
        />
        <AuthRoute
          path={`${path}/passenger/:passengerId`}
          component={PassengerOverview}
        />
        <AuthRoute path={`${path}/covidSurvey`} component={CovidSurvey} />

        <GuestRouteWithLogout
          path={`${path}/guest/:passengerId/finish/:token`}
          component={GuestLockForm}
        />
        <GuestRouteWithLogout
          path={`${path}/guest/:passengerId/form/:token`}
          component={PassengerFormPage}
        />
        <GuestRouteWithLogout
          path={`${path}/guest/:passengerId/review/:token`}
          component={PassengerReview}
        />
        <GuestRouteWithLogout
          path={`${path}/guest/:token`}
          component={GuestOverview}
        />
      </Switch>
    </PreCruiseProvider>
  );
};

export default PrecruiseRoutes;

const Default = () => {
  return <div>Default precruise route</div>;
};
