import { IExcursionProduct } from "interfaces/Musement/IExcursionProduct";

const TYPE_ADULT = "ADULT";
const TYPE_CHILDREN = "CHILDREN";

type Props = {
  products: IExcursionProduct[];
};

type PriceProps = {
  adult: number | null;
  children: number | null;
};

export const useGetExcursionPricesFromStoreByExcursionId = ({
  products,
}: Props) => {
  const calculatedPrices: PriceProps = {
    adult: null,
    children: null,
  };

  products?.forEach((product: IExcursionProduct) => {
    if (product.holder_code_normalized === TYPE_ADULT) {
      calculatedPrices.adult = product.original_retail_price.value;
    } else if (product.holder_code_normalized === TYPE_CHILDREN) {
      calculatedPrices.children = product.original_retail_price.value;
    }
  });

  return { calculatedPrices };
};
