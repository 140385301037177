import Price from "components/Price/Price";
import ModalFooter from "components/modals/ModalFooter";
import { FC, useState } from "react";
import { usePostAddToPlanner } from "../hooks/usePostAddToPlanner";
import { buildTestId } from "utils/buildTestId";
import RadioButton from "components/Buttons/RadioButton/RadioButton";
import Loader from "components/atoms/Loader";
import { useUpdateCart } from "actions/useUpdateCart";
import { IPassenger } from "interfaces/Common/IPassenger";

export type Props = {
  passengers: IPassenger[];
  price: number;
  treatmentId?: number;
  itineraryId: string;
  openTimeSlotId?: number;
  openSlotStartDateTime?: string;
  onCancel: () => void;
  onSuccess: ({ img, message }: { img: string; message: string }) => void;
  onError: ({ img, message }: { img: string; message: string }) => void;
};

const INITIAL_PASSENGER_DETAILS = {
  apiStatus: "",
  cardLinked: false,
  cardRegistrationStatus: "",
  cashOptedIn: false,
  covidSurveyStatus: "",
  dob: "",
  firstName: "",
  gender: "",
  isEmailLinkEnabled: false,
  isLead: true,
  isTravelInfoSecured: false,
  lastName: "",
  passengerId: "",
  title: "",
  travelInfoStatus: "",
  type: "",
};

const SUCCESS_MESSAGE = "Added to Planner Successfully";
const ERROR_MESSAGE =
  "We’re sorry, but your booking can’t be processed at the moment – please try again later.";

const OneSpaWorldAddToPlannerForm: FC<Props> = ({
  passengers,
  treatmentId,
  itineraryId,
  openTimeSlotId,
  openSlotStartDateTime,
  price,
  onCancel,
  onSuccess,
  onError,
}) => {
  const { postAddToPlanner, data, loading, error } = usePostAddToPlanner();
  const { updateCart } = useUpdateCart();
  const [selectedPassenger, setSelectedPassenger] = useState(
    INITIAL_PASSENGER_DETAILS
  );

  const handleCheckboxChange = (passengerId: string) => {
    const foundPassenger =
      passengers?.find((passenger) => passenger.passengerId === passengerId) ??
      INITIAL_PASSENGER_DETAILS;
    setSelectedPassenger(foundPassenger);
  };

  const addToCart = async () => {
    if (treatmentId && openTimeSlotId && openSlotStartDateTime) {
      await postAddToPlanner({
        treatmentId,
        itineraryId,
        openTimeSlotId,
        price,
        // TODO There is no option to add comments for now
        comments: "",
        guestId: selectedPassenger.passengerId,
        openSlotStartDateTime,
      });

      await updateCart();
    }
  };

  if (loading) {
    return <Loader text="Adding to planner..." />;
  }

  if (data) {
    onSuccess({
      img: "success-tick-green.svg",
      message: SUCCESS_MESSAGE,
    });
  }

  if (error) {
    onError({
      img: "close.png",
      message: ERROR_MESSAGE,
    });
  }

  return (
    <>
      <div
        data-testid={buildTestId(
          "container",
          "spa treatment add to planner form"
        )}
        className="flex flex-col flex-grow"
      >
        <div className="flex-grow bg-white py-4 px-2 sm:px-4">
          <div className="font-bold">Who's the treatment for?</div>
          <div className="bg-blue-lightest px-4 py-2 mt-4">
            {passengers.map((passenger: IPassenger) => (
              <div className="my-2" key={passenger.passengerId}>
                <RadioButton
                  label={`${passenger.firstName} ${passenger.lastName}${
                    passenger?.isLead ? " (Lead)" : ""
                  }`}
                  isDisabled={passenger?.type !== "Adult"}
                  _onChange={() => handleCheckboxChange(passenger.passengerId)}
                  isSelected={
                    passenger.passengerId === selectedPassenger?.passengerId
                  }
                />
              </div>
            ))}
          </div>

          <Price preText="Total price" price={price.toString()} />
        </div>

        <ModalFooter
          onCancel={onCancel}
          actionDisabled={selectedPassenger?.passengerId === ""}
          actionButtonText={"Add to planner"}
          onActionClick={addToCart}
        />
      </div>
    </>
  );
};

export default OneSpaWorldAddToPlannerForm;
