import { Link } from "react-router-dom";
import BackLink from "../../components/molecules/BackLink";
import { imagePath } from "../../utils/imageUtils";
import useTravelDocs from "../../hooks/useTravelDocs";
import DownloadButton from "../../components/pdf/DownloadButton";

const LuggageTag = () => {
  const { passengers, bookingData } = useTravelDocs();

  if (bookingData.cabinAllocated === null) {
    return (
      <div className="container mx-auto mt-4">
        <p>loading...</p>
      </div>
    );
  }

  if (bookingData.cabinAllocated === false) {
    return (
      <div className="container mx-auto mt-4">
        <div className="mb-4">
          <BackLink to="/" text="Back to Cruise Control Dashboard" />
        </div>
        <p>
          Thank you for preparing to print your luggage labels. Unfortunately,
          the luggage tags are not available at the moment.
        </p>

        <p>If you can’t see your luggage labels, don’t worry.</p>

        <p>
          If you have access to a printer, we have blank luggage labels for you
          to print and write your cabin number on. Give us a ring, pop into your
          local store or message us on social media or live chat – we’ll send
          you a copy.
        </p>

        <p>
          If you don’t have access to a printer, you can write your cabin number
          on any other label instead.
        </p>

        <p>
          If your cabin number doesn’t look right, check in as normal at your UK
          airport and we’ll take care of all the details when you get to the
          port terminal.
        </p>
        <p>Sea you soon.</p>
      </div>
    );
  }

  if (bookingData.error) {
    return (
      <div className="container mx-auto mt-4">
        <p>There has been an error, please try again later.</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto mt-4">
      <BackLink to="/" text="Back to Cruise Control Dashboard" />

      <div className="md:flex">
        <div className="w-full md:w-2/3 md:pr-4">
          <h1>Luggage tag</h1>
          <div className="md:hidden">
            <img
              className="w-full mt-6"
              src={imagePath("luggage_tag_circpe.svg")}
              alt=""
            />
          </div>

          <div className="mt-4">
            <p className="text-lg text-grey-darker">
              <p>Please use the button below to download your luggage tag.</p>
              <p>
                Please print one for every item of luggage and follow the
                instructions to add a label to a handle on each piece of
                luggage.
              </p>
              <p>
                Printing and attaching your luggage labels to your bags is an
                important part of preparing to sail with us. It makes sure your
                luggage is with you as quickly as possible onboard.
              </p>
              <p>
                Don't have access to a printer? You can write your cabin number
                on any other label instead.
              </p>
              <p>
                If your cabin number doesn’t look right, check in as normal at
                your UK airport and we’ll take care of all the details when you
                get to the port terminal.
              </p>
            </p>
          </div>

          <div className="mt-6">
            <DownloadButton
              type="luggage"
              bookingData={bookingData}
              passengers={passengers}
              label="Download luggage tag"
            />
          </div>

          <div className="mt-4">
            <Link
              className="block action-button action-button-enabled w-mobile"
              to="/luggage/instructions"
            >
              Folding instructions
            </Link>
          </div>

          <div className="mt-4 mb-12">
            <Link
              className="hidden xl:block action-button action-button-enabled w-mobile"
              to={{
                pathname: "/luggage/detail",
                state: {
                  passengers,
                  bookingData,
                },
              }}
            >
              View luggage tag
            </Link>
          </div>
        </div>
        <div className="hidden md:flex md:w-1/3 items-center pl-4">
          <img
            className="w-full"
            src={imagePath("luggage_tag_circpe.svg")}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default LuggageTag;
