import { useCallback, useEffect, useState } from "react";
import { clearPayment } from "../../actions/paymentActions";
import PaymentConfirmationOverview from "../../components/PaymentConfirmationOverview";
import BackLink from "../../components/molecules/BackLink";
import { orderCart } from "../../actions/cartActions";
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";

const PaymentConfirmationB2C = () => {
  const dispatch = useAppDispatch();

  const payment = useAppSelector((state) => state.payment);

  const [orderError, setOrderError] = useState<boolean | null>(null);
  const [orderRequestResult, setOrderRequestResult] = useState<any>(null);

  const onOrderCompleted = useCallback(
    (response: any) => {
      setOrderRequestResult(response);
      setOrderError(false);

      dispatch(clearPayment());
    },
    [dispatch]
  );

  const onOrderError = useCallback((response: any) => {
    setOrderError(true);
    setOrderRequestResult(response);
    console.error("error while executing order, response: ", response);
  }, []);

  const _order = useCallback(() => {
    let payload = {
      confirmationMail: payment.confirmationMail,
      paymentReference: payment.paymentReference,
      mobileNumber: payment.mobileNumber,
      tomcatInstance: payment.tomcatInstance,
    };

    orderCart(payload, onOrderCompleted, onOrderError);
  }, [
    onOrderCompleted,
    onOrderError,
    payment.confirmationMail,
    payment.mobileNumber,
    payment.paymentReference,
    payment.tomcatInstance,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    _order();
  }, [_order]);

  if (orderError === null) {
    return (
      <div>
        <div className="container mx-auto mt-4 text-blue-dark">
          <div className="xl:inline xl:pr-2 text-4xl">
            Placing your order...
          </div>
        </div>
      </div>
    );
  }

  if (orderError) {
    if (orderRequestResult?.response?.data === "ShoppingCart NOT bookable") {
      return (
        <div className="container mx-auto mt-4 text-blue-dark">
          <div className="text-4xl">
            As the sold out item was not bookable, no payment has been taken
            from you for this booking. Please resubmit your booking.
          </div>
          <div className="my-4">
            <BackLink to="/itinerary" text="Return to Cruise control Planner" />
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="container mx-auto mt-4 text-blue-dark">
          <div className="xl:inline xl:pr-2 text-4xl">
            We’re sorry, something went wrong while placing your order. Please
            contact Customer Services
          </div>
          <div className="mt-2 mb-2">
            <a
              className="text-blue"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.tui.co.uk/destinations/contact-us"
            >
              https://www.tui.co.uk/destinations/contact-us
            </a>
          </div>
          <div className="mb-4">
            <BackLink to="/itinerary" text="Return to Cruise control Planner" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <PaymentConfirmationOverview
      bookingNr={orderRequestResult.groupReference}
    />
  );
};

export default PaymentConfirmationB2C;
