import { FC, useEffect, useState } from "react";
import Price from "./Price/Price";
import Checkbox from "./Checkbox";
import { getFromHub, postToHub } from "../actions/networkActions";
import ModalFooter from "./modals/ModalFooter";
import { useUpdateCart } from "actions/useUpdateCart";
import { IPassenger } from "interfaces/Common/IPassenger";

type AddToPlannerFormProps = {
  itineraryId: string;
  excursionId: string;
  adultPrice: number;
  childPrice: number;
  onCancel: () => void;
  onSuccess: (text: string) => void;
};

type BookingInformation = {
  passengers: IPassenger[];
  bookingInformation: string;
  numberOfAvailabilities: number;
};

type StatusMessages = {
  [key: string]: string;
};

const AddToPlannerForm: FC<AddToPlannerFormProps> = ({
  excursionId,
  itineraryId,
  adultPrice,
  childPrice,
  onCancel,
  onSuccess,
}) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [passengers, setPassengers] = useState<any>([]);
  const [bookingInformationId, setBookingInformationId] = useState("");
  const [initialSelected, setInitialSelected] = useState(0);
  const [nrAdultsBooked, setNrAdultsBooked] = useState(0);
  const [updateCartText, setUpdateCartText] = useState("");
  const [successText, setSuccessText] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [nrAvailable, setNrAvailable] = useState(0);

  const { updateCart } = useUpdateCart();

  const statusMessages: StatusMessages = {
    Bookable: "",
    AlreadyInCart: "",
    NoPriceAvailable: "No price available",
    TimeOverlapWithOtherBooking: "Time overlap with another booking",
    AlreadyBooked: "Already booked",
    TimeOverlapWithOtherCartEntry: "Time overlap with another planner entry",
    FirstCruiseDay: "First day of the cruise",
    LastCruiseDay: "Last day of the cruise",
    MinimumAge: "Below minimum age for this excursion",
    MaximumAge: "Above the maximum age for this excursion",
  };

  const updateBookingInformation = (bookingInformation: BookingInformation) => {
    let nrSelected = 0;
    let nrAdultsBooked = 0;

    const updatedPassengers = bookingInformation.passengers.map(
      (passenger: any) => {
        passenger.selected =
          passenger.bookable && passenger.status === "AlreadyInCart";
        passenger.reason = statusMessages[passenger.status];
        passenger.isAdult = passenger.ageType === "Adult";

        if (passenger.selected) {
          nrSelected++;
        }

        if (passenger.isAdult && passenger.status === "AlreadyBooked") {
          nrAdultsBooked++;
        }

        return passenger;
      }
    );

    setPassengers(updatedPassengers);
    setNrAdultsBooked(nrAdultsBooked);
    setHasLoaded(true);
    setInitialSelected(nrSelected);
    setBookingInformationId(bookingInformation.bookingInformation);
    setNrAvailable(bookingInformation.numberOfAvailabilities);
  };

  const getTotalPrice = () => {
    return passengers.reduce((total: number, passenger: any) => {
      if (passenger.selected) {
        return total + passenger.price;
      }
      return total;
    }, 0);
  };

  const handleCheckboxChange = (id: string) => {
    let nrSelected: number = 0;
    let nrAdultsSelected: number = 0;

    const updatedPassengers = passengers.map((passenger: any) => {
      if (passenger.id === id) {
        passenger.selected = !passenger.selected;
      }
      if (passenger.selected) {
        nrSelected++;

        if (passenger.isAdult) {
          nrAdultsSelected++;
        }
      }
      return passenger;
    });

    const { updateCartText, successText, errorMessage } =
      chooseTextsBasedOnSelectedPassengers(nrSelected, nrAdultsSelected);

    setPassengers(updatedPassengers);
    setUpdateCartText(updateCartText);
    setSuccessText(successText);
    setErrorMessage(errorMessage);
  };

  const chooseTextsBasedOnSelectedPassengers = (
    nrSelected: number,
    nrAdultsSelected: number
  ) => {
    let updateCartText = "";
    let successText = "";
    let errorMessage = "";

    if (nrSelected > nrAvailable) {
      errorMessage = `Too many passengers selected, this excursion only has
                            ${nrAvailable} space${
        nrAvailable !== 1 ? "s" : ""
      } left`;

      return {
        updateCartText,
        successText,
        errorMessage,
      };
    }

    //only children selected and no adult already booked
    if (nrAdultsBooked === 0 && nrSelected > 0 && nrAdultsSelected === 0) {
      errorMessage = "Adults must accompany Children/Infant";
    }
    //at least one adult selected or no passengers selected or at least one adult already booked
    else {
      if (initialSelected > 0) {
        if (nrSelected > 0) {
          updateCartText = "Update planner";
          successText = "Excursion updated in your planner";
        } else {
          updateCartText = "Remove from planner";
          successText = "Excursion removed from your planner";
        }
      } else {
        if (nrSelected > 0) {
          updateCartText = "Add to planner";
          successText = "Excursion added to your planner";
        }
      }
    }

    return {
      updateCartText,
      successText,
      errorMessage,
    };
  };

  const addToCart = async () => {
    const data = passengers.map((passenger: any) => {
      return {
        bookingInformation: bookingInformationId,
        passenger: passenger.id,
        action: passenger.selected ? "add" : "remove",
      };
    });

    await postToHub("prebooking/cart", data);
    onSuccess(successText);
    await updateCart();
  };

  useEffect(() => {
    getFromHub(
      `prebooking/cruises/itinerary/${itineraryId}/excursions/${excursionId}/bookingInformation`,
      updateBookingInformation
    );
  }, [itineraryId, excursionId]);

  return (
    <>
      {!hasLoaded ? (
        <div>Loading...</div>
      ) : (
        <div className="flex flex-col flex-grow">
          <div className="flex-grow bg-white py-4 px-2 sm:px-4">
            <div className="font-bold">Who's travelling on the excursion?</div>
            <div>
              £{adultPrice} per adult, £{childPrice || "/"} per child (2-12)
            </div>

            <div className="bg-blue-lightest px-4 py-2 mt-4">
              {passengers.map((passenger: any) => (
                <div className="my-2" key={passenger.id}>
                  <Checkbox
                    label={`${passenger.firstName} ${passenger.lastName}`}
                    checked={passenger.selected}
                    disabled={!passenger.bookable}
                    disabledReason={passenger.reason}
                    handleCheckboxChange={() =>
                      handleCheckboxChange(passenger.id)
                    }
                  />
                </div>
              ))}
            </div>

            <Price preText="Total price" price={getTotalPrice()} />
            {errorMessage && <div className="errors mt-2">{errorMessage}</div>}
          </div>

          <ModalFooter
            onCancel={onCancel}
            actionDisabled={updateCartText === ""}
            actionButtonText={updateCartText || "Add to planner"}
            onActionClick={addToCart}
          />
        </div>
      )}
    </>
  );
};

export default AddToPlannerForm;
