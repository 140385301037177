import { FC } from "react";
import CartElementCheckout from "../CartElementCheckout";
import { formatCartElementDate } from "utils/dateTimeUtils";

type Props = {
  entry: any;
};
const CartElementTacCheckout: FC<Props> = (props) => {
  const entry = props.entry;
  const nrGuests = entry.nrAdults + entry.nrChildren;

  return (
    <CartElementCheckout
      name={entry.name}
      imageReference={entry.serviceProfileId}
      linkTo={{ pathname: "/tac/restaurants", state: { date: entry.date } }}
      pricePreText="Restaurant cost:"
      price={entry.price}
    >
      <div>
        On day {entry.dayNr} ({formatCartElementDate(entry.date)})
      </div>
      <div className="mt-2">
        Table for {nrGuests} {nrGuests > 1 ? "people" : "person"} at{" "}
        <span className="font-bold">{entry.time}</span>
      </div>
    </CartElementCheckout>
  );
};

export default CartElementTacCheckout;
