import { FC, useCallback, useEffect, useState } from "react";
import { formatPortDate, formatTimeHM } from "../../utils/dateTimeUtils";
import "moment/locale/en-gb";
import _union from "lodash/union";
import PlannerContainer from "../../components/PlannerContainer";
import { getFromHub } from "../../actions/networkActions";
import BackLink from "../../components/molecules/BackLink";

// custom css for excursion page
import "../../resources/css/excursion.css";
import useAppSelector from "../../hooks/useAppSelector";
import FilteredExcursions from "./FilteredExcursions";
import { RouteComponentProps } from "react-router-dom";

const ExcursionsList: FC<
  RouteComponentProps<{
    itineraryId: string;
  }>
> = ({ match }) => {
  const itinerary = useAppSelector((state) =>
    state.itinerary.find(
      (itinerary: any) => itinerary.id === match.params.itineraryId
    )
  );

  const [excursions, setExcursions] = useState([]);
  const [typeFilterOptions, setTypeFilterOptions] = useState();

  //take only data we will use from the response
  const parseExcursionsData = useCallback((response: any) => {
    return response.map((responseEntry: any) => {
      responseEntry.excursion.excursionTypes =
        responseEntry.excursion.categories.map(
          (category: any) => category.name
        );
      responseEntry.excursion.currentAvailability =
        responseEntry.currentAvailability;
      responseEntry.excursion.itinerary = responseEntry.itinerary.id;
      return responseEntry.excursion;
    });
  }, []);

  const updateExcursions = useCallback(
    (response: any) => {
      setExcursions(parseExcursionsData(response));
    },
    [parseExcursionsData]
  );

  useEffect(() => {
    getFromHub(
      `prebooking/cruises/itinerary/${match.params.itineraryId}/excursions`,
      updateExcursions
    );
  }, [match.params.itineraryId, updateExcursions]);

  useEffect(() => {
    const excursionTypes: any = _union(
      ...excursions.map((excursion: any) => excursion.excursionTypes)
    );

    let typeFilterOptionsToSet: any = {};

    for (const excursionType of excursionTypes) {
      typeFilterOptionsToSet[excursionType] = {
        selected: false,
        available: true,
      };
    }

    setTypeFilterOptions(typeFilterOptionsToSet);
  }, [excursions]);

  if (!itinerary) {
    return null;
  }

  return (
    <div>
      <PlannerContainer />

      {/*basic itinerary info*/}
      <div className="container mx-auto my-4 text-blue-dark">
        <BackLink to="/itinerary" text="Cruise Planner" />
        <h1>
          {excursions.length} excursions from{" "}
          {itinerary.port.name.defaultValue?.toUpperCase()}
        </h1>
        <div className="text-2xl">
          DAY {itinerary.dayOfCruise} ({formatPortDate(itinerary.date)})
        </div>
        <div className="text-3xl">
          Time in port: {formatTimeHM(itinerary.arrival, "Previous day")} -{" "}
          {formatTimeHM(itinerary.departure, "Next day")}
        </div>
      </div>

      {/*filters and filtered excursions*/}
      {excursions.length > 0 && (
        <FilteredExcursions
          excrusionFilterOptions={typeFilterOptions}
          excursions={excursions}
        />
      )}
    </div>
  );
};

export default ExcursionsList;
