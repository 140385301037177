const AppInfo = () => {
  return (
    <div className="flex flex-col items-center">
      <h4>App Information</h4>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Version</td>
            <td>{process.env.REACT_APP_VERSION}</td>
          </tr>
          <tr>
            <td>Application Name</td>
            <td> {process.env.REACT_APP_NAME}</td>
          </tr>
          <tr>
            <td>React App Process Environment</td>
            <td>{process.env.REACT_APP_ENV}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AppInfo;
