import { FC, useEffect } from "react";
import ReactDOM from "react-dom";
import { useSurveyContext } from "../../../context/SurveyContext";
import RadioQuestion from "./RadioQuestion";
import TextQuestion from "./TextQuestion";
import TextAreaQuestion from "./TextAreaQuestion";
import CheckboxQuestion from "./CheckboxQuestion";
import { getAnswerErrorMessage } from "utils/questionnaireUtils";
import DatePickerQuestion from "./DatePickerQuestion";
import { IQuestion } from "interfaces/Travelnformation/IQuestion";
import EmailConfirmQuestion from "./EmailConfirm";

type Props = {
  question: IQuestion;
  activePart: number;
  isFirst?: boolean;
};

const Question: FC<Props> = ({ question, activePart, isFirst }) => {
  const {
    setActiveField,
    survey: { errors },
    dispatch,
  } = useSurveyContext();

  useEffect(() => {
    setActiveField(question.id, true);

    return () => {
      setActiveField(question.id, false);
    };
  }, [question.id, setActiveField]);

  const validate = (
    question: { id: any; validation: string[] },
    answer: any,
    secondaryAnswer?: string
  ) => {
    const errorMessage = getAnswerErrorMessage(
      question,
      answer,
      secondaryAnswer
    );

    const type = errorMessage ? "SET_ERROR" : "REMOVE_ERROR";

    dispatch({
      type,
      questionId: question.id,
      errorMessage,
    });
  };

  const isShown = question.part === activePart;

  const commonProps = {
    question: question,
    activePart: activePart,
    validate,
  };

  let questionElement = <div>Unknown question type {question.type}</div>;
  switch (question.type) {
    case "radio":
      questionElement = <RadioQuestion {...commonProps} />;
      break;

    case "text":
      questionElement = <TextQuestion {...commonProps} />;
      break;

    case "email":
      questionElement = <EmailConfirmQuestion {...commonProps} />;
      break;

    case "textarea":
      questionElement = <TextAreaQuestion {...commonProps} />;
      break;

    case "checkbox":
      questionElement = <CheckboxQuestion {...commonProps} />;
      break;

    case "datepicker":
      questionElement = <DatePickerQuestion {...commonProps} />;
      break;

    default:
      break;
  }

  const questionContent = (
    <div className={isFirst ? "" : isShown ? "mt-6" : ""}>
      {/* 
      // @ts-ignore */}
      {question.sectionHeader && (
        <h1 className={isShown ? "mt-6" : "hidden"}>
          {/* 
          // @ts-ignore */}
          {question.sectionHeader}
        </h1>
      )}

      {questionElement}

      {errors[question.id] && isShown && (
        <div className="mt-2 text-red">{errors[question.id]}</div>
      )}
    </div>
  );

  const lastQuestionsDiv = document.getElementById("last-questions-portal");
  // @ts-ignore
  if (question.moveToEndOfPage && lastQuestionsDiv) {
    return ReactDOM.createPortal(questionContent, lastQuestionsDiv);
  }

  return questionContent;
};

export default Question;
