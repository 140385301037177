import axios from "axios";
import useAppSelector from "hooks/useAppSelector";
import { useQuery } from "@tanstack/react-query";
import { getPrebookUrl } from "config/configUtils";
import { IRiverGuestInfo } from "interfaces/Travelnformation/IRiverGuestInfo";

export const RiverGuestInitialData = {
  additionalInformation: "",
  id: "",
  paxId: "",
  passengerId: "",
  hotelfriendExternalId: "",
  gender: "",
  birthDate: "",
  nationality: "",
  embarkationDate: "",
  disembarkationDate: "",
  language: "",
  contactInformation: {
    id: "",
    phoneNumber: "",
    email: "",
  },
  passportInformation: null,
  dietaryInformation: {
    id: "",
    restriction: "",
  },
  isLead: false,
  isTravelInfoCompleted: false,
  isTravelInfoLocked: false,
};

export const useGetRiverGuestInfo = (passengerId: string) => {
  const sessionId = useAppSelector((state) => state.authentication.sessionId);

  const URL = `${getPrebookUrl()}/api/hotelfriend/guests/${passengerId}`;

  const { refetch, status, data, error, isFetching, isSuccess } = useQuery({
    queryKey: ["useGetRiverGuestInfo", passengerId],
    queryFn: async () =>
      await axios
        .get(URL, {
          headers: {
            SessionId: sessionId,
          },
        })
        .then((res) => res.data as Promise<IRiverGuestInfo>)
        .catch(() => RiverGuestInitialData),
    refetchOnWindowFocus: false,
    enabled: !!passengerId,
    gcTime: 0,
    staleTime: 0,
  });

  return {
    refetchRiverGuestInfo: refetch,
    riverGuestInfoData: data ?? RiverGuestInitialData,
    status,
    error,
    isFetching,
    isSuccess,
  };
};
