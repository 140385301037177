import { convertYMDtoDMY, formatDuration } from "utils/dateTimeUtils";

type BookingPartPassengerExcursionDetails = {
  excursion: {
    id: string;
    name: { defaultValue: string };
    duration: number;
  };
  itinerary: {
    id: string;
    date: string;
    port: {
      name: { defaultValue: string };
    };
  };
  price: number;
  passenger: {
    firstName: string;
    lastName: string;
  };
};

type Excursion = {
  excursionId: string;
  itineraryId: string;
  price: number;
  excursionName: string;
  passengers: string[];
};

type BookingPartTacDetails = {
  fromDate: string;
  fromTime: string;
  id: string;
  price: number;
  serviceProfile: {
    id: string;
    name: { defaultValue: string };
  };
  numberOfAdults: number;
  numberOfChildren: number;
};

type TacEntry = {
  id: string;
  serviceProfileId: string;
  name: string;
  time: string;
  nrAdults: number;
  nrChildren: number;
  price: number;
};
// TODO separate data types into Excursion and Tac
type GeneralData = {
  type: string;
  entryId: string;
  name: string;
  date: string;
  cost: number;
  passengers?: string;
  duration?: string;
  portName?: string;
  time?: string;
  nrAdults?: number;
  nrChildren?: number;
};

type EntryDetail = {
  bookingPartPassengerExcursion: BookingPartPassengerExcursionDetails;
  bookingPartTac: BookingPartTacDetails;
};

type Entry = {
  details: EntryDetail[];
  id: string;
  groupReference: string;
  total: number;
  paymentReference: string;
};

class OpenRefundsConverter {
  private hubOpenRefunds: any[];
  private readonly excursions: {
    [key: string]: {
      [key: string]: Excursion;
    };
  };
  private readonly tacEntries: {
    [key: string]: TacEntry[];
  };
  private readonly bookingParts: any[];
  private nrEntries: number;
  private groupedDetails: {
    [key: string]: {
      portName?: string;
      entries: {
        [key: string]: GeneralData;
      };
    };
  };

  constructor(openRefunds: []) {
    this.hubOpenRefunds = openRefunds;
    this.excursions = {};
    this.tacEntries = {};
    this.bookingParts = [];
    this.nrEntries = 0;

    this.groupedDetails = {};
  }

  prepareForStore() {
    this.parseOpenRefunds();

    return {
      excursions: this.excursions,
      tac: this.tacEntries,
      bookingParts: this.bookingParts,
      nrEntries: this.nrEntries,
    };
  }

  parseOpenRefunds() {
    this.hubOpenRefunds.forEach((entry) => {
      this.nrEntries++;

      // group all information inside of details - needed for the booking parts
      this.groupedDetails = {};

      entry.details.forEach((detail: EntryDetail) => {
        if (detail.bookingPartPassengerExcursion) {
          this.addExcursion(detail.bookingPartPassengerExcursion);
          this.addExcursionDetail(detail.bookingPartPassengerExcursion);
        }
        if (detail.bookingPartTac) {
          this.addTac(detail.bookingPartTac);
          this.addTacDetail(detail.bookingPartTac);
        }
      });

      // append the extracted data to the booking parts
      this.addToBookingParts(entry);
    });
  }

  addExcursion = (details: BookingPartPassengerExcursionDetails) => {
    const excursionId = details.excursion.id;
    const itineraryId = details.itinerary.id;
    const relevantData = {
      excursionId,
      itineraryId,
      price: details.price,
      excursionName: details.excursion.name.defaultValue?.toUpperCase(),
      passengers: [
        `${details.passenger.firstName} ${details.passenger.lastName}`,
      ],
    };

    if (!this.excursions.hasOwnProperty(itineraryId)) {
      //no open refunds for this itinerary id, create
      this.excursions[itineraryId] = {};
      this.excursions[itineraryId][excursionId] = relevantData;
    } else {
      if (this.excursions[itineraryId].hasOwnProperty(excursionId)) {
        // existing open refund for this excursion, add the passenger and update the price
        this.excursions[itineraryId][excursionId].price += relevantData.price;
        this.excursions[itineraryId][excursionId].passengers.push(
          relevantData.passengers[0]
        );
      } else {
        // excursion does not exist yet, add it
        this.excursions[itineraryId][excursionId] = relevantData;
      }
    }
  };

  addTac = (details: BookingPartTacDetails) => {
    const date = convertYMDtoDMY(details.fromDate);
    const time = details.fromTime;

    if (!this.tacEntries.hasOwnProperty(date)) {
      this.tacEntries[date] = [];
    }

    //add the tac reservation info
    this.tacEntries[date].push({
      id: details.id,
      serviceProfileId: details.serviceProfile.id,
      name: details.serviceProfile.name.defaultValue?.toUpperCase(),
      time,
      nrAdults: details.numberOfAdults,
      nrChildren: details.numberOfChildren,
      price: details.price,
    });
  };

  addToBookingParts = (entry: Entry) => {
    this.bookingParts.push({
      id: entry.id,
      groupReference: entry.groupReference,
      total: entry.total,
      paymentReference: entry.paymentReference,
      details: this.groupedDetails,
    });
  };

  addExcursionDetail = (detailData: BookingPartPassengerExcursionDetails) => {
    const date = detailData.itinerary.date;

    const excursionData = {
      type: "excursion",
      entryId: detailData.excursion.id,
      name: detailData.excursion.name.defaultValue?.toUpperCase(),
      duration: formatDuration(detailData.excursion.duration),
      passengers: `${detailData.passenger.firstName} ${detailData.passenger.lastName}`,
      cost: detailData.price,
      portName: detailData.itinerary.port.name.defaultValue?.toUpperCase(),
      date,
    };

    this.addEntryToGroupedDetails(date, excursionData);
  };

  addTacDetail = (detailData: BookingPartTacDetails) => {
    const date = convertYMDtoDMY(detailData.fromDate);

    const tacData = {
      type: "tac",
      entryId: detailData.id,
      name: detailData.serviceProfile.name.defaultValue?.toUpperCase(),
      time: detailData.fromTime,
      nrAdults: detailData.numberOfAdults,
      nrChildren: detailData.numberOfChildren,
      cost: detailData.price,
      date,
    };

    this.addEntryToGroupedDetails(date, tacData);
  };

  addEntryToGroupedDetails = (date: string, data: GeneralData) => {
    if (this.groupedDetailsHasDate(date)) {
      // append to existing date
      if (this.groupedDetailHasEntryForDate(date, data.entryId)) {
        // append price for all, pax info for excursions and times and nr of pax for tac
        this.groupedDetails[date].entries[data.entryId].cost += data.cost;

        if (data.type === "excursion") {
          this.groupedDetails[date].entries[
            data.entryId
          ].passengers += `, ${data.passengers}`;
        }
        if (data.type === "tac") {
          // @ts-ignore
          this.groupedDetails[date].entries[data.entryId].nrAdults +=
            // @ts-ignore
            data.nrAdults;
          // @ts-ignore
          this.groupedDetails[date].entries[data.entryId].nrChildren +=
            // @ts-ignore
            data.nrChildren;
          this.groupedDetails[date].entries[
            data.entryId
          ].time += `, ${data.time}`;
        }
      } else {
        // add entry to date
        this.groupedDetails[date].entries[data.entryId] = data;
      }
    }
    // no entries for the given date
    else {
      // create new date and add there
      this.groupedDetails[date] = {
        portName: data.portName?.toUpperCase(),
        entries: {},
      };

      this.groupedDetails[date].entries[data.entryId] = data;
    }
  };

  groupedDetailsHasDate = (date: string) => {
    return this.groupedDetails.hasOwnProperty(date);
  };

  groupedDetailHasEntryForDate = (date: string, entryId: string) => {
    return this.groupedDetails[date].entries.hasOwnProperty(entryId);
  };
}

export default OpenRefundsConverter;
