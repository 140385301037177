import axios from "axios";
import useAppSelector from "hooks/useAppSelector";

import { useMutation } from "@tanstack/react-query";
import { useUpdateCart } from "actions/useUpdateCart";
import { getDinningUrl } from "config/configUtils";
import {
  updateBookings,
  updateMxpBookings,
  updateOpenRefunds,
} from "actions/cartActions";

type Props = {
  transactionId: string;
  vacancyId: string;
};

export const usePostRefundMxpDining = () => {
  const sessionId = useAppSelector((state) => state.authentication.sessionId);
  const { updateCart, loading } = useUpdateCart();

  const { mutateAsync, isPending, error, isSuccess, reset } = useMutation({
    mutationFn: async ({ transactionId, vacancyId }: Props) => {
      const URL = `${getDinningUrl()}/api/payments/opp/refund/${transactionId}/items/${vacancyId}`;

      await axios.post(
        URL,
        {},
        {
          headers: {
            SessionId: sessionId,
          },
        }
      );
    },
    onSuccess: async () => {
      await updateBookings();
      await updateMxpBookings();
      await updateOpenRefunds();
      await updateCart();
    },
  });

  return {
    postRefundMxpDining: mutateAsync,
    postResetRefundMxpDining: reset,
    postRefundMxpDiningLoading: isPending || loading,
    postRefundMxpDiningError: error,
    postRefundMxpDiningIsSuccess: isSuccess,
  };
};
