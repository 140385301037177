import { useEffect } from "react";
import browserHistory from "../../utils/history";
import { logOutPassenger } from "../../actions/authenticationActions";

const PassengerLogout = () => {
  useEffect(() => {
    logOutPassenger().then(() => {
      browserHistory.push("/login");
    });
  }, []);

  return null;
};

export default PassengerLogout;
