import { useMutation } from "@tanstack/react-query";
import { store } from "WithProviders";
import axios, { AxiosResponse } from "axios";
import { getPrebookUrl } from "config/configUtils";
import {
  IExcursionProduct,
  IExcursionSlots,
  IExcursionSlotsMap,
  PriceProps,
} from "interfaces/Musement/IExcursionProduct";

const TYPE_ADULT = "ADULT";
const TYPE_CHILDREN = "CHILDREN";

type Props = {
  activityUuids: string[];
  date: string;
};

interface ICustomAxiosResponse extends AxiosResponse {
  data: IExcursionSlots;
}

const usePostExcursionsSlots = () => {
  const { mutate, isPending, data } = useMutation<
    IExcursionSlotsMap,
    Error,
    Props
  >({
    mutationKey: ["postExcursionsSlots"],
    mutationFn: async ({ activityUuids, date }) => {
      const URL = `${getPrebookUrl()}/api/activities/slots`;

      const response = (await axios.post(
        URL,
        { activityUuids, date },
        {
          headers: {
            SessionId: store.getState().authentication.sessionId,
          },
        }
      )) as ICustomAxiosResponse;

      const slotsMap: IExcursionSlotsMap = {};

      for (const [key, slotItem] of Object.entries(response.data)) {
        const calculatedPrices: PriceProps = {
          adult: null,
          children: null,
        };

        const excursionProducts = slotItem?.products || [];
        const excursionStartTime = slotItem?.time || "N/A";

        if (excursionProducts?.length && excursionProducts.length > 0) {
          excursionProducts.forEach((product: IExcursionProduct) => {
            if (product.holder_code_normalized === TYPE_ADULT) {
              calculatedPrices.adult = product.original_retail_price.value;
            } else if (product.holder_code_normalized === TYPE_CHILDREN) {
              calculatedPrices.children = product.original_retail_price.value;
            }
          });
        }

        slotsMap[key] = {
          products: excursionProducts,
          prices: calculatedPrices,
          time: excursionStartTime,
        };
      }

      return slotsMap;
    },
    onError: (error) => {
      console.error(`Error executing API call "${URL}": ${error}`);
      return {};
    },
    retry: false,
  });

  return {
    excursionsSlots: data,
    fetchExcursionsSlots: mutate,
    excursionsSlotsIsLoading: isPending,
  };
};

export default usePostExcursionsSlots;
