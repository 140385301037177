import { FC } from "react";
import {
  convertDurationPeriod,
  convertDurationPeriodFromMinutes,
} from "utils/dateTimeUtils";
import { imagePath } from "utils/imageUtils";

export type Props = {
  duration: number | string;
  dataTest?: string;
  isISO8601?: boolean;
};

const ActivityDuration: FC<Props> = ({ duration, dataTest, isISO8601 }) => {
  let displayDuration = `${duration} Minutes`;

  if (isISO8601 && typeof duration === "string") {
    // Converts time from ISO 8601 format like "PT3H30M" into equvalent of "3.5 Hours"
    displayDuration = convertDurationPeriod(duration);
  }

  if (typeof duration === "number") {
    displayDuration = convertDurationPeriodFromMinutes(duration);
  }

  return (
    <div className="flex items-center">
      <img
        className="w-4 h-4 align-middle mr-2 inline"
        alt="hourglass icon"
        src={imagePath("hourglass.png")}
      />
      <span data-testid={dataTest} className="align-middle text-grey-darker">
        Duration {displayDuration}
      </span>
    </div>
  );
};

export default ActivityDuration;
