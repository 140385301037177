import { FC } from "react";
import { Link } from "react-router-dom";
import SvgIcon from "components/atoms/SvgIcon";

type Props = {
  to: string;
  text: string;
  onClick?: () => void;
};

const BackLink: FC<Props> = ({ to, text, onClick }) => {
  return (
    <Link
      onClick={onClick ? () => onClick() : () => {}}
      className="text-blue inline-flex items-center hover:underline"
      to={to}
    >
      <SvgIcon name="chevron-left" className="w-4 h-4 inline" /> {text}
    </Link>
  );
};

export default BackLink;
