import { Component } from "react";
import ImageGallery from "react-image-gallery";
import { connect } from "react-redux";
import { getFromHub } from "../../actions/networkActions";
import {
  convertDMYtoYMD,
  formatPortDate,
  formatTimeHM,
} from "../../utils/dateTimeUtils";
import RestaurantEntry from "../../components/RestaurantEntry";
import ModalGallery from "../../components/modals/ModalGallery";
import { imagePath } from "../../utils/imageUtils";
import { formatPortName } from "../../utils/cruiseUtils";
import { getHubBaseUrl } from "../../config/configUtils";
import BackLink from "../../components/molecules/BackLink";
import browserHistory from "../../utils/history";

class RestaurantsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      restaurants: [],
      descriptions: {},
      showGallery: false,
      galleryType: "",
      galleryId: null,
      galleries: {},
      exp360Galleries: {},
      isBookingEnabled: false,
    };
  }

  componentDidMount() {
    const date = this.props.location?.state?.date;
    if (!date) {
      return;
    }

    getFromHub(
      `prebooking/tac/templates/types/restaurant?date=${convertDMYtoYMD(date)}`,
      this.updateRestaurants
    );
    getFromHub("prebooking/tac/config", this.updateConfig);
  }

  updateRestaurants = (restaurants) => {
    let restaurantsInfo = [];
    restaurants.forEach((restaurant) => {
      const restaurantBaseInfo = {
        templateId: restaurant.id,
        location: restaurant.location,
      };

      if (restaurant.serviceProfiles && restaurant.serviceProfiles.length > 0) {
        //add each service profile as separate restaurant
        restaurant.serviceProfiles.forEach((serviceProfile) => {
          restaurantsInfo.push({
            templateId: restaurantBaseInfo.templateId,
            location: restaurantBaseInfo.location,
            ...serviceProfile,
          });
        });
      }
    });

    this.setState(
      {
        restaurants: restaurantsInfo,
        loaded: true,
      },
      this.fetchDescriptions
    );
  };

  updateConfig = (config) => {
    this.setState({
      isBookingEnabled: config.diningBookingEnabled,
    });
  };

  fetchDescriptions = () => {
    const ids = this.state.restaurants
      .map((restaurant) => restaurant.id)
      .join(",");
    getFromHub(`descriptions/${ids}`, this.updateDescriptions);
  };

  updateDescriptions = (descriptions) => {
    const mappedDescriptions = {};

    descriptions.forEach((description) => {
      if (!mappedDescriptions[description.reference]) {
        mappedDescriptions[description.reference] = {};
      }

      mappedDescriptions[description.reference][
        description.descriptionType.toLowerCase()
      ] = description.value.defaultPlainValue;
    });

    this.setState({
      descriptions: mappedDescriptions,
    });
  };

  toggleGallery = (id, isExp360Gallery) => {
    if (this.state.showGallery) {
      this.setState({
        showGallery: false,
        galleryId: null,
        galleryType: "",
      });
    } else {
      if (isExp360Gallery) {
        //exp360 playlist id already fetched?
        if (this.state.exp360Galleries[id]) {
          this.showGallery(id, "exp360");
        } else {
          this.fetchExp360BundleId(id);
        }
      } else {
        //list of gallery images already fetched?
        if (this.state.galleries[id]) {
          this.showGallery(id, "standard");
        } else {
          this.fetchImagesListForGallery(id);
        }
      }
    }
  };

  fetchImagesListForGallery = (id) => {
    getFromHub(`blobs/images/${id}`, (imagesResponse) =>
      this.updateImagesForGallery(id, imagesResponse)
    );
  };

  fetchExp360BundleId = async (id) => {
    const response = await getFromHub(`exp360/${id}`);
    let exp360BundleId = "";
    if (response.length) {
      exp360BundleId = response[0].snippet;
    }

    if (exp360BundleId === "") {
      this.setState({
        exp360Galleries: {
          ...this.state.exp360Galleries,
          [id]: exp360BundleId,
        },
      });
    } else {
      this.setState(
        {
          exp360Galleries: {
            ...this.state.exp360Galleries,
            [id]: exp360BundleId,
          },
        },
        () => this.showGallery(id, "exp360")
      );
    }
  };

  updateImagesForGallery = (id, imagesResponse) => {
    const images = imagesResponse.map((image) => {
      return {
        thumbnail: `${getHubBaseUrl(false)}${image.url}&width=200`,
        original: `${getHubBaseUrl(false)}${image.url}`,
      };
    });

    const galleries = { ...this.state.galleries };
    galleries[id] = images;

    this.setState(
      {
        galleries,
      },
      () => this.showGallery(id, "standard")
    );
  };

  showGallery = (id, type) => {
    this.setState({
      showGallery: true,
      galleryType: type,
      galleryId: id,
    });
  };

  render() {
    if (!this.state.loaded) {
      return null;
    }

    if (this.state.loaded && this.state.restaurants.length === 0) {
      return (
        <div className="container mx-auto my-4">
          <BackLink to="/itinerary" text="Cruise Planner" />
          <div className="text-xl mt-4 mb-6">
            Unfortunately, Speciality Dining is not yet available to book on
            your chosen date, please check back again later.
          </div>
        </div>
      );
    }

    const date = this.props.location.state.date;
    const day = this.props.itinerary.dayOfCruise;
    const port = formatPortName(
      this.props.itinerary.type,
      this.props.itinerary.port
    );

    return (
      <div>
        <div className="container mx-auto my-4 text-blue-dark">
          <BackLink to="/itinerary" text="Cruise Planner" />
          <h1>Restaurant options</h1>
          <div className="text-2xl">
            DAY {day} ({formatPortDate(date)})
          </div>
          <div className="text-3xl mt-1">{port}</div>
          {this.props.itinerary.type === "Port" && (
            <div className="text-3xl mt-1">
              Time in port:{" "}
              {formatTimeHM(this.props.itinerary.arrival, "Previous day")} -{" "}
              {formatTimeHM(this.props.itinerary.departure, "Next day")}
            </div>
          )}

          <div className="print-hidden">
            <ModalGallery
              show={this.state.showGallery}
              onClose={this.toggleGallery}
            >
              {this.state.galleryType === "standard" && (
                <ImageGallery
                  items={this.state.galleries[this.state.galleryId]}
                  lazyLoad={true}
                  showIndex={true}
                  defaultImage={imagePath("2_1_no_image.png")}
                  showPlayButton={false}
                />
              )}
            </ModalGallery>
          </div>
        </div>

        <div className="bg-blue-lightest-25 mt-8">
          <div className="container mx-auto px-2 sm:px-4 py-8">
            {this.state.restaurants.map((restaurant) => (
              <RestaurantEntry
                key={restaurant.id}
                restaurant={restaurant}
                descriptions={this.state.descriptions[restaurant.id]}
                toggleGallery={() => this.toggleGallery(restaurant.id, false)}
                toggleExp360Gallery={
                  restaurant.exp360Available
                    ? () => this.toggleGallery(restaurant.id, true)
                    : false
                }
                date={date}
                preselectedDetails={
                  this.props.location.state.preselectedDetails
                }
                isBookingEnabled={this.state.isBookingEnabled}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const propsDate = ownProps.location?.state?.date;

  if (!propsDate) {
    browserHistory.push("/");
  }

  return {
    itinerary: state.itinerary.find(({ date }) => date === propsDate),
  };
}

export default connect(mapStateToProps)(RestaurantsList);
