import { Link } from "react-router-dom";
import BackLink from "../../components/molecules/BackLink";

const Instructions = () => {
  return (
    <div className="container mx-auto mt-4">
      <BackLink to="/" text="Back to Cruise Control Dashboard" />
      <h1>Luggage tag</h1>

      <h2 className="capitalize mt-4">Folding Instructions</h2>

      <div className="mt-8">
        <video controls poster="/images/luggage_tag_video_poster_tmp.jpg">
          <source src="/videos/luggage_tag.mp4" type="video/mp4" />
          <p>
            Your browser doesn't support HTML5 video. Here is a
            <a href="/videos/luggage_tag.mp4">link to the video</a> instead.
          </p>
        </video>
      </div>

      <div className="mt-8">
        <ul className="list-square list-inside text-lg text-blue-dark">
          <li>
            <span className="text-grey-darker">
              Be sure to attach a luggage tag to each piece of luggage before
              you leave home.
            </span>
          </li>

          <li className="mt-3">
            <span className="text-grey-darker">
              Do not pack valuables or your boarding documents in your checked
              luggage.
            </span>
          </li>

          <li className="mt-3">
            <span className="text-grey-darker">
              Make as many copies of the tag as you require, preferably printed
              in colour.
            </span>
          </li>

          <li className="mt-3">
            <span className="text-grey-darker">
              Fold (do not cut) along the dotted lines with the luggage tag
              print facing out (you should be able to read the ship name and see
              the company logo).
            </span>
          </li>

          <li className="mt-3">
            <span className="text-grey-darker">
              After folding is completed, staple twice or tape the tag around
              the luggage handle.
            </span>
          </li>
        </ul>
      </div>

      <div className="mt-6 mb-8 xl:mb-12">
        <Link
          className="block action-button action-button-enabled w-mobile"
          to="/luggage/printableInstructions"
        >
          Printable folding instructions
        </Link>
      </div>
    </div>
  );
};

export default Instructions;
