import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import CenteredImageContainer from "../CenteredImageContainer";
import { getResourceImageUrl } from "../../actions/networkActions";
import { LocationDescriptor } from "history";
import { FC } from "react";

type Props = {
  name: string;
  imageReference: any;
  tooltipId: string | string[];
  imageCategory?: any;
  linkTo: string | LocationDescriptor;
  children: any;
};

const CartElementOpenRefund: FC<Props> = (props) => {
  return (
    <div className="flex flex-wrap bg-grey-lighter shadow sm:p-4 my-4">
      {/* image */}
      <div className="w-full sm:w-1/2 md:w-2/5 xl:w-1/3">
        <CenteredImageContainer
          alt={props.name}
          src={getResourceImageUrl(props.imageReference, {
            width: 400,
            category: props.imageCategory,
          })}
        />
      </div>

      {/* rest of card */}
      <div className="sm:flex sm:flex-col px-4 py-2 sm:p-0 w-full sm:w-1/2 md:w-3/5 xl:w-2/3 sm:pl-4 mt-2 sm:mt-0">
        <div>
          <Link
            className="text-blue underline text-2xl uppercase sm:pr-3 md:pr-0"
            to={props.linkTo}
          >
            {props.name}
          </Link>
        </div>

        <div className="my-2 sm:flex-grow">{props.children}</div>

        {/* refund tooltip */}
        <div className="text-right">
          Pending refund -
          <span
            className="cursor-pointer ml-2 text-blue-light border-dotted border-b border-blue"
            data-tip="We will contact you directly to process your refund."
            data-for={props.tooltipId}
            data-effect="solid"
            data-place="bottom"
          >
            More details
          </span>
          <ReactTooltip id={props.tooltipId as string} />
        </div>
      </div>
    </div>
  );
};

export default CartElementOpenRefund;
