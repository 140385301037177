import { FC } from "react";
import { buildTestId } from "utils/buildTestId";

export type Props = {
  _onChange: () => void;
  isDisabled?: boolean;
  label: string;
  isSelected: boolean;
};

const RadioButton: FC<Props> = ({
  _onChange,
  isDisabled,
  isSelected,
  label,
}) => {
  const toggleRadioChange = () => {
    _onChange();
  };

  return (
    <div className="tui-radio flex">
      <label
        className={
          "flex inline-flex items-center" + (isDisabled ? " disabled" : "")
        }
      >
        <input
          data-testid={buildTestId("button", "radio")}
          type="radio"
          value={label}
          onChange={toggleRadioChange}
          checked={isSelected}
          disabled={isDisabled}
        />
        <span className="radio-indicator"></span>
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
