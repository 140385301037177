import Price from "components/Price/Price";
import { FC } from "react";

type SimpleAvailabilityOptionProps = {
  timeSlot: string;
  price: string;
  selected: boolean;
  onClick: () => void;
};

const SimpleAvailabilityOption: FC<SimpleAvailabilityOptionProps> = ({
  timeSlot,
  price,
  selected,
  onClick,
}) => {
  let classes =
    "relative p-4 border shadow flex flex-col border-blue cursor-pointer select-none";

  if (selected) {
    classes += " bg-blue text-white";
  } else {
    classes += " text-grey-darker hover:bg-blue-lightest";
  }

  return (
    <div className="md:px-2 pb-4  md:w-1/4 text-center">
      <div className={classes} onClick={onClick}>
        {selected && (
          <div className="diagonal-blue w-6 h-6 absolute top-0 right-0 text-white text-xs text-right">
            &#x2714;
          </div>
        )}
        {/* <IconWithText
          icon={
            <i className="flex items-center justify-center font-bold bg-green rounded-full w-5 h-5">
              £
            </i>
          }
          classNames="justify-end absolute top-[8px] right-[12px]"
        /> */}
        <div className={`font-bold mr-2 ${selected ? "" : "text-blue"}`}>
          {timeSlot}
        </div>
        <Price price={price} />
      </div>
    </div>
  );
};

export default SimpleAvailabilityOption;
