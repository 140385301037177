import { ICruise } from "interfaces/ReduxState/ICruise";
import { CruisesActions } from "../actions/cruisesActions";

const initialState: ICruise = {
  cruiseDetails: [],
  cruiseStartDate: "",
  cruiseEndDate: "",
  imageReferenceId: "",
};

const cruisesReducers = (state = initialState, action: CruisesActions) => {
  switch (action.type) {
    case "CLEAR_CRUISES":
      return initialState;

    case "SET_CRUISES":
      return {
        ...state,
        cruiseDetails: action.cruises,
        cruiseStartDate: action.cruiseStartDate,
        cruiseEndDate: action.cruiseEndDate,
      };

    case "SET_CRUISE_IMAGE_REFERENCE":
      return { ...state, imageReferenceId: action.imageReferenceId };

    default:
      return state;
  }
};

export default cruisesReducers;
