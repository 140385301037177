import { getHubBaseUrl } from "../../config/configUtils";

const AgencySSORedirect = () => {
  window.location.href = `${getHubBaseUrl()}authentication/SingleSignOnServlet`;
  return (
    <div className="container mx-auto pt-8">
      Redirecting to single sign on...
    </div>
  );
};

export default AgencySSORedirect;
