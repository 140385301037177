import { addDays, format, parse } from "date-fns";
import { ICruise } from "interfaces/ReduxState/ICruise";
import { IPort } from "interfaces/ReduxState/IItinerary";

/**
 * Calculates the latest end date from an array of cruises.
 * @param {Array} cruiseDetails - Array of cruise objects.
 * @returns {string} - The latest end date in "dd/MM/yyyy" format.
 */
export const calculateLatestEndDate = (
  cruiseDetails: ICruise["cruiseDetails"]
) => {
  if (!cruiseDetails.length) return "";

  return format(
    cruiseDetails.reduce((maxDate, cruise) => {
      const endDate = addDays(
        parse(cruise.fromDate, "dd/MM/yyyy", new Date()),
        cruise.numberOfDays
      );
      return maxDate > endDate ? maxDate : endDate;
    }, new Date(0)),
    "dd/MM/yyyy"
  );
};

export const getCruisesInfo = (cruiseDetails?: ICruise["cruiseDetails"]) => {
  let cruiseDuration = 0;
  let cruiseName = "";
  const cruiseStart = cruiseDetails?.[0]?.fromDate;
  const shipName = cruiseDetails?.[0]?.ship?.name ?? "N/A";
  let cruiseEnd = "";

  cruiseDetails?.forEach((cruise) => {
    cruiseDuration += cruise?.numberOfDays;
    cruiseName += cruise?.name?.defaultValue + " & ";
  });
  cruiseName = cruiseName?.substring(0, cruiseName?.length - 3);

  if (cruiseDetails && cruiseDetails?.length) {
    cruiseEnd = calculateLatestEndDate(cruiseDetails);
  }

  return {
    shipName,
    cruiseName,
    cruiseStart,
    cruiseEnd,
    cruiseDuration,
  };
};

export const formatPortName = (type: string, port: IPort) => {
  if (type === "Sea") {
    return "AT SEA...";
  }
  return (
    port?.name?.defaultValue?.toUpperCase() +
    ", " +
    port?.countryName?.toUpperCase()
  );
};
