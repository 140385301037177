import { ILocation } from "interfaces/ReduxState/ILocation";
import { LocationAction } from "../actions/locationActions";

const INITIAL_STATE: ILocation = {
  isItineraryShown: false,
  isDashboardShown: false,
};

const locationReducers = (state = INITIAL_STATE, action: LocationAction) => {
  switch (action.type) {
    case "SET_IS_ITINERARY_SHOWN":
      return { ...state, isItineraryShown: action.isItineraryShown };

    case "SET_IS_DASHBOARD_SHOWN":
      return { ...state, isDashboardShown: action.isDashboardShown };

    default:
      return state;
  }
};

export default locationReducers;
