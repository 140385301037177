import { IOpenRefunds } from "interfaces/ReduxState/IOpenRefunds";
import { OpenRefundAction } from "../actions/cartActions";

const INITIAL_STATE: IOpenRefunds = {
  nrEntries: 0,
  excursions: {},
  tac: {},
};

const openRefundsReducers = (
  state = INITIAL_STATE,
  action: OpenRefundAction
) => {
  switch (action.type) {
    case "SET_OPEN_REFUNDS":
      return action.openRefunds;

    case "CLEAR_OPEN_REFUNDS":
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default openRefundsReducers;
