import { isValidDate } from "../dateTimeUtils";

export const isEmail = (email: string) => {
  const re =
    /^(?=.{1,64}@)[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*@[^-][A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/;
  return re.test(email.toLowerCase());
};

export const requiredErrorMessage =
  "This field is required, please fill it out";
export const alphabeticalErrorMessage =
  "Alphabetical letters only. No double spaces.";
export const validDateErrorMessage = "Please make sure to enter a valid date";
export const documentTypeErrorMessage = "Please select the document type";
export const validOptionErrorMessage = "Please select a valid option";

export const getNameError = (name: string, isRequired: boolean) => {
  if (isRequired && isEmpty(name)) {
    return requiredErrorMessage;
  }

  if (!isRequired && isEmpty(name)) {
    return "";
  }

  const regexp = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  return !regexp.test(name) && !name.includes("  ")
    ? ""
    : alphabeticalErrorMessage;
};

export const getGenderError = (gender: string) => {
  return isEmpty(gender) ? requiredErrorMessage : "";
};

export const getDateError = (dateString: string) => {
  return isValidDate(dateString) ? "" : validDateErrorMessage;
};

export const getDocumentTypeError = (documentType: string) => {
  if (isEmpty(documentType) || documentType === "Select document type") {
    return documentTypeErrorMessage;
  }
  return "";
};

export const getDocumentNumberError = (documentNumber: string) => {
  return isEmpty(documentNumber) ? requiredErrorMessage : "";
};

export const getDropdownError = (dropdownValue: string) => {
  if (isEmpty(dropdownValue) || dropdownValue === "Please select") {
    return validOptionErrorMessage;
  }

  return "";
};

type PassengerData = {
  firstName: string;
  middleName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
  documentType: string;
  documentNumber: string;
  documentExpiryDate: string;
  documentIssuanceCountry: string;
  nationality: string;
};

export const getApisErrors = (
  passengerData: PassengerData,
  getAllErrors = true
): { [key: string]: string } => {
  const errors: { [key: string]: string } = {};

  // validate firstName
  const firstNameError = getNameError(passengerData.firstName, true);
  if (firstNameError) {
    errors.firstName = firstNameError;

    if (!getAllErrors) {
      return errors;
    }
  }

  // validate middleName
  const middleNameError = getNameError(passengerData.middleName, false);
  if (middleNameError) {
    errors.middleName = middleNameError;

    if (!getAllErrors) {
      return errors;
    }
  }

  // validate lastName
  const lastNameError = getNameError(passengerData.lastName, true);
  if (lastNameError) {
    errors.lastName = lastNameError;

    if (!getAllErrors) {
      return errors;
    }
  }

  // validate gender
  const genderError = getGenderError(passengerData.gender);
  if (genderError) {
    errors.gender = genderError;

    if (!getAllErrors) {
      return errors;
    }
  }

  // validate date of birth
  const dateOfBirthError = getDateError(passengerData.dateOfBirth);
  if (dateOfBirthError) {
    errors.dateOfBirth = dateOfBirthError;

    if (!getAllErrors) {
      return errors;
    }
  }

  // validate travel document type
  const documentTypeError = getDocumentTypeError(passengerData.documentType);
  if (documentTypeError) {
    errors.documentType = documentTypeError;

    if (!getAllErrors) {
      return errors;
    }
  }

  // validate document number
  const documentNumberError = getDocumentNumberError(
    passengerData.documentNumber
  );
  if (documentNumberError) {
    errors.documentNumber = documentNumberError;

    if (!getAllErrors) {
      return errors;
    }
  }

  // validate expiry date
  const expiryError = getDateError(passengerData.documentExpiryDate);
  if (expiryError) {
    errors.documentExpiryDate = expiryError;

    if (!getAllErrors) {
      return errors;
    }
  }

  // validate issuing country
  const documentCountryError = getDropdownError(
    passengerData.documentIssuanceCountry
  );
  if (documentCountryError) {
    errors.documentIssuanceCountry = documentCountryError;

    if (!getAllErrors) {
      return errors;
    }
  }

  // validate nationality
  const nationalityError = getDropdownError(passengerData.nationality);
  if (nationalityError) {
    errors.nationality = nationalityError;

    if (!getAllErrors) {
      return errors;
    }
  }

  return errors;
};

export const isEmpty = (value: string) => {
  return !value;
};
