import { FC } from "react";
import Loader from "components/atoms/Loader";
import CartElementItinerary from "components/cartElements/CartElementItinerary";
import { useDeleteRestaurantBooking } from "modules/mxp/dining/hooks/useDeleteRestaurantBooking/useDeleteRefundOSW";
import { LocationDescriptor } from "history";
import { buildTestId } from "utils/buildTestId";

type Props = {
  title: string;
  image: string | null;
  vacancyId: string;
  scheduledTime: string;
  nrOfPeople: number;
  linkTo: string | LocationDescriptor;
};

const CartElementElementItineraryMxpDining: FC<Props> = ({
  title,
  image,
  vacancyId,
  scheduledTime,
  nrOfPeople,
  linkTo,
}) => {
  const { deleteRestaurantBooking, deleteRestaurantBookingIsLoading } =
    useDeleteRestaurantBooking();

  if (deleteRestaurantBookingIsLoading) {
    return <Loader text="Removing from planner..." />;
  }

  return (
    <CartElementItinerary
      name={title}
      imageReference={image}
      imageCategory="cruiseExcursion"
      remove={() => deleteRestaurantBooking({ vacancyId })}
      // TODO maybe - no requirements yet
      changePassengersLinkText=""
      // TODO
      changePassengersLinkTo={`/todo`}
      dataTest={buildTestId("container", "cart element itinerary")}
      linkTo={linkTo}
    >
      <div className="my-2">
        <div className="mb-1">
          Booking for {nrOfPeople} {nrOfPeople > 1 ? "people" : "person"}
        </div>
        <div>
          at <span className="font-bold">{scheduledTime}</span>
        </div>
      </div>
    </CartElementItinerary>
  );
};

export default CartElementElementItineraryMxpDining;
