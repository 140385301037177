import axios from "axios";
import { getDinningUrl } from "config/configUtils";
import useAppSelector from "hooks/useAppSelector";
import { useCallback, useState } from "react";

type Props = {
  tenantReference: string;
  emailAddress: string;
};

export const usePostInitPaymentMxp = () => {
  const sessionId = useAppSelector((state) => state.authentication.sessionId);

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const postInitPaymentMxp = useCallback(
    async ({ tenantReference, emailAddress }: Props) => {
      setLoading(true);
      let initialError = null;
      const URL = `${getDinningUrl()}/api/payments/opp/init`;

      const response = await axios
        .post(
          URL,
          {
            tenantReference,
            emailAddress,
          },
          {
            headers: {
              SessionId: sessionId,
            },
          }
        )
        .catch((error) => {
          setError(error);
          initialError = error;
        });

      setData(response?.data);
      setLoading(false);

      return { responseData: response?.data, error: initialError };
    },
    [sessionId]
  );

  return { postInitPaymentMxp, data, loading, error };
};
