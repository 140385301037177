import { useState, useEffect, FormEventHandler, FormEvent, FC } from "react";
import RadioButton from "../Buttons/RadioButton/RadioButton";

type Props = {
  onSubmit: (value: any) => void;
  label: string;
};

const PassengerConfirmationForm: FC<Props> = ({ onSubmit, label }) => {
  const [inputValue, setInputValue] = useState<boolean | null>(null);
  const handleSubmit: FormEventHandler<HTMLFormElement> = (e: FormEvent) => {
    e.preventDefault();
    onSubmit(inputValue);
  };

  useEffect(() => {
    setInputValue(null);
  }, [label]);

  return (
    <div className="my-4">
      <div className="my-4 text-lg text-grey-darker">{label}</div>
      <div>
        <form action="#" onSubmit={handleSubmit}>
          <RadioButton
            label="Yes"
            _onChange={() => setInputValue(true)}
            isSelected={inputValue === true}
          />

          <div className="mt-4">
            <RadioButton
              label="No"
              _onChange={() => setInputValue(false)}
              isSelected={inputValue === false}
            />
          </div>

          <button
            type="submit"
            className={`mt-6 w-mobile action-button ${
              inputValue === null
                ? "action-button-disabled pointer-not-allowed"
                : "action-button-enabled"
            }`}
          >
            Next
          </button>
        </form>
      </div>
    </div>
  );
};

export default PassengerConfirmationForm;
