import { PropsWithChildren } from "react";
import { getResourceImageUrl } from "../actions/networkActions";
import ImageWithFallback from "./atoms/ImageWithFallback";
import { FC } from "react";

type Props = PropsWithChildren & {
  altText: string;
  resourceId?: string;
  width?: number;
  category?: string;
};
const ContentHeaderImage: FC<Props> = ({
  category,
  altText,
  width,
  resourceId,
  children,
}) => {
  return (
    <div className="container-2-1">
      <ImageWithFallback
        className="container-2-1-img cover-fit"
        alt={altText}
        src={getResourceImageUrl(resourceId, {
          width: width,
          category: category,
        })}
      />
      {children}
    </div>
  );
};

export default ContentHeaderImage;
