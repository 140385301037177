import { mergeAndSortEntries } from "utils/cartUtils";
import useAppSelector from "hooks/useAppSelector";
import CartElementCheckoutMusement from "../CartElementCheckoutMusement";
import { getCombinedMusementEntryByActivityUuid } from "modules/musement/utils/getCombinedMusementEntryByActivityUuid";
import { IMusementActivitiesCartType } from "interfaces/Musement/IExcursionCartType";

const CartElementsCheckoutCombinedMusement = () => {
  const musementEntries = useAppSelector((state) => state.cart.musement);
  const cruiseStartDate = useAppSelector(
    (state) => state.cruises.cruiseStartDate
  );

  const combinedSortedEntries = mergeAndSortEntries(
    undefined,
    undefined,
    undefined,
    musementEntries,
    undefined,
    cruiseStartDate
  );

  return (
    <div className="bg-blue-lightest-25 px-4 py-1">
      {combinedSortedEntries?.map(
        (musementEntries: IMusementActivitiesCartType[]) => {
          const combinedMusementEntryByActivityUuid =
            getCombinedMusementEntryByActivityUuid({ musementEntries });

          return (
            <CartElementCheckoutMusement
              key={`${combinedMusementEntryByActivityUuid.id}-${combinedMusementEntryByActivityUuid.product.scheduledDay}-${combinedMusementEntryByActivityUuid.product.scheduledTime}`}
              activity={combinedMusementEntryByActivityUuid}
            />
          );
        }
      )}
    </div>
  );
};

export default CartElementsCheckoutCombinedMusement;
