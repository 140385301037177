import { FC, useEffect } from "react";
import { logOutPassenger } from "../../actions/authenticationActions";
import { useRouteMatch } from "react-router-dom";
import useAppSelector from "hooks/useAppSelector";
import { useLoginByToken } from "./hooks/useLoginByToken";

interface IMatchState {
  token: string;
}

const PassengerTokenLogin: FC = () => {
  const match = useRouteMatch<IMatchState>();
  const isRiverUrl = useAppSelector((state) => state.appConfig.isRiverUrl);

  const { postLoginByToken, postLoginByTokenLoading, postLoginByTokenError } =
    useLoginByToken();

  const login = async () => {
    await logOutPassenger();
    await postLoginByToken({ token: match.params.token, isRiverUrl });
  };

  useEffect(() => {
    login();
  }, []);

  if (postLoginByTokenError) {
    return (
      <div className="container mx-auto">
        Login failed. Please make sure the token is valid
      </div>
    );
  }

  if (postLoginByTokenLoading) {
    return (
      <div className="container mx-auto">Login in progress. Please wait</div>
    );
  }

  return <div className="container mx-auto">Login starting.</div>;
};

export default PassengerTokenLogin;
