import { FC } from "react";
import { sanitizeHTML } from "utils/sanitizeHTML/sanitizeHTML";

type Props = {
  title: string;
  content: any;
  isContentInHtml: boolean;
};

const ImportantInformationEntry: FC<Props> = ({
  title,
  content,
  isContentInHtml,
}) => {
  if (!content) {
    return <></>;
  }

  return (
    <div>
      <div className="text-blue-dark uppercase mt-2">{title}</div>
      {isContentInHtml ? (
        <div
          className="text leading-normal"
          dangerouslySetInnerHTML={sanitizeHTML({ html: content })}
        />
      ) : (
        <div className="text leading-normal">{content}</div>
      )}
    </div>
  );
};

export default ImportantInformationEntry;
