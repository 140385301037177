import { useSurveyContext } from "../../../context/SurveyContext";
import Checkbox from "../../Checkbox";
import InfoBox from "../InfoBox/InfoBox";
import { isQuestionShown } from "utils/questionnaireUtils";
import Question from "./Question";
import { FC } from "react";

type Props = {
  question: any;
  activePart: any;
};

const CheckboxQuestion: FC<Props> = ({ question, activePart }) => {
  const {
    survey: { answers, passenger },
    setAnswer,
  } = useSurveyContext();

  const answer = answers[question.id] ? answers[question.id] : [];

  const nestedQuestions = answer.reduce(
    (items: any[], item: string) =>
      question.questions && question.questions[item]
        ? [
            ...items,
            ...question.questions[item]?.filter(isQuestionShown(passenger)),
          ]
        : items,
    []
  );

  const isShown = question.part === activePart;

  const answersSort = (a: string, b: string) =>
    question.options.indexOf(a) - question.options.indexOf(b);

  return (
    <div>
      <div className={isShown ? "" : "hidden"}>
        <div className="text-lg text-grey-darker">{question.questionText}</div>

        {question.questionInfo && (
          <InfoBox
            classes="mt-6 md:inline-block"
            description={question.infoDescription}
          />
        )}

        <div className="mt-4">
          {question.options.map((option: any) => (
            <div className="my-3" key={option}>
              <Checkbox
                checked={answer.includes(option)}
                value={option}
                handleCheckboxChange={() =>
                  setAnswer(
                    question.id,
                    answer.includes(option)
                      ? answer.filter((item: string) => item !== option)
                      : [...answer, option].sort(answersSort)
                  )
                }
              >
                {option}
              </Checkbox>
            </div>
          ))}
        </div>
      </div>

      {nestedQuestions && (
        <div>
          {nestedQuestions.map((question: any) => (
            <Question
              key={question.id}
              activePart={activePart}
              question={question}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default CheckboxQuestion;
