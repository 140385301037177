import { IBookingsState } from "interfaces/ReduxState/IBookings";
import { BookingAction } from "../actions/cartActions";

const INITIAL_STATE: IBookingsState = {
  totalPrice: 0,
  nrExcursions: 0,
  excursions: {},
  tac: {},
  osw: {},
  musement: {},
  mxp: {},
};

const bookingsReducers = (state = INITIAL_STATE, action: BookingAction) => {
  switch (action.type) {
    case "SET_BOOKINGS":
      return {
        ...state,
        totalPrice: action.bookings.totalPrice,
        nrExcursions: action.bookings.nrExcursions,
        excursions: action.bookings.excursions,
        tac: action.bookings.tacEntries,
      };
    case "SET_OSW_BOOKINGS":
      return {
        ...state,
        osw: action.bookings.oswEntries,
      };
    case "SET_MUSEMENT_BOOKINGS":
      return {
        ...state,
        musement: action.bookings.musementEntries,
      };

    case "SET_MXP_BOOKINGS":
      return {
        ...state,
        mxp: action.bookings.mxpEntries,
      };

    case "CLEAR_BOOKINGS":
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default bookingsReducers;
