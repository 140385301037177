import { useMutation, useQueryClient } from "@tanstack/react-query";
import { store } from "WithProviders";
import axios from "axios";
import { getPrebookUrl } from "config/configUtils";

type Props = {
  passengerId: string;
  payload: {
    birthDate: string;
    additionalInformation: string;
    contactInformation: {
      phoneNumber: string;
      email: string;
    };
    dietaryInformation: {
      restriction: string;
    };
    passportInformation: {
      documentNumber: string;
      documentIssuePlace: string;
      documentExpiryDate: string;
      fullName: string;
    } | null;
  };
};

type Response = {
  paxId: string;
  birthDate: string;
  additionalInformation: string;
  contactInformation: {
    phoneNumber: string;
    email: string;
  };
  dietaryInformation: {
    restriction: string;
  };
  passportInformation: {
    documentNumber: string;
    documentIssuePlace: string;
    documentExpiryDate: string;
    fullName: string;
  } | null;
};
const usePostRiverGuestInfo = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, data } = useMutation<Response, Error, Props>({
    mutationKey: ["usePostRiverGuestInfo"],
    mutationFn: async ({ passengerId, payload }: Props) => {
      const URL = `${getPrebookUrl()}/api/hotelfriend/guests`;

      const response = await axios.post(
        URL,
        {
          passengerId,
          ...payload,
        },
        {
          headers: {
            SessionId: store.getState().authentication.sessionId,
          },
        }
      );

      return response?.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["useGetRiverGuestInfo"],
      });
    },
    onError: (error) => {
      const msg = `Error executing API call "${URL}": ${error}`;
      console.error(msg);
      return msg;
    },
    gcTime: 0,
  });

  return {
    postRiverGuestInfo: mutateAsync,
    postRiverGuestInfoData: data,
  };
};

export default usePostRiverGuestInfo;
