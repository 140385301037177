import Loader from "components/atoms/Loader";
import { useGetRiverGuestInfo } from "components/passengerInformation/hooks/useGetRiverGuestInfo";
import CCInfo from "modules/passengerInformation/common/CCInfo";
import QuestionsAndAnswers from "modules/passengerInformation/common/QuestionsAndAnswers";
import { flattenQuestions } from "modules/passengerInformation/utils/flattenQuestions";
import { sortQuestions } from "modules/passengerInformation/utils/sortQuestions";
import { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { classNames } from "utils/miscUtils";
import {
  additionalInformationRiverFormSetup,
  contactDetailsRiverFormSetup,
  dietaryInformationRiverFormSetup,
  passportInformationRiverFormSetup,
} from "utils/questionnaireUtils";

type RiverSectionProps = {
  section: any;
  token: any;
  passengerId: any;
  index: number;
  data: any;
};

const RiverSection: FC<RiverSectionProps> = ({
  section,
  token,
  index,
  passengerId,
  data,
}) => {
  const { riverGuestInfoData, isFetching } = useGetRiverGuestInfo(passengerId);

  if (isFetching) {
    return <Loader />;
  }

  const editUrl = token
    ? `/precruise/guest/${passengerId}/form/${token}`
    : `/precruise/passenger/${passengerId}/form`;

  data.paxId = riverGuestInfoData.paxId;
  data.isLead = data?.passenger?.isLead || false;
  data.isTravelInfoCompleted = riverGuestInfoData?.isTravelInfoCompleted;
  data.isTravelInfoLocked = riverGuestInfoData?.isTravelInfoLocked;

  data.contactInformation = contactDetailsRiverFormSetup;
  data.contactInformation.answers.email =
    riverGuestInfoData.contactInformation.email;
  data.contactInformation.answers.confirmEmail =
    riverGuestInfoData.contactInformation.email;
  data.contactInformation.answers.phoneNumber =
    riverGuestInfoData.contactInformation.phoneNumber;

  data.dietaryInformation = dietaryInformationRiverFormSetup;
  data.dietaryInformation.answers.restriction =
    riverGuestInfoData.dietaryInformation.restriction;

  data.passportInformation = passportInformationRiverFormSetup;
  data.passportInformation.answers.birthDate = riverGuestInfoData.birthDate;

  data.passportInformation.answers.fullName =
    riverGuestInfoData?.passportInformation?.fullName;
  data.passportInformation.answers.documentNumber =
    riverGuestInfoData?.passportInformation?.documentNumber;
  data.passportInformation.answers.documentIssuePlace =
    riverGuestInfoData?.passportInformation?.documentIssuePlace;
  data.passportInformation.answers.documentExpiryDate =
    riverGuestInfoData.passportInformation?.documentExpiryDate;

  data.additionalInformation = additionalInformationRiverFormSetup;
  data.additionalInformation.answers.additionalInformation =
    riverGuestInfoData.additionalInformation;

  const answers = data?.[section.hubId]?.answers || {};

  const questions = sortQuestions(
    flattenQuestions(data?.[section.hubId].questions)
  ).filter((question) => answers[question.id]);

  return (
    <div
      className={classNames(
        "relative bg-white p-4 shadow",
        index !== 0 ? "mt-6" : ""
      )}
      key={section.hubId}
    >
      <h2 className="mr-8">{section.reviewLabel}</h2>
      <Link
        className="absolute top-0 right-0 mt-6 mr-4 text-blue underline"
        to={{ pathname: editUrl, state: { section: index } }}
      >
        Edit
      </Link>
      <div>
        <QuestionsAndAnswers questions={questions} answers={answers} />
      </div>
    </div>
  );
};

export default RiverSection;
