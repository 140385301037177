import { IPayment } from "interfaces/ReduxState/IPayment";
import { AppThunk } from "../configureStore";
import { Action } from "redux";

export interface PaymentAction extends Action {
  payment?: IPayment;
}

export const savePayment =
  (payment: IPayment): AppThunk =>
  (dispatch) => {
    dispatch<PaymentAction>({ type: "SET_PAYMENT", payment: payment });
  };

export const clearPayment = (): AppThunk => (dispatch) => {
  dispatch<PaymentAction>({ type: "CLEAR_PAYMENT" });
};
