import uniqueId from "lodash/uniqueId";
import { imagePath } from "../utils/imageUtils";
import { FC } from "react";

type Props = {
  hasError: boolean;
  errorMessage: string | null;
  classNames: string;
  label: string;
  labelClassNames: string;
  subLabel: string;
  subLabelIcon: string;
  subLabelClasses: string;
  type: any;
  value: any;
  onBlur: (value: any) => void;
  placeholder: string;
};

const TextInputWithLabel: FC<
  Partial<Props> & { _onChange: (value: string) => void }
> = ({
  subLabel,
  subLabelIcon,
  labelClassNames,
  classNames,
  subLabelClasses,
  label,
  placeholder,
  errorMessage,
  hasError,
  onBlur,
  value,
  type,
  _onChange,
}) => {
  const id = uniqueId("input-");

  const inputClassNames = `input-field text-black ${
    errorMessage || hasError ? " border-red" : ""
  }`;
  return (
    <div>
      <div className={classNames || "w-full sm:w-1/2 xl:w-1/3"}>
        {label && (
          <div className="mb-1">
            <label htmlFor={id} className={labelClassNames || "input-label"}>
              {label}
            </label>
          </div>
        )}

        {subLabel && (
          <div className="flex items-center text-sm mb-1">
            {subLabelIcon && (
              <img
                alt=""
                className="w-4 h-4 mr-2 inline"
                src={imagePath(subLabelIcon)}
              />
            )}
            <span className={subLabelClasses || ""}>{subLabel}</span>
          </div>
        )}

        <input
          id={id}
          className={inputClassNames}
          type={type || "text"}
          value={value}
          onChange={(e) => _onChange(e.target.value)}
          onBlur={onBlur}
          placeholder={placeholder}
        />
      </div>

      {errorMessage && <div className="mt-2 errors">{errorMessage}</div>}
    </div>
  );
};

export default TextInputWithLabel;
