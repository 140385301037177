import { FC, FormEventHandler, useState } from "react";
import { Link } from "react-router-dom";
import { isEmail } from "../../utils/validationUtils";
import { postToHub } from "../../actions/networkActions";
import PreFormInfo from "./PreFormInfo/PreFormInfo";
import PassengerConfirmationForm from "./PassengerConfirmationForm";
import SvgIcon from "../atoms/SvgIcon";

type Props = {
  passenger: any;
  token?: any;
};
const PreForm: FC<Props> = ({ passenger, token }) => {
  const name = `${passenger.firstName} ${passenger.lastName}`;
  const [userConfirmed, setUserConfirmed] = useState(null);
  const [userHasPermission, setUserHasPermission] = useState(null);

  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [submitError, setSubmitError] = useState(false);

  if (!passenger?.passengerId) {
    return null;
  }

  if (userConfirmed || userHasPermission || token) {
    return <PreFormInfo passengerId={passenger.passengerId} token={token} />;
  }

  if (userConfirmed === null && passenger.type === "Adult") {
    return (
      <PassengerConfirmationForm
        label={`Are you ${name}?`}
        onSubmit={setUserConfirmed}
      />
    );
  }

  if (userHasPermission === null) {
    return (
      <PassengerConfirmationForm
        label={`Do you have permission from ${name} to provide information on their behalf?`}
        onSubmit={setUserHasPermission}
      />
    );
  }

  if (submitError) {
    return (
      <div className="text-lg text-grey-darker mt-6">
        <div>There has been an error submitting the form, please try again</div>
        <div className="mt-4">
          <Link
            to="/precruise/passengers"
            className="inline-block mb-8 xl:mb-12 w-mobile action-button action-button-enabled"
          >
            Back to passengers list
          </Link>
        </div>
      </div>
    );
  }

  if (emailSent) {
    return (
      <div className="text-lg text-grey-darker mt-6">
        <div className="flex items-center">
          <div className="border-green border-2 rounded-full mr-2">
            <SvgIcon name="tick" className="w-4 h-4 text-green" />
          </div>
          Thanks for sharing.
        </div>
        <p className="mt-6 leading-middle">
          {name} will receive a message from us inviting them to add their
          travel information.
        </p>
        <p>
          If they don’t receive this straightaway, please ask them to check
          their junk mail folder.
        </p>
        <div className="mt-6">
          <Link
            to="/dashboard"
            className="inline-block mb-8 xl:mb-12 w-mobile action-button action-button-enabled"
          >
            Back to dashboard
          </Link>
        </div>
      </div>
    );
  }

  const getError = () => {
    if (!isEmail(email)) {
      return "Please enter a valid email address";
    }

    if (email !== confirmEmail) {
      return "Please make sure the emails you entered match";
    }

    if (validationError) {
      setValidationError("");
    }
    return "";
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    const errorMessage = getError();
    setValidationError(errorMessage);
    if (errorMessage) {
      return;
    }

    const result = await postToHub("precruise/passenger/submitEmailId", {
      passengerId: passenger.passengerId,
      emailId: email,
      confirmEmailId: confirmEmail,
    });

    if (result.status === "success") {
      setEmailSent(true);
    } else {
      setSubmitError(true);
    }
  };

  return (
    <form action="#" onSubmit={onSubmit}>
      <div className="p-4 bg-grey-lightest-2 border">
        <div>
          Please share an email address for {name} so they can complete their
          travel information.
        </div>
        <div>
          <div className="mt-2">
            <input
              placeholder="email"
              className="border py-2 px-4 w-mobile"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="mt-4">
            <input
              placeholder="confirm email"
              className="border py-2 px-4 w-mobile"
              type="text"
              value={confirmEmail}
              onChange={(e) => setConfirmEmail(e.target.value)}
            />
          </div>
          {validationError && (
            <div className="mt-4 text-red-tui">{validationError}</div>
          )}
        </div>
      </div>
      <div>
        <button
          type="submit"
          className={`mt-6 mb-12 w-mobile action-button ${
            getError() ? "action-button-disabled" : "action-button-enabled"
          }`}
        >
          Next
        </button>
      </div>
    </form>
  );
};

export default PreForm;
