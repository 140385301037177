import axios from "axios";
import useAppSelector from "hooks/useAppSelector";

import { useMutation } from "@tanstack/react-query";
import { useUpdateCart } from "actions/useUpdateCart";
import { getDinningUrl } from "config/configUtils";

type Props = {
  vacancyId: string;
};

export const useDeleteRestaurantBooking = () => {
  const sessionId = useAppSelector((state) => state.authentication.sessionId);
  const { updateCart, loading } = useUpdateCart();

  const { mutateAsync, isPending, error, isSuccess } = useMutation({
    mutationFn: async ({ vacancyId }: Props) => {
      const URL = `${getDinningUrl()}/api/dining/carts/${vacancyId}`;

      await axios.delete(URL, {
        headers: {
          SessionId: sessionId,
        },
      });
    },
    onSuccess: () => {
      updateCart();
    },
  });

  return {
    deleteRestaurantBooking: mutateAsync,
    deleteRestaurantBookingIsLoading: isPending || loading,
    deleteRestaurantBookingError: error,
    deleteRestaurantBookingIsSuccess: isSuccess,
  };
};
