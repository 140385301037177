import axios from "axios";
import useAppSelector from "hooks/useAppSelector";
import { useMutation } from "@tanstack/react-query";
import { useUpdateCart } from "actions/useUpdateCart";
import { getDinningUrl } from "config/configUtils";
import { IMxpAvailabilitySlot } from "interfaces/MXP/IMxpAvailabilitySlot";
import { getFormattedError } from "utils/apiConfigurations";

type Props = {
  vacancy: IMxpAvailabilitySlot;
};

export const usePostAddRestaurantToPlanner = () => {
  const sessionId = useAppSelector((state) => state.authentication.sessionId);
  const { updateCart, loading } = useUpdateCart();

  const { mutateAsync, isPending, error, isSuccess } = useMutation({
    mutationFn: async ({ vacancy }: Props) => {
      const URL = `${getDinningUrl()}/api/dining/carts`;

      const result = await axios.post(URL, vacancy, {
        headers: {
          SessionId: sessionId,
        },
      });

      return result;
    },
    onSuccess: () => {
      updateCart();
    },
  });

  const postAddRestaurantToPlanner = async (vacancy: Props) => {
    await mutateAsync(vacancy);
  };

  return {
    postAddRestaurantToPlanner,
    postAddRestaurantToPlannerLoading: isPending || loading,
    postAddRestaurantToPlannerError: getFormattedError(error),
    postAddRestaurantToPlannerIsSuccess: isSuccess,
  };
};
