import { FC, FormEventHandler, useState } from "react";
import BackLink from "../molecules/BackLink";
import usePreCruisePassenger from "../../hooks/usePreCruisePassenger";
import RadioButton from "../Buttons/RadioButton/RadioButton";
import browserHistory from "../../utils/history";
import { postToHub } from "../../actions/networkActions";
import { RouteComponentProps } from "react-router-dom";

const ResetData: FC<
  RouteComponentProps<{
    passengerId: string;
  }>
> = ({ match }) => {
  const [proceed, setProceed] = useState<boolean | null>(null);
  const [error, setError] = useState<string | null>(null);

  const passengerId = match.params.passengerId;
  const { firstName, lastName } = usePreCruisePassenger(passengerId);
  const name = `${firstName} ${lastName}`;

  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (proceed === null) {
      setError("Please select an option");
      return;
    }

    if (!proceed) {
      browserHistory.push("/precruise/passengers");
    } else {
      const response = await postToHub("precruise/passenger/resetPrecruise", {
        passengerId,
      });

      if (response === "") {
        browserHistory.push(`/precruise/passenger/${passengerId}/resetSuccess`);
      } else {
        setError("There has been an error, please try again");
      }
    }
  };

  return (
    <div className="container mx-auto">
      <div className="mt-4">
        <BackLink
          to="/precruise/passengers"
          text="Back to Travel information dashboard"
        />
      </div>

      <h1 className="my-6">Forgotten PIN and memorable word for {name}</h1>

      <div className="mt-4 text-lg">
        You are about to reset the data for {name}, any pre-cruise information
        that is currently entered will be removed and you'll need to recomplete
        it.
      </div>

      <div className="mt-6 text-lg">Do you wish to proceed?</div>

      <div className="mt-4">
        <form action="#" onSubmit={onSubmit}>
          <RadioButton
            label="Yes"
            _onChange={() => setProceed(true)}
            isSelected={proceed === true}
          />

          <div className="mt-4">
            <RadioButton
              label="No"
              _onChange={() => setProceed(false)}
              isSelected={proceed === false}
            />
          </div>

          {error && <div className="mt-4 text-red-tui">{error}</div>}

          <button
            type="submit"
            className={`mt-6 mb-8 w-mobile action-button ${
              proceed === null
                ? "action-button-disabled pointer-not-allowed"
                : "action-button-enabled"
            }`}
          >
            Next
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetData;
