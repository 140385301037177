import { IMusementActivitiesCartType } from "interfaces/Musement/IExcursionCartType";

export const getCombinedMusementEntryByActivityUuid = ({
  musementEntries,
}: {
  musementEntries: IMusementActivitiesCartType[];
}) => {
  // Create a object to store combined data
  const combinedMusementEntryObject: any = {};

  const activityUuid = musementEntries[0].product.activityUuid;

  // Iterate through the data and combine based on activityUuid
  musementEntries.forEach((item) => {
    const itemActivityUuid = item.product.activityUuid;

    if (combinedMusementEntryObject.hasOwnProperty(itemActivityUuid)) {
      // If the itemActivityUuid already exists in the object, update totalPrice and merge persons
      combinedMusementEntryObject[itemActivityUuid].totalPrice +=
        item.totalPrice;
      combinedMusementEntryObject[itemActivityUuid].persons =
        combinedMusementEntryObject[itemActivityUuid].persons.concat(
          item.persons
        );
    } else {
      // If the itemActivityUuid is not in the object, add a new entry
      combinedMusementEntryObject[itemActivityUuid] = {
        ...item,
      };
    }
  });

  const combinedMusementEntryByActivityUuid: IMusementActivitiesCartType =
    combinedMusementEntryObject[activityUuid];

  return combinedMusementEntryByActivityUuid;
};
