import logo from "resources/images/tuilogo.svg";
import { imagePath } from "utils/imageUtils";

const Footer = () => {
  return (
    <footer className="print-hidden">
      {/* first light blue part */}
      <div className="bg-blue-light">
        <div className="container mx-auto">
          <div className="sm:flex sm:justify-between">
            <div className="flex items-center">
              <img src={logo} alt="logo" className="h-8 my-4" />
            </div>
            <div className="flex items-center pb-4 sm:pb-0 text-center">
              <a
                href="https://www.tui.co.uk/destinations/shop-finder"
                className="footer-link-white mr-4"
                target="_blank"
                rel="noopener noreferrer"
              >
                Shop Finder
              </a>
              <a
                href="https://www.tui.co.uk/destinations/faqCategories"
                className="footer-link-white mr-4"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ask a Question?
              </a>
              <a
                href="https://www.tui.co.uk/destinations/contact-us"
                className="footer-link-white"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* white part */}
      <div className="bg-white">
        <div className="container mx-auto">
          <div className="text-blue-dark leading-normal">
            <div className="text-lg uppercase pt-5">More from TUI</div>
            <div className="pt-2 pb-5">
              <a
                href="https://www.tui.co.uk/destinations/info/about-us"
                className="link-blue mr-8"
                target="_blank"
                rel="noopener noreferrer"
              >
                About TUI
              </a>
              <a
                href="https://www.tui.co.uk/destinations/info/website-terms-and-conditions"
                className="link-blue"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms & Conditions
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* grey part */}
      <div className="bg-grey-lighter text-grey-darker">
        <div className="container mx-auto text-sm">
          {/* travel aware */}
          <div className="md:flex md:flex-row-reverse border-b">
            <div className="flex-grow md:pb-4 pt-4">
              <div className="text-blue-dark text-lg uppercase pb-1">
                Travel aware - <strong>staying safe and healthy abroad</strong>
              </div>
              <p>
                The Foreign & Commonwealth Office and National Travel Health
                Network and Centre have up-to-date advice on staying safe and
                healthy abroad.
              </p>
              <p>
                For the latest travel advice from the Foreign & Commonwealth
                Office including security and local laws, plus passport and visa
                information check{" "}
                <a
                  href="https://twitter.com/FCOtravel"
                  className="link-blue"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  @FCOtravel
                </a>{" "}
                and{" "}
                <a
                  href="https://www.facebook.com/fcotravel/"
                  className="link-blue"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook.com/FCOtravel
                </a>
              </p>
              <p>
                More information is available by checking{" "}
                <a
                  href="https://www.tui.co.uk/destinations/info/travel-aware"
                  className="link-blue"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.tui.co.uk/destinations/info/travel-aware
                </a>
              </p>
              <p>
                Keep informed of current travel health news by visiting{" "}
                <a
                  href="https://www.travelhealthpro.org.uk"
                  className="link-blue"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.travelhealthpro.org.uk
                </a>
              </p>
              <p>The advice can change so check regularly for updates.</p>
            </div>
            <div className="flex-none mb-4 md:mt-4 md:mr-4 text-right">
              <img
                className="w-16"
                src={imagePath("travel_aware_logo.png")}
                alt="travel aware logo"
              />
            </div>
          </div>

          {/* you are in safe hands */}
          <div className="py-4 border-b">
            <div className="text-blue-dark text-lg uppercase pb-1">
              You’re in safe hands
            </div>
            <p>
              We're part of TUI Group - one of the world's leading travel
              companies. And all of our holidays are designed to help you
              Discover Your Smile.
            </p>
          </div>

          {/* your financial protection */}
          <div className="md:flex">
            <div className="flex-grow py-4">
              <div className="text-blue-dark text-lg uppercase pb-1">
                Your Financial Protection
              </div>
              <p className="leading-middle">
                Many of the flights and flight-inclusive holidays on this
                website are financially protected by the ATOL scheme. But ATOL
                protection does not apply to all holiday and travel services
                listed on this website. Please ask us to confirm what protection
                may apply to your booking. If you do not receive an ATOL
                Certificate then the booking will not be ATOL protected. If you
                do receive an ATOL Certificate but all the parts of your trip
                are not listed on it, those parts will not be ATOL protected.
                Please see our booking conditions for information or for more
                information about financial protection and the ATOL Certificate
                go to:{" "}
                <a
                  href="https://www.caa.co.uk/ATOL-protection/Consumers/ATOL-certificate/"
                  className="link-blue"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.caa.co.uk/ATOL-protection/Consumers/ATOL-certificate/
                </a>
              </p>
            </div>

            <div className="flex-none md:flex pb-4 md:pt-4 md:pl-4 text-right">
              <img
                className="w-48 md:self-end"
                src={imagePath("ABTA-ATOL-logo_sm.png")}
                alt="abta seal logo"
              />
            </div>
          </div>
        </div>
      </div>

      {/* last blue part */}
      <div className="bg-blue-light text-white">
        <div className="container mx-auto">
          <div className="text-center text-sm py-4">
            <a
              href="https://www.tui.co.uk/destinations/info/privacy-policy"
              className="footer-link-white mx-4"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>{" "}
            |{" "}
            <a
              href="https://www.tui.co.uk/destinations/info/statement-on-cookies"
              className="footer-link-white mx-4"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cookies Policy
            </a>{" "}
            |{" "}
            <div className="inline-block mx-4">
              &copy; {new Date().getFullYear()} TUI Group
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
