import axios from "axios";
import { getPrebookUrl } from "config/configUtils";
import useAppSelector from "hooks/useAppSelector";
import { useCallback, useState } from "react";
import { ReactImageGalleryItem } from "react-image-gallery";

type Props = {
  activityUuid: string;
};

export const useGetMusementMedia = () => {
  const sessionId = useAppSelector((state) => state.authentication.sessionId);

  const [data, setData] = useState<ReactImageGalleryItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const getMusementMedia = useCallback(
    async ({ activityUuid }: Props) => {
      setLoading(true);
      const URL = `${getPrebookUrl()}/api/activities/${activityUuid}/media`;

      const response = await axios
        .get(URL, {
          headers: {
            SessionId: sessionId,
          },
        })
        .catch((error) => {
          setError(error);
        });

      setData(response?.data);
      setLoading(false);
    },
    [sessionId]
  );

  return { getMusementMedia, data, loading, error };
};
