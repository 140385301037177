import { FC } from "react";
import RadioButton from "../Buttons/RadioButton/RadioButton";

type Props = {
  lock: boolean | null;
  setLock: (value: boolean) => void;
};

const FormLockBase: FC<Props> = ({ lock, setLock }) => {
  return (
    <>
      <h1>Travel information completed</h1>
      <div className="text-lg text-grey-darker">
        <p>Thank you for answering all our pre-travel questions.</p>
        <p>
          You can lock your information so other members of your travel party
          won’t be able to see what you’ve submitted.
        </p>
      </div>

      <div className="mt-4">
        <div className="text-lg text-grey-darker">
          Do you wish to lock your information?
        </div>
        <div className="mt-4">
          <RadioButton
            label="Yes"
            _onChange={() => setLock(true)}
            isSelected={lock === true}
          />
        </div>
        <div className="mt-4">
          <RadioButton
            label="No"
            _onChange={() => setLock(false)}
            isSelected={lock === false}
          />
        </div>
      </div>
    </>
  );
};

export default FormLockBase;
