import { FormEvent, useState } from "react";
import { postToHub } from "../actions/networkActions";
import TextInputWithLabel from "./TextInputWithLabel";
import {
  updateBookings,
  updateOswBookings,
  updateOpenRefunds,
  updateMusementBookings,
  updateMxpBookings,
} from "../actions/cartActions";
import { FC } from "react";

type Props = {
  paymentReference: any;
  refundId: any;
  groupReference: any;
  details: any;
  total: any;
};

const OpenRefund: FC<Props> = ({
  paymentReference,
  refundId,
  groupReference,
  total,
  details,
}) => {
  const [paymentReferenceState, setPaymentReferenceState] = useState(
    paymentReference || ""
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [refundComplete, setRefundComplete] = useState<boolean>(false);

  const processForm = (e: FormEvent) => {
    e.preventDefault();
    let errorMessage = "";

    if (!paymentReferenceState) {
      errorMessage = "Please enter the payment authorisation code";
    } else {
      postToHub(
        `prebooking/bookings/bookingPartGroups/${refundId}`,
        { paymentReference: paymentReferenceState },
        onPaymentReferenceUpdated
      );
    }

    setErrorMessage(errorMessage);
  };

  const onPaymentReferenceUpdated = () => {
    setRefundComplete(true);
    updateBookings();
    updateOswBookings();
    updateMusementBookings();
    updateMxpBookings();
    updateOpenRefunds();
  };

  const dateSort = (a: any, b: any) => {
    let aa = a.split("/").reverse().join(),
      bb = b.split("/").reverse().join();
    return aa < bb ? -1 : aa > bb ? 1 : 0;
  };

  if (refundComplete) {
    return null;
  }

  return (
    <div className="my-4 pb-4 border-b">
      <div>group reference: {groupReference}</div>
      <div className="mb-4">total refund value: {total}£</div>
      {Object.keys(details)
        .sort(dateSort)
        .map((date) => (
          <div key={date}>
            <div className="font-bold">{`${date} - ${
              details[date].portName || "TAC"
            }`}</div>
            {Object.keys(details[date].entries).map((entryId, index) => {
              const entry = details[date].entries[entryId];

              if (entry.type === "excursion") {
                return <ExcursionOpenRefund key={index} excursion={entry} />;
              }
              if (entry.type === "tac") {
                return <TacOpenRefund key={index} tac={entry} />;
              }
              return null;
            })}
          </div>
        ))}

      <form onSubmit={processForm}>
        <div className="my-4">
          <TextInputWithLabel
            label="Payment authorisation code"
            type="text"
            value={paymentReferenceState}
            _onChange={(value: any) => setPaymentReferenceState(value)}
            placeholder=""
            errorMessage={errorMessage}
            labelClassNames="input-label"
          />
        </div>
        <div>
          <input
            className="block action-button action-button-enabled"
            type="submit"
            value="update payment authorisation code"
          />
        </div>
      </form>
    </div>
  );
};

export default OpenRefund;

type ExcursionOpenRefundProps = {
  excursion: any;
};

const ExcursionOpenRefund: FC<ExcursionOpenRefundProps> = ({ excursion }) => {
  return (
    <div className="mb-4">
      <div>Excursion: {excursion.name}</div>
      <div>Duration: {excursion.duration}</div>
      <div>Passengers: {excursion.passengers}</div>
      <div>Cost: £{excursion.cost}</div>
    </div>
  );
};

type TacOpenRefundProps = {
  tac: any;
};

const TacOpenRefund: FC<TacOpenRefundProps> = ({ tac }) => {
  return (
    <div className="mb-4">
      <div>Name: {tac.name}</div>
      <div>Time: {tac.time}</div>
      <div>
        Passengers:
        {tac.nrAdults} Adult{tac.nrAdults === 1 ? "" : "s"},{tac.nrChildren}{" "}
        Child{tac.nrChildren === 1 ? "" : "ren"}
      </div>
      <div>Cost: £{tac.cost}</div>
    </div>
  );
};
