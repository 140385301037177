import { FC } from "react";
import PaymentConfirmationOverview from "../../components/PaymentConfirmationOverview";

type OwnProps = {
  location: any;
};

const PaymentConfirmationB2B: FC<OwnProps> = ({ location }) => {
  const locationState = location.state;

  if (!locationState) {
    return (
      <div>
        There was an error, confirmation page can currently not be shown.
      </div>
    );
  }

  return (
    <div>
      <PaymentConfirmationOverview
        bookingNr={locationState.bookingNr}
        includePrintLink={true}
        isB2B={true}
        location={location}
      />
    </div>
  );
};

export default PaymentConfirmationB2B;
