import {
  Dispatch,
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_SURVEY_DATA":
      return {
        ...state,
        passenger: action.passenger,
        answers: action.answers,
        questions: action.questions,
        parts: action.parts,
        card: action.card,
      };

    case "SET_ANSWER":
      return {
        ...state,
        answers: { ...state.answers, [action.questionId]: action.value },
      };

    case "SET_ACTIVE_FIELD":
      if (action.active) {
        state.activeFields.add(action.field);
      } else {
        state.activeFields.delete(action.field);
      }
      return state;

    case "SET_ERRORS":
      return { ...state, errors: action.errors };

    case "REMOVE_ERROR":
      const errors = { ...state.errors };
      delete errors[action.questionId];

      return {
        ...state,
        errors,
      };

    case "SET_ERROR":
      const updatedErrors = { ...state.errors };
      updatedErrors[action.questionId] = action.errorMessage;

      return {
        ...state,
        errors: updatedErrors,
      };

    default:
      return state;
  }
};

type SurveyContextType = {
  survey: any;
  dispatch: Dispatch<any>;
  setAnswer: (questionId: any, value: unknown) => void;
  setActiveField: (field: any, active: boolean) => void;
};

const SurveyContext = createContext<SurveyContextType | null>(null);

export const useSurveyContext = () => {
  const context = useContext(SurveyContext);
  if (!context) {
    throw new Error(
      "useTravelSurveyContext must be used within TravelSurveyProvider!"
    );
  }

  return context;
};

type Props = {
  children?: ReactNode;
};

export const SurveyProvider: FC<Props> = ({ children, ...rest }) => {
  const [survey, dispatch] = useReducer(reducer, {
    passenger: {},
    questions: {},
    answers: {},
    parts: {},
    activeFields: new Set(),
    errors: {},
  });

  const setAnswer = (questionId: any, value: any) => {
    dispatch({
      type: "SET_ANSWER",
      questionId,
      value,
    });

    dispatch({
      type: "REMOVE_ERROR",
      questionId,
    });
  };

  const setActiveField = useCallback((field: any, active: any) => {
    dispatch({
      type: "SET_ACTIVE_FIELD",
      field,
      active,
    });
  }, []);

  const value = { survey, dispatch, setAnswer, setActiveField };

  return (
    <SurveyContext.Provider value={value} {...rest}>
      {children}
    </SurveyContext.Provider>
  );
};
