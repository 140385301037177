import ImageWithFallback from "components/atoms/ImageWithFallback";
import { PropsWithChildren } from "react";
import { FC } from "react";

type Props = PropsWithChildren & {
  altText: string;
  resourceId: any;
};
const MusementContentHeaderImage: FC<Props> = ({
  altText,
  resourceId,
  children,
}) => {
  return (
    <div className="container-2-1">
      <ImageWithFallback
        className="container-2-1-img cover-fit"
        alt={altText}
        src={resourceId}
      />
      {children}
    </div>
  );
};

export default MusementContentHeaderImage;
