import { store } from "WithProviders";
import { Action } from "redux";
import { IPreCruiseStatus } from "interfaces/ReduxState/IPreCruiseStatus";

export interface PreCruiseStatusAction extends Action {
  preCruiseStatus: IPreCruiseStatus;
}

export const setPreCruiseStatus = (preCruiseStatus: IPreCruiseStatus) => {
  store.dispatch({ type: "SET_PRECRUISE_STATUS", preCruiseStatus });
};

export const clearPreCruiseStatus = () => {
  store.dispatch({ type: "CLEAR_PRECRUISE_STATUS" });
};
