const NoActiveCruiseNotice = () => {
  return (
    <div className="my-4">
      <div className="text-3xl">No active cruise</div>
      <div className="text-grey-darker">
        There are currently no active cruises for this booking.
      </div>
    </div>
  );
};

export default NoActiveCruiseNotice;
