import axios from "axios";
import { getDinningUrl } from "config/configUtils";
import useAppSelector from "hooks/useAppSelector";
import { useMutation } from "@tanstack/react-query";
import { useUpdateCart } from "actions/useUpdateCart";
import {
  updateBookings,
  updateMxpBookings,
  updateOpenRefunds,
} from "actions/cartActions";

type Props = {
  itemId: string | null;
  transactionId: string | null;
  internalMessage?: string;
  customerMessage?: string;
};

export const usePostRefundAgencyMxpDining = () => {
  const sessionId = useAppSelector((state) => state.authentication.sessionId);
  const { updateCart, loading } = useUpdateCart();

  const { mutateAsync, isPending, error, isSuccess, reset } = useMutation({
    mutationFn: async ({
      transactionId,
      itemId,
      internalMessage = "",
      customerMessage = "",
    }: Props) => {
      const URL = `${getDinningUrl()}/api/payments/agency/refund/${transactionId}/items/${itemId}`;

      await axios.post(
        URL,
        { internalMessage, customerMessage },
        {
          headers: {
            SessionId: sessionId,
          },
        }
      );
    },
    onSuccess: async () => {
      await updateMxpBookings();
      await updateBookings();
      await updateOpenRefunds();
      await updateCart();
    },
  });

  return {
    postRefundAgencyMxpDining: mutateAsync,
    postResetRefundAgencyMxpDining: reset,
    postRefundAgencyMxpDiningIsLoading: isPending || loading,
    postRefundAgencyMxpDiningError: error,
    postRefundAgencyMxpDiningIsSuccess: isSuccess,
  };
};
