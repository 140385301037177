import { ReactNode, FC } from "react";
import SvgIcon from "../../atoms/SvgIcon";

type Props = {
  classes?: string;
  twBackgroundColorClass?: string;
  icon?: string;
  infoText?: string;
  description?: string;
  children?: ReactNode;
};

const InfoBox: FC<Props> = ({
  infoText,
  icon,
  description,
  children,
  classes,
  twBackgroundColorClass = "bg-grey-lighter",
}) => {
  return (
    <div className={`${twBackgroundColorClass} p-4 ${classes ? classes : ""}`}>
      <div className="flex">
        <SvgIcon
          name={icon || "info-inverted"}
          className="flex-shrink-0 w-6 h-6 text-blue-dark"
        />
        <div className="ml-2 text-left leading-normal">{infoText}</div>
      </div>
      {description && <div className="mt-2">{description}</div>}
      {children && children}
    </div>
  );
};

export default InfoBox;
