import { FC } from "react";
import CartElementItinerary from "../CartElementItinerary";
import { useDeleteFromPlanner } from "modules/oneSpaWorld/hooks/useDeleteFromPlanner";
import Loader from "components/atoms/Loader";

type Props = {
  appointmentId: number;
  itineraryId: string;
  title: string;
  image: string | null;
  scheduledDay: string;
  scheduledTime: string;
  duration: number;
  fullName: string;
  price: string;
};

const CartElementItineraryOsw: FC<Props> = ({
  appointmentId,
  itineraryId,
  title,
  image,
  scheduledDay,
  scheduledTime,
  duration,
  fullName,
}) => {
  const { deleteFromPlanner, loading } = useDeleteFromPlanner();

  if (loading) {
    return <Loader text="Removing from planner..." />;
  }

  return (
    <CartElementItinerary
      name={title}
      imageReference={image}
      imageCategory="cruiseExcursion"
      remove={() => deleteFromPlanner({ appointmentId })}
      linkTo={`/spa-and-wellness/${itineraryId}`}
      // TODO maybe - no requirements yet
      changePassengersLinkText=""
      // TODO
      changePassengersLinkTo={`/todo`}
      dataTest="cartElementOswTreatment"
    >
      <ul className="list-disc my-2 xl:flex-grow">
        <li className="ml-4">Scheduled for {scheduledDay}</li>
        <li className="ml-4">Start time {scheduledTime}</li>
        {duration !== 0 && (
          <li className="ml-4">Duration {duration} Minutes</li>
        )}
        <li className="ml-4">Pax name: {fullName}</li>
      </ul>
    </CartElementItinerary>
  );
};

export default CartElementItineraryOsw;
