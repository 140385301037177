import { postToHub } from "./networkActions";

export const removeTacEntryFromCart = async (cartEntryId: string) => {
  const payload = [
    {
      type: "Tac",
      id: cartEntryId,
      action: "remove",
    },
  ];

  await postToHub("prebooking/cart", payload);
};
