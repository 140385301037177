import { Link } from "react-router-dom";
import useAppSelector from "hooks/useAppSelector";
import { FC } from "react";

type Props = {
  passengerId: string;
  token?: string;
};

const PreFormInfo: FC<Props> = ({ passengerId, token }) => {
  const isRiverUrl = useAppSelector((state) => state.appConfig.isRiverUrl);
  const surveyUrl = token
    ? `/precruise/guest/${passengerId}/form/${token}`
    : `/precruise/passenger/${passengerId}/form`;

  return (
    <div className="my-4 text-grey-darker text-lg leading-middle">
      {isRiverUrl ? <RiverText /> : <MarellaText />}
      <Link
        className="mt-6 mb-8 md:mb-12 block w-mobile action-button action-button-enabled"
        to={surveyUrl}
      >
        Continue to survey
      </Link>
    </div>
  );
};

export default PreFormInfo;

const MarellaText: FC = () => {
  return (
    <>
      <p>
        Before we can release the travel documents for your cruise, we need some
        more information about you.
      </p>

      <p className="mt-4">We’ll ask you a series of questions about:</p>
      <ul className="list-square list-inside mt-3 text-base">
        <li className="mt-2">Emergency contact</li>
        <li className="mt-2">Medical and accessibility needs</li>
        <li className="mt-2">Dietary requirements</li>
      </ul>

      <div className="mt-6">
        Completing the questions is essential but you don’t need to provide all
        the information if you prefer not to. It all helps to give you a
        smoother, speedier check-in process.
      </div>

      <div className="mt-2">
        You’ll also be able to lock your information so other members of your
        travel party won’t be able to see what you’ve submitted.
      </div>

      <div className="mt-2">
        Any information you share will be seen only by the relevant Marella
        Cruises team and our onboard providers and will be used in accordance
        with our privacy policy{" "}
        <a
          className="text-blue underline"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.tui.co.uk/destinations/info/privacy-notice"
        >
          https://www.tui.co.uk/destinations/info/privacy-notice
        </a>
      </div>
    </>
  );
};

const RiverText: FC = () => {
  return (
    <div className="mt-6">
      <p>
        Before you travel with TUI River Cruises, we need some more information
        about you.
      </p>
      <p>
        It is essential to complete the questions prior to your sailing with us.
      </p>
      <p>
        Any information you share will be seen only by the relevant TUI River
        Cruises team and our onboard providers and will be used in accordance
        with our privacy policy{" "}
        <a
          className="text-blue underline"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.tui.co.uk/destinations/info/privacy-notice"
        >
          https://www.tui.co.uk/destinations/info/privacy-notice
        </a>
        .
      </p>
    </div>
  );
};
