import axios from "axios";
import { getDinningUrl } from "config/configUtils";
import useAppSelector from "hooks/useAppSelector";
import { IPayment } from "interfaces/ReduxState/IPayment";
import { useCallback, useState } from "react";

type Props = {
  transactionId: IPayment["transactionId"];
};

interface IGetConfirmationCartOswResult {
  bookingReference: number;
}

export const useGetConfiramtionCartMxp = () => {
  const sessionId = useAppSelector((state) => state.authentication.sessionId);

  const [data, setData] = useState<IGetConfirmationCartOswResult>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<boolean>(false);

  const getConfiramtionCartMxp = useCallback(
    async ({ transactionId }: Props) => {
      const URL = `${getDinningUrl()}/api/payments/opp/confirm/${transactionId}`;
      let loading = true;
      let initialError = false;

      setLoading(loading);
      const response = await axios
        .get(URL, {
          headers: {
            SessionId: sessionId,
          },
        })
        .catch((error: unknown) => {
          if (error instanceof Error) {
            setError(true);
            initialError = true;
          }
        });

      loading = false;

      setData(response?.data);
      setLoading(loading);

      return { data: response?.data, error: initialError, loading };
    },
    [sessionId]
  );

  return { getConfiramtionCartMxp, data, loading, error };
};
