import { FC } from "react";
import { useSurveyContext } from "../../../context/SurveyContext";
import TextInputWithLabel from "../../TextInputWithLabel";

type Props = {
  question: any;
  activePart: any;
  validate: (question: any, answer: any) => void;
};

const TextQuestion: FC<Props> = ({ question, activePart, validate }) => {
  const {
    survey: { answers },
    setAnswer,
  } = useSurveyContext();
  const answer = answers[question.id] || "";

  const isShown = question.part === activePart;

  return (
    <div className={isShown ? "" : "hidden"}>
      <TextInputWithLabel
        label={question.questionText}
        subLabel={question.questionInfo}
        subLabelClasses="text-grey-darker"
        value={answer}
        _onChange={(value: unknown) => setAnswer(question.id, value)}
        onBlur={() => validate(question, answer)}
        placeholder=""
        labelClassNames="input-label"
      />
    </div>
  );
};

export default TextQuestion;
