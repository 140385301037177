import { useState, useEffect, FC } from "react";
import useGet from "../../hooks/useGet";
import SvgIcon from "../../components/atoms/SvgIcon";
import TextInputWithLabel from "../../components/TextInputWithLabel";
import RadioButton from "../../components/Buttons/RadioButton/RadioButton";
import SelectDropdown from "../../components/SelectDropdown";
import { isoCountries, isoNationalities } from "../../config/contryUtils";
import BackLink from "../../components/molecules/BackLink";
import { postToHub } from "../../actions/networkActions";
import {
  getApisErrors,
  getDateError,
  getDocumentTypeError,
  getDropdownError,
  getGenderError,
  getNameError,
} from "../../utils/validationUtils";
import browserHistory from "../../utils/history";
import { potentialdmYtoDMY, isValidDate } from "../../utils/dateTimeUtils";
import { IPassenger } from "interfaces/Common/IPassenger";

const ApisForm = () => {
  const passengers = useGet("passengers/api", []);
  const [formState, setFormState] = useState(new Map());
  const [errors, setErrors] = useState(new Map());
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState("");

  // once data is fetched from hub, take the stored data and map it to form state
  useEffect(() => {
    if (passengers.length) {
      const hubFormState = new Map();

      passengers.forEach(
        (passenger: {
          passengerId: any;
          advancedPassengerInformation: any;
        }) => {
          hubFormState.set(passenger.passengerId, {
            ...passenger.advancedPassengerInformation,
          });
        }
      );

      setFormState(hubFormState);

      checkIfFormValid(hubFormState);
    }
  }, [passengers]);

  const updateFormValue = (passengerId: any, fieldName: string, value: any) => {
    const passengerFormValues = { ...formState.get(passengerId) };
    passengerFormValues[fieldName] = value;

    const updatedFormState = new Map(formState).set(
      passengerId,
      passengerFormValues
    );
    setFormState(updatedFormState);

    checkIfFormValid(updatedFormState);
  };

  // if all pax have no errors set submit enabled to true otherwise to false
  const checkIfFormValid = (formValues: Map<any, any>) => {
    const isValid = passengers.every(
      (passenger: IPassenger) =>
        Object.keys(getApisErrors(formValues.get(passenger.passengerId), false))
          .length === 0
    );

    setSubmitEnabled(isValid);

    if (submitErrorMessage) {
      setSubmitErrorMessage("");
    }
  };

  const submit = async () => {
    const body: any = { passengers: [] };
    const validationErrors = new Map();
    let isFormValid = true;

    formState.forEach((passengerData, passengerId) => {
      body.passengers.push({
        ...passengerData,
        passengerId,
        dateOfBirth: potentialdmYtoDMY(passengerData.dateOfBirth),
        documentExpiryDate: potentialdmYtoDMY(passengerData.documentExpiryDate),
      });

      const passengerErrors = getApisErrors(passengerData);
      validationErrors.set(passengerId, passengerErrors);
      if (Object.keys(passengerErrors).length > 0) {
        isFormValid = false;
      }
    });

    if (!isFormValid) {
      setErrors(validationErrors);
      return;
    }

    if (submitInProgress) {
      return;
    }

    setSubmitInProgress(true);
    const result = await postToHub("passengers/api/submitApi", body);
    setSubmitInProgress(false);

    if (result === "") {
      browserHistory.push("/");
      return;
    }

    const hasError = result?.error;
    if (hasError) {
      const errorCode = result?.error?.response?.status;
      if (errorCode === 400) {
        const passengerId = result.error.response.data?.passengerId;
        const passenger = passengers.find(
          (p: any) => p.passengerId === passengerId
        );
        const fieldNames: any = {
          firstName: "first name",
          lastName: "surname",
          gender: "gender",
          dateOfBirth: "date of birth",
          documentType: "travel document type",
          documentNumber: "travel document number",
          documentExpiryDate: "expiry date",
          documentIssuanceCountry: "issuing country",
          nationality: "nationality/region",
        };

        const fieldName = result.error.response.data.errorField;
        const fieldLabel = fieldNames[fieldName] || fieldName;

        if (passengerId && passenger) {
          setError(
            passengerId,
            fieldName,
            "Please make sure this field is valid"
          );
          setSubmitErrorMessage(
            `Please correct the field ${fieldLabel} for passenger ${passenger?.title} ${passenger?.firstName} ${passenger?.lastName} and resubmit the form`
          );
        }
      } else {
        setSubmitErrorMessage(
          "There has been an error with submitting your form. Please try again later."
        );
      }
    } else {
      setSubmitErrorMessage("Unexpected error happened.");
    }
  };

  const setError = (passengerId: any, fieldName: any, error: any) => {
    const passengerErrors = { ...errors.get(passengerId) };
    if (error) {
      passengerErrors[fieldName] = error;
    } else {
      delete passengerErrors[fieldName];
    }

    setErrors(new Map(errors).set(passengerId, passengerErrors));
  };

  if (passengers.error) {
    return (
      <div className="container mx-auto mt-6">
        <p>There has been an error, please try again</p>
      </div>
    );
  }

  if (passengers.length === 0) {
    return (
      <div className="container mx-auto mt-6">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <>
      <div className="container mx-auto my-4">
        <BackLink to="/" text="Back to Cruise Control Dashboard" />
      </div>

      <div className="bg-grey-lighter py-4 mb-8">
        <div className="container mx-auto">
          <div className="flex">
            <SvgIcon
              name="info-inverted"
              className="flex-shrink-0 w-6 h-6 text-blue-dark"
            />
            <div className="ml-2 text-left leading-normal">
              All passenger information must be filled in before submitting. You{" "}
              <strong>must</strong> travel using the same document you provide
              here.
              <strong>
                {" "}
                If you supply incorrect information you may not be accepted for
                travel.{" "}
              </strong>
              Learn more.
              <div className="mt-2">
                <strong>
                  Once you have checked in, you will no longer be able to edit
                  these.
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto mb-8 xl:mb-12">
        {passengers.map((passenger: IPassenger) => (
          <PassengerApisForm
            key={passenger.passengerId}
            passenger={passenger}
            formValues={formState.get(passenger.passengerId) || {}}
            onUpdate={updateFormValue}
            errors={errors.get(passenger.passengerId) || {}}
            onValidate={setError}
          />
        ))}

        <div className="mt-6">
          <button
            className={`w-mobile action-button ${
              submitEnabled && !submitInProgress
                ? "action-button-enabled"
                : "action-button-disabled"
            }`}
            onClick={submit}
          >
            Submit and return to booking
          </button>
        </div>
        <ErrorMessage message={submitErrorMessage} />
      </div>
    </>
  );
};

export default ApisForm;

type PassengerApisFormProps = {
  passenger: IPassenger;
  onUpdate: (passengerId: any, fieldValue: any, value: any) => void;
  onValidate: (passengerId: any, fieldValue: any, value: any) => void;
  errors: any;
  formValues: any;
};

const PassengerApisForm: FC<PassengerApisFormProps> = ({
  passenger,
  errors,
  onUpdate,
  onValidate,
  formValues,
}) => {
  const handleChange = (fieldName: string, value: any) => {
    onUpdate(passenger.passengerId, fieldName, value);

    if (errors[fieldName]) {
      const error = getErrorForField(fieldName, value);
      onValidate(passenger.passengerId, fieldName, error);
    }
  };

  const handleBlur = (fieldName: string, value: any) => {
    const error = getErrorForField(fieldName, value);
    onValidate(passenger.passengerId, fieldName, error);
  };

  const getErrorForField = (fieldName: string, value: any) => {
    let error = "";
    switch (fieldName) {
      case "middleName":
        error = getNameError(value, false);
        break;

      case "firstName":
      case "lastName":
        error = getNameError(value, true);
        break;

      case "gender":
        error = getGenderError(value);
        break;

      case "dateOfBirth":
      case "documentExpiryDate":
        error = getDateError(value);
        break;

      case "documentType":
        error = getDocumentTypeError(value);
        break;

      case "documentIssuanceCountry":
      case "nationality":
        error = getDropdownError(value);
        break;
    }
    return error;
  };

  return (
    <div className="border mt-6">
      <div className="border-b p-4">
        <div className="flex items-center text-blue-dark text-3xl font-bold">
          <SvgIcon name="adult" className="w-8 h-8" />
          <div className="ml-2">
            {passenger.title} {passenger.firstName} {passenger.lastName}
          </div>
        </div>
      </div>
      <div className="p-4 bg-grey-lightest">
        <div className="w-full md:w-3/4 xl:w-1/2">
          <div className="text-grey-darker leading-normal">
            Please ensure that the information entered exactly matches your
            travel document.
          </div>

          <div className="sm:flex">
            <div className="sm:flex-grow sm:mr-2">
              <TextInputWithLabel
                classNames="w-full mt-4"
                label="First name"
                value={formValues.firstName || ""}
                _onChange={(value: any) => {
                  handleChange("firstName", value);
                }}
                onBlur={(e: any) => handleBlur("firstName", e.target.value)}
                hasError={errors.firstName}
              />
              {errors.firstName && (
                <div className="sm:hidden mt-1 errors">{errors.firstName}</div>
              )}
            </div>

            <div className="sm:flex-grow sm:mr-2">
              <TextInputWithLabel
                classNames="w-full mt-4"
                label="Middle name(s)"
                value={formValues.middleName || ""}
                _onChange={(value: any) => {
                  handleChange("middleName", value);
                }}
                onBlur={(e: any) => handleBlur("middleName", e.target.value)}
                hasError={errors.middleName}
              />
              {errors.middleName && (
                <div className="sm:hidden mt-1 errors">{errors.middleName}</div>
              )}
            </div>

            <div className="sm:flex-grow">
              <TextInputWithLabel
                classNames="w-full mt-4"
                label="Surname"
                value={formValues.lastName || ""}
                _onChange={(value: any) => {
                  handleChange("lastName", value);
                }}
                onBlur={(e: any) => handleBlur("lastName", e.target.value)}
                hasError={errors.lastName}
              />
              {errors.lastName && (
                <div className="sm:hidden mt-1 errors">{errors.lastName}</div>
              )}
            </div>
          </div>

          {errors.firstName && (
            <div className="hidden sm:block mt-2 errors">
              {errors.firstName}
            </div>
          )}

          {errors.middleName && (
            <div className="hidden sm:block mt-2 errors">
              {errors.middleName}
            </div>
          )}

          {errors.lastName && (
            <div className="hidden sm:block mt-2 errors">{errors.lastName}</div>
          )}

          <div className="mt-4">
            <div className="input-label">Gender</div>
            <div className="flex mt-1">
              <div>
                <RadioButton
                  label="Male"
                  _onChange={() => handleChange("gender", "Male")}
                  isSelected={formValues.gender === "Male"}
                />
              </div>
              <div className="ml-4">
                <RadioButton
                  label="Female"
                  _onChange={() => handleChange("gender", "Female")}
                  isSelected={formValues.gender === "Female"}
                />
              </div>
            </div>
            {errors.gender && (
              <div className="hidden sm:block mt-2 errors">{errors.gender}</div>
            )}
          </div>

          <DateInput
            label="Date of birth (DD/MM/YYYY)"
            value={formValues.dateOfBirth}
            onChange={(value) => handleChange("dateOfBirth", value)}
            error={errors.dateOfBirth}
          />

          <div className="mt-4">
            <SelectDropdown
              selectId="document-type-dropdown"
              labelClasses="block input-label mb-1"
              label="Travel document"
              error={errors.documentType}
              options={["Select document type", "Passport", "Identity Card"]}
              handleChange={(e: any) =>
                handleChange("documentType", e.target.value)
              }
              handleBlur={(e: any) =>
                handleBlur("documentType", e.target.value)
              }
              value={formValues.documentType}
            />
            <ErrorMessage message={errors.documentType} />
          </div>

          <TextInputWithLabel
            classNames="w-full mt-4"
            label="Travel document number"
            errorMessage={errors.documentNumber}
            value={formValues.documentNumber || ""}
            _onChange={(value: any) => handleChange("documentNumber", value)}
            onBlur={(e: any) => handleBlur("documentNumber", e.target.value)}
          />

          <DateInput
            label="Expiry date"
            value={formValues.documentExpiryDate}
            onChange={(value) => handleChange("documentExpiryDate", value)}
            error={errors.documentExpiryDate}
          />

          <div className="mt-4">
            <SelectDropdown
              selectId="country-dropdown"
              labelClasses="block input-label mb-1"
              label="Issuing country"
              error={errors.documentIssuanceCountry}
              options={isoCountries}
              handleChange={(e: any) =>
                handleChange("documentIssuanceCountry", e.target.value)
              }
              handleBlur={(e: any) =>
                handleBlur("documentIssuanceCountry", e.target.value)
              }
              value={formValues.documentIssuanceCountry}
            />
          </div>

          <div className="mt-4">
            <SelectDropdown
              selectId="nationality-dropdown"
              labelClasses="block input-label mb-1"
              label="Nationality/region"
              error={errors.nationality}
              options={isoNationalities}
              handleChange={(e: any) =>
                handleChange("nationality", e.target.value)
              }
              handleBlur={(e: any) => handleBlur("nationality", e.target.value)}
              value={formValues.nationality}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

type DateInputProps = {
  value: string;
  label: string;
  onChange: (value: any) => void;
  error: string;
};
const DateInput: FC<DateInputProps> = ({ value, label, error, onChange }) => {
  const dateParts = value?.split("/") || ["", "", ""];

  const [errors, setErrors] = useState<any>({
    day: null,
    month: null,
    year: null,
    date: null,
  });

  const handleChange = (field: string, value: any) => {
    const updatedParts = [...dateParts];
    switch (field) {
      case "day":
        if (value.length < 3) {
          updatedParts[0] = value;
        }
        break;

      case "month":
        if (value.length < 3) {
          updatedParts[1] = value;
        }
        break;

      case "year":
        if (value.length < 5) {
          updatedParts[2] = value;
        }
        break;
    }

    const updatedString = updatedParts.join("/");

    // check if error for this field was set and if so validate again (and potentially remove the error)
    let shouldUpdateErrors = false;
    const newErrors: any = { ...errors };

    if (errors[field]) {
      if (!getError(field, value)) {
        newErrors[field] = false;
        shouldUpdateErrors = true;
      }
    }

    // check if general error is set on date and clear it if problem has been fixed
    if (errors.date) {
      if (isValidDate(updatedString)) {
        newErrors.date = false;
        shouldUpdateErrors = true;
      }
    }

    if (shouldUpdateErrors) {
      setErrors(newErrors);
    }

    onChange(updatedParts.join("/"));
  };

  const handleBlur = (field: string, blurValue: any) => {
    const newErrors: any = { ...errors };
    newErrors[field] = getError(field, blurValue);

    // if all 3 parts are populated, check if date is valid and other relevant date checks
    if (dateParts.every((datePart) => datePart)) {
      newErrors.date = isValidDate(value)
        ? false
        : "Please make sure to enter a valid date";
    }

    setErrors(newErrors);
  };

  const getError = (field: string, value: any) => {
    switch (field) {
      case "day":
        const day = parseInt(value);
        return isNaN(day) || day < 1 || day > 31
          ? "Please enter a valid day"
          : false;

      case "month":
        const month = parseInt(value);
        return isNaN(month) || month < 1 || month > 12
          ? "Please enter a valid month"
          : false;

      case "year":
        const year = parseInt(value);
        const currentYear = new Date().getFullYear();

        return isNaN(year) ||
          currentYear - year > 115 ||
          year - currentYear > 20
          ? "Please enter a valid year"
          : false;
    }
  };

  return (
    <div className="mt-4">
      <div className="input-label mb-1">{label}</div>
      <div className="flex">
        <div className="sm:w-1/4 md:w-1/5">
          <TextInputWithLabel
            classNames="w-full"
            type="tel"
            value={dateParts[0]}
            _onChange={(value: any) => handleChange("day", value)}
            placeholder="DD"
            onBlur={() => handleBlur("day", dateParts[0])}
            hasError={errors.day || error}
          />
        </div>

        <div className="sm:w-1/4 md:w-1/5">
          <TextInputWithLabel
            classNames="ml-2"
            type="tel"
            value={dateParts[1]}
            _onChange={(value: any) => handleChange("month", value)}
            placeholder="MM"
            onBlur={() => handleBlur("month", dateParts[1])}
            hasError={errors.month || error}
          />
        </div>

        <div className="sm:w-1/4 md:w-1/5">
          <TextInputWithLabel
            classNames="ml-2"
            type="tel"
            value={dateParts[2]}
            _onChange={(value: any) => handleChange("year", value)}
            placeholder="YYYY"
            onBlur={() => handleBlur("year", dateParts[2])}
            hasError={errors.year || error}
          />
        </div>
      </div>

      <ErrorMessage message={error} />
      <ErrorMessage message={errors.day} />
      <ErrorMessage message={errors.month} />
      <ErrorMessage message={errors.year} />
      <ErrorMessage message={errors.date} />
    </div>
  );
};

type ErrorMessageProps = {
  message: string;
};
const ErrorMessage: FC<ErrorMessageProps> = ({ message }) => {
  if (!message) {
    return null;
  }

  return <div className="mt-2 errors">{message}</div>;
};
