import axios from "axios";
import { getOswUrl, getShipCodeByName } from "config/configUtils";
import useAppSelector from "hooks/useAppSelector";
import { useCallback, useState } from "react";
import { getCruisesInfo } from "utils/cruiseUtils";

type Props = {
  treatmentId: number;
  itineraryId: string;
  openTimeSlotId: number;
  price: number;
  comments: string;
  guestId: string;
  openSlotStartDateTime: string;
};

export const usePostAddToPlanner = () => {
  const sessionId = useAppSelector((state) => state.authentication.sessionId);
  const cruiseDetails = useAppSelector((state) => state.cruises.cruiseDetails);
  const cruisesInfo = getCruisesInfo(cruiseDetails);

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const postAddToPlanner = useCallback(
    async ({
      treatmentId,
      itineraryId,
      openTimeSlotId,
      price,
      comments,
      guestId,
      openSlotStartDateTime,
    }: Props) => {
      setLoading(true);
      const URL = `${getOswUrl()}/api/ships/${getShipCodeByName({
        shipName: cruisesInfo.shipName,
      })}/planner/assign`;

      const response = await axios
        .post(
          URL,
          {
            treatmentId,
            itineraryId,
            openTimeSlotId,
            price,
            comments,
            guestId,
            openTimeSlotDateTime: openSlotStartDateTime,
          },
          {
            headers: {
              SessionId: sessionId,
            },
          }
        )
        .catch((error) => {
          setError(error);
        });

      setData(response?.data);
      setLoading(false);
    },
    [cruisesInfo.shipName, sessionId]
  );

  return { postAddToPlanner, data, loading, error };
};
