import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from "react";

const PreCruiseContext = createContext<any>({ passengers: [] });

export const usePreCruiseContext = () => {
  const context = useContext(PreCruiseContext);
  if (!context) {
    throw new Error(
      "usePreCruiseContext must be used within PrecruiseProvider"
    );
  }

  return context;
};

type PreCruiseProviderProps = PropsWithChildren & {};

export const PreCruiseProvider: FC<PreCruiseProviderProps> = (props) => {
  const [preCruiseInfo, setPreCruiseInfo] = useState<any>({});

  return (
    <PreCruiseContext.Provider
      value={{ preCruiseInfo, setPreCruiseInfo }}
      {...props}
    />
  );
};
