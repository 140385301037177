import { ChangeEvent, FC, useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import SvgIcon from "../../atoms/SvgIcon";
import Price from "../../Price/Price";
import { getFromHub, postToHub } from "../../../actions/networkActions";
import {
  convertDMYtoYMD,
  prepareDateTimeForOverlapCheck,
  formatPortDate,
  formatTimeHM,
} from "utils/dateTimeUtils";
import Checkbox from "../../Checkbox";
import TextInputWithLabel from "../../TextInputWithLabel";
import ModalFooter from "../ModalFooter";
import { removeTacEntryFromCart } from "../../../actions/tacActions";
import { imagePath } from "../../../utils/imageUtils";
import SelectDropdown from "../../SelectDropdown";
import AvailabilityOption from "../../availabilityOption";
import { useUpdateCart } from "actions/useUpdateCart";

type Props = {
  preselectedDetails: any;
  restaurant: any;
  date: any;
  onError: (value: string) => void;
  onSuccess: () => void;
  closeModal: () => void;
};

const dietaryOptions: any = {
  dairy: "Dairy free",
  vegan: "Vegan",
  gluten: "Gluten free",
  nut: "Nut allergy",
  shellfish: "Shellfish allergy",
  lactose: "Lactose intolerant",
  other: "Other",
};

const otherPrefix = "Other: ";

const RestaurantAvailabilityModal: FC<Props> = ({
  preselectedDetails,
  restaurant,
  date,
  onError,
  onSuccess,
  closeModal,
}) => {
  // special rule for The Exchange events only, refer to CRUISE-503 task
  const THE_EXCHANGE_RESTAURANT = "The Exchange";
  const isExchangeRestaurant = restaurant.name.defaultPlainValue.includes(
    THE_EXCHANGE_RESTAURANT
  );
  const adultsDropdownLabel = isExchangeRestaurant
    ? "Adults (18+)"
    : "Adults (13+)";
  const initialNrAdultsSelected = preselectedDetails
    ? preselectedDetails.nrAdults
    : restaurant.minimumNumberOfAdults < 2 &&
      restaurant.maximumNumberOfAdults > 1
    ? 2
    : restaurant.minimumNumberOfAdults;
  const initialNrChildrenSelected = preselectedDetails
    ? preselectedDetails.nrChildren
    : 0;

  const initialSpecialRequirements: any = {};
  let initialOtherAllergyText = "";

  //special case for other
  const otherRequirement =
    preselectedDetails &&
    preselectedDetails.specialRequirements.find((requirement: any) =>
      requirement.startsWith(otherPrefix)
    );
  if (otherRequirement) {
    initialSpecialRequirements.other = true;
    initialOtherAllergyText = otherRequirement.substring(otherPrefix.length);
  }

  // convert preselected to state format
  Object.keys(dietaryOptions).forEach((key) => {
    initialSpecialRequirements[key] =
      preselectedDetails &&
      preselectedDetails.specialRequirements.includes(dietaryOptions[key]);
  });

  const [groupSizeTooLarge, setGroupSizeTooLarge] = useState(false);
  const [nrAdultsSelected, setNrAdultsSelected] = useState(
    initialNrAdultsSelected
  );
  const [nrChildrenSelected, setNrChildrenSelected] = useState(
    initialNrChildrenSelected
  );
  const [isLoading, setIsLoading] = useState(false);
  const [availabilities, setAvailabilities] = useState<any[]>([]);
  const [selectedAvailability, setSelectedAvailability] = useState<any>(false);
  const [isDietaryFormOpen, setIsDietaryFormOpen] = useState(false);
  const [isOtherAllergyInputShown, setIsOtherAllergyInputShown] = useState(
    !!otherRequirement
  );
  const [otherAllergyText, setOtherAllergyText] = useState(
    initialOtherAllergyText
  );
  const [otherAllergyTextError, setOtherAllergyTextError] = useState("");
  const [specialRequirements, setSpecialRequirements] = useState(
    initialSpecialRequirements
  );
  const [overlapElements, setOverlapElements] = useState([]);
  const [addingToCart, setAddingToCart] = useState(false);
  const { updateCart } = useUpdateCart();

  useEffect(() => {
    //load the initial availabilities
    loadAvailabilities(true);
  }, []);

  const loadAvailabilities = (preselect = false) => {
    setIsLoading(true);

    getFromHub(
      `prebooking/tac/templates/${restaurant.templateId}/serviceProfiles/${
        restaurant.id
      }/availabilities?date=${convertDMYtoYMD(
        date
      )}&numberOfAdults=${nrAdultsSelected}&numberOfChildren=${nrChildrenSelected}`,
      (response: any) => updateAvailabilities(response, preselect)
    );
  };

  const updateAvailabilities = (response: any, preselect = false) => {
    const preselectedDateTime = preselectedDetails
      ? `${date} ${preselectedDetails.time}`
      : null;
    let selectedAvailability: any = false;

    const availabilities = response.availabilities
      .filter((availability: any) => availability.numberOfAvailabilities > 0)
      .sort((a: any, b: any) => (a.fromTime > b.fromTime ? 1 : -1))
      .map((availability: any, index: number) => {
        const isSelected =
          preselect && availability.fromTime === preselectedDateTime;
        if (isSelected) {
          selectedAvailability = index;
        }

        return { ...availability, selected: isSelected };
      });

    setIsLoading(false);
    setSelectedAvailability(selectedAvailability);
    setAvailabilities(availabilities);
  };

  const handleDropdownChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.id === "adults-dropdown") {
      setNrAdultsSelected(parseInt(e.target.value));
    } else {
      setNrChildrenSelected(parseInt(e.target.value));
    }
  };

  useEffect(() => {
    validateDropdownChange();
  }, [nrAdultsSelected, nrChildrenSelected]);

  const validateDropdownChange = () => {
    if (
      nrAdultsSelected + nrChildrenSelected >
      restaurant.maximumNumberOfPersons
    ) {
      setGroupSizeTooLarge(true);
      setAvailabilities([]);
      setSelectedAvailability(false);
    } else {
      setGroupSizeTooLarge(false);
      loadAvailabilities();
    }
  };

  const handleSlotSelect = (selectedIndex: any, availability: any) => {
    if (availability.numberOfAvailabilities === 0) {
      return;
    }

    const updatedAvailabilities = availabilities.map(
      (availability: any, index: number) => {
        availability.selected = index === selectedIndex;
        return availability;
      }
    );

    setAvailabilities(updatedAvailabilities);
    setSelectedAvailability(selectedIndex);
  };

  const toggleDietaryForm = () => {
    setIsDietaryFormOpen(!isDietaryFormOpen);
  };

  const handleCheckboxChange = (key: any) => {
    const updatedSpecialRequirements = { ...specialRequirements };
    updatedSpecialRequirements[key] = !updatedSpecialRequirements[key];

    setSpecialRequirements(updatedSpecialRequirements);
    setIsOtherAllergyInputShown(
      key === "other" ? !isOtherAllergyInputShown : isOtherAllergyInputShown
    );
  };

  const handleAllergyTextChange = (value: string) => {
    if (value.length > 30) {
      setOtherAllergyTextError("Please limit your response to 30 characters");
      return;
    }

    setOtherAllergyText(value);
    setOtherAllergyTextError("");
  };

  const prepareSpecialRequirements = () => {
    const updatedSpecialRequirements: any = [];
    Object.keys(dietaryOptions).forEach((key) => {
      if (specialRequirements[key]) {
        if (key === "other") {
          updatedSpecialRequirements.push(`${otherPrefix}${otherAllergyText}`);
        } else {
          updatedSpecialRequirements.push(dietaryOptions[key]);
        }
      }
    });

    return updatedSpecialRequirements;
  };

  const addToCart = async () => {
    // make sure other text is not empty when selected:
    if (!validateOtherAllergy()) {
      return;
    }

    const overlaps = await checkForOverlaps();
    if (overlaps.length) {
      // show the overlap modal
      setOverlapElements(overlaps);
      return;
    }

    postToCart();
  };

  const postToCart = async () => {
    setAddingToCart(true);

    if (preselectedDetails) {
      await removeTacEntryFromCart(preselectedDetails.id);
      await updateCart();
    }

    const payload = prepareAddToCartPayload();
    const result = await postToHub("prebooking/cart", payload);

    setAddingToCart(false);

    if (result && result.error) {
      if (
        result.error.response &&
        result.error.response.data === "AlreadyInCart"
      ) {
        onError(
          "You already have a reservation for this date, time and restaurant in your Planner."
        );
      } else {
        onError(
          "Reservation could not be added to cart. Please try again later or contact support."
        );
      }
    } else {
      onSuccess();
      await updateCart();
    }
  };

  const validateOtherAllergy = () => {
    // check if other checkbox is selected and other allergy text input is empty
    if (specialRequirements["other"] && otherAllergyText.trim() === "") {
      setOtherAllergyTextError(
        "Please describe your allergy, or untick the 'Other' checkbox"
      );

      return false;
    }

    return true;
  };

  const prepareAddToCartPayload = () => {
    const specialRequirements = prepareSpecialRequirements();

    return [
      {
        type: "Tac",
        action: "add",
        availabilityId: availabilities[selectedAvailability].availabilityId,
        serviceProfile: restaurant.id,
        fromTime: availabilities[selectedAvailability].fromTime,
        toTime: availabilities[selectedAvailability].toTime,
        numberOfAdults: nrAdultsSelected,
        numberOfChildren: nrChildrenSelected,
        specialRequirements,
      },
    ];
  };

  const checkForOverlaps = async () => {
    const overlaps: any = [];
    // fetch overlap info from hub
    const currentSelectedAvailability = availabilities[selectedAvailability];
    const fromTime = prepareDateTimeForOverlapCheck(
      currentSelectedAvailability.fromTime,
      false
    );
    const toTime = prepareDateTimeForOverlapCheck(
      currentSelectedAvailability.toTime,
      true
    );
    const hubOverlaps = await getFromHub(
      `prebooking/cart/validate?fromTime=${fromTime}&toTime=${toTime}`
    );

    Object.keys(hubOverlaps.timeCheckOverlapsBookingParts).forEach(
      (dateTime) => {
        const parsedOverlaps = parseOverlaps(
          hubOverlaps.timeCheckOverlapsBookingParts[dateTime]
        );
        if (parsedOverlaps.length) {
          overlaps.push(...parsedOverlaps);
        }
      }
    );

    Object.keys(hubOverlaps.timeCheckOverlapsCartEntries).forEach(
      (dateTime) => {
        const parsedOverlaps = parseOverlaps(
          hubOverlaps.timeCheckOverlapsCartEntries[dateTime]
        );
        if (parsedOverlaps.length) {
          overlaps.push(...parsedOverlaps);
        }
      }
    );

    // return if any overlap exists
    return overlaps;
  };

  const parseOverlaps = (potentialOverlaps: any) => {
    const processedOverlaps: any = [];

    potentialOverlaps.forEach((item: any) => {
      const overlapToAdd = parseOverlap(item);
      if (overlapToAdd) {
        processedOverlaps.push(overlapToAdd);
      }
    });

    return processedOverlaps;
  };

  const parseOverlap = (overlap: any) => {
    // Excursions
    if (overlap.type === "CruiseExcursion") {
      // TODO - implement cruise excursion overlaps checking once/molecules/If we get real times from MXP
      return false;
    }

    // TAC Booking
    if (overlap.type === "BookingPartTac") {
      return extractTacInfo(overlap);
    }

    // TAC Cart entry
    if (overlap.type === "Tac") {
      const currentSelectedAvailability = availabilities[selectedAvailability];

      // skip the overlap with the entry that will be replaced (when dealing with edit reservation
      if (preselectedDetails && overlap.id === preselectedDetails.id) {
        return false;
      }

      // skip the overlap with an entry that will be replaced because it is for the exact same timeslot as an existing one in cart
      if (
        overlap.serviceProfile.id === restaurant.id &&
        currentSelectedAvailability.fromTime === overlap.fromTime &&
        currentSelectedAvailability.toTime === overlap.toTime
      ) {
        return false;
      }

      return extractTacInfo(overlap);
    }

    return false;
  };

  const extractTacInfo = (overlap: any) => {
    const partySize = overlap.numberOfAdults + overlap.numberOfChildren;
    const fromTime = overlap.fromDate
      ? overlap.fromTime
      : formatTimeHM(overlap.fromTime, "Couldn't get time");
    const toTime = overlap.fromDate
      ? overlap.toTime
      : formatTimeHM(overlap.toTimey, "Couldn't get time");
    const time = `${fromTime} - ${toTime}`;
    const date = overlap.fromDate
      ? overlap.fromDate
      : overlap.fromTime.substring(0, 10);

    return {
      serviceProfileId: overlap.serviceProfile.id,
      title: overlap.serviceProfile.name.defaultValue?.toUpperCase(),
      passengers: `Table for ${partySize} ${
        partySize > 1 ? "people" : "person"
      }`,
      time,
      date,
    };
  };

  const hideOverlapWarning = () => {
    setOverlapElements([]);
  };

  return (
    <Modal show={true} onClose={closeModal} title="Restaurant reservation">
      {overlapElements.length === 0 && (
        <>
          <div className="px-4 pt-2 text-blue-dark">
            <div className="text-lg border-b pb-2">
              {restaurant.name.defaultValue?.toUpperCase()}
            </div>
            <div className="text-grey-darker text-lg my-4">
              {formatPortDate(date)}
            </div>
            <div className="my-4">How many people?</div>
            <div className="my-4 flex items-end">
              <SelectDropdown
                classes="mr-4 w-1/2 md:w-40"
                selectId="adults-dropdown"
                dataTestIdName="adults-dropdown"
                label={adultsDropdownLabel}
                disabled={isLoading}
                error={groupSizeTooLarge}
                options={range(
                  restaurant.minimumNumberOfAdults,
                  restaurant.maximumNumberOfAdults
                )}
                handleChange={handleDropdownChange}
                value={nrAdultsSelected}
              />

              {!isExchangeRestaurant && (
                <SelectDropdown
                  classes="w-1/2 md:w-40"
                  selectId="children-dropdown"
                  dataTestIdName="children-dropdown"
                  label="Children (2-12 years)"
                  disabled={isLoading}
                  error={groupSizeTooLarge}
                  options={range(
                    restaurant.minimumNumberOfChildren,
                    restaurant.maximumNumberOfChildren
                  )}
                  handleChange={handleDropdownChange}
                  value={nrChildrenSelected}
                />
              )}
            </div>

            {/* group size too large to book online */}
            {groupSizeTooLarge && (
              <div className="border border-red text-red p-4 mt-4 mb-6">
                We are unable to take bookings for more than{" "}
                {restaurant.maximumNumberOfPersons} passenger
                {restaurant.maximumNumberOfPersons > 1 ? "s" : ""} online.
                Please check onboard for availability.
              </div>
            )}

            {/* loading availabilities */}
            {!groupSizeTooLarge && isLoading && (
              <div className="my-4">Searching for available time slots...</div>
            )}

            {/* no error and availabilities loaded*/}
            {!groupSizeTooLarge && !isLoading && (
              <div>
                <div className="my-4">What time would you like your table?</div>

                {availabilities.length === 0 && (
                  <div className="bg-grey-lighter px-4 py-3">
                    Unfortunately, we don’t have any tables available for your
                    dining party to book at this time. Please enquire about your
                    dining options onboard.
                  </div>
                )}
                <div className="mt-4 md:flex md:flex-wrap md:-mx-2">
                  {availabilities.map((availability, index) => (
                    <AvailabilityOption
                      key={index}
                      availability={availability}
                      onClick={() => handleSlotSelect(index, availability)}
                    />
                  ))}
                </div>
              </div>
            )}

            {/* Dietary restrictions / alergies */}
            {!isLoading && availabilities.length > 0 && (
              <div>
                {isDietaryFormOpen && (
                  <div className="flex flex-wrap text-grey-dark">
                    {Object.keys(dietaryOptions).map((key) => (
                      <div key={key} className="w-1/2 sm:w-1/3 mt-4">
                        <Checkbox
                          checked={specialRequirements[key]}
                          value={dietaryOptions[key]}
                          handleCheckboxChange={() => handleCheckboxChange(key)}
                        >
                          {dietaryOptions[key]}
                        </Checkbox>
                      </div>
                    ))}
                  </div>
                )}
                {isDietaryFormOpen && isOtherAllergyInputShown && (
                  <div>
                    <TextInputWithLabel
                      classNames="w-full mt-2"
                      labelClassNames="text-blue-dark leading-loose"
                      label="Please provide further details about your allergy:"
                      type="text"
                      value={otherAllergyText}
                      _onChange={(value: string) =>
                        handleAllergyTextChange(value)
                      }
                      placeholder="max 30 characters"
                      errorMessage={otherAllergyTextError}
                    />
                  </div>
                )}
                <div
                  className="my-4 text-blue cursor-pointer"
                  onClick={toggleDietaryForm}
                >
                  Add a dietary/allergy request{" "}
                  <SvgIcon
                    name={isDietaryFormOpen ? "chevron-up" : "chevron-down"}
                    className="w-6 h-6 inline ml-2"
                  />
                </div>

                {/* total cost summary */}
                <div className="border-b border-t py-2 flex justify-between items-center">
                  <div className="uppercase font-bold">Total cost</div>
                  <Price
                    price={
                      selectedAvailability === false
                        ? "0.00"
                        : availabilities[selectedAvailability].price
                    }
                  />
                </div>
              </div>
            )}
          </div>

          <ModalFooter
            onCancel={closeModal}
            actionDisabled={selectedAvailability === false}
            actionButtonText={
              preselectedDetails ? "Amend in planner" : "Add to planner"
            }
            onActionClick={addToCart}
          />
        </>
      )}

      {overlapElements.length > 0 && (
        <>
          <div className="flex-grow bg-white py-4 px-2 sm:px-4">
            <div className="bg-grey-lighter py-4 px-2 sm:px-4">
              <div className="flex items-center text-2xl mb-4 uppercase">
                <img
                  alt=""
                  className="w-6 h-6 mr-2"
                  src={imagePath("infoicon_inverted.svg")}
                />
                <span>Overlapping Planner Items</span>
              </div>
              <div>
                If you add this item to planner, there will be an overlap with
                the following planner items:
              </div>
              <div className="mt-4">
                {overlapElements.map((overlap: any, index: number) => (
                  <div key={index} className="mb-2">
                    <div className="text-lg">{overlap.title}</div>
                    <div>
                      {overlap.passengers}: {overlap.date} {overlap.time}
                    </div>
                  </div>
                ))}
              </div>
              <div className="my-4">
                Do you wish to continue with adding the selected item?
              </div>
            </div>
          </div>

          <ModalFooter
            onCancel={hideOverlapWarning}
            actionDisabled={addingToCart}
            actionButtonText="Confirm"
            onActionClick={postToCart}
          />
        </>
      )}
    </Modal>
  );
};

export default RestaurantAvailabilityModal;

const range = (start: number, stop: number) =>
  Array.from({ length: stop - start + 1 }, (_, i) => start + i);
