import { FC, FormEvent, useEffect, useState } from "react";

import {
  logoutAgency,
  logOutPassenger,
} from "../../actions/authenticationActions";
import If from "../../components/molecules/IF";
import TextInputWithLabel from "../../components/TextInputWithLabel";
import { setStore } from "../../actions/storeActions";
import { getHubBaseUrl } from "../../config/configUtils";
import useAppSelector from "../../hooks/useAppSelector";
import { useRouteMatch } from "react-router-dom";
import useAppDispatch from "../../hooks/useAppDispatch";
import { useLoginByToken } from "./hooks/useLoginByToken";

interface IMatchState {
  token: string;
}

const AgencyTokenLogin: FC = () => {
  const match = useRouteMatch<IMatchState>();
  const dispatch = useAppDispatch();

  const isRiverUrl = useAppSelector((state) => state.appConfig.isRiverUrl);

  const storeId = useAppSelector((state) => state.store.id);

  const { postLoginByToken, postLoginByTokenLoading, postLoginByTokenError } =
    useLoginByToken();

  const [currentStoreId, setCurrentStoreId] = useState<string>("");
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    logoutAgency();
    logOutPassenger();

    setCurrentStoreId(storeId);
  }, []);

  const validateForm = (e: FormEvent) => {
    e.preventDefault();

    let errors: any = {};

    if (!currentStoreId) {
      errors.storeId = "Please enter your Store Number";
    }

    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      _onLogin();
    }
  };

  const _onLogin = async () => {
    dispatch(setStore(currentStoreId));

    await postLoginByToken({
      token: match.params.token,
      isRiverUrl,
      storeId: currentStoreId,
    });
  };

  return (
    <div className="container mx-auto">
      <If test={!postLoginByTokenLoading && !postLoginByTokenError}>
        <form className="mt-8" onSubmit={(e) => validateForm(e)}>
          <div className="my-4">
            <TextInputWithLabel
              label="Store Number"
              type="text"
              value={currentStoreId}
              _onChange={(value: any) => setCurrentStoreId(value)}
              placeholder=""
              errorMessage={errors.storeId}
            />
          </div>

          <div className="my-4">
            <input
              className="inline-block w-1/2 sm:w-30 action-button action-button-enabled"
              type="submit"
              value="Login"
            />
          </div>
        </form>
      </If>

      <If test={postLoginByTokenLoading}>
        <div>
          <h2>Please wait...</h2>
        </div>
      </If>

      <If test={postLoginByTokenError}>
        <div className="my-8 text-xl">
          Unable to log in with the given credentials, please try again by
          visiting the{" "}
          <a
            className="underline text-blue-dark"
            href={`${getHubBaseUrl()}authentication/SingleSignOnServlet`}
          >
            login page.
          </a>
        </div>
      </If>
    </div>
  );
};

export default AgencyTokenLogin;
