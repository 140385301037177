import { FC } from "react";

type Props = {
  attributes: any;
};

const ExcursionAttributes: FC<Props> = ({ attributes }) => {
  return (
    <ul className="flex flex-col p-0 m-0 list-none text-grey-darker flex-grow">
      {attributes.map((attribute: any, index: number) => (
        <li className="excursion-attribute" key={index}>
          {attribute}
        </li>
      ))}
    </ul>
  );
};

export default ExcursionAttributes;
