import { useState, useEffect, useRef } from "react";
import BackLink from "../../components/molecules/BackLink";
import ActionButton from "../../components/Buttons/ActionButton";

const InstructionsPrintable = () => {
  const [iframeHeight, setIframeHeight] = useState(0);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const updateHeight = () => {
    if (iframeRef?.current?.contentWindow) {
      setIframeHeight(
        iframeRef.current.contentWindow.document.body.offsetHeight
      );
    }
  };

  const observer = useRef(new ResizeObserver(updateHeight));

  // register/deregister observer
  useEffect(() => {
    if (iframeRef.current) {
      observer.current.observe(iframeRef.current);
    }

    return () => {
      observer.current.disconnect();
    };
  }, [iframeRef, observer]);

  const print = () => {
    iframeRef?.current?.contentWindow?.print();
  };

  return (
    <div className="container mx-auto mt-4">
      <BackLink to="/" text="Back to Cruise Control Dashboard" />
      <h1>Luggage tag</h1>

      <h2 className="capitalize mt-4">Folding Instructions</h2>

      <ActionButton
        classes="mt-4"
        buttonText="Print folding instructions"
        onClick={print}
        disabled={false}
      />

      <div className="mt-4 mb-8 xl:mb-12">
        <iframe
          className="w-full shadow"
          ref={iframeRef}
          onLoad={updateHeight}
          style={{ height: `${iframeHeight}px` }}
          src="/luggage_tag_instructions.html"
        />
      </div>
    </div>
  );
};

export default InstructionsPrintable;
