import { store } from "WithProviders";
import { clearCruises, setCruises } from "./cruisesActions";
import { getFromHub } from "./networkActions";
import { ThunkAction } from "redux-thunk";
import {
  updateBookings,
  updateOswBookings,
  updateCart,
  updateMusementBookings,
  updateMxpBookings,
} from "./cartActions";
import { updateItinerary, updateItineraryExcursions } from "./itineraryActions";
import { RootState } from "../configureStore";
import { Action, AnyAction } from "redux";
import {
  clearPreCruiseStatus,
  setPreCruiseStatus,
} from "./preCruiseStatusAction";

export interface AuthenticationAction extends Action {
  authentication: any;
}

export const logoutAgency = (forgetStore?: any) => {
  store.dispatch({ type: "CLEAR_AGENCY_AUTHENTICATION" });
  logOutPassenger();
  if (forgetStore) {
    store.dispatch({ type: "CLEAR_STORE" });
  }
};

export const onLoginSuccess =
  (
    data: any,
    isRiverUrl: boolean
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch<AuthenticationAction>({
      type: "SET_AUTHENTICATION",
      authentication: { ...data, isRiverBooking: isRiverUrl },
    });

    const response = await getFromHub("precruise/status");

    setPreCruiseStatus(response);

    await getFromHub("prebooking/cruises", setCruises);
    await updateItinerary();
    await updateCart();
    await updateBookings();
    await updateOswBookings();
    await updateMusementBookings();
    await updateMxpBookings();
    await dispatch(updateItineraryExcursions());
  };

export const logOutPassenger = async () => {
  await Promise.all([
    store.dispatch({ type: "CLEAR_AUTHENTICATION" }),
    store.dispatch({ type: "CLEAR_PAYMENT" }),
    store.dispatch({ type: "CLEAR_CART" }),
    store.dispatch({ type: "CLEAR_BOOKINGS" }),
    store.dispatch({ type: "CLEAR_ITINERARY" }),
    clearPreCruiseStatus(),
    clearCruises(),
  ]);
};
