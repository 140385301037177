import { FC, ReactNode } from "react";
import Modal from "../Modal/Modal";
import { buildTestId } from "utils/buildTestId";

export type Props = {
  show: boolean;
  title: string;
  message: string;
  img?: ReactNode;
  footer?: ReactNode;
  onClose: () => void;
};

const NotificationModal: FC<Props> = ({
  show,
  title,
  message,
  img,
  footer,
  onClose,
}) => {
  return (
    <Modal show={show} onClose={onClose} title={title}>
      <div className="min-h-10em flex flex-grow flex-col">
        <div className="flex flex-grow flex-col justify-center">
          <div
            data-testid={buildTestId("img", "notification modal img")}
            className="text-center mt-8 mb-3"
          >
            {img}
          </div>
          <div className="text-blue-dark text-2xl text-center">
            <p data-testid={buildTestId("text", "notification modal message")}>
              {message}
            </p>
          </div>
        </div>
        <div
          data-testid={buildTestId("container", "notification modal footer")}
        >
          {footer}
        </div>
      </div>
    </Modal>
  );
};

export default NotificationModal;
