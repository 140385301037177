import { FC, FormEvent, KeyboardEvent } from "react";

type Props = {
  pin: any;
  setPin: (value: any) => void;
  submit: any;
  idPrefix: string;
  autoFocus?: boolean;
};

const PinInput: FC<Props> = ({
  pin,
  setPin,
  submit,
  idPrefix,
  autoFocus = false,
}) => {
  // only allow a single digit number to be entered in the field and move focus to the next element after input
  const handleInput = (e: FormEvent<HTMLInputElement>, index: number) => {
    if (e.currentTarget.value.match(/^[0-9]?$/)) {
      const newPin = [...pin];
      newPin[index] = e.currentTarget.value;
      setPin(newPin);

      if (e.currentTarget.value.length > 0) {
        const nextField = document.getElementById(`${idPrefix}-${index + 1}`);
        if (nextField) {
          nextField.focus();
        }
      }
    }
  };

  // handle the case to move the focus to prev field in case current one is empty when backspace is pressed
  const handleKeyDown = (e: KeyboardEvent, index: number) => {
    if (e.key === "Backspace" && pin[index].length === 0) {
      const prevField = document.getElementById(`${idPrefix}-${index - 1}`);
      if (prevField) {
        prevField.focus();
      }
    }
    if (e.key === "Enter" && submit) {
      submit();
    }
  };

  return (
    <div className="mt-2 flex justify-between sm:block">
      {pin.map((digit: any, index: number) => (
        <input
          autoFocus={autoFocus && index === 0}
          id={`${idPrefix}-${index}`}
          key={index}
          type="tel"
          className="w-10 h-10 border bg-white p-2 text-center sm:mr-4 rounded"
          onInput={(e) => handleInput(e, index)}
          onFocus={(e) => e.currentTarget.select()}
          value={pin[index]}
          onKeyDown={(e) => handleKeyDown(e, index)}
        />
      ))}
    </div>
  );
};

export default PinInput;
