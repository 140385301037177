import { FC, MouseEvent, useState } from "react";
import SvgIcon from "../../atoms/SvgIcon";

type ModalGalleryProps = {
  show: boolean;
  onClose: () => void;
  children: any;
};

const ModalGallery: FC<ModalGalleryProps> = (props) => {
  const [isMouseDown, setIsMouseDown] = useState(false);

  if (!props.show) {
    return null;
  }

  const preventClickPropagation = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const onMouseDown = (e: MouseEvent) => {
    setIsMouseDown(true);
  };

  const onMouseUp = (e: MouseEvent) => {
    setIsMouseDown(false);
  };

  const handleBgClick = (e: MouseEvent) => {
    if (!isMouseDown) {
      props.onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 z-50 overflow-auto bg-smoke-darker flex items-center"
      onClick={handleBgClick}
    >
      <div className="relative w-full md:max-w-md xl:max-w-xl m-auto">
        <div
          className="text-grey-darker m-2 cursor-pointer absolute z-20 right-0 top-0"
          onClick={props.onClose}
        >
          <SvgIcon name="x" className="w-6 h-6 inline" />
        </div>
        <div
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onClick={preventClickPropagation}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default ModalGallery;
