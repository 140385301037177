import { FC } from "react";
import Price from "../Price/Price";
import { Link } from "react-router-dom";
import { buildTestId } from "utils/buildTestId";

type Props = {
  classes?: string;
  adultPrice?: string | null;
  childPrice?: string | number | null;
  buttonIsLink?: boolean;
  buttonText?: string;
  linkTo?: string;
  minimumAge?: number;
  currentAvailability: number;
  _onClick?: () => void;
};

const PriceInfoBox: FC<Props> = ({
  classes,
  adultPrice,
  childPrice,
  buttonIsLink,
  buttonText,
  linkTo,
  _onClick,
  minimumAge,
  currentAvailability,
}) => {
  const adultPriceLocal = adultPrice ? adultPrice : "XX";
  const limitedPlaces =
    currentAvailability > 0 && currentAvailability < 10
      ? `Hurry, only ${currentAvailability} place${
          currentAvailability > 1 ? "s" : ""
        } left!`
      : "";
  const buttonIsDisabled = !adultPrice && !childPrice;
  const buttonCssClasses = `action-button action-button-${
    buttonIsDisabled ? "disabled" : "enabled"
  } block w-full`;

  let childPriceLocal = childPrice;
  let hidePoundSign = false;
  let ageRange = "(2-12)";

  if (!childPriceLocal) {
    childPriceLocal = childPrice === 0 ? "FREE" : "N/A";
    hidePoundSign = true;
  }

  if (minimumAge && minimumAge > 2) {
    ageRange = `(${minimumAge}-12)`;

    if (minimumAge > 12) {
      childPriceLocal = "N/A";
      ageRange = "(2-12)";
    }
  }

  return (
    <div className={classes}>
      {limitedPlaces && (
        <div className="mb-2">
          <span
            data-testid={buildTestId("text", "limited places")}
            className="text-red border-dotted border-b border-red"
          >
            {limitedPlaces}
          </span>
        </div>
      )}
      <Price price={adultPriceLocal} postText=" per adult" />
      {childPrice && (
        <Price
          hidePoundSign={hidePoundSign}
          price={childPriceLocal?.toString()}
          postText={` per child ${ageRange}`}
        />
      )}

      <div className="mt-2">
        {buttonIsLink && linkTo ? (
          <Link
            data-testid={buildTestId("button", "link")}
            className="action-button action-button-enabled block w-full"
            to={linkTo}
          >
            {buttonText}
          </Link>
        ) : (
          <button
            data-testid={buildTestId("button", "not link")}
            className={buttonCssClasses}
            onClick={_onClick}
            disabled={buttonIsDisabled}
          >
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
};

export default PriceInfoBox;
