import axios from "axios";
import { getPrebookUrl } from "config/configUtils";
import useAppSelector from "hooks/useAppSelector";
import { useCallback, useState } from "react";

type Props = {
  cartUuid: string;
  cartItemUuid: string;
};

export const useDeleteActivityFromPlanner = () => {
  const sessionId = useAppSelector((state) => state.authentication.sessionId);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const deleteActivityFromPlanner = useCallback(
    async ({ cartUuid, cartItemUuid }: Props) => {
      const URL = `${getPrebookUrl()}/api/carts/${cartUuid}/items/${cartItemUuid}`;
      setLoading(true);

      await axios
        .delete(URL, {
          headers: {
            SessionId: sessionId,
          },
        })
        .catch((error) => {
          console.error("Error => ", error);
          setError(error);
        });

      setLoading(false);
    },
    [sessionId]
  );

  return { deleteActivityFromPlanner, loading, error };
};
