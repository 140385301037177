import CheckoutSubheaderEntry from "./CheckoutSubheaderEntry";
import If from "./molecules/IF";
import useAppSelector from "../hooks/useAppSelector";
import { FC } from "react";

type Props = {
  active: string;
  hideOnMobile: number | boolean;
};
const CheckoutSubheader: FC<Props> = ({ active, hideOnMobile }) => {
  const isAgency = useAppSelector((state) => state.agency.isAuthenticated);

  return (
    <div className="bg-blue text-white uppercase print-hidden">
      <div className="container mx-auto flex justify-start py-4 overflow-hidden">
        {/* header for agency */}
        <If test={isAgency}>
          <CheckoutSubheaderEntry
            active={active === "summary"}
            nr="1"
            text="Summary"
            hideOnMobile={false}
          />
          <CheckoutSubheaderEntry
            active={active === "confirmation"}
            nr="2"
            text="confirmation"
            hideOnMobile={false}
          />
        </If>

        {/* header for customer */}
        <If test={!isAgency}>
          <CheckoutSubheaderEntry
            active={active === "summary"}
            nr="1"
            text="Summary"
            hideOnMobile={hideOnMobile === 1}
          />
          <CheckoutSubheaderEntry
            active={active === "payment"}
            nr="2"
            text="payment"
            hideOnMobile={hideOnMobile === 2}
          />
          <CheckoutSubheaderEntry
            active={active === "confirmation"}
            nr="3"
            text="confirmation"
            hideOnMobile={hideOnMobile === 3}
          />
        </If>
      </div>
    </div>
  );
};

export default CheckoutSubheader;
