import { FC, useState } from "react";
import { postToHub } from "../../actions/networkActions";
import { Link, useRouteMatch } from "react-router-dom";
import usePreCruisePassenger from "../../hooks/usePreCruisePassenger";
import browserHistory from "../../utils/history";
import BackLink from "../../components/molecules/BackLink";
import PinInput from "../../components/passengerInformation/PinInput";
import { isPinValid } from "../../utils/miscUtils";

type Props = {
  passengerId: string;
};

interface IMatchState {
  passengerId: string;
}

const PassengerUnlock: FC<Props> = () => {
  const match = useRouteMatch<IMatchState>();
  const passengerId = match.params.passengerId;

  const [pin, setPin] = useState(["", "", "", "", "", ""]);
  const [error, setError] = useState("");

  const { firstName, lastName } = usePreCruisePassenger(passengerId);
  const name = `${firstName} ${lastName}`;

  const pinValid = isPinValid(pin);

  const submit = async () => {
    if (!isPinValid(pin)) {
      setError("Please enter your pin");
      return;
    }

    const result = await postToHub("precruise/login", {
      passengerId,
      loginPin: pin.join(""),
    });

    if (result.passenger) {
      browserHistory.push(`/precruise/passenger/${passengerId}/review`);
    } else {
      if (result?.error?.response?.status === 401) {
        setError("Please make sure you have entered the correct PIN");
      } else {
        setError("An unexpected error happened, please try again");
      }
    }
  };

  return (
    <div className="container mx-auto">
      <div className="mt-4">
        <BackLink
          to="/precruise/passengers"
          text="Back to Travel information dashboard"
        />
      </div>

      <h1 className="my-6">Travel information for {name}</h1>

      <div className="my-4">The information for {name} is locked.</div>

      <div className="bg-grey-lightest-2 border p-4 mt-6">
        <div>
          Please input the PIN in order to review or edit your information.
        </div>
        <div className="mt-2 text-sm">
          You set your PIN when you completed your pre-cruise information.
        </div>

        <PinInput
          autoFocus={true}
          pin={pin}
          setPin={setPin}
          submit={submit}
          idPrefix="pin"
        />

        <div className="mt-4">
          <Link
            className="text-blue underline"
            to={`/precruise/passenger/${passengerId}/forgottenPin`}
          >
            I've forgotten my PIN
          </Link>
        </div>
      </div>

      {error && <div className="mt-4 text-red-tui">{error}</div>}

      <div className="mt-6 mb-8">
        <button
          className={`action-button w-mobile ${
            pinValid ? "action-button-enabled" : "action-button-disabled"
          }`}
          onClick={submit}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PassengerUnlock;
