import { FC, PropsWithChildren } from "react";

// TODO when migrate other components check if types are correct
type Props = PropsWithChildren & {
  test: any;
};

const If: FC<Props> = ({ test, children }) => {
  if (!test) {
    return <></>;
  }

  return <>{children}</>;
};

export default If;
