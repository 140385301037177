import CartExcursionEntryItinerary from "./CartExcursionEntryItinerary";
import CartExcursionEntryBooking from "./CartExcursionEntryBooking";
import { flattenExcursions } from "../../../utils/cartUtils";
import useAppSelector from "../../../hooks/useAppSelector";
import { RootState } from "../../../configureStore";
import { ComponentType, FC } from "react";
import CartExcursionEntryOpenRefund from "./CartExcursionEntryOpenRefund";

type Props = {
  cartType: "itinerary" | "booking" | "openRefund";
  itineraryId: string | null;
};

type CartElementComponents = {
  [key: string]: ComponentType<{ excursion: any }>;
};

const cartElementComponents: CartElementComponents = {
  itinerary: CartExcursionEntryItinerary,
  booking: CartExcursionEntryBooking,
  openRefund: CartExcursionEntryOpenRefund,
};

const CartElements: FC<Props> = ({ cartType, itineraryId }) => {
  const cartExcursions = useAppSelector((state) => {
    switch (cartType) {
      case "booking":
        return state.bookings.excursions;
      case "openRefund":
        return state.openRefunds.excursions;
      default:
        return state.cart.excursions;
    }
  });

  const CartElementComponent = cartElementComponents[cartType];
  const excursions = getExcursions(cartExcursions, itineraryId);

  function getExcursions(
    cartExcursions: RootState["cart"]["excursions"],
    itineraryId: string | null
  ) {
    // nothing in the cart, return empty array
    if (cartExcursions === null) {
      return [];
    }

    // looking for all cart elements
    if (!itineraryId) {
      return flattenExcursions(cartExcursions);
    }

    // looking for cart elements belonging to one itinerary
    let cartEntries: Record<string, RootState["cart"]["excursions"]> = {};
    cartEntries[itineraryId] = cartExcursions[itineraryId];
    return flattenExcursions(cartEntries);
  }

  return (
    <div className="w-full my-2 text-grey-darker">
      {excursions.map((excursion) => (
        <CartElementComponent
          key={excursion.excursionId}
          excursion={excursion}
        />
      ))}
    </div>
  );
};

export default CartElements;
