import { getCruisesInfo } from "../utils/cruiseUtils";
import { formatItineraryDepartDate } from "../utils/dateTimeUtils";
import { imagePath } from "../utils/imageUtils";
import useAppSelector from "../hooks/useAppSelector";

const CruisesItineraryInfo = () => {
  const cruises = useAppSelector((state) => state.cruises.cruiseDetails);
  const cruisesInfo = getCruisesInfo(cruises);

  return (
    <div className="bg-blue-lightest-25 shadow p-4 xl:flex-grow">
      {/* cruise name */}
      <div className="text-3xl text-blue-dark font-bold mb-4">
        {cruisesInfo.cruiseName}
      </div>

      <div className="sm:flex md:block">
        {/* cruise info */}
        <div className="border-b border-blue-dark pb-4 sm:border-b-0 sm:border-r sm:w-1/2 sm:pr-4 md:border-b md:border-r-0 md:w-full md:pr-0">
          <div className="flex items-center mb-2">
            <img
              className="w-4 h-4 mr-2 inline flex-shrink-0"
              alt="calendar icon"
              src={imagePath("ship.svg")}
            />
            <span>Sailing on {cruisesInfo.shipName}</span>
          </div>

          <div className="flex items-center mb-2">
            <img
              className="w-4 h-4 mr-2 inline flex-shrink-0"
              alt="calendar icon"
              src={imagePath("calendar.svg")}
            />
            <span>
              Departs {formatItineraryDepartDate(cruisesInfo.cruiseStart)}
            </span>
          </div>
          <div className="flex items-center mb-2">
            <img
              className="w-4 h-4 mr-2 inline flex-shrink-0"
              alt="calendar icon"
              src={imagePath("moon.svg")}
            />
            <span>{cruisesInfo.cruiseDuration} nights cruise</span>
          </div>
        </div>

        {/* nrExcursions and nrRestaurants */}
        <div className="mt-4 sm:mt-0 sm:w-1/2 sm:pl-4 md:mt-4 md:w-full md:pl-0">
          <div className="mb-2">
            Get ahead and plan your perfect cruise experience.
          </div>
        </div>
      </div>
    </div>
  );
};

export default CruisesItineraryInfo;
