import { imagePath } from "../utils/imageUtils";
import { FC } from "react";
type Props = {
  toggleExp360Gallery?: () => void;
  toggleGallery?: () => void;
};

const GalleryToggleButton: FC<Props> = ({
  toggleExp360Gallery,
  toggleGallery,
}) => {
  return (
    <div className="absolute bottom-0 right-0">
      {toggleExp360Gallery && (
        <button
          className="mr-4 p-3 bg-blue-light cursor-pointer print-hidden"
          onClick={toggleExp360Gallery}
        >
          <img
            className="w-6 h-6"
            src={imagePath("360play.svg")}
            alt="open video"
          />
        </button>
      )}

      {toggleGallery && (
        <button
          className="p-3 bg-blue-light cursor-pointer print-hidden"
          onClick={toggleGallery}
        >
          <img
            className="w-6 h-6"
            src={imagePath("cameraicon.svg")}
            alt="open gallery"
          />
        </button>
      )}
    </div>
  );
};

export default GalleryToggleButton;
