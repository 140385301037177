import { IPayment } from "interfaces/ReduxState/IPayment";
import { PaymentAction } from "../actions/paymentActions";

const INITIAL_STATE: IPayment = {
  confirmationMail: null,
  mobileNumber: null,
  paidAmount: null,
  paidExcursions: null,
  paidTacEntries: null,
  paymentReference: null,
  tomcatInstance: null,
  transactionId: null,
  paymentType: "dinning",
};

const paymentReducers = (state = INITIAL_STATE, action: PaymentAction) => {
  switch (action.type) {
    case "SET_PAYMENT":
      return {
        confirmationMail: action.payment?.confirmationMail,
        mobileNumber: action.payment?.mobileNumber,
        paidAmount: action.payment?.paidAmount,
        paidExcursions: action.payment?.paidExcursions,
        paidTacEntries: action.payment?.paidTacEntries,
        paymentReference: action.payment?.paymentReference,
        transactionId: action.payment?.transactionId,
        tomcatInstance: action.payment?.tomcatInstance,
        paymentType: action.payment?.paymentType,
      };

    case "CLEAR_PAYMENT":
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default paymentReducers;
