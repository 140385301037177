import { FC } from "react";
import ImageWithFallback from "./atoms/ImageWithFallback";

type Props = {
  alt: string;
  src: string;
};

const CenteredImageContainer: FC<Props> = ({ alt, src }) => {
  return (
    <div className="container-2-1">
      <ImageWithFallback
        className="container-2-1-img cover-fit"
        alt={alt}
        src={src}
      />
    </div>
  );
};

export default CenteredImageContainer;
