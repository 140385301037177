import { FC, useState } from "react";
import browserHistory from "../../../../utils/history";
import { postToHub } from "actions/networkActions";
import InfoBox from "components/passengerInformation/InfoBox/InfoBox";

type LockInfoProps = {
  passengerId: any;
};

const LockInfo: FC<LockInfoProps> = ({ passengerId }) => {
  const [lockError, setLockError] = useState("");

  const lockData = async () => {
    const result = await postToHub("precruise/passenger/closeSession", {
      passengerId: passengerId,
    });

    if (result === "") {
      browserHistory.push("/precruise/passengers");
    } else {
      setLockError(
        "Unexpected error happened when securing the data, please try again."
      );
    }
  };

  return (
    <InfoBox
      classes="mb-6"
      infoText="Protected data view"
      description="You are viewing PIN protected information, which will be automatically locked after 30 minutes of inactivity.If you would like to lock it before that, please use the button below."
    >
      <div className="mt-4">
        <button
          className="border rounded px-4 py-2 bg-yellow"
          onClick={lockData}
        >
          Lock Information
        </button>
      </div>
      {lockError && <div className="mt-4 text-red-tui">{lockError}</div>}
    </InfoBox>
  );
};

export default LockInfo;
