import { removeTacEntryFromCart } from "../../../actions/tacActions";
import CartElementTacOpenRefund from "./CartElementTacOpenRefund";
import useAppSelector from "../../../hooks/useAppSelector";
import { ComponentType, FC } from "react";
import CartElementTacBooking from "./CartElementTacBooking";
import CartElementTacItinerary from "./CartElementTacItinerary";
import { flattenEntries } from "utils/cartUtils";
import { useUpdateCart } from "actions/useUpdateCart";

interface Props {
  cartType: "itinerary" | "booking" | "openRefund";
  date?: string;
}

type CartElementComponents = {
  [key: string]: ComponentType<{
    entry: any;
    date: any;
    removeTacFromCart: (entryId: any) => void;
  }>;
};

const cartElementComponents: CartElementComponents = {
  itinerary: CartElementTacItinerary,
  booking: CartElementTacBooking,
  openRefund: CartElementTacOpenRefund,
};

const CartElementsTac: FC<Props> = ({ cartType, date }) => {
  const { updateCart } = useUpdateCart();

  const tacEntries = useAppSelector((state) => {
    switch (cartType) {
      case "booking":
        return state.bookings.tac[date ?? ""] ?? [];
      case "openRefund":
        return state.openRefunds.tac[date ?? ""] ?? [];
      default:
        return date
          ? state.cart.tac[date] ?? []
          : flattenEntries(state.cart.tac);
    }
  });

  const CartElementComponent = cartElementComponents[cartType];

  const onRemoveTacFromCartClick = async (entryId: any) => {
    await removeTacEntryFromCart(entryId);
    await updateCart();
  };

  if (tacEntries.length === 0) {
    return null;
  }

  return (
    <div className="w-full my-2 text-grey-darker">
      {tacEntries.map((entry: any) => (
        <CartElementComponent
          key={entry.id}
          entry={entry}
          removeTacFromCart={() => onRemoveTacFromCartClick(entry.id)}
          date={date}
        />
      ))}
    </div>
  );
};

export default CartElementsTac;
