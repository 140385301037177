import Modal from "../Modal/Modal";
import { imagePath } from "../../../utils/imageUtils";
import ModalFooter from "../ModalFooter";
import { FC } from "react";

type Props = {
  showModal: boolean;
  hideModal: () => void;
  logout: () => void;
};

const LogoutModal: FC<Props> = ({ showModal, hideModal, logout }) => {
  return (
    <Modal show={showModal} onClose={hideModal} title="Cruise planner">
      <div className="flex flex-grow flex-col">
        <div className="flex-grow bg-white py-4 px-2 sm:px-4">
          <div className="bg-grey-lighter py-4 px-2 sm:px-4">
            <div className="flex items-center text-2xl mb-4 uppercase">
              <img
                alt=""
                className="w-6 h-6 mr-2"
                src={imagePath("infoicon_inverted.svg")}
              />
              <span>Are you forgetting something?</span>
            </div>
            <div className="my-2">
              You're about to leave this page, but you haven't paid for some of
              the items you added to your planner.
            </div>
            <div className="my-2">
              Any items in your planner, but not paid for, will not be booked.
            </div>
            <div className="my-2">Do you still want to leave?</div>
          </div>
        </div>
        <ModalFooter
          onCancel={hideModal}
          actionDisabled={false}
          actionButtonText="Continue"
          onActionClick={logout}
        />
      </div>
    </Modal>
  );
};

export default LogoutModal;
