import { FC, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

type Props = {
  children: JSX.Element;
  location: { pathname: string };
};

const ScrollToTop: FC<Props & RouteComponentProps> = ({
  children,
  location: { pathname },
}) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, [pathname]);

  return children || null;
};

export default withRouter(ScrollToTop);
