import axios from "axios";
import { getOswUrl, getShipCodeByName } from "config/configUtils";
import useAppSelector from "hooks/useAppSelector";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { IOneSpaWorldCategory } from "interfaces/OSW/IOneSpaWorldCategory";
import { getCruisesInfo } from "utils/cruiseUtils";

interface Props {
  date: string | undefined;
}

export const useGetAllSpaCategories = ({ date }: Props) => {
  const sessionId = useAppSelector((state) => state.authentication.sessionId);
  const cruiseDetails = useAppSelector((state) => state.cruises.cruiseDetails);
  const cruisesInfo = getCruisesInfo(cruiseDetails);

  const URL = `${getOswUrl()}/api/ships/${getShipCodeByName({
    shipName: cruisesInfo.shipName,
  })}/category/by-date/${date}`;

  const { data, isLoading }: UseQueryResult<any, Error> = useQuery({
    queryKey: ["useGetAllSpaCategories", date],
    queryFn: async () => {
      try {
        const response = await axios.get(URL, {
          headers: {
            SessionId: sessionId,
          },
        });

        return response.data;
      } catch (error: any | Error) {
        console.error(
          error?.response?.data?.message ||
            "An error occurred while fetching data."
        );
      }
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity, // Data will be considered fresh indefinitely
  });

  return {
    allSpaCategories: data as IOneSpaWorldCategory[],
    allSpaCategoriesIsLoading: isLoading,
  };
};
