import { FC } from "react";
import CartElementConfirmationMusement from "../CartElementConfirmationMusement";
import { IExcursionPassenger } from "interfaces/Common/IPassenger";

type Props = {
  title: string;
  subtitle?: string;
  day: string;
  price: string;
  people: IExcursionPassenger[];
};

const CartElementEntryConfirmationMusement: FC<Props> = ({
  title,
  subtitle,
  day,
  people,
  price,
}) => {
  const implodePassengers = () => {
    return people
      .map(
        (passenger: { firstName: string; lastName: string }) =>
          `${passenger.firstName} ${passenger.lastName}`
      )
      .join(", ");
  };

  return (
    <CartElementConfirmationMusement
      title={title}
      subtitle={subtitle}
      people={implodePassengers()}
      price={price}
      day={day}
    />
  );
};

export default CartElementEntryConfirmationMusement;
