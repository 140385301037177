import { FC } from "react";
import CartElementItinerary from "../CartElementItinerary";

type Props = {
  entry: any;
  removeTacFromCart: (entryId: any) => void;
  date: any;
};

const CartElementTacItinerary: FC<Props> = (props) => {
  const entry = props.entry;
  const nrGuests = entry.nrAdults + entry.nrChildren;

  return (
    <CartElementItinerary
      name={entry.name}
      imageReference={entry.serviceProfileId}
      remove={() => props.removeTacFromCart(entry.id)}
      linkTo={{
        pathname: "/tac/restaurants",
        state: { date: props.date },
      }}
      changePassengersLinkText="Edit reservation"
      changePassengersLinkTo={{
        pathname: "/tac/restaurants",
        state: {
          date: props.date,
          preselectedDetails: {
            ...entry,
          },
        },
      }}
      dataTest="cartElementTacItinerary"
    >
      <div className="my-2">
        <div className="mb-1">
          Table for {nrGuests} {nrGuests > 1 ? "people" : "person"}
        </div>
        <div>
          at <span className="font-bold">{entry.time}</span>
        </div>
      </div>
    </CartElementItinerary>
  );
};

export default CartElementTacItinerary;
