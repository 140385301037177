import { FC } from "react";
import { convertDurationPeriodFromMinutes } from "utils/dateTimeUtils";
import { imagePath } from "utils/imageUtils";

export type Props = {
  timeLeftValue: number;
};

const CountdownTimer: FC<Props> = ({ timeLeftValue }) => {
  if (timeLeftValue === 0) {
    return null;
  }

  const displayTimeLeftValue = convertDurationPeriodFromMinutes(timeLeftValue);

  return (
    <div className="flex items-center">
      <img
        className="w-4 h-4 align-middle mr-2 inline"
        alt="hourglass icon"
        src={imagePath("hourglass.png")}
      />
      <span className="align-middle text-red-tui text-sm">
        {displayTimeLeftValue} left
      </span>
    </div>
  );
};

export default CountdownTimer;
