import { FC, useState } from "react";
import browserHistory from "../../utils/history";
import RadioButton from "../Buttons/RadioButton/RadioButton";

type Props = {
  cardInfo: any;
  token: any;
  passengerId: string;
  backToReview: boolean;
  replaceCard: () => void;
};

const ExistingCC: FC<Props> = ({
  token,
  backToReview,
  replaceCard,
  cardInfo,
  passengerId,
}) => {
  const [answer, setAnswer] = useState<boolean | null>(null);

  const last4Digits = cardInfo.cardNumber.substr(-4);
  const infoText = cardInfo.isCardOwner
    ? `You already have a card assigned to you ending in ${last4Digits}`
    : `${cardInfo.cardOwner} has already assigned their card to you.`;

  const onSubmit = () => {
    if (answer === null) {
      return;
    }

    if (answer === false) {
      const url = token
        ? `/precruise/guest/${passengerId}/${
            backToReview ? "review" : "finish"
          }/${token}`
        : `/precruise/passenger/${passengerId}/${
            backToReview ? "review" : "finish"
          }`;
      browserHistory.push(url);
    } else {
      replaceCard();
    }
  };

  return (
    <div>
      <div className="mt-6 text-lg">
        <p>{infoText}</p>
        <p className="mt-4">
          Do you want to add a different card, to replace it?
        </p>
      </div>
      <div>
        <div className="mt-4">
          <RadioButton
            label="Yes"
            _onChange={() => setAnswer(true)}
            isSelected={answer === true}
          />
        </div>

        <div className="mt-4">
          <RadioButton
            label="No"
            _onChange={() => setAnswer(false)}
            isSelected={answer === false}
          />
        </div>
      </div>
      <div className="mt-6 mb-8 xl:mb-12">
        <button
          className={`action-button w-mobile ${
            answer !== null ? "action-button-enabled" : "action-button-disabled"
          }`}
          onClick={onSubmit}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ExistingCC;
