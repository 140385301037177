import { IPassenger } from "interfaces/Common/IPassenger";
import useGet from "./useGet";

const useTravelDocs = () => {
  const {
    passenger: passengers,
    cabinInformation,
    ...bookingData
  } = useGet("precruise/travelDocs", {
    passenger: null,
    cabinInformation: null,
    cabinAllocated: null,
  });

  if (!passengers) {
    return { passengers, bookingData };
  }

  // make a map of external IDs for simple reference when adding cabin info to pax
  const passengerExternalIds = new Map();
  passengers.forEach((passenger: IPassenger, index: number) => {
    passengerExternalIds.set(passenger.externalId, index);
  });

  // loop over all cabins and over all pax in the cabin and add that data to the pax info
  if (cabinInformation && cabinInformation.cabin) {
    cabinInformation.cabin.forEach(
      (cabin: { cabinNumber: any; passengerId: string[] }) => {
        const deckNumber = getDeckNumber(cabin.cabinNumber);

        cabin.passengerId.forEach((passengerId: string) => {
          const passengerIndex = passengerExternalIds.get(passengerId + "");
          passengers[passengerIndex].cabinNumber = cabin.cabinNumber;
          passengers[passengerIndex].deckNumber = deckNumber;
        });
      }
    );
  }

  return { passengers, bookingData };
};

export default useTravelDocs;

const getDeckNumber = (cabinNumber: string) => {
  if (cabinNumber.length === 5) {
    return cabinNumber.substring(0, 2);
  }

  return cabinNumber.substring(0, 1);
};
