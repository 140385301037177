import { FC, ReactNode } from "react";
import CartElementItinerary from "components/cartElements/CartElementItinerary";
import { useDeleteActivityFromPlanner } from "modules/musement/hooks/useDeleteActivityFromPlanner";
import Loader from "components/atoms/Loader";
import { useUpdateCart } from "actions/useUpdateCart";

type Props = {
  title: string;
  image: string | null;
  cartUuid: string;
  adultItemUuids: string[];
  childItemUuids: string[];
  babyItemUuids: string[];
  children: ReactNode;
};

const CartElementItineraryMusement: FC<Props> = ({
  title,
  image,
  cartUuid,
  adultItemUuids,
  childItemUuids,
  babyItemUuids,
  children,
}) => {
  const { deleteActivityFromPlanner, loading } = useDeleteActivityFromPlanner();
  const { updateCart } = useUpdateCart();

  const onRemoveClick = async () => {
    if (childItemUuids?.length > 0) {
      const deleteChildActivityPromises = childItemUuids?.map((childItemUuid) =>
        deleteActivityFromPlanner({
          cartUuid,
          cartItemUuid: childItemUuid,
        })
      );

      await Promise.all(deleteChildActivityPromises);
    }

    if (babyItemUuids?.length > 0) {
      const deleteBabyActivityPromises = babyItemUuids?.map((babyItemUuid) =>
        deleteActivityFromPlanner({
          cartUuid,
          cartItemUuid: babyItemUuid,
        })
      );

      await Promise.all(deleteBabyActivityPromises);
    }

    const deleteAdultActivityPromises = adultItemUuids?.map((adultItemUuid) =>
      deleteActivityFromPlanner({
        cartUuid,
        cartItemUuid: adultItemUuid,
      })
    );

    await Promise.all(deleteAdultActivityPromises);

    await updateCart();
  };

  if (loading) {
    return <Loader text="Removing from planner..." />;
  }

  return (
    <CartElementItinerary
      name={title}
      imageReference={image}
      imageCategory="cruiseExcursion"
      remove={onRemoveClick}
      // linkTo={`/musement/excursion/${itineraryId}/${activityUuid}`}
      // TODO maybe - no requirements yet
      changePassengersLinkText=""
      // TODO maybe - no requirements yet
      changePassengersLinkTo={``}
      dataTest="cartElementOswTreatment"
      isOldSolutionForImage={false}
    >
      {children}
    </CartElementItinerary>
  );
};

export default CartElementItineraryMusement;
