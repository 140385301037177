import { SurveyProvider } from "../../context/SurveyContext";
import { RouteComponentProps } from "react-router-dom";
import { FC } from "react";
import PassengerFormNew from "components/passengerInformation/newFlow/PassengerFormNew";

type OwnProps = RouteComponentProps & {};
const PassengerFormPage: FC<OwnProps> = (props) => {
  return (
    <SurveyProvider>
      <PassengerFormNew {...props} />
    </SurveyProvider>
  );
};

export default PassengerFormPage;
