import { FC, ReactNode } from "react";

export type Props = {
  icon: ReactNode;
  text?: string;
  classNames?: string;
};

const IconWithText: FC<Props> = ({ icon, text, classNames }) => {
  return (
    <span className={`flex items-center gap-2 text-blue-darker ${classNames}`}>
      {icon}
      {text}
    </span>
  );
};

export default IconWithText;
