import { useState } from "react";
import ExistingCC from "../../components/pxp/ExistingCC";
import CardRegistration from "../../components/pxp/CardRegistration";

const CardCapture = (props) => {
  const [replaceExisting, setReplaceExisting] = useState(false);

  if (props.card && props.card.cardInfo?.cardNumber && !replaceExisting) {
    return (
      <ExistingCC
        passengerId={props.passengerId}
        cardInfo={props.card.cardInfo}
        replaceCard={() => setReplaceExisting(true)}
        backToReview={props.backToReview}
        token={props.token}
      />
    );
  }

  return (
    <CardRegistration
      cardInfo={props.card.cardInfo}
      passengerId={props.passengerId}
      token={props.token}
      replaceExisting={replaceExisting}
      backToReview={props.backToReview}
      submit={props.submit}
    />
  );
};

export default CardCapture;
