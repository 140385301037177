import NoActiveCruiseNotice from "./molecules/NoActiveCruiseNotice";
import { getCruisesInfo } from "../utils/cruiseUtils";
import { formatCruiseStartDate } from "../utils/dateTimeUtils";
import useAppSelector from "../hooks/useAppSelector";
import { FC } from "react";

type Props = {
  cruisesSnapshot: any;
  preText: string;
};

const CruisesOverview: FC<Props> = ({ cruisesSnapshot, preText }) => {
  const cruises = useAppSelector((state) =>
    cruisesSnapshot ? cruisesSnapshot : state.cruises ? state.cruises : null
  );

  if (!cruises) {
    return null;
  }

  if (cruises.length === 0) {
    return <NoActiveCruiseNotice />;
  }

  const cruisesInfo = getCruisesInfo(cruises.cruiseDetails);

  return (
    <div className="my-4">
      <div className="text-3xl">
        <span className="text-grey-darker text-lg">{preText}</span>
        {cruisesInfo.cruiseName}
      </div>
      <div className="text-grey-darker mt-2">
        {formatCruiseStartDate(cruisesInfo.cruiseStart)},{" "}
        {cruisesInfo.cruiseDuration} nights on {cruisesInfo.shipName}
      </div>
    </div>
  );
};

export default CruisesOverview;
