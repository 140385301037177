import { AppThunk } from "../configureStore";
import { Action } from "redux";

export interface StoreAction extends Action {
  storeId?: string;
}

export const clearStore = (): AppThunk => (dispatch) => {
  return async () => {
    dispatch<StoreAction>({ type: "CLEAR_STORE" });
  };
};

export const setStore =
  (storeId: string): AppThunk =>
  (dispatch) => {
    return async () => {
      dispatch<StoreAction>({ type: "SET_STORE", storeId: storeId });
    };
  };
