import Price from "components/Price/Price";
import { FC } from "react";

type Props = {
  title: string;
  subtitle?: string;
  day: string;
  time: string;
  nrGuests: number;
  price: string;
};

const CartElementMxpConfirmation: FC<Props> = (props) => {
  return (
    <div className="mt-4 border-b print-border-0">
      <div className="sm:flex sm:justify-between sm:items-end pb-2">
        <div>
          <div className="sm:flex sm:items-baseline sm:flex-wrap">
            <div className="text-blue-dark text-lg sm:mr-2">{props.title}</div>
          </div>

          <div className="mt-1">{props.day}</div>
          <div className="mt-2">
            Booking for {props.nrGuests}{" "}
            {props.nrGuests > 1 ? "people" : "person"} at{" "}
            <span className="font-bold">{props.time}</span>
          </div>
        </div>
        <div className="text-right">
          <Price price={props?.price?.toString()} />
        </div>
      </div>
    </div>
  );
};

export default CartElementMxpConfirmation;
