import { FC } from "react";

type CCInfoProps = {
  cardInfo: any;
};

const CCInfo: FC<CCInfoProps> = ({ cardInfo }) => {
  if (!cardInfo?.cardNumber) {
    return null;
  }

  return (
    <div>
      <div className="mt-4 text-lg">
        <div className="text-grey-darker">Card number</div>
        <div>**** **** **** {cardInfo.cardNumber.substr(-4)}</div>
      </div>

      <div className="mt-4 text-lg">
        <div className="text-grey-darker">Card details applied to</div>
        <div>{cardInfo.cardAppliesTo.join(", ")}</div>
      </div>
    </div>
  );
};

export default CCInfo;
