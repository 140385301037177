import BackLink from "../../components/molecules/BackLink";
import { Link } from "react-router-dom";

const PaymentFailure = () => {
  return (
    <div>
      <div className="container mx-auto mt-4 text-primary-dark">
        <div className="mb-4">
          <BackLink to="/checkout" text="Checkout" />
        </div>
        <div className="text-xl">
          We’re sorry, something went wrong while processing payment. No payment
          was taken. Please visit the{" "}
          <Link className="text-blue underline" to={`/checkout`}>
            checkout
          </Link>{" "}
          page and try again.
        </div>
      </div>
    </div>
  );
};

export default PaymentFailure;
