import { RootState } from "./configureStore";

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

export const saveState = (state: RootState) => {
  try {
    const serializedState = JSON.stringify({
      agency: state.agency,
      authentication: state.authentication,
      preCruiseStatus: state.preCruiseStatus,
      cruises: state.cruises,
      cart: state.cart,
      itinerary: state.itinerary,
      payment: state.payment,
      store: state.store,
      appConfig: state.appConfig,
    });
    localStorage.setItem("state", serializedState);
  } catch (error) {
    //TODO - decide how to handle errors - if needed
  }
};

export const removeLocalStorageState = () => {
  try {
    localStorage.removeItem("state");
  } catch (error) {
    // TODO - decide if this needs to be handled
  }
};
