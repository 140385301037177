export const isoCountries = [
  {
    value: "",
    label: "Please select",
  },
  {
    value: "GB",
    label: "United Kingdom",
  },
  {
    value: "AF",
    label: "Afghanistan",
  },
  {
    value: "AL",
    label: "Albania",
  },
  {
    value: "DZ",
    label: "Algeria",
  },
  {
    value: "AD",
    label: "Andorra",
  },
  {
    value: "AO",
    label: "Angola",
  },
  {
    value: "AG",
    label: "Antigua and Barbuda",
  },
  {
    value: "AR",
    label: "Argentina",
  },
  {
    value: "AM",
    label: "Armenia",
  },
  {
    value: "AU",
    label: "Australia",
  },
  {
    value: "AT",
    label: "Austria",
  },
  {
    value: "AZ",
    label: "Azerbaijan",
  },
  {
    value: "BS",
    label: "Bahamas",
  },
  {
    value: "BH",
    label: "Bahrain",
  },
  {
    value: "BD",
    label: "Bangladesh",
  },
  {
    value: "BB",
    label: "Barbados",
  },
  {
    value: "BY",
    label: "Belarus",
  },
  {
    value: "BE",
    label: "Belgium",
  },
  {
    value: "BZ",
    label: "Belize",
  },
  {
    value: "BJ",
    label: "Benin",
  },
  {
    value: "BT",
    label: "Bhutan",
  },
  {
    value: "BO",
    label: "Bolivia",
  },
  {
    value: "BA",
    label: "Bosnia and Herzegovina",
  },
  {
    value: "BW",
    label: "Botswana",
  },
  {
    value: "BR",
    label: "Brazil",
  },
  {
    value: "BN",
    label: "Brunei",
  },
  {
    value: "BG",
    label: "Bulgaria",
  },
  {
    value: "BF",
    label: "Burkina Faso",
  },
  {
    value: "BI",
    label: "Burundi",
  },
  {
    value: "KH",
    label: "Cambodia",
  },
  {
    value: "CM",
    label: "Cameroon",
  },
  {
    value: "CA",
    label: "Canada",
  },
  {
    value: "CV",
    label: "Cape Verde",
  },
  {
    value: "CF",
    label: "Central African Republic",
  },
  {
    value: "TD",
    label: "Chad",
  },
  {
    value: "CL",
    label: "Chile",
  },
  {
    value: "CN",
    label: "China",
  },
  {
    value: "CO",
    label: "Colombia",
  },
  {
    value: "KM",
    label: "Comoros",
  },
  {
    value: "CR",
    label: "Costa Rica",
  },
  {
    value: "HR",
    label: "Croatia",
  },
  {
    value: "CU",
    label: "Cuba",
  },
  {
    value: "CY",
    label: "Cyprus",
  },
  {
    value: "CZ",
    label: "Czechia",
  },
  {
    value: "CD",
    label: "DR Congo",
  },
  {
    value: "DK",
    label: "Denmark",
  },
  {
    value: "DJ",
    label: "Djibouti",
  },
  {
    value: "DM",
    label: "Dominica",
  },
  {
    value: "DO",
    label: "Dominican Republic",
  },
  {
    value: "EC",
    label: "Ecuador",
  },
  {
    value: "EG",
    label: "Egypt",
  },
  {
    value: "SV",
    label: "El Salvador",
  },
  {
    value: "GQ",
    label: "Equatorial Guinea",
  },
  {
    value: "ER",
    label: "Eritrea",
  },
  {
    value: "EE",
    label: "Estonia",
  },
  {
    value: "SZ",
    label: "Eswatini",
  },
  {
    value: "ET",
    label: "Ethiopia",
  },
  {
    value: "FJ",
    label: "Fiji",
  },
  {
    value: "FI",
    label: "Finland",
  },
  {
    value: "FR",
    label: "France",
  },
  {
    value: "GA",
    label: "Gabon",
  },
  {
    value: "GM",
    label: "Gambia",
  },
  {
    value: "GE",
    label: "Georgia",
  },
  {
    value: "DE",
    label: "Germany",
  },
  {
    value: "GH",
    label: "Ghana",
  },
  {
    value: "GR",
    label: "Greece",
  },
  {
    value: "GD",
    label: "Grenada",
  },
  {
    value: "GT",
    label: "Guatemala",
  },
  {
    value: "GN",
    label: "Guinea",
  },
  {
    value: "GW",
    label: "Guinea-Bissau",
  },
  {
    value: "GY",
    label: "Guyana",
  },
  {
    value: "HT",
    label: "Haiti",
  },
  {
    value: "HN",
    label: "Honduras",
  },
  {
    value: "HU",
    label: "Hungary",
  },
  {
    value: "IS",
    label: "Iceland",
  },
  {
    value: "IN",
    label: "India",
  },
  {
    value: "ID",
    label: "Indonesia",
  },
  {
    value: "IR",
    label: "Iran",
  },
  {
    value: "IQ",
    label: "Iraq",
  },
  {
    value: "IE",
    label: "Ireland",
  },
  {
    value: "IL",
    label: "Israel",
  },
  {
    value: "IT",
    label: "Italy",
  },
  {
    value: "CI",
    label: "Ivory Coast",
  },
  {
    value: "JM",
    label: "Jamaica",
  },
  {
    value: "JP",
    label: "Japan",
  },
  {
    value: "JO",
    label: "Jordan",
  },
  {
    value: "KZ",
    label: "Kazakhstan",
  },
  {
    value: "KE",
    label: "Kenya",
  },
  {
    value: "KI",
    label: "Kiribati",
  },
  {
    value: "KW",
    label: "Kuwait",
  },
  {
    value: "KG",
    label: "Kyrgyzstan",
  },
  {
    value: "LA",
    label: "Laos",
  },
  {
    value: "LV",
    label: "Latvia",
  },
  {
    value: "LB",
    label: "Lebanon",
  },
  {
    value: "LS",
    label: "Lesotho",
  },
  {
    value: "LR",
    label: "Liberia",
  },
  {
    value: "LY",
    label: "Libya",
  },
  {
    value: "LI",
    label: "Liechtenstein",
  },
  {
    value: "LT",
    label: "Lithuania",
  },
  {
    value: "LU",
    label: "Luxembourg",
  },
  {
    value: "MG",
    label: "Madagascar",
  },
  {
    value: "MW",
    label: "Malawi",
  },
  {
    value: "MY",
    label: "Malaysia",
  },
  {
    value: "MV",
    label: "Maldives",
  },
  {
    value: "ML",
    label: "Mali",
  },
  {
    value: "MT",
    label: "Malta",
  },
  {
    value: "MH",
    label: "Marshall Islands",
  },
  {
    value: "MR",
    label: "Mauritania",
  },
  {
    value: "MU",
    label: "Mauritius",
  },
  {
    value: "MX",
    label: "Mexico",
  },
  {
    value: "FM",
    label: "Micronesia",
  },
  {
    value: "MD",
    label: "Moldova",
  },
  {
    value: "MC",
    label: "Monaco",
  },
  {
    value: "MN",
    label: "Mongolia",
  },
  {
    value: "ME",
    label: "Montenegro",
  },
  {
    value: "MA",
    label: "Morocco",
  },
  {
    value: "MZ",
    label: "Mozambique",
  },
  {
    value: "MM",
    label: "Myanmar",
  },
  {
    value: "NA",
    label: "Namibia",
  },
  {
    value: "NR",
    label: "Nauru",
  },
  {
    value: "NP",
    label: "Nepal",
  },
  {
    value: "NL",
    label: "Netherlands",
  },
  {
    value: "NZ",
    label: "New Zealand",
  },
  {
    value: "NI",
    label: "Nicaragua",
  },
  {
    value: "NE",
    label: "Niger",
  },
  {
    value: "NG",
    label: "Nigeria",
  },
  {
    value: "KP",
    label: "North Korea",
  },
  {
    value: "MK",
    label: "North Macedonia",
  },
  {
    value: "NO",
    label: "Norway",
  },
  {
    value: "OM",
    label: "Oman",
  },
  {
    value: "PK",
    label: "Pakistan",
  },
  {
    value: "PW",
    label: "Palau",
  },
  {
    value: "PA",
    label: "Panama",
  },
  {
    value: "PG",
    label: "Papua New Guinea",
  },
  {
    value: "PY",
    label: "Paraguay",
  },
  {
    value: "PE",
    label: "Peru",
  },
  {
    value: "PH",
    label: "Philippines",
  },
  {
    value: "PL",
    label: "Poland",
  },
  {
    value: "PT",
    label: "Portugal",
  },
  {
    value: "QA",
    label: "Qatar",
  },
  {
    value: "CG",
    label: "Republic of the Congo",
  },
  {
    value: "RO",
    label: "Romania",
  },
  {
    value: "RU",
    label: "Russia",
  },
  {
    value: "RW",
    label: "Rwanda",
  },
  {
    value: "KN",
    label: "Saint Kitts and Nevis",
  },
  {
    value: "LC",
    label: "Saint Lucia",
  },
  {
    value: "VC",
    label: "Saint Vincent and the Grenadines",
  },
  {
    value: "WS",
    label: "Samoa",
  },
  {
    value: "SM",
    label: "San Marino",
  },
  {
    value: "SA",
    label: "Saudi Arabia",
  },
  {
    value: "SN",
    label: "Senegal",
  },
  {
    value: "RS",
    label: "Serbia",
  },
  {
    value: "SC",
    label: "Seychelles",
  },
  {
    value: "SL",
    label: "Sierra Leone",
  },
  {
    value: "SG",
    label: "Singapore",
  },
  {
    value: "SK",
    label: "Slovakia",
  },
  {
    value: "SI",
    label: "Slovenia",
  },
  {
    value: "SB",
    label: "Solomon Islands",
  },
  {
    value: "SO",
    label: "Somalia",
  },
  {
    value: "ZA",
    label: "South Africa",
  },
  {
    value: "KR",
    label: "South Korea",
  },
  {
    value: "SS",
    label: "South Sudan",
  },
  {
    value: "ES",
    label: "Spain",
  },
  {
    value: "LK",
    label: "Sri Lanka",
  },
  {
    value: "SD",
    label: "Sudan",
  },
  {
    value: "SR",
    label: "Suriname",
  },
  {
    value: "SE",
    label: "Sweden",
  },
  {
    value: "CH",
    label: "Switzerland",
  },
  {
    value: "SY",
    label: "Syria",
  },
  {
    value: "ST",
    label: "São Tomé and Príncipe",
  },
  {
    value: "TJ",
    label: "Tajikistan",
  },
  {
    value: "TZ",
    label: "Tanzania",
  },
  {
    value: "TH",
    label: "Thailand",
  },
  {
    value: "TL",
    label: "Timor-Leste",
  },
  {
    value: "TG",
    label: "Togo",
  },
  {
    value: "TO",
    label: "Tonga",
  },
  {
    value: "TT",
    label: "Trinidad and Tobago",
  },
  {
    value: "TN",
    label: "Tunisia",
  },
  {
    value: "TR",
    label: "Turkey",
  },
  {
    value: "TM",
    label: "Turkmenistan",
  },
  {
    value: "TV",
    label: "Tuvalu",
  },
  {
    value: "UG",
    label: "Uganda",
  },
  {
    value: "UA",
    label: "Ukraine",
  },
  {
    value: "AE",
    label: "United Arab Emirates",
  },
  {
    value: "GB",
    label: "United Kingdom",
  },
  {
    value: "US",
    label: "United States",
  },
  {
    value: "UY",
    label: "Uruguay",
  },
  {
    value: "UZ",
    label: "Uzbekistan",
  },
  {
    value: "VU",
    label: "Vanuatu",
  },
  {
    value: "VA",
    label: "Vatican City",
  },
  {
    value: "VE",
    label: "Venezuela",
  },
  {
    value: "VN",
    label: "Vietnam",
  },
  {
    value: "YE",
    label: "Yemen",
  },
  {
    value: "ZM",
    label: "Zambia",
  },
  {
    value: "ZW",
    label: "Zimbabwe",
  },
];

export const isoNationalities = [
  {
    value: "",
    label: "Please select",
  },
  {
    value: "GB",
    label: "British",
  },
  {
    value: "AF",
    label: "Afghan",
  },
  {
    value: "AL",
    label: "Albanian",
  },
  {
    value: "DZ",
    label: "Algerian",
  },
  {
    value: "US",
    label: "American",
  },
  {
    value: "AD",
    label: "Andorran",
  },
  {
    value: "AO",
    label: "Angolan",
  },
  {
    value: "AG",
    label: "Antiguan, Barbudan",
  },
  {
    value: "AR",
    label: "Argentine",
  },
  {
    value: "AM",
    label: "Armenian",
  },
  {
    value: "AU",
    label: "Australian",
  },
  {
    value: "AT",
    label: "Austrian",
  },
  {
    value: "AZ",
    label: "Azerbaijani",
  },
  {
    value: "BS",
    label: "Bahamian",
  },
  {
    value: "BH",
    label: "Bahraini",
  },
  {
    value: "BD",
    label: "Bangladeshi",
  },
  {
    value: "BB",
    label: "Barbadian",
  },
  {
    value: "BY",
    label: "Belarusian",
  },
  {
    value: "BE",
    label: "Belgian",
  },
  {
    value: "BZ",
    label: "Belizean",
  },
  {
    value: "BJ",
    label: "Beninese",
  },
  {
    value: "BT",
    label: "Bhutanese",
  },
  {
    value: "BO",
    label: "Bolivian",
  },
  {
    value: "BA",
    label: "Bosnian, Herzegovinian",
  },
  {
    value: "BR",
    label: "Brazilian",
  },
  {
    value: "GB",
    label: "British",
  },
  {
    value: "BN",
    label: "Bruneian",
  },
  {
    value: "BG",
    label: "Bulgarian",
  },
  {
    value: "BF",
    label: "Burkinabe",
  },
  {
    value: "MM",
    label: "Burmese",
  },
  {
    value: "BI",
    label: "Burundian",
  },
  {
    value: "KH",
    label: "Cambodian",
  },
  {
    value: "CM",
    label: "Cameroonian",
  },
  {
    value: "CA",
    label: "Canadian",
  },
  {
    value: "CV",
    label: "Cape Verdian",
  },
  {
    value: "CF",
    label: "Central African",
  },
  {
    value: "TD",
    label: "Chadian",
  },
  {
    value: "CL",
    label: "Chilean",
  },
  {
    value: "CN",
    label: "Chinese",
  },
  {
    value: "CO",
    label: "Colombian",
  },
  {
    value: "KM",
    label: "Comoran",
  },
  {
    value: "CD",
    label: "Congolese",
  },
  {
    value: "CG",
    label: "Congolese",
  },
  {
    value: "CR",
    label: "Costa Rican",
  },
  {
    value: "HR",
    label: "Croatian",
  },
  {
    value: "CU",
    label: "Cuban",
  },
  {
    value: "CY",
    label: "Cypriot",
  },
  {
    value: "CZ",
    label: "Czech",
  },
  {
    value: "DK",
    label: "Danish",
  },
  {
    value: "DJ",
    label: "Djibouti",
  },
  {
    value: "DM",
    label: "Dominican",
  },
  {
    value: "DO",
    label: "Dominican",
  },
  {
    value: "NL",
    label: "Dutch",
  },
  {
    value: "TL",
    label: "East Timorese",
  },
  {
    value: "EC",
    label: "Ecuadorean",
  },
  {
    value: "EG",
    label: "Egyptian",
  },
  {
    value: "AE",
    label: "Emirati",
  },
  {
    value: "GQ",
    label: "Equatorial Guinean",
  },
  {
    value: "ER",
    label: "Eritrean",
  },
  {
    value: "EE",
    label: "Estonian",
  },
  {
    value: "ET",
    label: "Ethiopian",
  },
  {
    value: "FJ",
    label: "Fijian",
  },
  {
    value: "PH",
    label: "Filipino",
  },
  {
    value: "FI",
    label: "Finnish",
  },
  {
    value: "FR",
    label: "French",
  },
  {
    value: "GA",
    label: "Gabonese",
  },
  {
    value: "GM",
    label: "Gambian",
  },
  {
    value: "GE",
    label: "Georgian",
  },
  {
    value: "DE",
    label: "German",
  },
  {
    value: "GH",
    label: "Ghanaian",
  },
  {
    value: "GR",
    label: "Greek",
  },
  {
    value: "GD",
    label: "Grenadian",
  },
  {
    value: "GT",
    label: "Guatemalan",
  },
  {
    value: "GW",
    label: "Guinea-Bissauan",
  },
  {
    value: "GN",
    label: "Guinean",
  },
  {
    value: "GY",
    label: "Guyanese",
  },
  {
    value: "HT",
    label: "Haitian",
  },
  {
    value: "HN",
    label: "Honduran",
  },
  {
    value: "HU",
    label: "Hungarian",
  },
  {
    value: "KI",
    label: "I-Kiribati",
  },
  {
    value: "IS",
    label: "Icelander",
  },
  {
    value: "IN",
    label: "Indian",
  },
  {
    value: "ID",
    label: "Indonesian",
  },
  {
    value: "IR",
    label: "Iranian",
  },
  {
    value: "IQ",
    label: "Iraqi",
  },
  {
    value: "IE",
    label: "Irish",
  },
  {
    value: "IL",
    label: "Israeli",
  },
  {
    value: "IT",
    label: "Italian",
  },
  {
    value: "CI",
    label: "Ivorian",
  },
  {
    value: "JM",
    label: "Jamaican",
  },
  {
    value: "JP",
    label: "Japanese",
  },
  {
    value: "JO",
    label: "Jordanian",
  },
  {
    value: "KZ",
    label: "Kazakhstani",
  },
  {
    value: "KE",
    label: "Kenyan",
  },
  {
    value: "KG",
    label: "Kirghiz",
  },
  {
    value: "KN",
    label: "Kittitian or Nevisian",
  },
  {
    value: "KW",
    label: "Kuwaiti",
  },
  {
    value: "LA",
    label: "Laotian",
  },
  {
    value: "LV",
    label: "Latvian",
  },
  {
    value: "LB",
    label: "Lebanese",
  },
  {
    value: "LR",
    label: "Liberian",
  },
  {
    value: "LY",
    label: "Libyan",
  },
  {
    value: "LI",
    label: "Liechtensteiner",
  },
  {
    value: "LT",
    label: "Lithuanian",
  },
  {
    value: "LU",
    label: "Luxembourger",
  },
  {
    value: "MK",
    label: "Macedonian",
  },
  {
    value: "MG",
    label: "Malagasy",
  },
  {
    value: "MW",
    label: "Malawian",
  },
  {
    value: "MY",
    label: "Malaysian",
  },
  {
    value: "MV",
    label: "Maldivan",
  },
  {
    value: "ML",
    label: "Malian",
  },
  {
    value: "MT",
    label: "Maltese",
  },
  {
    value: "MH",
    label: "Marshallese",
  },
  {
    value: "MR",
    label: "Mauritanian",
  },
  {
    value: "MU",
    label: "Mauritian",
  },
  {
    value: "MX",
    label: "Mexican",
  },
  {
    value: "FM",
    label: "Micronesian",
  },
  {
    value: "MD",
    label: "Moldovan",
  },
  {
    value: "MC",
    label: "Monegasque",
  },
  {
    value: "MN",
    label: "Mongolian",
  },
  {
    value: "ME",
    label: "Montenegrin",
  },
  {
    value: "MA",
    label: "Moroccan",
  },
  {
    value: "LS",
    label: "Mosotho",
  },
  {
    value: "BW",
    label: "Motswana",
  },
  {
    value: "MZ",
    label: "Mozambican",
  },
  {
    value: "NA",
    label: "Namibian",
  },
  {
    value: "NR",
    label: "Nauruan",
  },
  {
    value: "NP",
    label: "Nepalese",
  },
  {
    value: "NZ",
    label: "New Zealander",
  },
  {
    value: "VU",
    label: "Ni-Vanuatu",
  },
  {
    value: "NI",
    label: "Nicaraguan",
  },
  {
    value: "NG",
    label: "Nigerian",
  },
  {
    value: "NE",
    label: "Nigerien",
  },
  {
    value: "KP",
    label: "North Korean",
  },
  {
    value: "NO",
    label: "Norwegian",
  },
  {
    value: "OM",
    label: "Omani",
  },
  {
    value: "PK",
    label: "Pakistani",
  },
  {
    value: "PW",
    label: "Palauan",
  },
  {
    value: "PA",
    label: "Panamanian",
  },
  {
    value: "PG",
    label: "Papua New Guinean",
  },
  {
    value: "PY",
    label: "Paraguayan",
  },
  {
    value: "PE",
    label: "Peruvian",
  },
  {
    value: "PL",
    label: "Polish",
  },
  {
    value: "PT",
    label: "Portuguese",
  },
  {
    value: "QA",
    label: "Qatari",
  },
  {
    value: "RO",
    label: "Romanian",
  },
  {
    value: "RU",
    label: "Russian",
  },
  {
    value: "RW",
    label: "Rwandan",
  },
  {
    value: "LC",
    label: "Saint Lucian",
  },
  {
    value: "VC",
    label: "Saint Vincentian",
  },
  {
    value: "SV",
    label: "Salvadoran",
  },
  {
    value: "SM",
    label: "Sammarinese",
  },
  {
    value: "WS",
    label: "Samoan",
  },
  {
    value: "ST",
    label: "Sao Tomean",
  },
  {
    value: "SA",
    label: "Saudi Arabian",
  },
  {
    value: "SN",
    label: "Senegalese",
  },
  {
    value: "RS",
    label: "Serbian",
  },
  {
    value: "SC",
    label: "Seychellois",
  },
  {
    value: "SL",
    label: "Sierra Leonean",
  },
  {
    value: "SG",
    label: "Singaporean",
  },
  {
    value: "SK",
    label: "Slovak",
  },
  {
    value: "SI",
    label: "Slovene",
  },
  {
    value: "SB",
    label: "Solomon Islander",
  },
  {
    value: "SO",
    label: "Somali",
  },
  {
    value: "ZA",
    label: "South African",
  },
  {
    value: "KR",
    label: "South Korean",
  },
  {
    value: "SS",
    label: "South Sudanese",
  },
  {
    value: "ES",
    label: "Spanish",
  },
  {
    value: "LK",
    label: "Sri Lankan",
  },
  {
    value: "SD",
    label: "Sudanese",
  },
  {
    value: "SR",
    label: "Surinamer",
  },
  {
    value: "SZ",
    label: "Swazi",
  },
  {
    value: "SE",
    label: "Swedish",
  },
  {
    value: "CH",
    label: "Swiss",
  },
  {
    value: "SY",
    label: "Syrian",
  },
  {
    value: "TJ",
    label: "Tadzhik",
  },
  {
    value: "TZ",
    label: "Tanzanian",
  },
  {
    value: "TH",
    label: "Thai",
  },
  {
    value: "TG",
    label: "Togolese",
  },
  {
    value: "TO",
    label: "Tongan",
  },
  {
    value: "TT",
    label: "Trinidadian",
  },
  {
    value: "TN",
    label: "Tunisian",
  },
  {
    value: "TR",
    label: "Turkish",
  },
  {
    value: "TM",
    label: "Turkmen",
  },
  {
    value: "TV",
    label: "Tuvaluan",
  },
  {
    value: "UG",
    label: "Ugandan",
  },
  {
    value: "UA",
    label: "Ukrainian",
  },
  {
    value: "UY",
    label: "Uruguayan",
  },
  {
    value: "UZ",
    label: "Uzbekistani",
  },
  {
    value: "VA",
    label: "Vatican",
  },
  {
    value: "VE",
    label: "Venezuelan",
  },
  {
    value: "VN",
    label: "Vietnamese",
  },
  {
    value: "YE",
    label: "Yemeni",
  },
  {
    value: "ZM",
    label: "Zambian",
  },
  {
    value: "ZW",
    label: "Zimbabwean",
  },
];
