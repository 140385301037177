import PaymentConfirmationOverview from "../../components/PaymentConfirmationOverview";

type Props = {
  location: any;
};

const PaymentConfirmationComplimentary = ({ location }: Props) => {
  const locationState = location.state;

  if (!locationState) {
    return (
      <div>
        There was an error, confirmation page can currently not be shown.
      </div>
    );
  }

  return (
    <div>
      <PaymentConfirmationOverview
        bookingNr={locationState.bookingNr}
        location={location}
      />
    </div>
  );
};

export default PaymentConfirmationComplimentary;
