import { IItinerary } from "interfaces/ReduxState/IItinerary";
import { ItineraryAction } from "../actions/itineraryActions";

const INITIAL_STATE: IItinerary[] = [];

const itineraryReducers = (state = INITIAL_STATE, action: ItineraryAction) => {
  switch (action.type) {
    case "SET_ITINERARY":
      return action.itinerary;

    case "CLEAR_ITINERARY":
      return INITIAL_STATE;

    case "ITINERARY_TOGGLE_ONE":
      return state.map((day) => {
        if (day.id === action.itineraryId) {
          if (action.open !== null) {
            day.expanded = action.open;
          } else {
            day.expanded = !day.expanded;
          }
        }
        return day;
      });

    case "ITINERARY_TOGGLE_ALL":
      return state.map((day) => {
        day.expanded = action.open;
        return day;
      });

    case "ITINERARY_SET_SCROLL_TO":
      return state.map((day) => {
        if (day.id === action.itineraryId) {
          day.scrollTo = action.shouldScrollTo ?? false;
        }

        return day;
      });

    case "SET_ITINERARY_EXCURSIONS":
      const foundIndex = state.findIndex(
        (itinerary) => itinerary.id === action.payload.itineraryId
      );
      const newExcursions = action.payload.excursions;

      // Create a new state array with the updated excursions property
      const newState = state.map((item, index) => {
        if (index === foundIndex) {
          // If the index matches, update the excursions property
          return { ...item, excursions: newExcursions ?? [] };
        }
        return item;
      });

      return newState;

    default:
      return state;
  }
};

export default itineraryReducers;
