import { useRef } from "react";
import BackLink from "../../components/molecules/BackLink";
import { PDFViewer } from "@react-pdf/renderer";
import LuggageTagPdf from "../../components/pdf/LuggageTagPdf";
import { useLocation } from "react-router-dom";
import { IPassenger } from "interfaces/Common/IPassenger";

type LocationState = {
  passengers: IPassenger;
  bookingData: any;
};

const LuggageTagDetail = () => {
  const location = useLocation<LocationState>();
  const pdfRef = useRef<HTMLIFrameElement>(null);

  const handlePrint = () => {
    pdfRef?.current?.contentWindow?.print();
  };

  const { passengers, bookingData } = location.state;

  return (
    <div className="container mx-auto mt-4">
      <BackLink to="/" text="Back to Cruise Control Dashboard" />

      <h1>Luggage tag</h1>

      <div className="mt-4">
        <button
          className="action-button action-button-enabled w-mobile"
          onClick={handlePrint}
        >
          Print luggage tag
        </button>
      </div>

      <div className="w-full container-a4 mt-6 mb-8">
        <div className="container-a4-content">
          <PDFViewer
            innerRef={pdfRef}
            style={{ width: "100%", height: "100%" }}
          >
            <LuggageTagPdf passengers={passengers} bookingData={bookingData} />
          </PDFViewer>
        </div>
      </div>
    </div>
  );
};

export default LuggageTagDetail;
