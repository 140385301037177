import { FC, useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import BackLink from "../molecules/BackLink";
import browserHistory from "../../utils/history";
import usePreCruisePassenger from "../../hooks/usePreCruisePassenger";
import TextInputWithLabel from "../TextInputWithLabel";
import { postToHub } from "../../actions/networkActions";

const ForgottenPin: FC<
  RouteComponentProps<{
    passengerId: string;
  }>
> = ({ match }) => {
  const passengerId = match.params.passengerId;
  const { firstName, lastName } = usePreCruisePassenger(passengerId);
  const name = `${firstName} ${lastName}`;
  const [memorableWord, setMemorableWord] = useState<string>("");
  const [error, setError] = useState("");

  const isMemorableWordValid = memorableWord.length > 5;

  const submit = async () => {
    if (!isMemorableWordValid) {
      setError("Please provide your memorable word");
      return;
    }

    const response = await postToHub("precruise/passenger/forgotPin", {
      passengerId,
      memorableWord,
    });

    if (response.token) {
      browserHistory.push(
        `/precruise/passenger/${passengerId}/resetPin/${response.token}`
      );
    } else {
      if (response.error?.response?.status === 400) {
        setError("Please provide the correct memorable word");
      } else {
        setError("There has been an error, please try again");
      }
    }
  };

  return (
    <div className="container mx-auto">
      <div className="mt-4">
        <BackLink
          to="/precruise/passengers"
          text="Back to Travel information dashboard"
        />
      </div>

      <h1 className="my-6">Forgotten PIN for {name}</h1>

      <div className="mt-4 text-grey-darker">
        You will be able to set a new PIN upon providing the correct information
        below.
      </div>

      <div className="border bg-grey-lightest-2 p-4 mt-6">
        <div>
          <TextInputWithLabel
            label="Memorable word"
            type="text"
            value={memorableWord}
            _onChange={(val: string) => setMemorableWord(val)}
            errorMessage={""}
          />
        </div>
        <div className="mt-4">
          <Link
            className="text-blue underline"
            to={`/precruise/passenger/${passengerId}/reset`}
          >
            I've forgotten my memorable word
          </Link>
        </div>
      </div>

      {error && <div className="mt-4 text-red-tui">{error}</div>}

      <div className="mt-6 mb-8">
        <button
          className={`action-button w-mobile ${
            isMemorableWordValid
              ? "action-button-enabled"
              : "action-button-disabled"
          }`}
          onClick={submit}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ForgottenPin;
