import { Route, Redirect, RouteProps, useLocation } from "react-router-dom";
import { isCruiseStartDateDenied } from "../../utils/dateTimeUtils";
import useAppSelector from "../../hooks/useAppSelector";

import browserHistory from "utils/history";
import { FC, useEffect } from "react";
import { useCheckFeatureFlags } from "hooks/common/featureFlags/useCheckFeatureFlags";
import { useCheckConfigurationFlags } from "hooks/common/configuraitonFlags/useCheckConfigurationFlags";

type Props = RouteProps & {
  component: FC<any>;
};

const AuthRoute: FC<Props> = ({ component: Component, ...rest }) => {
  const { isMxpEnabled } = useCheckFeatureFlags();
  const { isMxpDiningEnabledPerShip } = useCheckConfigurationFlags();

  const isAuthenticated = useAppSelector(
    (state) => state.authentication.isAuthenticated
  );
  const cruiseStartDate = useAppSelector((state) =>
    state.cruises && state.cruises.cruiseStartDate
      ? state.cruises.cruiseStartDate
      : null
  );

  const isAgency = useAppSelector((state) => state.agency.isAuthenticated);

  const location = useLocation();

  useEffect(() => {
    if (
      !isMxpEnabled &&
      !isMxpDiningEnabledPerShip &&
      isAuthenticated &&
      location?.pathname?.includes("/mxp/")
    ) {
      browserHistory.push("/dashboard");
    } else if (
      isMxpEnabled &&
      isMxpDiningEnabledPerShip &&
      isAuthenticated &&
      location?.pathname?.includes("/tac/")
    ) {
      browserHistory.push("/dashboard");
    }
  }, [
    isAuthenticated,
    isMxpDiningEnabledPerShip,
    isMxpEnabled,
    location?.pathname,
  ]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { forward_to: window.location.pathname },
              }}
            />
          );
        }

        if (isCruiseStartDateDenied(cruiseStartDate, isAgency)) {
          return <Redirect to={{ pathname: "/logoutUser" }} />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default AuthRoute;
