import { parse, startOfDay, isAfter } from "date-fns";
import { convertDateFormat } from "utils/dateTimeUtils";
import { IShipsInformation } from "interfaces/Common/IShipsInformation";

export const hostnameDev = "www.tui-presales-dev.co.uk";
export const hostnameTest = "www.tui-presales-test.co.uk";
export const hostnameProd = "www.marellacruisecontrol.tui.co.uk";

const AVAILABLE_SHIPS_OSW_MVP = [
  "Marella Explorer",
  "Marella Explorer 2",
  "Marella Discovery",
  "Marella Discovery 2",
  "Marella Voyager",
  "Marella Explorer TEST",
];

const AVAILABLE_SHIPS_MXP_DINING_MVP = [
  "Marella Explorer",
  "Marella Explorer 2",
  "Marella Discovery",
  "Marella Discovery 2",
  "Marella Voyager",
  "Marella Explorer TEST",
];

const SHIP_NAME_TO_MUSEMENT_LIVE_DATES = {
  // Past days for Live Ships

  // To Go Live Soon

  // Days in the future
  "Marella Explorer": "31-12-2030",
  "Marella Explorer TEST": "31-12-2030",
  "Marella Discovery 2": "02-12-2030",
  "Marella Discovery": "31-12-2030",
  "Marella Explorer 2": "31-12-2030",
  "Marella Voyager": "31-12-2030",
  "N/A": "N/A",
};

enum ShipNamesToShipCode {
  "Marella Discovery" = "MDI",
  "Marella Discovery 2" = "MDI2",
  "Marella Explorer" = "MEX",
  "Marella Explorer 2" = "MEX2",
  "Marella Voyager" = "MVO",
  "N/A" = "N/A",
  "Marella Explorer TEST" = "MEX",
}

export const getIsRiverUrl = function () {
  const isRiverUrl =
    JSON.stringify(process.env.REACT_APP_IS_RIVER) === '"true"';
  return isRiverUrl;
};

export const getHubUrl = function () {
  return process.env.REACT_APP_HUB_API_END_POINT;
};

export const getHubBaseUrl = function () {
  return process.env.REACT_APP_HUB_BASE_API_END_POINT;
};

export const getOswUrl = function () {
  return process.env.REACT_APP_OSW_BASE_API;
};

export const getPrebookUrl = () => process.env.REACT_APP_PREBOOK_BASE_API;

export const getDinningUrl = () => process.env.REACT_APP_DINING_BASE_API;

export const isDevelopmentMode = function () {
  const reactAppEnv = process.env.REACT_APP_ENV;
  const a = {
    development: "development",
    test: "test",
    production: "production",
  } as const;

  // Use type assertion to ensure that reactAppEnv is one of the defined keys
  return (
    a[reactAppEnv as keyof typeof a] === "development" ||
    window.location.hostname === hostnameDev
  );
};

export const isTestMode = function () {
  const reactAppEnv = process.env.REACT_APP_ENV;
  const a = {
    development: "development",
    test: "test",
    production: "production",
  } as const;

  // Use type assertion to ensure that reactAppEnv is one of the defined keys
  return (
    a[reactAppEnv as keyof typeof a] === "test" ||
    window.location.hostname === hostnameTest
  );
};

export const isProductionMode = function () {
  const reactAppEnv = process.env.REACT_APP_ENV;
  const a = {
    development: "development",
    test: "test",
    production: "production",
  } as const;

  // Use type assertion to ensure that reactAppEnv is one of the defined keys
  return (
    a[reactAppEnv as keyof typeof a] === "production" ||
    window.location.hostname === hostnameProd
  );
};

export const isShipNameInOswMVP = ({
  shipName,
}: {
  shipName: IShipsInformation["shipName"];
}) => {
  const isInAvailableNames = AVAILABLE_SHIPS_OSW_MVP.includes(shipName);

  return isInAvailableNames;
};

export const isGoLiveForMusementPerShip = ({
  shipName,
  cruiseStart,
}: {
  shipName: IShipsInformation["shipName"];
  cruiseStart?: string;
}) => {
  const goLiveDateString = SHIP_NAME_TO_MUSEMENT_LIVE_DATES?.[shipName];

  if (!goLiveDateString) {
    return false; // Ship name not found in the object
  }

  // Parse the date from the object (assuming it's in DD-MM-YYYY format)
  const goLiveDate = parse(goLiveDateString, "dd-MM-yyyy", new Date());
  const goLiveStartOfDay = startOfDay(goLiveDate);

  if (!cruiseStart) {
    return false; // cruiseStart is not provided
  }

  // Convert cruiseStart to a Date object
  const cruiseStartDate = convertDateFormat(cruiseStart);

  return isAfter(cruiseStartDate, goLiveStartOfDay);
};

export const isShipNameInMxpDiningMVP = ({
  shipName,
}: {
  shipName: IShipsInformation["shipName"];
}) => {
  const isInAvailableNames = AVAILABLE_SHIPS_MXP_DINING_MVP.includes(shipName);

  return isInAvailableNames;
};

export const getShipCodeByName = ({
  shipName,
}: {
  shipName: IShipsInformation["shipName"];
}) => {
  const shipCode = ShipNamesToShipCode[shipName];

  return shipCode;
};
