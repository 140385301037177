export const flattenQuestions = (questions: any[], forceToEnd = false) => {
  const result: { id: any; text: any; moveToEnd: any }[] = [];

  questions.forEach((question) => {
    const moveToEnd = forceToEnd || question.moveToEndOfPage;
    // add the question to the result
    result.push({
      id: question.id,
      text: question.reviewText || question.questionText,
      moveToEnd,
    });

    // add the nested questions to the result
    Object.keys(question.questions || []).forEach((key) => {
      result.push(...flattenQuestions(question.questions[key], moveToEnd));
    });
  });

  return result;
};
