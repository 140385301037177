import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import useAppSelector from "hooks/useAppSelector";
import { getPrebookUrl } from "config/configUtils";
import { IFeatureFlag } from "interfaces/FeatureFlags/IFeatureFlags";
import { useLocation } from "react-router-dom";

export const useGetFeatureFlags = () => {
  const location = useLocation();
  const sessionId = useAppSelector((state) => state.authentication.sessionId);
  const URL = `${getPrebookUrl()}/api/feature-config`;

  const { refetch, status, data, error, isFetching, isSuccess } = useQuery({
    queryKey: ["getFeatureFlags", location?.pathname],
    queryFn: async () =>
      await axios
        .get(URL, {
          headers: {
            SessionId: sessionId,
          },
        })
        .then((res) => res.data as Promise<IFeatureFlag[]>),
    refetchOnMount: "always",
    enabled: !!sessionId,
  });

  return {
    getFeatureFlags: refetch,
    getFeatureFlagsData: data,
    getFeatureFlagsStatus: status,
    getFeatureFlagsError: error,
    getFeatureFlagsIsFetching: isFetching,
    getFeatureFlagsIsSuccess: isSuccess,
  };
};
