import PreForm from "../../components/passengerInformation/PreForm";
import BackLink from "../../components/molecules/BackLink";
import usePreCruisePassenger from "../../hooks/usePreCruisePassenger";

import { useRouteMatch } from "react-router-dom";

interface IMatchState {
  passengerId: string;
}

const PassengerOverview = () => {
  const match = useRouteMatch<IMatchState>();

  const passengerId = match.params.passengerId;
  const passenger = usePreCruisePassenger(passengerId);

  return (
    <div className="container mx-auto">
      <div className="mt-4">
        <BackLink
          to="/precruise/passengers"
          text="Back to Travel information dashboard"
        />
      </div>

      <h1>
        Travel Information for {passenger.firstName} {passenger.lastName}
      </h1>

      <PreForm passenger={passenger} />
    </div>
  );
};

export default PassengerOverview;
