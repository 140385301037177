import Modal from "../Modal/Modal";

import { removeNotBookableEntriesFromCart } from "../../../actions/cartActions";
import ModalFooter from "../ModalFooter";
import useAppSelector from "../../../hooks/useAppSelector";
import { useUpdateCart } from "actions/useUpdateCart";

const CartErrorModal = () => {
  const notBookableExcursions = useAppSelector(
    (state) => state.cart?.notBookableEntries?.excursions || []
  );
  const notBookableTac = useAppSelector(
    (state) => state.cart?.notBookableEntries?.tac || []
  );

  const { updateCart } = useUpdateCart();

  const onRemoveClick = async () => {
    await removeNotBookableEntriesFromCart();
    await updateCart();
  };

  return (
    <Modal
      show={notBookableExcursions.length > 0 || notBookableTac.length > 0}
      onClose={() => {}}
      title="Sold Out"
      removeDismissButton={true}
    >
      <div className="flex flex-grow flex-col">
        <div className="flex-grow bg-white py-4 px-2 sm:px-4">
          <div className="text-xl mb-4">
            We are sorry, but due to popular demand this cart items are now sold
            out. The following entries will be removed from your shopping cart:
          </div>
          <div className="my-2">
            {notBookableExcursions.map((excursion: any) => (
              <div key={excursion.excursionId}>
                <div className="my-4">
                  <div className="text-blue-dark text-lg">
                    {excursion.excursionName}
                  </div>
                  <div>
                    {excursion.passengers.map((passenger: any) => (
                      <div key={passenger.id}>
                        {`${passenger.firstName} ${passenger.lastName}`}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}

            {notBookableTac.map((tacEntry: any) => (
              <div key={tacEntry.id}>
                <div className="my-4">
                  <div className="text-blue-dark text-lg">{tacEntry.name}</div>
                  <div>Reservation at {tacEntry.time}</div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <ModalFooter
          actionDisabled={false}
          actionButtonText="Remove the listed items from your planner"
          onActionClick={onRemoveClick}
        />
      </div>
    </Modal>
  );
};

export default CartErrorModal;
