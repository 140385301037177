import { AppThunk } from "../configureStore";
import { Action } from "redux";

export interface LocationAction extends Action {
  isItineraryShown?: boolean;
  isDashboardShown?: boolean;
}

export const setIsItineraryShown =
  (shown: boolean): AppThunk =>
  (dispatch) => {
    dispatch<LocationAction>({
      type: "SET_IS_ITINERARY_SHOWN",
      isItineraryShown: shown,
    });
  };

export const setIsDashboardShown =
  (shown: boolean): AppThunk =>
  (dispatch) => {
    dispatch<LocationAction>({
      type: "SET_IS_DASHBOARD_SHOWN",
      isDashboardShown: shown,
    });
  };
