import axios from "axios";
import { getOswUrl, getShipCodeByName } from "config/configUtils";
import useAppSelector from "hooks/useAppSelector";
import { IOneSpaWorldDateAndSlotsEntryDetails } from "interfaces/OSW/IOneSpaWorldDateAndSlotsEntryDetails";
import { useCallback, useEffect, useState } from "react";
import { getCruisesInfo } from "utils/cruiseUtils";

type Props = {
  date: string;
  treatmentId: string;
};

export const useGetSpaTreatmentDetails = ({ date, treatmentId }: Props) => {
  const sessionId = useAppSelector((state) => state.authentication.sessionId);
  const cruiseDetails = useAppSelector((state) => state.cruises.cruiseDetails);
  const cruisesInfo = getCruisesInfo(cruiseDetails);

  const [data, setData] = useState<IOneSpaWorldDateAndSlotsEntryDetails>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const getSpaTreatmentDetails = useCallback(async () => {
    setLoading(true);
    const URL = `${getOswUrl()}/api/ships/${getShipCodeByName({
      shipName: cruisesInfo.shipName,
    })}/spa-treatment/date/${date}/by-id/${treatmentId}`;

    const response = await axios
      .get(URL, {
        headers: {
          SessionId: sessionId,
        },
      })
      .catch((error) => {
        setError(error);
      });

    setData(response?.data);
    setLoading(false);
  }, [cruisesInfo.shipName, date, sessionId, treatmentId]);

  useEffect(() => {
    getSpaTreatmentDetails();
  }, [getSpaTreatmentDetails]);

  return { getSpaTreatmentDetails, data, loading, error };
};
