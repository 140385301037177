import { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import InfoBox from "../../components/passengerInformation/InfoBox/InfoBox";
import SvgIcon from "../../components/atoms/SvgIcon";
import BackLink from "../../components/molecules/BackLink";
import { usePreCruiseContext } from "../../context/PreCruiseContext";
import { getFromHub } from "../../actions/networkActions";
import { IPassenger } from "interfaces/Common/IPassenger";
import { useGetRiverGuestInfo } from "components/passengerInformation/hooks/useGetRiverGuestInfo";
import useAppSelector from "hooks/useAppSelector";
import { IRiverGuestInfo } from "interfaces/Travelnformation/IRiverGuestInfo";

const PassengersOverview = () => {
  const { preCruiseInfo, setPreCruiseInfo }: any = usePreCruiseContext();

  useEffect(() => {
    let isCancelled = false;

    const fetchData = async () => {
      const response = await getFromHub("precruise/status");
      if (!isCancelled) {
        setPreCruiseInfo(response);
      }
    };

    fetchData();

    return () => {
      isCancelled = true;
    };
  }, [setPreCruiseInfo]);

  const { passengers = [] } = preCruiseInfo;
  return (
    <div className="container mx-auto">
      <div className="mt-4">
        <BackLink to="/dashboard" text="Back to Cruise Control Dashboard" />
      </div>
      <h1>Travel information dashboard</h1>

      <div className="bg-blue-lightest-25 py-8 my-8 shadow">
        <div className="container mx-auto">
          <div className="grid gap-x-4 gap-y-6 sm:grid-cols-2 xl:grid-cols-3">
            {passengers.map((passenger: IPassenger) => (
              <PassengerTile
                key={passenger.passengerId}
                passenger={passenger}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PassengersOverview;

type PassengerTileProps = {
  passenger: IPassenger;
};

const getInfoIcon = ({
  passengerInfo,
  isLead,
}: {
  passengerInfo: any;
  isLead: boolean;
}) => {
  if (
    passengerInfo?.isTravelInfoCompleted ||
    passengerInfo?.isTravelInfoSecured
  ) {
    return "lock";
  }

  if (isLead) {
    return "adult";
  }

  return "";
};

const getInfoText = ({
  passenger,
  isAdult,
}: {
  passenger: IPassenger;
  isAdult: boolean;
}) => {
  if (passenger?.isTravelInfoSecured) {
    return "Information locked";
  }

  if (passenger.isLead) {
    return "Lead Passenger";
  }

  if (passenger.travelInfoStatus === "COMPLETE") {
    if (isAdult) {
      return "If you prefer, you can ask this guest to complete their own information.";
    } else {
      return "You will need to complete this passenger's information.";
    }
  }

  return "";
};

const getRiverInfoText = ({
  riverGuestInfoData,
  passenger,
  isAdult,
}: {
  riverGuestInfoData: IRiverGuestInfo;
  passenger: IPassenger;
  isAdult: boolean;
}) => {
  if (passenger?.isTravelInfoSecured) {
    return "Information locked";
  }

  if (riverGuestInfoData?.isTravelInfoCompleted) {
    return "Information submitted";
  }

  if (passenger.isLead) {
    return "Lead Passenger";
  }

  if (!riverGuestInfoData.isTravelInfoCompleted) {
    if (isAdult) {
      return "If you prefer, you can ask this guest to complete their own information.";
    } else {
      return "You will need to complete this passenger's information.";
    }
  }

  return "";
};

const getLinkDetails = (passenger: IPassenger) => {
  // passenger was sent a magic link - only thing possible as logged in user is to reset (clear) any data
  if (passenger.isEmailLinkEnabled && passenger.isTravelInfoSecured) {
    return {
      to: `/precruise/passenger/${passenger.passengerId}/reset`,
      text: "Reset data",
    };
  }

  // passenger was not sent magic link and has not yet completed the survey
  if (passenger.travelInfoStatus !== "COMPLETE") {
    return {
      to: `/precruise/passenger/${passenger.passengerId}`,
      text: "Information required",
    };
  }

  // passenger was not sent magic link and has completed the survey
  return {
    to: passenger.isTravelInfoSecured
      ? `/precruise/passenger/${passenger.passengerId}/unlock`
      : `/precruise/passenger/${passenger.passengerId}/review`,
    text: "View and edit",
  };
};

const getRiverLinkDetails = (
  passenger: IPassenger,
  riverPassenger?: IRiverGuestInfo
) => {
  // passenger was sent a magic link - only thing possible as logged in user is to reset (clear) any data
  if (passenger.isEmailLinkEnabled && passenger.isTravelInfoSecured) {
    return {
      to: `/precruise/passenger/${passenger.passengerId}/reset`,
      text: "Reset data",
    };
  }

  // passenger was not sent magic link and has completed the survey
  if (riverPassenger?.isTravelInfoCompleted) {
    // passenger have locked their data
    const linkTo = passenger.isTravelInfoSecured
      ? `/precruise/passenger/${passenger.passengerId}/unlock`
      : `/precruise/passenger/${passenger.passengerId}`;

    return {
      to: linkTo,
      text: "View and edit",
    };
  }

  // passenger was not sent magic link and has not yet completed the survey
  return {
    to: `/precruise/passenger/${passenger.passengerId}`,
    text: "Information required",
  };
};

const PassengerTile: FC<PassengerTileProps> = ({ passenger }) => {
  const isRiverUrl = useAppSelector((state) => state.appConfig.isRiverUrl);
  const { riverGuestInfoData, isFetching } = useGetRiverGuestInfo(
    isRiverUrl ? passenger.passengerId : ""
  );

  const passengerName = `${passenger.firstName} ${passenger.lastName}`;
  const isAdult = passenger.type === "Adult";
  const isTravelInfoCompleted = isRiverUrl
    ? riverGuestInfoData.isTravelInfoCompleted
    : passenger.travelInfoStatus === "COMPLETE";

  if (isFetching) {
    return null;
  }

  const infoIcon = getInfoIcon({
    passengerInfo: passenger,
    isLead: passenger.isLead,
  });

  const infoText = isRiverUrl
    ? getRiverInfoText({
        riverGuestInfoData,
        passenger,
        isAdult: isAdult,
      })
    : getInfoText({
        passenger,
        isAdult: isAdult,
      });

  const { to, text } = isRiverUrl
    ? getRiverLinkDetails(passenger, riverGuestInfoData)
    : getLinkDetails(passenger);

  return (
    <div className="flex flex-col bg-white border text-center p-4 shadow">
      <div>
        <SvgIcon
          name={passenger.type.toLowerCase()}
          className="w-18 h-18 inline-block text-blue-dark"
        />
      </div>

      <div className="mt-4 text-lg font-bold text-blue-dark">
        {passengerName}
      </div>

      <div className="mt-2">
        {isTravelInfoCompleted && (
          <div className="flex items-center justify-center text-left">
            <SvgIcon
              name="tick-circle"
              className="w-6 h-6 inline-block text-green mr-2 flex-shrink-0"
            />
            Information submitted
          </div>
        )}

        <Link className="inline-block text-blue underline mt-2" to={to}>
          {text} <SvgIcon name="chevron-right" className="w-4 h-4 inline" />
        </Link>
      </div>

      {infoText && (
        <div className="flex-grow flex items-end">
          <InfoBox infoText={infoText} icon={infoIcon} classes="w-full mt-4" />
        </div>
      )}
    </div>
  );
};
