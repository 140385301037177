import { FC, MouseEvent } from "react";
import { buildTestId } from "utils/buildTestId";

export type Props = {
  disabled: boolean;
  classes: string;
  buttonText: string;
  onClick: (e: MouseEvent) => void;
};

const ActionButton: FC<Props> = ({
  disabled,
  classes,
  onClick,
  buttonText,
}) => {
  return (
    <button
      data-testid={buildTestId("button", "action")}
      onClick={onClick}
      disabled={disabled}
      className={`action-button ${`action-button-${
        disabled ? "disabled" : "enabled"
      }`} ${classes}`}
    >
      {buttonText}
    </button>
  );
};

export default ActionButton;
