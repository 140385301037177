import axios from "axios";
import { getPrebookUrl } from "config/configUtils";
import { useCallback, useState } from "react";
import useAppSelector from "hooks/useAppSelector";

type Props = {
  itemId: string | null;
  internalMessage?: string;
  customerMessage?: string;
};

export const usePostAgencyRefundMusement = () => {
  const sessionId = useAppSelector((state) => state.authentication.sessionId);

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const postAgencyRefundMusement = useCallback(
    async ({ itemId, internalMessage = "", customerMessage = "" }: Props) => {
      setLoading(true);
      const URL = `${getPrebookUrl()}/api/payments/agency/cancel/${itemId}`;

      const response = await axios
        .post(
          URL,
          { internalMessage, customerMessage },
          {
            headers: {
              SessionId: sessionId,
            },
          }
        )
        .catch((error) => {
          setError(error);
        });

      setData(response?.data);
      setLoading(false);
    },
    [sessionId]
  );

  return { postAgencyRefundMusement, data, loading, error };
};
