import { imagePath } from "utils/imageUtils";

type Props = {
  duration: number;
};

const ActivityDurationOSW = ({ duration }: Props) => {
  let displayDuration =
    duration === 1 ? `${duration} Minute` : `${duration} Minutes`;

  return (
    <div className="flex items-center">
      <img
        className="w-4 h-4 align-middle mr-2 inline"
        alt="hourglass icon"
        src={imagePath("hourglass.png")}
      />
      <span className="align-middle text-grey-darker">
        Duration {displayDuration}
      </span>
    </div>
  );
};

export default ActivityDurationOSW;
