import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import FoldLabelHorizontal from "./FoldLabelHorizontal";
import { convertYMDtoDMY } from "../../utils/dateTimeUtils";

// prevent hyphenation - this is a bit overkill registering it on all elements, it can also be done on component level
Font.registerHyphenationCallback((word) => {
  return [word];
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
  },
});

const LuggageTagPdf = (props) => {
  return (
    <Document title="Luggage tag">
      {props.passengers.map((passenger, index) => (
        <LuggageTagPage
          key={passenger.passengerId}
          passenger={passenger}
          bookingData={props.bookingData}
          passengerNr={index}
        />
      ))}
    </Document>
  );
};

export default LuggageTagPdf;

const LuggageTagPage = (props) => {
  const containerStyle = {
    height: "50%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <Page size="A4" style={styles.page}>
      <View style={{ ...containerStyle, borderBottom: "1px dashed #092a5e" }}>
        <EmptyPart border={true} />

        <FoldLabelVertical />

        <View
          style={{
            height: "100%",
            flex: 1,
            textAlign: "center",
            borderRight: "1px dashed #092a5e",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <InfoPart
            passenger={props.passenger}
            bookingData={props.bookingData}
            rotate={true}
            stepLetter="E"
          />
        </View>

        <FoldLabelVertical />

        <View
          style={{
            height: "100%",
            flex: 1,
            textAlign: "center",
            borderRight: "1px dashed #092a5e",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Instructions />
        </View>

        <FoldLabelVertical />

        <EmptyPart />
      </View>

      <FoldLabelHorizontal
        offsets={[
          { side: "left", value: "7%" },
          { side: "left", value: "33%" },
          { side: "right", value: "33%" },
          { side: "right", value: "7%" },
        ]}
      />

      <View style={containerStyle}>
        <EmptyPart letter="A" border={true} />

        <FoldLabelVertical />

        <View
          style={{
            height: "100%",
            flex: 1,
            textAlign: "center",
            borderRight: "1px dashed #092a5e",
          }}
        >
          <InfoPart
            passenger={props.passenger}
            bookingData={props.bookingData}
            stepLetter="B"
          />
        </View>

        <FoldLabelVertical />

        <EmptyPart letter="C" border={true} />

        <FoldLabelVertical />

        <EmptyPart letter="D" />
      </View>
    </Page>
  );
};

const EmptyPart = (props) => {
  const style = {
    height: "100%",
    flex: 1,
  };

  if (props.border) {
    style.borderRight = "1px dashed #092a5e";
  }

  if (props.letter) {
    style.display = "flex";
    style.justifyContent = "flex-end";
    style.paddingBottom = "16px";
    style.textAlign = "center";
  }

  return (
    <View style={style}>
      {props.letter && <CircledLetter letter={props.letter} />}
    </View>
  );
};

const InfoPart = (props) => {
  const containerStyle = {};
  if (props.rotate) {
    containerStyle.transform = "rotate(180deg)";
  }

  const guestName = `${props.passenger.title} ${props.passenger.firstName} ${props.passenger.lastName}`;
  const deckNr = props.passenger.deckNumber;
  const cabinNr = props.passenger.cabinNumber;
  const port = props.bookingData.departurePortName;
  const departureDate = convertYMDtoDMY(props.bookingData.embarkationDate);

  return (
    <View style={containerStyle}>
      <View style={{ padding: "24px 12px 12px 12px" }}>
        <View style={{ backgroundColor: "#092a5e" }}>
          <Text
            style={{
              color: "white",
              textTransform: "uppercase",
              fontSize: "13px",
              paddingVertical: "4px",
            }}
          >
            Marella cruises
          </Text>
        </View>
        <View
          style={{ backgroundColor: "#c2e6fa", padding: "8px 6px 0px 6px" }}
        >
          <TextWithLabel label="Guest name" value={guestName} rows={3} />
          <TextWithLabel
            label="Ship"
            value={props.bookingData.shipName}
            rows={2}
          />
          <TextWithLabel label="Cruise departure date" value={departureDate} />
          <TextWithLabel label="Departure port" value={port} rows={2} />
          <TextWithLabel label="Deck number" value={deckNr} />
          <TextWithLabel label="Cabin number" value={cabinNr} rows={2} />
        </View>
      </View>
      <View
        style={{
          paddingVertical: "6px",
          backgroundColor: "#fee100",
          textAlign: "center",
        }}
      >
        <Text
          style={{
            textTransform: "uppercase",
            color: "#092a5e",
            fontSize: "9px",
            fontWeight: "bold",
          }}
        >
          Staple or tape here
        </Text>
        <CircledLetter letter={props.stepLetter} />
      </View>
    </View>
  );
};

const CircledLetter = (props) => (
  <View
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "2px",
    }}
  >
    <Text
      style={{
        width: "18px",
        height: "18px",
        border: "1px solid #092a5e",
        borderRadius: "9999px",
        fontSize: "14px",
        fontWeight: "bold",
        paddingTop: "1px",
      }}
    >
      {props.letter}
    </Text>
  </View>
);

const TextWithLabel = (props) => {
  return (
    <View
      style={{ backgroundColor: "white", padding: "4px", marginBottom: "6px" }}
    >
      <Text
        style={{
          textTransform: "uppercase",
          fontSize: "8px",
          fontWeight: "bold",
        }}
      >
        {props.label}:
      </Text>
      <Text
        style={{
          marginTop: "2px",
          fontSize: props.fontSize || "12px",
          height: (props.rows || 1) * 14 + "px",
        }}
      >
        {props.value}
      </Text>
    </View>
  );
};

const FoldLabelVertical = (props) => {
  const noCutStyle = {
    position: "absolute",
    backgroundColor: "white",
    fontSize: "6px",
    textTransform: "uppercase",
    color: "#092a5e",
    fontWeight: "bold",
    top: "-26px",
    left: "3px",
    transform: "rotate(90deg)",
    letterSpacing: "0.4px",
  };

  return (
    <View
      style={{
        position: "relative",
        height: 0,
      }}
    >
      <Text style={{ ...noCutStyle }}>fold - do not cut</Text>
    </View>
  );
};

const Instructions = () => (
  <View style={{ paddingHorizontal: "14px", textAlign: "left" }}>
    <Text
      style={{
        textTransform: "uppercase",
        fontSize: "18px",
        fontWeight: "bold",
        color: "#092a5e",
      }}
    >
      Luggage tag instructions
    </Text>
    <View style={{ marginTop: "8px", fontSize: "10px" }}>
      <ListItem>
        <Text>
          Be sure to attach any personal ID, such as a name tag to each piece of
          luggage before you leave home.
        </Text>
      </ListItem>

      <ListItem>
        <Text>
          Do not pack valuables or your boarding documents in your checked
          luggage.
        </Text>
      </ListItem>

      <ListItem>
        <Text>
          Make as many copies of this tag as you require, preferably printed in
          colour.
        </Text>
      </ListItem>

      <ListItem>
        <Text>
          Fold (do not cut) along the dotted lines with the luggage tag print
          facing out (you should be able to read the ship name and see the
          company logo).
        </Text>
      </ListItem>

      <ListItem>
        <Text>
          After folding is completed, staple twice or tape the tag around the
          luggage handle.
        </Text>
      </ListItem>
    </View>
  </View>
);

const ListItem = (props) => (
  <View
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      marginBottom: "8px",
    }}
  >
    <View
      style={{
        marginTop: "3px",
        marginRight: "4px",
        width: "5px",
        height: "5px",
        backgroundColor: "#092a5e",
        flexShrink: 0,
      }}
    />
    <View style={{ flexShrink: 1, paddingRight: "8px" }}>{props.children}</View>
  </View>
);
