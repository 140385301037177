type ElementTypes =
  | "button"
  | "label"
  | "svg-icon"
  | "img"
  | "container"
  | "modal"
  | "select"
  | "text";

export const buildTestId = (type: ElementTypes, name: string): string =>
  `${type}-${name.toLocaleLowerCase().split(" ").join("-")}`;
