import { FC } from "react";

type QuestionsAndAnswersProps = {
  questions: any;
  answers: any;
};

const QuestionsAndAnswers: FC<QuestionsAndAnswersProps> = ({
  questions,
  answers,
}) => {
  const propQuestions = questions || [];

  const nrQuestions = propQuestions.length;
  const showInColumns = nrQuestions > 6;

  let displayQuestions = [propQuestions];
  if (showInColumns) {
    displayQuestions = [
      propQuestions.slice(0, nrQuestions / 2),
      propQuestions.slice(nrQuestions / 2),
    ];
  }

  return (
    <div className="xl:flex gap-4">
      {displayQuestions.map((questions, index) => (
        <div key={index} className={`${showInColumns ? "xl:w-1/2" : "w-full"}`}>
          {questions.map((question: any) => (
            <div className="mt-4 text-lg" key={question.id}>
              <div className="text-grey-darker">{question.text}</div>
              <div>
                {Array.isArray(answers[question.id])
                  ? answers[question.id].join(", ")
                  : answers[question.id]}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default QuestionsAndAnswers;
