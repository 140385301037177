import { FC, ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { LocationDescriptor } from "history";
import useAppSelector from "hooks/useAppSelector";
import {
  updateBookings,
  updateMusementBookings,
  updateMxpBookings,
  updateOpenRefunds,
} from "actions/cartActions";
import CenteredImageContainer from "components/CenteredImageContainer";
import SvgIcon from "components/atoms/SvgIcon";
import Modal from "components/modals/Modal/Modal";
import ModalFooter from "components/modals/ModalFooter";
import { usePostRefundMusement } from "modules/musement/hooks/usePostRefundMusement";
import Price from "components/Price/Price";
import { useUpdateCart } from "actions/useUpdateCart";
import { IExcursionPassenger } from "interfaces/Common/IPassenger";
import { usePostAgencyRefundMusement } from "modules/musement/hooks/usePostAgencyRefundMusement";

type Props = {
  title: string;
  imageReference: string;
  linkTo?: string | LocationDescriptor;
  children: ReactNode;
  tooltipText: string;
  tooltipId: string | string[];
  transactionUuid?: string;
  totalPrice: number;
  passengers: IExcursionPassenger[];
  isBookedByAgency: boolean;
  itemUuid: string;
  multipleItemUuids?: string;
  itemId: string;
  multipleItemIds?: string;
};
const CartElementPaidActivityMusement: FC<Props> = ({
  title,
  imageReference,
  linkTo,
  tooltipText,
  tooltipId,
  transactionUuid,
  itemUuid,
  totalPrice,
  passengers,
  children,
  multipleItemUuids,
  isBookedByAgency,
  itemId,
  multipleItemIds,
}) => {
  const [showRefundModal, setShowRefundModal] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const { deletePostRefundMusement, loading: paxRefundLoading } =
    usePostRefundMusement();
  const { postAgencyRefundMusement, loading: agencyRefundLoading } =
    usePostAgencyRefundMusement();
  const { updateCart } = useUpdateCart();

  const agencySessionType = useAppSelector(
    (state) => state?.agency?.sessionType
  );

  const isLoggedAsAgency = agencySessionType === "SingleSignOn";

  const processRefund = async () => {
    setShowRefundModal(false);
    setShowConfirmationModal(false);

    if (multipleItemUuids) {
      await deletePostRefundMusement({
        transactionId: transactionUuid,
        itemUuid: multipleItemUuids,
      });
    } else {
      await deletePostRefundMusement({
        transactionId: transactionUuid,
        itemUuid,
      });
    }

    await updateBookings();
    await updateMusementBookings();
    await updateMxpBookings();
    await updateOpenRefunds();
    await updateCart();
  };

  const processRefundAgency = async () => {
    setShowRefundModal(false);
    setShowConfirmationModal(false);

    if (multipleItemIds) {
      await postAgencyRefundMusement({
        itemId: multipleItemIds,
      });
    } else {
      await postAgencyRefundMusement({
        itemId,
      });
    }

    await updateBookings();
    await updateMusementBookings();
    await updateMxpBookings();
    await updateOpenRefunds();
    await updateCart();
  };

  const handleRefundClick = () => {
    if (isLoggedAsAgency) {
      processRefundAgency();
    } else {
      setShowRefundModal(false);
      setShowConfirmationModal(true);
    }
  };

  const isRefundDisabled = () =>
    (isBookedByAgency && !isLoggedAsAgency) ||
    (!isBookedByAgency && isLoggedAsAgency);

  return (
    <div>
      <div className="flex flex-wrap bg-blue-lightest shadow sm:p-4 my-4">
        {/* image */}
        <div className="w-full sm:w-1/2 md:w-2/5 xl:w-1/3">
          <CenteredImageContainer alt={title + " cover"} src={imageReference} />
        </div>

        {/* rest of card */}
        <div className="sm:flex sm:flex-col px-4 py-2 sm:p-0 w-full sm:w-1/2 md:w-3/5 xl:w-2/3 sm:pl-4 mt-2 sm:mt-0 relative">
          {/* Cancel the booking - show refund modal */}
          <span
            className="text-blue cursor-pointer absolute top-0 right-0 mr-4 mt-2 sm:m-0"
            onClick={() => {
              setShowRefundModal(true);
            }}
          >
            <span className="hidden md:inline">Cancel</span>{" "}
            <SvgIcon name="x" className="w-3 h-3 inline ml-1" />
          </span>

          {/* title link*/}
          <div className="mr-6 md:mr-16">
            {linkTo ? (
              <Link
                className="text-blue underline text-2xl uppercase sm:pr-3 md:pr-0"
                to={linkTo}
              >
                {title}
              </Link>
            ) : (
              <div className="text-blue underline text-2xl uppercase sm:pr-3 md:pr-0">
                {title}
              </div>
            )}
          </div>

          {/* details */}
          <div className="sm:flex-grow">
            <div className="my-2">{children}</div>
          </div>
          {/* cost info */}
          <div className="text-right">
            Already purchased -
            <span
              className="cursor-pointer ml-2 text-blue border-dotted border-b border-blue"
              data-tip={tooltipText}
              data-for={tooltipId}
              data-effect="solid"
              data-place="bottom"
              data-multiline={true}
            >
              See cost
            </span>
          </div>

          <ReactTooltip id={tooltipId as string} />
        </div>
      </div>

      {/* Refund modal */}
      <Modal
        show={showRefundModal}
        onClose={() => {
          setShowRefundModal(false);
        }}
        title="Cancel booking"
      >
        <div className="flex flex-grow flex-col">
          <div className="flex-grow bg-white py-4 px-2 sm:px-4">
            <div>
              <div className="font-bold">
                Issue refund for the following passengers:
              </div>

              <div className="bg-blue-lightest px-4 py-2 mt-4">
                <ul className="list-reset my-2 md:flex-grow flex flex-col gap-2">
                  {passengers.map(
                    (passenger: IExcursionPassenger, index: number) => (
                      <li key={index}>
                        {`${passenger.firstName} ${passenger.lastName}`}
                        {isRefundDisabled() && (
                          <p className="inline">
                            {
                              "  - Bookings can only be refunded via the same channel they were booked"
                            }
                          </p>
                        )}
                      </li>
                    )
                  )}
                </ul>
              </div>

              <>
                <Price preText="Refund value" price={totalPrice.toString()} />
              </>
            </div>
          </div>
          <ModalFooter
            onCancel={() => {
              setShowRefundModal(false);
            }}
            actionDisabled={
              isRefundDisabled() || paxRefundLoading || agencyRefundLoading
            }
            actionButtonText={
              paxRefundLoading || agencyRefundLoading
                ? "Please wait..."
                : "Refund booking"
            }
            onActionClick={handleRefundClick}
          />
        </div>
      </Modal>

      {/* Confirmation modal */}
      <Modal
        show={showConfirmationModal}
        onClose={() => {
          setShowConfirmationModal(false);
        }}
        title="Confirmation"
      >
        <div className="flex flex-grow flex-col">
          <div className="flex-grow bg-white py-4 px-2 sm:px-4">
            <div className="text-xl font-bold">
              Are you sure you want to cancel this booking?
            </div>
          </div>

          <ModalFooter
            cancelLabel="No"
            onCancel={() => {
              setShowConfirmationModal(false);
            }}
            actionDisabled={paxRefundLoading}
            actionButtonText={
              paxRefundLoading ? "Please wait..." : "Yes - cancel"
            }
            onActionClick={processRefund}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CartElementPaidActivityMusement;
