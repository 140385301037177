import { useMutation } from "@tanstack/react-query";
import { store } from "WithProviders";
import axios from "axios";
import { getPrebookUrl } from "config/configUtils";
import { IMusementTaxomomyData } from "interfaces/Musement/ITaxonomy";

type Props = {
  activityUuids: string[];
};

const usePostTaxonomies = () => {
  const { mutate, isPending, data } = useMutation<
    IMusementTaxomomyData,
    Error,
    Props
  >({
    mutationKey: ["postTaxonomies"],
    mutationFn: async ({ activityUuids }) => {
      const URL = `${getPrebookUrl()}/api/taxonomies/search`;

      const response = await axios.post(
        URL,
        { activityUuids },
        {
          headers: {
            SessionId: store.getState().authentication.sessionId,
          },
        }
      );

      return response.data;
    },
    onError: (error) => {
      console.error(`Error executing API call "${URL}": ${error}`);
      return {};
    },
  });

  return {
    fetchTaxonomies: mutate,
    taxonomies: data,
    taxonomiesIsLoading: isPending,
  };
};

export default usePostTaxonomies;
