import { Link } from "react-router-dom";
import CenteredImageContainer from "../CenteredImageContainer";
import { getResourceImageUrl } from "../../actions/networkActions";
import Price from "../Price/Price";
import { LocationDescriptor } from "history";
import { FC } from "react";
import { buildTestId } from "utils/buildTestId";

type Props = {
  name: string;
  imageReference: string;
  imageCategory?: string;
  linkTo?: string | LocationDescriptor;
  pricePreText: string;
  price: string;
  children: any;
  dataTest?: string;
  isOldImageSolution?: boolean;
};

const CartElementCheckout: FC<Props> = ({
  name,
  imageReference,
  imageCategory,
  linkTo,
  pricePreText,
  price,
  children,
  dataTest,
  isOldImageSolution = true,
}) => {
  return (
    <div
      className="flex flex-wrap bg-white border border-blue shadow my-6"
      data-testid={buildTestId("container", `cartElementCheckout ${dataTest}`)}
    >
      {/* image */}
      <div className="w-full sm:w-1/2 md:w-1/3 sm:p-4">
        <CenteredImageContainer
          alt={name + " cover"}
          src={
            isOldImageSolution
              ? getResourceImageUrl(imageReference, {
                  width: 400,
                  category: imageCategory,
                })
              : imageReference
          }
        />
      </div>

      {/* rest of card */}
      <div className="flex flex-col p-4 w-full sm:w-1/2 md:w-2/3">
        {/*Link and card content*/}
        <div className="flex-grow">
          {linkTo ? (
            <Link
              className="text-blue underline text-2xl uppercase"
              to={linkTo}
            >
              {name}
            </Link>
          ) : (
            <div className="text-blue underline text-2xl uppercase">{name}</div>
          )}

          <div className="mt-2">{children}</div>
        </div>

        {/* cost info */}
        <div className="text-right mt-2">
          <Price
            classes="sm:leading-negative"
            preText={pricePreText}
            price={price}
          />
        </div>
      </div>
    </div>
  );
};

export default CartElementCheckout;
