import { FC } from "react";
import CartElementConfirmation from "../CartElementConfirmation";
import { formatCartElementDate } from "utils/dateTimeUtils";

type Props = {
  entry: any;
};

const CartElementTacConfirmation: FC<Props> = (props) => {
  const entry = props.entry;
  const nrGuests = entry.nrAdults + entry.nrChildren;

  return (
    <CartElementConfirmation
      title={entry.name}
      day={`On Day ${entry.dayNr} (${formatCartElementDate(entry.date)})`}
      time={`Time ${entry.time}`}
      people={`${nrGuests} ${nrGuests > 1 ? "people" : "person"}`}
      price={entry.price}
    />
  );
};

export default CartElementTacConfirmation;
