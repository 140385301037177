export const getTravelTileText = (
  status: string,
  isOpen: boolean,
  hasPassed: boolean
) => {
  if (hasPassed) {
    return status === "COMPLETE"
      ? "Complete."
      : "It's almost time to sail, this travel information can no longer be submitted.";
  }

  if (isOpen) {
    return status === "COMPLETE"
      ? "Complete. You can update this up to the day before departure."
      : "Submit your travel information.";
  }

  return "Available 6 weeks before your cruise";
};

export const getCruisePlannerTileText = (showCruisePlannerTile: boolean) => {
  return showCruisePlannerTile
    ? "Book trips, tours and onboard experiences."
    : "It's almost time to sail. Please make your bookings onboard.";
};

export const getPassportInformationTileText = (
  is3rdParty: boolean,
  status: string,
  isOpen: boolean
) => {
  if (is3rdParty) {
    if (isOpen) {
      return status === "COMPLETE"
        ? "Complete."
        : "Add your passport information here.";
    } else {
      return status === "COMPLETE"
        ? "It's almost time to sail. Your passport information is complete and locked for departure."
        : "It's almost time to sail. Passport information can no longer be submitted.";
    }
  } else {
    if (isOpen) {
      return status === "COMPLETE"
        ? "Complete."
        : "Add your passport information in MMB";
    } else {
      return status === "COMPLETE"
        ? "It's almost time to sail. Your passport information is complete and locked for departure."
        : "It's almost time to sail. Passport information can no longer be submitted.";
    }
  }
};

export const getCovidTileText = (
  status: string,
  isOpen: boolean,
  hasPassed: boolean,
  covidPositive: boolean
) => {
  if (covidPositive) {
    return "Please call the contact centre on 020 3451 2688.";
  }

  if (hasPassed) {
    return status === "COMPLETE"
      ? "It's almost time to sail. Your COVID-19 survey is complete and locked for departure."
      : "It's almost time to sail. Your COVID-19 survey can no longer be submitted.";
  }

  if (isOpen) {
    return status === "COMPLETE"
      ? "Complete."
      : "You'll need to complete this survey before you travel.";
  }
  return "Available 3 days before departure.";
};

export const getBoardingTileText = (
  preTravelStatus: string,
  covidStatus: string,
  covidPositive: boolean,
  isOpen: boolean,
  hasCovidPassed: boolean
) => {
  // Covid survey tile is temporary hidden as deprecated but might return in the future

  // if(covidPositive) {
  //     return "Locked. Please call the contact centre on 020 3451 2688.";
  // }
  //
  // if(hasCovidPassed) {
  //     return preTravelStatus === "COMPLETE" && covidStatus === "COMPLETE" ?
  //         "Click here for your boarding passes." :
  //         "It's almost time to sail. Your boarding passes are now locked for departure.";
  // }
  //
  // if(isOpen) {
  //     if(preTravelStatus === "COMPLETE" && covidStatus === "COMPLETE") {
  //         return "Click here for your boarding passes.";
  //     }
  //
  //     if(preTravelStatus !== "COMPLETE" && covidStatus !== "COMPLETE") {
  //         return "Locked. Please complete your travel information and COVID-19 survey.";
  //     }
  //
  //     return preTravelStatus !== "COMPLETE" ?
  //         "Locked. Please complete your travel information." :
  //         "Locked. Please complete your COVID-19 survey.";
  //
  // }
  //
  // return "Locked. Please complete your travel information and COVID-19 survey.";

  return "Click here for your boarding passes.";
};

export const getLuggageTileText = (showLuggageTile: boolean) =>
  showLuggageTile
    ? "Click here for your luggage labels."
    : "Available two weeks before your cruise.";
