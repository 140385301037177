import useAppSelector from "hooks/useAppSelector";
import { FC } from "react";
import CartElementCheckout from "../CartElementCheckout";
import { formatCartElementDate } from "utils/dateTimeUtils";
import { getDayNr } from "utils/cartUtils";

type Props = {
  excursion: any;
};

const CartExcursionEntryCheckout: FC<Props> = (props) => {
  const excursion = props.excursion;
  const cruiseStartDate = useAppSelector(
    (state) => state.cruises.cruiseStartDate
  );

  return (
    <CartElementCheckout
      name={excursion.excursionName}
      imageReference={excursion.excursionId}
      imageCategory="cruiseExcursion"
      linkTo={`/excursion/${excursion.itineraryId}/${excursion.excursionId}`}
      pricePreText="Excursion cost:"
      price={excursion.price}
    >
      <div className="uppercase">{excursion.portName}</div>
      <div>
        On day {getDayNr(excursion.date, cruiseStartDate)} (
        {formatCartElementDate(excursion.date)})
      </div>
      <ul className="list-reset mt-4 md:flex-grow">
        {excursion.passengers.map(
          (passenger: { firstName: any; lastName: any }, index: number) => (
            <li
              key={index}
            >{`${passenger.firstName} ${passenger.lastName}`}</li>
          )
        )}
      </ul>
    </CartElementCheckout>
  );
};

export default CartExcursionEntryCheckout;
