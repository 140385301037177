import { useMutation } from "@tanstack/react-query";
import {
  AuthenticationAction,
  logOutPassenger,
  logoutAgency,
  onLoginSuccess,
} from "actions/authenticationActions";
import axios from "axios";
import { getHubUrl } from "config/configUtils";
import useAppDispatch from "hooks/useAppDispatch";

import browserHistory from "utils/history";

type LoginParamsProps = {
  token: string;
  isRiverUrl: boolean;
  storeId?: string;
};

type HeadersProp = {
  Accept: string;
  "Content-Type": string;
  SessionId?: string;
};

const headers: HeadersProp = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const useLoginByToken = () => {
  const dispatch = useAppDispatch();

  const { mutateAsync, isPending, error, isSuccess } = useMutation<
    any,
    Error,
    LoginParamsProps
  >({
    mutationFn: async ({ token, isRiverUrl, storeId }: LoginParamsProps) => {
      let request: any = { token };

      if (storeId) {
        request.externalId = storeId;
      }

      const response = await axios.post(
        getHubUrl() + "authentication/loginByToken",
        request,
        { headers }
      );

      return {
        data: response.data,
        isRiverUrl,
      };
    },
    onSuccess: async ({ data, isRiverUrl }) => {
      if (data.sessionType === "SingleSignOn") {
        logOutPassenger();
        dispatch<AuthenticationAction>({
          type: "SET_AGENCY_AUTHENTICATION",
          authentication: data,
        });
        browserHistory.push("/login");
      } else if (data.sessionType === "Passenger") {
        logoutAgency();
        await dispatch(onLoginSuccess(data, isRiverUrl));
        browserHistory.push("/dashboard");
      }
    },
    onError: (error) => {
      console.error("Mutation error:", error);
      dispatch({
        type: "SET_ERROR",
        message: error?.message ?? "Unknown error occurred",
      });
    },
  });

  const postLoginByToken = async (params: LoginParamsProps) => {
    try {
      await mutateAsync(params);
    } catch (error) {
      // This catch is for any unhandled errors in mutationFn
      console.error("Failed to post login by passenger:", error);
      // You might want to handle this error further or rethrow it
    }
  };

  return {
    postLoginByToken,
    postLoginByTokenLoading: isPending,
    postLoginByTokenError: error,
    postLoginByTokenIsSuccess: isSuccess,
  };
};
