import { FC } from "react";
import { buildTestId } from "utils/buildTestId";
import { imagePath } from "utils/imageUtils";

export type Props = {
  alt: string;
  src: string | null;
  className: string;
};

const ImageWithFallback: FC<Props> = ({ alt, src, className, ...rest }) => {
  return (
    <img
      {...rest}
      alt={alt}
      src={src ?? undefined}
      className={className}
      data-testid={buildTestId("img", alt)}
      onError={(e) => {
        (e.target as HTMLImageElement).onerror = null;
        (e.target as HTMLImageElement).src = imagePath("image_coming_soon.jpg");
      }}
    />
  );
};

export default ImageWithFallback;
