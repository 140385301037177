import axios from "axios";
import { getPrebookUrl } from "config/configUtils";
import useAppSelector from "hooks/useAppSelector";
import { IItineraryExcursion } from "interfaces/Musement/IItineraryExcursion";
import { useCallback, useState } from "react";

type Props = {
  activityId: string;
};

export const useGetExcursionDetails = () => {
  const sessionId = useAppSelector((state) => state.authentication.sessionId);

  const [data, setData] = useState<IItineraryExcursion>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const getExcursionDetails = useCallback(
    async ({ activityId }: Props) => {
      setLoading(true);
      const URL = `${getPrebookUrl()}/api/activities/${activityId}`;

      const response = await axios
        .get(URL, {
          headers: {
            SessionId: sessionId,
          },
        })
        .catch((error) => {
          setError(error);
        });

      setData(response?.data);
      setLoading(false);
    },
    [sessionId]
  );

  return { getExcursionDetails, data, loading, error };
};
