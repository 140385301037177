import { useUpdateCart } from "actions/useUpdateCart";
import axios from "axios";
import { getOswUrl, getShipCodeByName } from "config/configUtils";
import useAppSelector from "hooks/useAppSelector";
import { useCallback, useState } from "react";
import { getCruisesInfo } from "utils/cruiseUtils";

type Props = {
  appointmentId: number;
};

export const useDeleteFromPlanner = () => {
  const sessionId = useAppSelector((state) => state.authentication.sessionId);
  const cruiseDetails = useAppSelector((state) => state.cruises.cruiseDetails);
  const cruisesInfo = getCruisesInfo(cruiseDetails);
  const { updateCart } = useUpdateCart();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const deleteFromPlanner = useCallback(
    async ({ appointmentId }: Props) => {
      setLoading(true);
      const URL = `${getOswUrl()}/api/ships/${getShipCodeByName({
        shipName: cruisesInfo.shipName,
      })}/planner/cancel/${appointmentId}`;

      const response = await axios
        .delete(URL, {
          headers: {
            SessionId: sessionId,
          },
        })
        .catch((error) => {
          setError(error);
        });

      setData(response?.data);
      await updateCart();
      setLoading(false);
    },
    [cruisesInfo.shipName, sessionId, updateCart]
  );

  return { deleteFromPlanner, data, loading, error };
};
