import { FC, useState } from "react";
import { postToHub } from "../../actions/networkActions";
import browserHistory from "../../utils/history";
import FormLockBase from "../../components/passengerInformation/FormLockBase";
import useSubmitNoCC from "../../hooks/useSubmitNoCC";
import { RouteComponentProps } from "react-router-dom";

export interface Props
  extends RouteComponentProps<{
    passengerId: string;
    token: string;
  }> {}

const GuestLockForm: FC<Props> = ({ match }) => {
  const [lock, setLock] = useState<boolean | null>(null);
  const [error, setError] = useState<string>("");
  const { loading, ccError } = useSubmitNoCC(
    match.params.passengerId,
    match.params.token
  );

  const onSubmit = async () => {
    if (lock === null) {
      setError("Please select one of the options");
      return;
    }

    const passengerId = match.params.passengerId;
    const token = match.params.token;

    const data = {
      secure: lock,
    };

    const result = await postToHub(
      `precruise/guest/secure/${token}/${lock ? "true" : "false"}`,
      data
    );

    if (result === "") {
      browserHistory.push(`/precruise/guest/${passengerId}/review/${token}`);
    } else {
      setError(
        "There has been an error with submitting the data. Please try again."
      );
    }
  };

  if (loading) {
    return (
      <div className="container mx-auto">
        <div className="mt-4">Loading...</div>
      </div>
    );
  }

  if (ccError) {
    return (
      <div className="container mx-auto">
        <div className="mt-4">{ccError}</div>
      </div>
    );
  }

  return (
    <div className="container mx-auto">
      <FormLockBase lock={lock} setLock={setLock} />

      {error && <div className="mt-4 text-red-tui">{error}</div>}

      <div className="mt-6 mb-8 xl:mb-12">
        <button
          className={`w-mobile action-button ${
            lock !== null ? "action-button-enabled" : "action-button-disabled"
          }`}
          disabled={lock === null}
          onClick={onSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default GuestLockForm;
