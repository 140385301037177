import { FC } from "react";

const SoldOutBox: FC = () => {
  return (
    <div className="w-full text-grey-darkest bg-grey-lighter p-2">
      <div className="uppercase text-2xl mb-2">Sold out online</div>
      <div>
        Contact the onboard team during your cruise for latest availability
      </div>
    </div>
  );
};

export default SoldOutBox;
