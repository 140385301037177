import { FC } from "react";

type Props = {
  active: boolean;
  hideOnMobile: boolean;
  text: string;
  nr: string;
};

const CheckoutSubheaderEntry: FC<Props> = ({
  active,
  nr,
  hideOnMobile,
  text,
}) => {
  return (
    <div
      className={
        "flex-shrink-0 inline-block  mr-4" +
        (active ? " opacity-100" : " opacity-50") +
        (hideOnMobile ? " hidden sm:block" : "")
      }
    >
      <div className="opacity-100 flex items-center">
        <div className="bg-white text-blue w-6 h-6 mr-2 flex items-center justify-center">
          {nr}
        </div>
        <div className="whitespace-no-wrap">{text}</div>
      </div>
    </div>
  );
};

export default CheckoutSubheaderEntry;
