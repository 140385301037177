import { Link } from "react-router-dom";
import Price from "./Price/Price";
import { getResourceImageUrl } from "../actions/networkActions";
import ImageWithFallback from "./atoms/ImageWithFallback";
import { FC } from "react";

type Props = {
  recommendation: any;
};

const RecommendationEntry: FC<Props> = ({ recommendation }) => {
  const { excursion, itinerary } = recommendation;

  return (
    <div>
      <div className="w-rec bg-white mx-auto">
        <div>
          <ImageWithFallback
            className="w-rec h-rec cover-fit"
            alt={excursion.name.defaultValue}
            src={getResourceImageUrl(excursion.id, {
              width: 250,
              category: "cruiseExcursion",
            })}
          />
        </div>
        <div className="p-2">
          <Link
            className="text-blue underline text-lg uppercase"
            to={`/excursion/${itinerary.id}/${excursion.id}`}
          >
            {excursion.name.defaultValue}
          </Link>
          <div className="mt-2 pb-2 text-lg border-b uppercase">
            {excursion.port.name.defaultValue}
          </div>
          <div className="mt-1">
            <Price
              preText="from"
              price={excursion.price.adult}
              postText="pp"
              largePostText={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecommendationEntry;
