import useAppSelector from "hooks/useAppSelector";
import { FC } from "react";
import { formatCartElementDate } from "utils/dateTimeUtils";
import { getDayNr } from "utils/cartUtils";
import CartElementCheckout from "components/cartElements/CartElementCheckout";
import { IMusementActivitiesCartType } from "interfaces/Musement/IExcursionCartType";

type Props = {
  activity: IMusementActivitiesCartType;
};

const CartElementCheckoutMusement: FC<Props> = ({ activity }) => {
  const cruiseStartDate = useAppSelector(
    (state) => state.cruises.cruiseStartDate
  );

  return (
    <CartElementCheckout
      name={activity.product.title}
      imageReference={activity.product.coverImageUrl}
      // linkTo={`/musement/excursion/${activity.itineraryId}/${activity.product.activityUuid}`}
      pricePreText="Excursion cost:"
      price={activity.totalPrice.toString()}
      isOldImageSolution={false}
    >
      <div>
        On day{" "}
        {getDayNr(
          `${activity.product.scheduledDay} ${activity.product.scheduledTime}`,
          cruiseStartDate
        )}{" "}
        ({formatCartElementDate(activity.product?.scheduledDay)})
      </div>
      <ul className="list-reset mt-4 md:flex-grow">
        {activity?.persons?.map(
          (
            passenger: { firstName: string; lastName: string },
            index: number
          ) => (
            <li
              key={index}
            >{`${passenger.firstName} ${passenger.lastName}`}</li>
          )
        )}
      </ul>
    </CartElementCheckout>
  );
};

export default CartElementCheckoutMusement;
