import { mergeAndSortEntries } from "../../utils/cartUtils";
import { FC } from "react";
import CartExcursionEntryConfirmation from "./CartElementsExcursions/CartExcursionEntryConfirmation";
import CartElementTacConfirmation from "./CartElementsTAC/CartElementTacConfirmation";
import CartElementOswConfirmation from "./CartElementsOswTreatments/CartElementOswConfirmation";
import { convertYMDtoDMY, formatCartElementDate } from "utils/dateTimeUtils";
import CartElementEntryConfirmationMusement from "modules/musement/cartElements/CartElementEntryConfirmationMusement";
import { getCombinedMusementEntryByActivityUuid } from "modules/musement/utils/getCombinedMusementEntryByActivityUuid";
import { ICruise } from "interfaces/ReduxState/ICruise";
import CartElementMxpConfirmation from "./mxp/checkout/CartElementMxpConfirmation";

type Props = {
  paidExcursions?: any;
  paidTacEntries?: any;
  cruiseStartDate: ICruise["cruiseStartDate"];
  oswEntries?: any;
  mxpEntries?: any;
  musementEntries?: any;
};

const CartElementsConfirmationCombined: FC<Props> = (props) => {
  const combinedSortedEntries = mergeAndSortEntries(
    props.paidExcursions,
    props.paidTacEntries,
    props.oswEntries,
    props.musementEntries,
    props.mxpEntries,
    props.cruiseStartDate
  );

  return (
    <div className="w-full my-2 text-grey-darker">
      {combinedSortedEntries.map((entry) => {
        if (entry?.excursionId) {
          return (
            <CartExcursionEntryConfirmation
              key={entry.excursionId}
              excursion={entry}
            />
          );
        }

        if (entry?.treatmentId) {
          return (
            <CartElementOswConfirmation
              key={entry.treatmentId}
              title={entry.title}
              day={`On Day ${entry.dayNr} (${formatCartElementDate(
                entry.scheduledDay
              )})`}
              time={`Time ${entry.scheduledTime}`}
              people={entry.fullName}
              price={entry.price}
            />
          );
        }

        if (entry?.VenueId) {
          return (
            <CartElementMxpConfirmation
              key={entry.VenueId}
              title={entry.VenueName}
              day={`On Day ${entry.dayNr} (${formatCartElementDate(
                convertYMDtoDMY(entry.Date)
              )})`}
              time={`Time ${entry.AvailableTime}`}
              nrGuests={entry.numberOfPersons}
              price={entry.totalAmount || ""}
            />
          );
        }

        // Musement entries are comming in group of arrays
        if (entry instanceof Array) {
          const combinedMusementEntryByActivityUuid =
            getCombinedMusementEntryByActivityUuid({ musementEntries: entry });

          return (
            <CartElementEntryConfirmationMusement
              key={`${combinedMusementEntryByActivityUuid.id}-${combinedMusementEntryByActivityUuid.product.scheduledDay}-${combinedMusementEntryByActivityUuid.product.scheduledTime}`}
              title={combinedMusementEntryByActivityUuid.product.title}
              day={`On Day ${
                combinedMusementEntryByActivityUuid.dayNr
              } (${formatCartElementDate(
                combinedMusementEntryByActivityUuid.product.scheduledDay
              )})`}
              people={combinedMusementEntryByActivityUuid.persons}
              price={combinedMusementEntryByActivityUuid.totalPrice.toString()}
            />
          );
        }

        return <CartElementTacConfirmation key={entry.id} entry={entry} />;
      })}
    </div>
  );
};

export default CartElementsConfirmationCombined;
