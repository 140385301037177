import { isShipNameInMxpDiningMVP } from "config/configUtils";
import useAppSelector from "hooks/useAppSelector";
import { getCruisesInfo } from "utils/cruiseUtils";

export const useCheckConfigurationFlags = () => {
  const cruiseDetails = useAppSelector((state) => state.cruises.cruiseDetails);
  const cruisesInfo = getCruisesInfo(cruiseDetails);

  const isMxpDiningEnabledPerShip = isShipNameInMxpDiningMVP({
    shipName: cruisesInfo.shipName,
  });

  return {
    isMxpDiningEnabledPerShip,
  };
};
