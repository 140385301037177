import { useSurveyContext } from "../../../context/SurveyContext";
import { isQuestionShown } from "../../../utils/questionnaireUtils";
import InfoBox from "../InfoBox/InfoBox";
import RadioButton from "../../Buttons/RadioButton/RadioButton";
import Question from "./Question";
import { FC } from "react";

type Props = {
  question: any;
  activePart: any;
  validate: (question: any, answer: any) => void;
};

const RadioQuestion: FC<Props> = ({ question, activePart, validate }) => {
  const {
    survey: { answers, passenger },
    setAnswer,
  } = useSurveyContext();
  const answer = answers[question.id];
  const nestedQuestions =
    answer &&
    question.questions &&
    question.questions[answer]?.filter(isQuestionShown(passenger));

  const isShown = question.part === activePart;

  return (
    <div>
      <div
        className={isShown ? "mt-6" : "hidden"}
        onBlur={() => validate(question, answer)}
      >
        <div className="text-lg text-grey-darker">{question.questionText}</div>

        {question.questionInfo && (
          <InfoBox
            classes="mt-6 md:inline-block"
            infoText={question.questionInfo}
            description={question.infoDescription}
          />
        )}

        <div>
          {question.options.map((option: any) => (
            <div key={option} className="mt-4">
              <RadioButton
                label={option}
                _onChange={() => setAnswer(question.id, option)}
                isSelected={answer === option}
              />
            </div>
          ))}
        </div>

        {question.answersInfo && question.answersInfo[answer] && (
          <InfoBox
            classes="mt-6 md:inline-block"
            infoText={question.answersInfo[answer]}
          />
        )}
      </div>

      {nestedQuestions && (
        <div>
          {nestedQuestions.map((question: any) => (
            <Question
              key={question.id}
              activePart={activePart}
              question={question}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default RadioQuestion;
