import { FC } from "react";
import { imagePath } from "utils/imageUtils";

export type Props = {
  startTime: string;
  imageName?: string;
  dataTest?: string;
};

const ActivityStartTime: FC<Props> = ({
  startTime,
  imageName = "clock.png",
  dataTest,
}) => {
  return (
    <div className="flex items-center">
      <img
        className="w-4 h-4 align-middle mr-2 inline"
        alt="clock icon"
        src={imagePath(imageName)}
      />
      <span data-testid={dataTest} className="align-middle text-grey-darker">
        Start Time: {startTime}
      </span>
    </div>
  );
};

export default ActivityStartTime;
