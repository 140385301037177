import { Link } from "react-router-dom";
import { FC } from "react";
import CenteredImageContainer from "components/CenteredImageContainer";
import ExcursionAttributes from "components/ExcursionAttributes";
import ActivityDuration from "components/molecules/ActivityDuration";
import If from "components/molecules/IF";
import SoldOutBox from "components/molecules/SoldOutBox";
import { useGetExcursionAvailabilityFromStoreByExcursionId } from "../hooks/useGetExcursionAvailabilityFromStoreByExcursionId";
import { useGetExcursionPricesFromStoreByExcursionId } from "../hooks/useGetExcursionPricesFromStoreByExcursionId";
import { IExcursionProduct } from "interfaces/Musement/IExcursionProduct";
import MusementPriceInfoBox from "./MusementPriceInfoBox/MusementPriceInfoBox";
import { useGetExcursionProductsAgeRanges } from "../hooks/useGetExcursionProductsAgeRanges";

type Props = {
  itineraryId: any;
  uuid: any;
  title: any;
  duration_range: { max: string };
  original_retail_price: { value: number };
  cover_image_url: string;
  taxonomies: any[];
  products: IExcursionProduct[];
};

const MusementExcursionEntry: FC<Props> = ({
  itineraryId,
  uuid,
  title,
  duration_range,
  cover_image_url,
  taxonomies,
  products,
}) => {
  const { totalAvailability } =
    useGetExcursionAvailabilityFromStoreByExcursionId({
      products,
    });

  const { calculatedPrices } = useGetExcursionPricesFromStoreByExcursionId({
    products,
  });

  const { childAgeRange } = useGetExcursionProductsAgeRanges({
    products,
  });

  const excursionLink = `/musement/excursion/${itineraryId}/${uuid}`;
  const priceBoxDivClasses = `${
    totalAvailability > 0 ? "w-1/2 justify-end" : "w-full justify-between"
  } sm:w-1/3 xl:w-1/4 flex flex-col self-end py-4 px-2 sm:px-4 h-full`;

  return (
    <div className="bg-white border border-blue flex flex-wrap md:min-h-13em">
      <div className="w-full sm:w-1/3 p-2 sm:p-4">
        <CenteredImageContainer alt="excursion preview" src={cover_image_url} />
      </div>

      <div className="w-1/2 sm:w-1/3 xl:flex-grow px-2 sm:px-0 pb-4 sm:pt-4 flex flex-col justify-between">
        <Link
          className="text-blue underline text-2xl uppercase"
          to={excursionLink}
        >
          {title}
        </Link>

        {taxonomies && (
          <ExcursionAttributes
            attributes={taxonomies?.map((taxonomy: any) => taxonomy?.label)}
          />
        )}

        {duration_range?.max && (
          <ActivityDuration duration={duration_range.max} isISO8601 />
        )}
      </div>

      <div className={priceBoxDivClasses}>
        <If test={totalAvailability === 0 || !totalAvailability}>
          <SoldOutBox />
          <Link
            className="block w-full action-button action-button-enabled mt-4"
            to={excursionLink}
          >
            More details
          </Link>
        </If>

        <If test={totalAvailability > 0}>
          <MusementPriceInfoBox
            classes="w-full text-blue-dark float-right"
            adultPrice={calculatedPrices?.adult}
            childPrice={calculatedPrices?.children?.toString()}
            childAgeRange={childAgeRange}
            buttonText="More details"
            buttonIsLink={true}
            linkTo={excursionLink}
            currentAvailability={totalAvailability}
          />
        </If>
      </div>
    </div>
  );
};

export default MusementExcursionEntry;
