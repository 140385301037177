import { FC } from "react";
import Icons from "resources/images/icons.svg";
import { buildTestId } from "utils/buildTestId";

export type Props = {
  className: string;
  name: string;
};

const SvgIcon: FC<Props> = ({ name, className }) => {
  return (
    <svg
      data-testid={buildTestId("svg-icon", name)}
      className={`fill-current ${className}`}
    >
      <use xlinkHref={`${Icons}#icon-${name}`} />
    </svg>
  );
};

export default SvgIcon;
