import { FC } from "react";
import { useSurveyContext } from "../../../context/SurveyContext";
import CalendarPicker from "components/CalendarPicker";
import { IQuestion } from "interfaces/Travelnformation/IQuestion";

interface Props {
  question: IQuestion;
  activePart: number;
}

const DatePickerQuestion: FC<Props> = ({ question, activePart }) => {
  const {
    survey: { answers },
    setAnswer,
  } = useSurveyContext();
  const answer = answers[question.id] || "";

  const isShown = question.part === activePart;

  return (
    <div className={"w-full sm:w-1/2 xl:w-1/3"}>
      <div className="mb-1">
        <label className={"input-label"}>{question.questionText}</label>
      </div>

      <div className={isShown ? "" : "hidden"}>
        <CalendarPicker
          id="question-form-datepicker"
          onChange={(value: unknown) => setAnswer(question.id, value)}
          className="input-field text-black mt-1"
          initialValue={answer}
          initialMinDate={question.initialMinDate}
          initialMaxDate={question.initialMaxDate}
        />
      </div>
    </div>
  );
};

export default DatePickerQuestion;
