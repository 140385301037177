import { Link, RouteComponentProps } from "react-router-dom";
import BackLink from "../molecules/BackLink";
import usePreCruisePassenger from "../../hooks/usePreCruisePassenger";
import { FC } from "react";

const ResetPinSuccess: FC<
  RouteComponentProps<{
    passengerId: string;
  }>
> = ({ match }) => {
  const passengerId = match.params.passengerId;
  const passenger = usePreCruisePassenger(passengerId);
  const passengerName = `${passenger.firstName} ${passenger.lastName}`;

  return (
    <div className="container mx-auto">
      <div className="mt-6">
        <BackLink
          to={"/dashboard"}
          text="Back to pre-cruise information dashboard"
        />
      </div>

      <h1>Pre-cruise information PIN reset for {passengerName}</h1>
      <div className="text-lg text-grey-darker mt-6">
        <p>Your Pre-cruise information PIN has been successfully reset.</p>
        <p>You can now view and edit your pre-cruise information.</p>
      </div>

      <Link
        className="block w-mobile action-button action-button-enabled mt-6"
        to={`/precruise/passenger/${passengerId}/unlock`}
      >
        View my info
      </Link>

      <Link
        className="block w-mobile action-button action-button-enabled mt-6 mb-8 xl:mb-12"
        to="/dashboard"
      >
        Back to dashboard
      </Link>
    </div>
  );
};

export default ResetPinSuccess;
