import InfoBox from "components/passengerInformation/InfoBox/InfoBox";
import useAppSelector from "hooks/useAppSelector";
import { FC } from "react";
import { convertDurationPeriodFromMinutes } from "utils/dateTimeUtils";

const CartExpirationTimeWarningMessage: FC = () => {
  const cartExpirationTime = useAppSelector(
    (state) => state.cart.cartExpirationTime
  );

  if (cartExpirationTime === 0) {
    return null;
  }

  const displayTimeLeftValue =
    convertDurationPeriodFromMinutes(cartExpirationTime);

  return (
    <InfoBox
      twBackgroundColorClass="bg-blue-lightest"
      classes="flex w-full flex-row p-2 sm:p-4"
    >
      <div className="flex flex-col grow ml-4">
        <div>
          We'll hold your items for {displayTimeLeftValue}, but your booking
          will only be confirmed once you've paid.
        </div>
        <div>
          If your cart expires, you'll need to select your items again, subject
          to availability.
        </div>
      </div>
    </InfoBox>
  );
};

export default CartExpirationTimeWarningMessage;
