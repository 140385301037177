import { mergeAndSortEntries } from "utils/cartUtils";
import useAppSelector from "../../../hooks/useAppSelector";
import CartExcursionEntryCheckout from "../CartElementsExcursions/CartExcursionEntryCheckout";
import CartElementTacCheckout from "../CartElementsTAC/CartElementTacCheckout";

const CartElementsCheckoutCombined = () => {
  const excursions = useAppSelector((state) => state.cart.excursions);
  const tacEntries = useAppSelector((state) => state.cart.tac);
  const cruiseStartDate = useAppSelector(
    (state) => state.cruises.cruiseStartDate
  );

  const combinedSortedEntries = mergeAndSortEntries(
    excursions,
    tacEntries,
    undefined,
    undefined,
    undefined,
    cruiseStartDate
  );

  return (
    <div className="bg-blue-lightest-25 px-4 py-1">
      {combinedSortedEntries.map((entry) => {
        if (entry.excursionId) {
          return (
            <CartExcursionEntryCheckout
              key={entry.excursionId}
              excursion={entry}
            />
          );
        }

        if (entry.id) {
          return <CartElementTacCheckout key={entry.id} entry={entry} />;
        }

        return null;
      })}
    </div>
  );
};

export default CartElementsCheckoutCombined;
