import { FC, PropsWithChildren } from "react";
import If from "../../molecules/IF";
import SvgIcon from "../../atoms/SvgIcon";
import { buildTestId } from "utils/buildTestId";
import Loader from "../../atoms/Loader";

type Props = PropsWithChildren & {
  show: boolean;
  title: string;
  removeDismissButton?: boolean;
  loading?: boolean;
  onClose: () => void;
};

const Modal: FC<Props> = ({
  show,
  title,
  removeDismissButton,
  loading,
  children,
  onClose,
}) => {
  if (!show) {
    return null;
  }

  return (
    <div
      data-testid={buildTestId("modal", "basic")}
      className="fixed inset-0 z-50 overflow-auto bg-smoke-dark flex items-center"
    >
      <div className="relative bg-white w-full md:max-w-md m-auto min-h-full md:min-h-0 md:h-auto flex-col flex">
        {/* modal header */}
        <div className="bg-blue-lighter px-4 py-2 text-xl flex items-center justify-between">
          <span className="uppercase text-blue-dark">{title}</span>
          <If test={!removeDismissButton}>
            <div
              className="inline text-blue-light cursor-pointer"
              onClick={onClose}
            >
              <SvgIcon name="x" className="w-4 h-4" />
            </div>
          </If>
        </div>

        {/* modal content */}
        {loading ? <Loader /> : children}
      </div>
    </div>
  );
};

export default Modal;
