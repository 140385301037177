import CCInfo from "modules/passengerInformation/common/CCInfo";
import QuestionsAndAnswers from "modules/passengerInformation/common/QuestionsAndAnswers";
import { flattenQuestions } from "modules/passengerInformation/utils/flattenQuestions";
import { sortQuestions } from "modules/passengerInformation/utils/sortQuestions";
import { FC } from "react";
import { Link } from "react-router-dom";
import { classNames } from "utils/miscUtils";

type SectionProps = {
  section: any;
  sectionData: any;
  token: any;
  passengerId: any;
  index: number;
};

const OceanSection: FC<SectionProps> = ({
  sectionData,
  section,
  token,
  index,
  passengerId,
}) => {
  const answers = sectionData?.answers || {};

  const questions = sortQuestions(
    flattenQuestions(sectionData.questions)
  ).filter((question) => answers[question.id]);

  const editUrl = token
    ? `/precruise/guest/${passengerId}/form/${token}`
    : `/precruise/passenger/${passengerId}/form`;

  return (
    <div
      className={classNames(
        "relative bg-white p-4 shadow",
        index !== 0 ? "mt-6" : ""
      )}
      key={section.hubId}
    >
      <h2 className="mr-8">{section.reviewLabel}</h2>
      <Link
        className="absolute top-0 right-0 mt-6 mr-4 text-blue underline"
        to={{ pathname: editUrl, state: { section: index } }}
      >
        Edit
      </Link>
      <div>
        {sectionData.cardInfo?.cardNumber && (
          <CCInfo cardInfo={sectionData.cardInfo} />
        )}
        {!sectionData.cardInfo?.cardNumber && (
          <QuestionsAndAnswers questions={questions} answers={answers} />
        )}
      </div>
    </div>
  );
};

export default OceanSection;
