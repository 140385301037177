import { PropsWithChildren, FC } from "react";

type Props = PropsWithChildren & {
  label?: string;
  disabledReason?: string;
  value?: any;
  disabled?: boolean;
  handleCheckboxChange?: () => void;
  checked?: boolean;
};

const Checkbox: FC<Props> = ({
  disabledReason,
  label,
  disabled,
  value,
  handleCheckboxChange,
  checked,
  children,
}) => {
  const getLabelContent = () => {
    if (children) {
      return children;
    }
    return (
      <span>
        {label}
        {disabledReason ? " - " + disabledReason : ""}
      </span>
    );
  };

  return (
    <div className="tui-checkbox">
      <label
        className={
          "flex inline-flex items-center select-none" +
          (disabled ? " disabled" : "")
        }
      >
        <input
          type="checkbox"
          value={value || label}
          onChange={handleCheckboxChange}
          checked={checked}
          disabled={disabled}
        />
        <span className="checkbox-indicator flex-shrink-0"></span>
        <div className="inline">{getLabelContent()}</div>
      </label>
    </div>
  );
};

export default Checkbox;
