import { IFeatureFlag } from "interfaces/FeatureFlags/IFeatureFlags";
import { AppThunk } from "../configureStore";
import { Action } from "redux";

export interface AppConfigAction extends Action {
  isRiverUrl?: boolean;
  featureFlags?: IFeatureFlag[];
}

export const setIsRiverUrl =
  (isRiverUrl: boolean): AppThunk =>
  (dispatch) => {
    dispatch<AppConfigAction>({
      type: "SET_IS_RIVER_URL",
      isRiverUrl,
    });
  };
