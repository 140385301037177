import RadioButton from "components/Buttons/RadioButton/RadioButton";

type RadioButtonsListFilterProps = {
  options: any;
  selectedRadio: any;
  handleRadioChange: (option: any) => void;
};

const RadioButtonsListFilter = ({
  options,
  handleRadioChange,
  selectedRadio,
}: RadioButtonsListFilterProps) => {
  return (
    <div>
      <ul className="list-reset flex flex-wrap">
        {options.map((option: any, index: number) => (
          <li className="my-2 h-6 w-1/2 sm:w-1/4" key={index}>
            <RadioButton
              label={option.label}
              _onChange={() => handleRadioChange(option)}
              isSelected={selectedRadio === option.label}
              isDisabled={option.disabled}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RadioButtonsListFilter;
