import { FC, MouseEvent } from "react";
import Price from "./Price/Price";
import ActionButton from "./Buttons/ActionButton";
import useAppSelector from "../hooks/useAppSelector";

type Props = {
  selectedItemsSum?: number;
  inProgress?: boolean;
  onClick: (e: MouseEvent) => void;
};

const CartSumInfo: FC<Props> = ({ selectedItemsSum, inProgress, onClick }) => {
  const totalSum = useAppSelector((state) => state.cart.totalPrice);
  const isCartEmpty = useAppSelector((state) => state.cart.nrExcursions === 0);

  return (
    <div className="text-right my-8">
      <Price
        largePreText={true}
        preText="Total cost for your planner"
        price={
          selectedItemsSum ? selectedItemsSum.toString() : totalSum.toString()
        }
      />

      <ActionButton
        onClick={onClick}
        disabled={isCartEmpty}
        classes="w-full mt-3 sm:px-6 sm:w-auto"
        buttonText={inProgress ? "Please wait..." : "Continue"}
      />
    </div>
  );
};

export default CartSumInfo;
