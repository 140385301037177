import CartElementItinerary from "../CartElementItinerary";
import { removeExcursionFromCart } from "../../../actions/cartActions";
import { FC } from "react";
import { useUpdateCart } from "actions/useUpdateCart";

type Props = {
  excursion: any;
};

const CartExcursionEntryItinerary: FC<Props> = (props) => {
  const excursion = props.excursion;
  const { updateCart } = useUpdateCart();

  return (
    <CartElementItinerary
      name={excursion.excursionName}
      imageReference={excursion.excursionId}
      imageCategory="cruiseExcursion"
      remove={async () => {
        await removeExcursionFromCart(
          excursion.itineraryId,
          excursion.excursionId
        );
        await updateCart();
      }}
      linkTo={`/excursion/${excursion.itineraryId}/${excursion.excursionId}`}
      changePassengersLinkText="Change passengers"
      changePassengersLinkTo={`/excursion/${excursion.itineraryId}/${excursion.excursionId}/passengers`}
      dataTest="cartExcursionEntryItinerary"
    >
      <ul className="list-reset my-2 xl:flex-grow">
        {excursion.passengers.map(
          (passenger: { firstName: any; lastName: any }, index: number) => (
            <li
              key={index}
            >{`${passenger.firstName} ${passenger.lastName}`}</li>
          )
        )}
      </ul>
    </CartElementItinerary>
  );
};

export default CartExcursionEntryItinerary;
