import PreForm from "../../components/passengerInformation/PreForm";
import useGet from "../../hooks/useGet";
import browserHistory from "../../utils/history";
import { useRouteMatch } from "react-router-dom";

interface IMatchState {
  token: string;
}

const GuestOverview = () => {
  const match = useRouteMatch<IMatchState>();

  const token = match.params.token;
  const passenger = useGet(`precruise/guest/${token}`, false);

  if (!passenger) {
    return (
      <div className="container mx-auto">
        <div>Loading...</div>
      </div>
    );
  }

  if (passenger.travelInfoStatus === "COMPLETE") {
    browserHistory.push(
      `/precruise/guest/${passenger.passengerId}/review/${token}`
    );
    return null;
  }

  return (
    <div className="container mx-auto">
      <h1>
        Travel information for {passenger.firstName} {passenger.lastName}
      </h1>

      <PreForm passenger={passenger} token={token} />
    </div>
  );
};

export default GuestOverview;
