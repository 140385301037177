import { useRouteMatch } from "react-router-dom";
import useGet from "../../hooks/useGet";
import { getSections } from "../../utils/questionnaireUtils";
import BackLink from "../../components/molecules/BackLink";
import usePreCruisePassenger from "../../hooks/usePreCruisePassenger";
import browserHistory from "../../utils/history";
import useAppSelector from "hooks/useAppSelector";
import RiverSection from "./RiverFlow/RiverSection";
import OceanSection from "./Ocean/OceanSection";
import LockInfo from "./common/LockInfo";

interface IMatchState {
  passengerId: string;
  token: string;
}

const PassengerReview = () => {
  const match = useRouteMatch<IMatchState>();
  const isRiverUrl = useAppSelector((state) => state.appConfig.isRiverUrl);

  const passengerId = match.params.passengerId;
  const token = match.params.token;
  const passenger = usePreCruisePassenger(passengerId, token);
  const passengerName = `${passenger.firstName} ${passenger.lastName}`;
  const questionsUrl = token
    ? `precruise/guest/questions/travelInfo/${token}`
    : `precruise/questions/travelInfo/${passengerId}`;

  const data = useGet(
    {
      path: questionsUrl,
      logoutOn403: false,
    },
    false
  );

  if (!data) {
    return <div className="container mx-auto mt-6">Loading...</div>;
  }

  if (data.error) {
    if (!token && data.error.response?.status === 403) {
      browserHistory.push(`/precruise/passenger/${passengerId}/unlock`);
    } else {
      return <div>An unexpected error has happened please try again.</div>;
    }
  }

  return (
    <div>
      <div className="container mx-auto">
        {!token && (
          <div className="mt-6">
            <BackLink
              to={"/dashboard"}
              text="Back to pre-cruise information dashboard"
            />
          </div>
        )}

        <h1>Pre-cruise information for {passengerName}</h1>
      </div>

      <div className="bg-blue-lightest shadow mb-12 mt-6">
        <div className="container mx-auto py-6">
          {passenger.isTravelInfoSecured && !token && (
            <LockInfo passengerId={passengerId} />
          )}
          {getSections(isRiverUrl).map((section, index) => {
            if (isRiverUrl) {
              return (
                <RiverSection
                  key={section.hubId}
                  section={section}
                  index={index}
                  passengerId={passengerId}
                  data={data}
                  token={token}
                />
              );
            }

            return (
              <OceanSection
                key={section.hubId}
                section={section}
                index={index}
                passengerId={passengerId}
                sectionData={data[section.hubId]}
                token={token}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PassengerReview;
