import { useState, useEffect } from "react";
import { postToHub } from "../actions/networkActions";
import useAppSelector from "./useAppSelector";

const useSubmitNoCC = (passengerId: string, token: string | null = null) => {
  const [loading, setLoading] = useState(true);
  const [ccError, setCcError] = useState("");
  const isRiverUrl = useAppSelector((state) => state.appConfig.isRiverUrl);

  useEffect(() => {
    if (!passengerId) {
      return;
    }

    if (isRiverUrl) {
      setLoading(false);
      return;
    }

    let isCancelled = false;

    const postData = async () => {
      const submitUrl = token
        ? `precruise/guest/submitAnswers/${token}`
        : "precruise/questions/submitAnswers";
      const body = {
        passengerId,
        category: "cardDetails",
        answers: {
          "cc-1": "System not ready",
        },
      };

      const result = await postToHub(submitUrl, body);

      if (result !== "") {
        setCcError("Unexpected error happened please reload the page");
        setLoading(false);
        return;
      }
      if (!isCancelled) {
        setLoading(false);
      }
    };

    postData();

    return () => {
      isCancelled = true;
    };
  }, [isRiverUrl, passengerId, token]);

  return { loading, ccError };
};

export default useSubmitNoCC;
