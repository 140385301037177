import {
  IExcursionProduct,
  ILanguageCapacity,
  IPaxCapacity,
  ISharedCapacity,
} from "interfaces/Musement/IExcursionProduct";

type Props = {
  products?: IExcursionProduct[];
};

const UNLIMITED_CAPACITY = 9999;

export const useGetExcursionAvailabilityFromStoreByExcursionId = ({
  products,
}: Props) => {
  let totalAvailability = 0;

  if (products?.length === 0) return { totalAvailability };

  // Check Shared Capacity
  const isTotalAvailabilityShared = products?.every(
    (product: IExcursionProduct) =>
      product?.capacities?.capacity_type === "shared"
  );

  if (isTotalAvailabilityShared) {
    const sharedCapacity = products?.[0]?.capacities as ISharedCapacity;
    totalAvailability = sharedCapacity.remaining;

    return { totalAvailability };
  }

  // Check Pax Capacity
  const isTotalAvailabilityPax = products?.every(
    (product: IExcursionProduct) => product?.capacities?.capacity_type === "pax"
  );

  if (isTotalAvailabilityPax && products && products?.length > 0) {
    totalAvailability = products.reduce(
      (total: number, product: IExcursionProduct) => {
        const sharedCapacity = product?.capacities as IPaxCapacity;

        if (sharedCapacity?.remaining) {
          return total + sharedCapacity.remaining;
        }
        return total;
      },
      0
    );

    return { totalAvailability };
  }

  // Check Unlimited Capacity
  const isTotalAvailabilityUnlimited = products?.every(
    (product: IExcursionProduct) =>
      product?.capacities?.capacity_type === "unlimited"
  );

  if (isTotalAvailabilityUnlimited) {
    totalAvailability = UNLIMITED_CAPACITY;

    return { totalAvailability };
  }

  // Check Language Capacity
  const isTotalAvailabilityLanguage = products?.every(
    (product: IExcursionProduct) =>
      product?.capacities?.capacity_type === "language"
  );

  if (isTotalAvailabilityLanguage) {
    const sharedCapacity = products?.[0]?.capacities as ILanguageCapacity;
    totalAvailability = sharedCapacity.en.remaining;

    return { totalAvailability };
  }

  return { totalAvailability };
};
