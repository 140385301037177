import { useMutation, useQueryClient } from "@tanstack/react-query";
import { store } from "WithProviders";
import axios from "axios";
import { getDinningUrl } from "config/configUtils";

type Props = {
  emailAddress: string;
};

type Response = {
  bookingReference: string;
  RequestId: null;
  Response: {
    ErrorCode: "OK";
    ErrorDescription: null;
    Tables: [
      {
        VacancyId: string;
        PersonId: number;
        MxpReservationId: number;
        Accepted: boolean;
        TableNumber: string;
        TableLocation: string;
        VenueName: string;
        VenueId: number;
        ErrorCode: null;
        ErrorDescription: null;
      }
    ];
  };
};

const usePostMxpZeroPrice = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, data, error } = useMutation<Response, Error, Props>({
    mutationKey: ["usePostMxpZeroPrice"],
    mutationFn: async ({ emailAddress }: Props) => {
      const URL = `${getDinningUrl()}/api/payments/zero-price/confirm`;

      const response = await axios.post(
        URL,
        {
          emailAddress,
        },
        {
          headers: {
            SessionId: store.getState().authentication.sessionId,
          },
        }
      );

      return response?.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["usePostMxpZeroPrice"],
      });
    },
    onError: (error) => {
      const msg = `Error executing API call "${URL}": ${error}`;
      console.error(msg);
      return msg;
    },
    gcTime: 0,
  });

  return {
    postMxpZeroPrice: mutateAsync,
    postMxpZeroPriceData: data,
    postMxpZeroPriceError: error,
  };
};

export default usePostMxpZeroPrice;
