export const titleTrue = "Cruise Control for TUI River Cruises";
export const titleFalse = "Cruise Control for Marella Cruises";
export const infoDescriptionTrue =
  "Welcome to your pre-cruise portal, where you can provide additional information before you travel with TUI River Cruises.";
export const infoDescriptionFalse =
  "Welcome to Cruise Control, your one-stop shop for everything you need before you travel with Marella Cruises.";
export const infoItemsTrue = [];
export const infoItemsFalse = [
  "Complete the online check-in process to access your travel documents.",
  "Print your luggage labels and boarding passes for your cruise.",
  "Book your excursions and onboard experiences.",
];
export const imageAltTextTrue = "TUI River Cruises experiences logo";
export const imageAltTextFalse = "Marella cruises experiences logo";
export const imageFileNameTrue = "login_image_river.png";
export const imageFileNameFalse = "login_image.png";

export const getTexts = (isRiver: boolean) => {
  return {
    title: isRiver ? titleTrue : titleFalse,
    infoDescription: isRiver ? infoDescriptionTrue : infoDescriptionFalse,
    infoItems: isRiver ? infoItemsTrue : infoItemsFalse,
    imageAltText: isRiver ? imageAltTextTrue : imageAltTextFalse,
    imageFileName: isRiver ? imageFileNameTrue : imageFileNameFalse,
  };
};
