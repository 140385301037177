import { useMutation } from "@tanstack/react-query";
import { onLoginSuccess } from "actions/authenticationActions";
import axios from "axios";
import { getHubUrl } from "config/configUtils";
import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";
import { loginAsAgencyResponse } from "mockedData/LoginAsAgency";
import { BookingTypes } from "modules/login/PassengerLoginForm";
import { isCruiseStartDateDenied } from "utils/dateTimeUtils";

type LoginParamsProps = {
  departureDate: string;
  bookingReference: string;
  lastName: string;
  bookingType?: BookingTypes;
  shouldLoginAsAgency: boolean;
};

type HeadersProp = {
  Accept: string;
  "Content-Type": string;
  SessionId?: string;
};

const headers: HeadersProp = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const useLoginByPassenger = () => {
  const sessionId = useAppSelector((state) => state.authentication.sessionId);
  const isAgency = useAppSelector((state) => state.agency.isAuthenticated);
  const agencySessionId = useAppSelector((state) => state.agency.sessionId);

  const dispatch = useAppDispatch();

  const { mutateAsync, isPending, error, isSuccess } = useMutation<
    any,
    Error,
    LoginParamsProps
  >({
    mutationFn: async ({
      departureDate,
      bookingReference,
      lastName,
      bookingType,
      shouldLoginAsAgency,
    }) => {
      if (isCruiseStartDateDenied(departureDate, isAgency)) {
        // Manually throw an error that will be caught by React Query
        throw new Error("Cruise start date is denied.");
      }

      if (isAgency && agencySessionId) {
        headers.SessionId = agencySessionId;
      } else if (sessionId) {
        headers.SessionId = sessionId;
      }

      const response = await axios.post(
        getHubUrl() + "authentication/loginByPassenger",
        {
          bookingReference,
          lastName,
          departureDate,
          bookingType,
        },
        { headers }
      );

      return {
        data: response.data,
        bookingType,
        shouldLoginAsAgency,
      };
    },
    onSuccess: ({ data, bookingType, shouldLoginAsAgency }) => {
      const isRiverUrl = bookingType === "RiverCruise";

      // Used only on TEST env for 'fake' agency login
      if (shouldLoginAsAgency) {
        dispatch({
          type: "SET_AGENCY_AUTHENTICATION",
          authentication: loginAsAgencyResponse,
        });
      }

      dispatch(onLoginSuccess(data, isRiverUrl));
    },
    onError: (error) => {
      console.error("Mutation error:", error);
      dispatch({
        type: "SET_ERROR",
        message: error?.message ?? "Unknown error occurred",
      });
    },
  });

  const postLoginByPassenger = async (params: LoginParamsProps) => {
    try {
      await mutateAsync(params);
    } catch (error) {
      // This catch is for any unhandled errors in mutationFn
      console.error("Failed to post login by passenger:", error);
      // You might want to handle this error further or rethrow it
    }
  };

  return {
    postLoginByPassenger,
    postLoginByPassengerIsPending: isPending,
    postLoginByPassengerError: error,
    postLoginByPassengerIsSuccess: isSuccess,
  };
};
