import { useState, useEffect, FC } from "react";
import { Route, RouteProps } from "react-router-dom";
import { logOutPassenger } from "../../actions/authenticationActions";
import useAppSelector from "../../hooks/useAppSelector";

type Props = RouteProps & {
  component: FC<any>;
};

const GuestRouteWithLogout: FC<Props> = ({ component: Component, ...rest }) => {
  const isAuthenticated = useAppSelector(
    (state) => state.authentication.isAuthenticated
  );

  const [checkFinished, setCheckFinished] = useState<boolean>(false);

  useEffect(() => {
    if (isAuthenticated) {
      logOutPassenger().then(() => {
        setCheckFinished(true);
      });
    } else {
      setCheckFinished(true);
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated || !checkFinished ? (
          <div>logging out...</div>
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default GuestRouteWithLogout;
