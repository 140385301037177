import { useEffect } from "react";
import { logoutAgency } from "../../actions/authenticationActions";
import { getHubBaseUrl } from "../../config/configUtils";
import useAppSelector from "../../hooks/useAppSelector";
import { useRouteMatch } from "react-router-dom";

interface IMatchState {
  forgetStore: any;
}

const AgencyLogout = () => {
  const match = useRouteMatch<IMatchState>();
  const isAgency = useAppSelector((state) => state.agency.isAuthenticated);

  useEffect(() => {
    logoutAgency(match.params.forgetStore);
  }, []);

  if (isAgency) {
    return (
      <div className="container mx-auto mt-8">
        Logging out. If this message is shown for more than a few seconds please
        reload the page.
      </div>
    );
  }

  return (
    <div className="container mx-auto mt-8">
      You have logged out of your Retail User session. Please visit the{" "}
      <a
        className="text-blue-dark underline"
        href={`${getHubBaseUrl()}authentication/SingleSignOnServlet`}
      >
        login page
      </a>{" "}
      to log in again.
    </div>
  );
};

export default AgencyLogout;
