import { buildTestId } from "utils/buildTestId";
import { priceWithTwoDecimals } from "../../utils/cartUtils";
import { FC } from "react";

type Props = {
  classes?: string;
  largePreText?: boolean;
  textBottom?: string;
  preText?: string;
  hidePoundSign?: boolean;
  price: string;
  largePostText?: boolean;
  postText?: string;
};

const Price: FC<Props> = ({
  classes,
  largePreText,
  textBottom,
  preText,
  hidePoundSign,
  price,
  largePostText,
  postText,
}) => {
  return (
    <div
      data-testid={buildTestId("container", "price")}
      className={"text-blue-dark" + (classes ? " " + classes : "")}
    >
      {preText && (
        <span
          data-testid={buildTestId("text", "price pre text")}
          className={
            (largePreText ? "text-lg" : "text-base") +
            (textBottom ? " align-bottom" : "")
          }
        >
          {`${preText} `}
        </span>
      )}

      {!hidePoundSign && (
        <span
          data-testid={buildTestId("text", "price pound sign")}
          className={"text-xl font-bold" + (textBottom ? " align-bottom" : "")}
        >
          £
        </span>
      )}

      <span
        data-testid={buildTestId("text", "price")}
        className={"text-4xl font-bold" + (textBottom ? " align-bottom" : "")}
      >
        {price === "N/A" ? "N/A" : priceWithTwoDecimals(price)}
      </span>

      <span
        data-testid={buildTestId("text", "price post text")}
        className={
          (largePostText ? "text-lg" : "text-base") +
          (textBottom ? " align-bottom" : "")
        }
      >
        {postText}
      </span>
    </div>
  );
};

export default Price;
