import axios from "axios";
import { getDinningUrl, isShipNameInMxpDiningMVP } from "config/configUtils";
import { IFeatureFlag } from "interfaces/FeatureFlags/IFeatureFlags";
import { IMxpAvailabilitySlot } from "interfaces/MXP/IMxpAvailabilitySlot";
import { getCruisesInfo } from "utils/cruiseUtils";
import { store } from "WithProviders";

export const getDiningCart = async () => {
  const sessionId = store.getState().authentication.sessionId;
  const isMxpActive = store
    .getState()
    // @ts-ignore
    .appConfig.featureFlags?.find(
      (flag: IFeatureFlag) => flag?.code === "IsMxpEnabled"
    )?.isActive;

  const cruisesInfo = getCruisesInfo(store.getState().cruises.cruiseDetails);

  if (
    !isMxpActive &&
    !isShipNameInMxpDiningMVP({
      shipName: cruisesInfo.shipName,
    })
  ) {
    const mxpNonPaidBookings: any[] = [];
    const mxpPaidBookings: any[] = [];
    return { mxpNonPaidBookings, mxpPaidBookings };
  }
  const URL = `${getDinningUrl()}/api/dining/carts`;

  const response = await axios
    .get(URL, {
      headers: {
        SessionId: sessionId,
      },
    })
    .catch((error) => {
      console.error("getDiningCart fn", error);
      return error;
    });

  const mxpNonPaidBookings =
    response?.data?.filter(
      (mxpDiningBooking: IMxpAvailabilitySlot) =>
        mxpDiningBooking?.status === "PREBOOK_OK"
    ) ?? [];

  const mxpPaidBookings =
    response?.data?.filter(
      (mxpDiningBooking: IMxpAvailabilitySlot) =>
        mxpDiningBooking?.status === "OK"
    ) ?? [];

  return {
    mxpNonPaidBookings,
    mxpPaidBookings,
  };
};
