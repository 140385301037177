import { useState, useEffect } from "react";
import axios from "axios";
import { getFromHub } from "../actions/networkActions";

const useGet = (urlOptions: any, defaultValue: any = {}) => {
  const [data, setData] = useState(defaultValue);

  // stringify the urlOptions in case it's an object to not trigger useEffect on every render
  const urlOptionsJsonString = JSON.stringify(urlOptions);

  useEffect(() => {
    const options = JSON.parse(urlOptionsJsonString);
    let isCancelled = false;

    const fetchData = async () => {
      try {
        const simplePath = typeof options === "string";
        const url = simplePath ? options : options.path;

        const isExternal =
          url.startsWith("http://") || url.startsWith("https://");

        const response = isExternal
          ? await axios.get(url)
          : await getFromHub(options);

        if (!isCancelled) {
          setData(isExternal ? response.data : response);
        }
      } catch (e) {
        console.error("error with use get", e);
      }
    };

    fetchData();

    return () => {
      isCancelled = true;
    };
  }, [urlOptionsJsonString]);

  return data;
};

export default useGet;
