import { useEffect } from "react";
import { usePreCruiseContext } from "../context/PreCruiseContext";
import { getFromHub } from "../actions/networkActions";
import { IPreCruisePassenger } from "interfaces/Common/IPassenger";

const usePreCruisePassengers = () => {
  const { preCruiseInfo, setPreCruiseInfo } = usePreCruiseContext();
  useEffect(() => {
    if (preCruiseInfo?.passengers) {
      return;
    }

    let isCancelled = false;

    const fetchData = async () => {
      const response = await getFromHub("passengers/all");
      if (!isCancelled) {
        setPreCruiseInfo({
          passengers: response.map((passenger: IPreCruisePassenger) => ({
            ...passenger,
            passengerId: passenger.id,
          })),
        });
      }
    };

    fetchData();

    return () => {
      isCancelled = true;
    };
  }, []);

  return preCruiseInfo?.passengers;
};

export default usePreCruisePassengers;
