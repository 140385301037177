import { FC } from "react";
import { useSurveyContext } from "../../../context/SurveyContext";
import InfoBox from "../InfoBox/InfoBox";

type Props = {
  question: any;
  activePart: any;
  validate: (question: any, answer: any) => void;
};

const TextAreaQuestion: FC<Props> = ({ question, activePart, validate }) => {
  const {
    survey: { answers },
    setAnswer,
  } = useSurveyContext();
  const answer = answers[question.id] || "";

  const isShown = question.part === activePart;

  return (
    <div
      className={isShown ? "" : "hidden"}
      onBlur={() => validate(question, answer)}
    >
      <label className="text-lg text-grey-darker">
        {question.questionText}
        <br />
        <textarea
          className="w-full xl:w-1/2 h-30 border mt-2 text-base"
          value={answer}
          onChange={(event) => setAnswer(question.id, event.target.value)}
        />
      </label>

      {question.answerInfo && answer && (
        <InfoBox
          classes="mt-6 md:inline-block"
          infoText={question.answerInfo}
        />
      )}
    </div>
  );
};

export default TextAreaQuestion;
