import { FC } from "react";
import ActionButton from "components/Buttons/ActionButton";

type Props = {
  actionButtonText: string;
  actionDisabled: boolean;
  cancelLabel?: string;
  onCancel?: () => void;
  onActionClick: () => void;
};

const ModalFooter: FC<Props> = ({
  onCancel,
  cancelLabel = "Cancel",
  actionDisabled,
  actionButtonText,
  onActionClick,
}) => {
  return (
    <div className="p-4 flex justify-end">
      {onCancel && (
        <button
          className="cursor-pointer underline text-blue mr-4"
          onClick={onCancel}
        >
          {cancelLabel}
        </button>
      )}

      <ActionButton
        classes="px-4"
        disabled={actionDisabled}
        buttonText={actionButtonText}
        onClick={onActionClick}
      />
    </div>
  );
};

export default ModalFooter;
